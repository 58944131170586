<div class="box-modal">
  <div class="modal-head">
    <div class="col">
      <h1></h1>
    </div>
  </div>
  <div class="container">
    <div class="row modal-confirm">
      <div class="col text-center">
        <p>{{message}}</p>
      </div>
    </div>
    <div class="row button-confirm">
      <div class="col text-center">
        <button class="btn btn-primary" (click)="cerrar()" >Cerrar</button>
      </div>
    </div>
  </div>
</div>

