<div id="div5" class="details3-1 vertical-scroll">
    <div class="subtitle">
        <i class="fas fa-history" aria-hidden="true"></i> Historial de acciones
    </div>
    <button class="btn-details3 close-details1" (click)="bsModalRef.hide()">
        <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <div class="title_doc">
        Actividad {{idActividad}}
    </div>
    <div class="see-all"> 
        <a [queryParams]="{uuidActividad: uuidActividad, idActividad: idActividad}"
        (click)="bsModalRef.hide()" routerLink="/web/gestion-historial" style="color:#6979D4 !important;">Ver todo
        </a> </div>
    <div class="historial-box" *ngFor="let accion of listadoHistorialActividades">
        <div class="historial-from">
            <div class="historial-from-left"><i class="fas fa-thumbs-up ico-historial" aria-hidden="true"></i></div>
            <div class="historial-data">
                <span class="historial-date-hour">{{accion.fechaCreacion + 'Z' | date: 'dd/MM/yyyy HH:mm'}}</span> <br>
                {{accion.usuario.nombre + ' ' + accion.usuario.apellido}}<br>
                {{accion.usuario.rol.nombre}}
            </div>
        </div>
        <div class="historial-action">
            <div class="historial-data">
                <strong> {{accion.accion}}</strong><br>
            </div>
        </div>
    </div>

</div>