import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalMcEliminarComponent } from '../modals/modal-mc-eliminar/modal-mc-eliminar.component';
import { ModalMcSubirarchivoComponent } from '../modals/modal-mc-subirarchivo/modal-mc-subirarchivo.component';
import { ModalMcVerarchivosComponent } from '../modals/modal-mc-verarchivos/modal-mc-verarchivos.component';
import { ModalMcSubirfotosComponent } from '../modals/modal-mc-subirfotos/modal-mc-subirfotos.component';
import { ActivatedRoute } from '@angular/router';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FotoImplementacionDTO } from 'src/dtos/gestion/fotoImplementacion.dto';

@Component({
  selector: 'app-fotos-implementacion5s',
  templateUrl: './fotos-implementacion5s.component.html',
  styleUrls: ['./fotos-implementacion5s.component.scss']
})
export class FotosImplementacion5sComponent {

  uuid: string;
  listadoFotosImplementacion: FotoImplementacionDTO[] = [];
  page: number = 1;
  pageSize: number = 10;
  busqueda = new FormControl('');
  formCtrlSub: Subscription;
  filtros: any;
  viewFilter: boolean = false;
  totalRegister: number = 0;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }
  
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      this.initFilters();
      this.getBusquedaPaginadaImplementacion();
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false)
        this.listadoFotosImplementacion = [];
        this.getBusquedaPaginadaImplementacion();
      }
    });
  }

  getBusquedaPaginadaImplementacion() {
    this.apiGestion.getBusquedaPaginadaImplementacion(this.page -1, this.pageSize, this.busqueda.value).subscribe((resp) => {
      this.listadoFotosImplementacion = resp.data.content;
      console.log(this.listadoFotosImplementacion);
    }, (error) => {
      this.toastr.error('Error al obtener listado fotos implementacion');
    })
  }

  initFilters(initData: boolean = true) {
    this.page = 1;
    this.filtros = {
      nombre: null,
      fechaCreacion: null,
      uuidArea: null,
      uuidSubarea: null,
      uuidSitio: null
    };

    if (initData) {
      // Post filtros paginado
      this.postImplementacionPaginadoFiltro();
    }
  }

  filterMC(event: any) {
    this.page = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postImplementacionPaginadoFiltro();
  }

  postImplementacionPaginadoFiltro() {
    this.busqueda.setValue(null);

    const dataRequest: any = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      uuidArea: this.filtros.uuidArea,
      uuidSubArea: this.filtros.uuidSubArea,
      uuidSitio: this.filtros.uuidSitio,
      nombre: this.filtros.nombre,
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
      // Post listado paginado filtrado
    
      this.apiGestion.postObtenerImplementacionFiltro(this.page - 1, this.pageSize, dataRequest).subscribe(
        (response) => {
          console.log(response);
          this.listadoFotosImplementacion = response.data.content;
          this.page = response.data.pageable.pageNumber + 1;
          this.totalRegister = response.data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  obtenerMesEnPalabras(fecha: string): string {
    const fechaDate = new Date(fecha);
    const mesEnPalabras = this.datePipe.transform(fechaDate, 'MMMM');
    return this.capitalize(mesEnPalabras);
  }

  capitalize(texto: string): string {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  }

  descargarAdjunto(url: string, nombre: string): void {
    const link = document.createElement('a');
    link.download = nombre;
    link.href = url;
    link.click();
    link.remove();
  }
  
  descargarListadoAdjuntos(implementacion: FotoImplementacionDTO) {
    implementacion.adjunto.forEach((adjunto, index) => {
      setTimeout(() => {
        this.descargarAdjunto(adjunto.url, adjunto.nombreArchivo);
      }, index * 1000); // 1000 ms = 1 segundo
    });
  }

  openModalMcEliminar(archivo: FotoImplementacionDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalMcEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: archivo.uuidImplementacion,
        nombre: archivo.nombre,
        tipo: 'implementacion'
      },
      class: "modal-dialog-centered",
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  openModalMcSubirarchivo() {
    const modalCrear: BsModalRef = this.modalService.show(ModalMcSubirarchivoComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuidMetodologia: this.uuid,
        tipo: 'implementacion'
      },
      class: "modal-dialog-centered",
    });

    this.cerrarModalCargaData(modalCrear);
  }

  openModalMcVerarchivos(implementacion: any) {
    this.modalService.show(ModalMcVerarchivosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        detalleImplementacion: implementacion
      },
      class: "modal-dialog-centered modal-lg",
    });
  }

  openModalMcSubirfotos() {
    this.modalService.show(ModalMcSubirfotosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
    });
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.getBusquedaPaginadaImplementacion();
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
