<div class="loader-container" [ngStyle]="{'visibility': loaderPaginado ? 'visible' : 'hidden'}">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-wrench ico-page" aria-hidden="true"></i><span class="title1">Usuarios</span>
        </div>
    </div>
    <div class="search-mantenedor">
        <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda" autocomplete="off" />
        <i class="fas fa-search ico-input-search" aria-hidden="true"></i>
    </div>
    <div class="section-page">
        <div class="table" [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}">
            <div class="table-responsive">
                <table id="example" class="display" style="width:100%">
                    <caption>Mantenedor usuarios</caption>
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Apellido</th>
                            <th scope="col">Email</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Rol</th>
                            <th scope="col">Área</th>
                            <th scope="col">Empresa</th>
                            <th scope="col" style="word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lpu of listadoPagUsuarios">
                            <td>{{lpu.nombre}}</td>
                            <td>{{lpu.apellido}}</td>
                            <td>{{lpu.email}}</td>
                            <td>{{lpu.cargo}}</td>
                            <td>{{lpu.rol?.nombre}}</td>
                            <td>{{lpu.area}}</td>
                            <td>{{lpu.filial.nombre}}</td>
                            <td class=" text-nowrap">
                                <button type="button" class="btn-ico-tool" (click)="openModalUsuariosEditar(lpu)">
                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Pagination-->
            <div class="row mt-3" *ngIf="totalRegisterUsuarios > pageSize"
                [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}">
                <div class="col text-right">
                    <div class="pagination">
                        <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                            [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                            [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}"
                            [totalItems]="totalRegisterUsuarios" (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>