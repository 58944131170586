import { Component, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalCCrearComponent } from './modals/modal-c-crear/modal-c-crear.component';
import { ModalCEditarComponent } from './modals/modal-c-editar/modal-c-editar.component';
import { ModalCHabilitarComponent } from './modals/modal-c-habilitar/modal-c-habilitar.component';
import { ModalCDeshabilitarComponent } from './modals/modal-c-deshabilitar/modal-c-deshabilitar.component';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { CategoriaDTO } from 'src/dtos/gestion/categoria.dto';
import { GetCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getCategoriasMantenedor.response.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { GetListadoPaginadoCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoCategoriasMantenedor.response.dto';
import { SubCategoriaDosDTO } from 'src/dtos/gestion/sub-categoria-dos.dto';
import { SubCategoriaUnoDTO } from 'src/dtos/gestion/sub-categoria-uno.dto';
import { GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoSubcategoriasUnoMantenedor.response.dto';

@Component({
  selector: 'app-mantenedor-categorias',
  templateUrl: './mantenedor-categorias.component.html',
  styleUrls: ['./mantenedor-categorias.component.scss']
})
export class MantenedorCategoriasComponent {

  totalRegister: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  indexSelected: number = 0;
  busqueda: string = '';

  activeTab: string = 'categoria';

  loaderPaginado: boolean = false;

  listadoCategorias: CategoriaDTO[] = [];
  listadoSubCategoriasUno: SubCategoriaUnoDTO[] = [];
  listadoSubCategoriasDos: SubCategoriaDosDTO[] = [];


  constructor(
    private modalService: BsModalService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) { }
  
  ngOnInit(): void {
    this.onTabChange();
  }

  onTabChange() {
    this.currentPage = 1;
    this.busqueda = '';
    this.totalRegister = 0;
    this.getListados();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getListados();
  }

  getListados() {
    if (this.activeTab === 'categoria')
      this.getListadoPaginadoCategorias();
    if (this.activeTab === 'sub-categoria1') 
      this.getListadoSubCategoriasUno();
    if (this.activeTab === 'sub-categoria2')
      this.getListadoSubCategoriasDos();
  }

  getListadoPaginadoCategorias() {
    this.listadoCategorias = [];
    this.loaderPaginado = true;
    this.apiGestion.getCategoriasListadoPaginado(this.currentPage -1, this.pageSize, this.busqueda).subscribe(
      (resp) => this.getCategoriasSuccess(resp),
      (error) => this.getCategoriasError(error));
  }


  getCategoriasSuccess(resp: GetListadoPaginadoCategoriasMantenedorResponseDTO) {
    this.listadoCategorias = resp.data.content;
    this.totalRegister = resp.data.totalElements;
    this.loaderPaginado = false;
  }

  getCategoriasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_CATEGORIAS;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  getListadoSubCategoriasUno() {
    this.listadoSubCategoriasUno = []
    this.loaderPaginado = true;
    this.apiGestion.getListadoPaginadoSubCategoriaUno(this.currentPage -1, this.pageSize, this.busqueda).subscribe(
      (resp) => this.getSubCategoriasUnoSuccess(resp),
      (error) => this.getSubCategoriasUnoError(error)
    );
  }

  getSubCategoriasUnoSuccess(resp: GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO) {
    this.listadoSubCategoriasUno = resp.data.content;
    this.totalRegister = resp.data.totalElements;
    this.loaderPaginado = false;
  }

  getSubCategoriasUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_UNO;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  getListadoSubCategoriasDos() {
    this.listadoSubCategoriasDos = []
    this.loaderPaginado = true;
    this.apiGestion.getListadoPaginadoSubCategoriaDos(this.currentPage -1, this.pageSize, this.busqueda).subscribe(
      (resp) => this.getSubCategoriasDosSuccess(resp),
      (error) => this.getSubCategoriasDosError(error)
    );
  }

  getSubCategoriasDosSuccess(resp: GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO) {
    this.listadoSubCategoriasDos = resp.data.content;
    this.totalRegister = resp.data.totalElements;
    this.loaderPaginado = false;
  }

  getSubCategoriasDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_UNO;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  openModalCCrear(tabName: string) {
    const modalCrear: BsModalRef = this.modalService.show(ModalCCrearComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        tabName: tabName
      }
    });
    this.cerrarModalCargaData(modalCrear);
  }
  openModalCEditar(tabName: string, categoria?: CategoriaDTO,
    subcategoriaUno?: SubCategoriaUnoDTO, subcategoriaDos?: SubCategoriaDosDTO) {
    
    let cat: any;
    if (categoria)
      cat = categoria;
    if (subcategoriaUno)
      cat = subcategoriaUno
    if (subcategoriaDos)
      cat = subcategoriaDos
    
    const modalEditar: BsModalRef = this.modalService.show(ModalCEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState:{
        tabName: tabName,
        cat: cat,
      }
    });
    this.cerrarModalCargaData(modalEditar);
  }
  openModalCHabilitar(tabName: string, uuid: string, nombre: string) {
    const modalHabilitar: BsModalRef = this.modalService.show(ModalCHabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: uuid,
        nombre: nombre,
        tabName: tabName
      }
    });
    this.cerrarModalCargaData(modalHabilitar);
  }
  openModalCDeshabilitar(tabName: string, uuid: string, nombre: string) {
    const modalDeshabilitar: BsModalRef = this.modalService.show(ModalCDeshabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: uuid,
        nombre: nombre,
        tabName: tabName
      }
    });
    this.cerrarModalCargaData(modalDeshabilitar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.getListados();
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
