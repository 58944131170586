import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { ApiService } from 'src/app/services/api.service';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { EditarActividadRequestDTO } from 'src/dtos/request/gestion/editarActividad.request.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetDetalleActividadResponseDTO } from 'src/dtos/response/gestion/getDetalleActividad.response.dto';
import { PutEditarActividadResponseDTO } from 'src/dtos/response/gestion/putEditarActividad.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';

@Component({
  selector: 'app-modal-gestion-editar',
  templateUrl: './modal-gestion-editar.component.html',
  styleUrls: ['./modal-gestion-editar.component.scss']
})
export class ModalGestionEditarComponent {
  uuid: string;

  loader: boolean = false;
  loaderArea: boolean = false;
  loaderSubarea: boolean = false;
  loaderSitio: boolean = false;
  detalleActividad: ActividadDTO;
  editarActividadForm: FormGroup;
  listaAreas: AreaDTO[] = [];
  listaSubareas: SubareaDTO[] = [];
  listaSitios: SitiosDTO[] = [];

  btnHabilitado: boolean = false;
  onClose: Subject<string>;


  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public bsModalRef: BsModalRef,
    private apiGestion: ApiGestionService,
    private apiMantenedor: ApiMantenedorService
  ) { }
  

  ngOnInit() {
    this.onClose = new Subject();
    this.getDetalleActividad();
    
  }

  initForm() {
    if (this.detalleActividad.asignacion) {
      this.editarActividadForm = this.formBuilder.group({
        uuidArea: new FormControl(this.detalleActividad.asignacion.area.uuid, [Validators.required]),
        uuidSubarea: new FormControl(this.detalleActividad.asignacion?.subArea?.uuid, [Validators.required]),
        uuidSitio: new FormControl(this.detalleActividad.asignacion?.sitio?.uuid, [Validators.required]),
        extensionPlazo: new FormControl(0),
      })
    }
    else {
      this.editarActividadForm = this.formBuilder.group({
        uuidArea: new FormControl(null, [Validators.required]),
        uuidSubarea: new FormControl(null, [Validators.required]),
        uuidSitio: new FormControl(null, [Validators.required]),
        extensionPlazo: new FormControl(0),
      })
    }
    
    this.getListadoAreas();
    this.getListadoSubAreas();
    this.getListadoSitios();
  }

  getDetalleActividad() {
    this.loader = true;
    this.apiGestion.getDetalleActividad(this.uuid).subscribe((resp) => this.getDetalleActividadSuccess(resp),
      (error) => this.getDetalleActividadError(error));
  }

  getDetalleActividadSuccess(resp: GetDetalleActividadResponseDTO) {
    this.detalleActividad = resp.data;
    this.initForm();
    this.loader = false;
  }

  getDetalleActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_ACTIVIDAD;
    this.toastrService.error(msg);
    this.loader = false;
  }

  getListadoAreas() {
    this.loaderArea = true;

    this.listaAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listaAreas = resp.data.filter(area => area.habilitado || area.uuid === this.detalleActividad.asignacion.area.uuid);
    this.loaderArea = false;
  }

  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.toastrService.error(msg);
    this.loaderArea = false;
  }

  getListadoSubAreas(change: boolean = false) {
    this.loaderSubarea = true;
    if (change) {
      this.editarActividadForm.patchValue({ uuidSubarea: null, uuidSitio: null });
    }
    this.listaSubareas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor(this.editarActividadForm.controls.uuidArea.value).subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listaSubareas = resp.data.filter(subArea => subArea.habilitado || subArea.uuid === this.detalleActividad.asignacion.subArea.uuid);
    this.loaderSubarea = false;
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastrService.error(msg);
  }

  getListadoSitios(change: boolean = false) {
    this.loaderSitio = true;
    if (change) {
      this.editarActividadForm.patchValue({ uuidSitio: null });
    }
    this.listaSitios = [];
    this.apiMantenedor.getListadoSitiosMantenedor(this.editarActividadForm.controls.uuidSubarea.value).subscribe((resp) => this.getListadoSitiosSuccess(resp),
    (error) => this.getListadoSitiosError(error))
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listaSitios = resp.data.filter(sitio => sitio.habilitado || sitio.uuid === this.detalleActividad.asignacion.sitio.uuid);
    this.loaderSitio = false;
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastrService.error(msg);
    this.loaderSitio = false;
  }

  editarActividad() {
    if (this.editarActividadForm.valid) {
      const form = this.editarActividadForm.value;
      let data = new EditarActividadRequestDTO();
      data.extensionPlazo = form.extensionPlazo;
      data.uuidArea = form.uuidArea;
      data.uuidSitio = form.uuidSitio;
      data.uuidSubArea = form.uuidSubarea;
      this.apiGestion.putEditarActividad(this.uuid, data).subscribe((resp) => this.putEditarActividadSuccess(resp),
        (error) => this.putEditarActividadError(error));
    }
    else {
      this.validateAllFormFields(this.editarActividadForm);
    }
  }

  putEditarActividadSuccess(resp: PutEditarActividadResponseDTO) {
    this.toastrService.success(AppMessage.SUCCESS_EDITAR_ACTIVIDAD);
    this.btnHabilitado = false;
    this.onClose.next('EDITAR');
  }

  putEditarActividadError(error: HttpErrorResponse) {
    this.btnHabilitado = false;
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_ACTIVIDAD;
    this.toastrService.error(msg);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {            
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

 }
