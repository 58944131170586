import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { AlertService } from 'src/app/services/alert.service';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { Base64Util } from 'src/app/util/base64.util';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { DesviacionDTO } from 'src/dtos/mantenedor/desviacion.dto';
import { MaterialDTO } from 'src/dtos/mantenedor/material.dto';
import { OrigenDTO } from 'src/dtos/mantenedor/origen.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { TipoPedidoDTO } from 'src/dtos/mantenedor/tipoPedido.dto';
import { TipoReclamoDTO } from 'src/dtos/mantenedor/tipoReclamo.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoDesviacionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDesviaciones.response.dto';
import { GetListadoMaterialesResponseDTO } from 'src/dtos/response/mantenedor/getListadoMateriales.response.dto';
import { GetListadoOrigenResponseDTO } from 'src/dtos/response/mantenedor/getListadoOrigen.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { GetListadoTipoPedidoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoPedido.response.dto';
import { GetListadoTipoReclamoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoReclamo.response.dto';
import { PostIngresarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postIngresarReclamo.response.dto';

@Component({
  selector: 'app-reclamos-ingresar',
  templateUrl: './reclamos-ingresar.component.html',
  styleUrls: ['./reclamos-ingresar.component.scss']
})
export class ReclamosIngresarComponent {
  @ViewChild(NgSelectComponent, { static: false }) ngselect: NgSelectComponent;

  crearForm: FormGroup;

  blobFile: Blob[] = [];

  activarDeshacer: boolean = false;
  terminos: boolean = false;

  adjuntoRemovido: any;

  listadoTipoReclamos: TipoReclamoDTO[] = [];
  listadoTipoPedidos: TipoPedidoDTO[] = [];
  listadoOrigen: OrigenDTO[] = [];
  listadoDesviaciones: DesviacionDTO[] = [];
  listaSimpleAreas: AreaDTO[] = [];
  listaSimpleAreasAsignacion: AreaDTO[] = [];
  listaSimpleSubareasLugar: SubareaDTO[] = [];
  listaSimpleSubareasAsignacion: SubareaDTO[] = [];
  listadoSitioLugar: SitiosDTO[] = [];
  listadoSitioAsignacion: SitiosDTO[] = [];
  listadoMaterial: MaterialDTO[] = [];
  disabledBtn: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private apiServiceMantenedor: ApiMantenedorService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.crearForm = this.formBuilder.group({
      tipoReclamo: new FormControl(null, [Validators.required]),
      tipoPedido: new FormControl(null, [Validators.required]),
      origen: new FormControl(null, [Validators.required]),
      numeroDoc: new FormControl(null, [Validators.required]),
      materiales: new FormArray([this.initialMaterial()]),
      detalles: new FormGroup({
        detalleExtendido: new FormControl(null, [Validators.required]),
        file: new FormControl(null),
        filesSource: new FormArray([]),
        impactoDesviacion: new FormControl(null, [Validators.required])
      }),
      lugarHallazgo: new FormGroup({
        area: new FormControl(null, [Validators.required]),
        subarea: new FormControl(null, [Validators.required]),
        sitio: new FormControl(null, [Validators.required]),
        ubicacion: new FormControl(null)
      }),
      asignacionHallazgo: new FormGroup({
        area: new FormControl(null),
        subarea: new FormControl(null),
        sitio: new FormControl(null)
      })
    });
    this.getListadoTipoReclamoMantenedor();
    this.getListadoTipoPedidoMantenedor();
    this.getListadoOrigenesMantenedor();
    this.getListadoDesviacionMantenedor();
    this.getListadoAreas();
    this.getListadoAreasReclamos();
  }

  initialMaterial() {
    return new FormGroup({
      numeroParte: new FormControl(null, [Validators.required]),
      cantidad: new FormControl(null, [Validators.required]),
      descripcion: new FormControl(null, [Validators.required]),
      detalle: new FormControl(null, [Validators.required]),
    });
  }

  getMateriales() {
    return ((this.crearForm.get('materiales') as FormArray).controls as FormGroup[]);
  }

  addMaterial() {
    this.listadoMaterial = [];
    (this.crearForm.get('materiales') as FormArray).insert(0, this.initialMaterial());
  }

  removeMaterial(i: number) {
    (this.crearForm.get('materiales') as FormArray).removeAt(i);
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(fileReader.result));
      };

      fileReader.onloadend = async () => {
        file['url'] = fileReader.result.toString().includes('image') ? fileReader.result.toString() : this.obtenerUrl(file);
      }

      fileReader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.crearForm.get('detalles.filesSource') as FormArray).push(new FormControl(file));
      this.crearForm.get('detalles.file').patchValue(null);
    }
  }

  removeAdjunto(i: number) {
    this.activarDeshacer = true;
    this.adjuntoRemovido = {
      blobFile: this.blobFile[i],
      filesSource: this.getAdjuntos()[i].value
    };
    (this.crearForm.get('detalles.filesSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }

  getAdjuntos() {
    return (this.crearForm.get('detalles.filesSource') as FormArray).controls;
  }

  deshacer() {
    this.activarDeshacer = false;
    (this.crearForm.get('detalles.filesSource') as FormArray).push(new FormControl(this.adjuntoRemovido.filesSource));
    this.blobFile.push(this.adjuntoRemovido.blobFile)
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
    }
    return url;
  }

  getListadoTipoReclamoMantenedor() {
    this.apiServiceMantenedor.getListadoTipoReclamoMantenedor().subscribe(
      (response: GetListadoTipoReclamoResponseDTO) => {
        this.listadoTipoReclamos = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_RECLAMOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoTipoPedidoMantenedor() {
    this.apiServiceMantenedor.getListadoTipoPedidoMantenedor().subscribe(
      (response: GetListadoTipoPedidoResponseDTO) => {
        this.listadoTipoPedidos = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_PEDIDOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoOrigenesMantenedor() {
    this.apiServiceMantenedor.getListadoOrigenesMantenedor().subscribe(
      (response: GetListadoOrigenResponseDTO) => {
        this.listadoOrigen = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_ORIGEN;
        this.toastr.error(msg);
      }
    );
  }

  getListadoDesviacionMantenedor() {
    this.apiServiceMantenedor.getListadoDesviacionMantenedor().subscribe(
      (response: GetListadoDesviacionesResponseDTO) => {
        this.listadoDesviaciones = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_DESVIACIONES;
        this.toastr.error(msg);
      }
    );
  }

  getListadoAreas() {
    this.apiServiceMantenedor.getListadoAreasReclamosMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleAreas = response.data.filter(area => area.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoAreasReclamos() {
    this.apiServiceMantenedor.getListadoAreasReclamosMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleAreasAsignacion = response.data.filter(area => area.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSubAreas(hallazgo: string, change: boolean = false) {
    const value = (hallazgo === 'lugar') ? this.crearForm.get('lugarHallazgo.area').value : this.crearForm.get('asignacionHallazgo.area').value;
    if (change) {
      this.crearForm.get((hallazgo === 'lugar') ? 'lugarHallazgo' : 'asignacionHallazgo').patchValue({ subarea: null, sitio: null });
    }

    this.apiServiceMantenedor.getListadoSubAreasReclamosMantenedor(value).subscribe(
      (response: GetListadoSubAreasResponseDTO) => {
        if (hallazgo === 'lugar') {
          this.listaSimpleSubareasLugar = response.data.filter(sub => sub.habilitado);
        } else {
          this.listaSimpleSubareasAsignacion = response.data.filter(sub => sub.habilitado);
        }
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSubAreasReclamos() {
    const value = this.crearForm.get('asignacionHallazgo.area').value;
    this.crearForm.get('asignacionHallazgo').patchValue({ subarea: null, sitio: null });
    this.apiServiceMantenedor.getListadoSubAreasReclamosMantenedor(value).subscribe(
      (response: GetListadoSubAreasResponseDTO) => {
        this.listaSimpleSubareasAsignacion = response.data.filter(sub => sub.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
    })
  }

  getListadoSitios(hallazgo: string, change: boolean = false) {
    const value = (hallazgo === 'lugar') ? this.crearForm.get('lugarHallazgo.subarea').value : this.crearForm.get('asignacionHallazgo.subarea').value;
    if (change) {
      this.crearForm.get((hallazgo === 'lugar') ? 'lugarHallazgo' : 'asignacionHallazgo').patchValue({ sitio: null });
    }
    this.apiServiceMantenedor.getListadoSitiosMantenedor(value).subscribe(
      (response: GetListadoSitiosResponseDTO) => {
        if (hallazgo === 'lugar') {
          this.listadoSitioLugar = response.data.filter(sitio => sitio.habilitado);
        } else {
          this.listadoSitioAsignacion = response.data.filter(sitio => sitio.habilitado);
        }
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
        this.toastr.error(msg);
      }
    );
  }

  asignarDescripcion(event: any, index: number) {
    const desc = this.listadoMaterial.find(lm => lm.numeroParte === event);
    this.getMateriales()[index].patchValue({ descripcion: desc ? desc.descripcion : null });
    if (desc) {
      this.getMateriales()[index].controls.descripcion.disable({ onlySelf: true });
      this.getMateriales()[index].controls.descripcion.markAsUntouched();
    } else {
      this.getMateriales()[index].controls.descripcion.enable({ onlySelf: true });
      this.listadoMaterial = [];
    }
  }

  getListadoMaterialesMantenedor(event: any, index: number, tipo: string) {
    let nombreMaterial = '';
    if (tipo === 'focus') {
      this.listadoMaterial = [];
      this.getMateriales()[index].patchValue({ numeroParte: null });
      this.getMateriales()[index].patchValue({ descripcion: null });
      this.getMateriales()[index].controls.descripcion.enable({ onlySelf: true });
    } else {
      nombreMaterial = event.term;
    }
    if (this.crearForm.controls.numeroDoc.value && nombreMaterial?.length > 3) {
      this.apiServiceMantenedor.getListadoMaterialesMantenedor(this.crearForm.controls.numeroDoc.value, nombreMaterial).subscribe(
        (response: GetListadoMaterialesResponseDTO) => {
          this.listadoMaterial = response.data;
          if (this.listadoMaterial.length === 0) {
            this.getMateriales()[index].patchValue({ numeroParte: nombreMaterial });
          }
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_MATERIALES;
          this.toastr.error(msg);
        }
      );
    }
  }

  ingresarReclamo() {
    if (this.crearForm.valid && this.terminos) {
      const value = this.crearForm.value;
      let formData = new FormData();
      formData.append('uuidTipoReclamo', value.tipoReclamo);
      formData.append('uuidTipoPedido', value.tipoPedido);
      formData.append('uuidOrigenReclamo', value.origen);
      formData.append('uuidImpactoDesviacion', value.detalles.impactoDesviacion);
      formData.append('numeroDocumento', value.numeroDoc);
      formData.append('detalleExtendido', value.detalles.detalleExtendido);
      formData.append('materiales', JSON.stringify(value.materiales));
      formData.append('hallazgo', JSON.stringify(value.lugarHallazgo, (indice, valor) => { if (valor !== null) { return valor; } }));
      formData.append('asignacion', JSON.stringify(value.asignacionHallazgo, (indice, valor) => { if (valor !== null) { return valor; } }));
  
      const nombreArchivos = (this.crearForm.get('detalles.filesSource') as FormArray).value;
      this.blobFile.forEach((file, index) => {
        formData.append('archivos[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      });


      if (!value.asignacionHallazgo.area && !value.asignacionHallazgo.subarea && !value.asignacionHallazgo.sitio) {
        this.alertService.showConfirm('Está a punto de ingresar un reclamo sin asignación. ¿Desea continuar?', () => {
          this.postIngresarReclamo(formData);
        });
      }
      else {
        this.postIngresarReclamo(formData);
      }
    }

    else {
      this.toastr.error('Complete todos los campos obligatorios', 'Faltan campos por rellenar');
      this.validateAllFormFields(this.crearForm);
    }
    
  }

  postIngresarReclamo(formData: FormData) {
    this.disabledBtn = true;
    this.apiServiceMantenedor.postCrearReclamo(formData).subscribe(
      (response: PostIngresarReclamoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_RECLAMO);
        this.disabledBtn = false;
        this.router.navigate(['web/reclamos']);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_RECLAMO;
        this.toastr.error(msg);
        this.disabledBtn = false;
      }
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
