import {BaseResponseDTO} from "./base.response.dto";


export class GeneralResponseDTO extends BaseResponseDTO {

  success: boolean;
  msg: string;
  data: any;

}
