<div class="container-fluid">
    <div class="loader-container" *ngIf="loader">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-thumbs-up ico-page" aria-hidden="true"></i>
            <span class="title1">Gestión de actividades</span>
        </div>
    </div>
    <div class="section-page">
        <div class="subtitle">Crear plan de acción</div>
    </div>
    <form [formGroup]="form">
        <div formArrayName="planes">
            <div class="section-page">
                <div *ngFor="let planForm of planes.controls; let i = index">
                    <div class="section-data" [formGroup]="planForm">
                        <div class="row1">
                            <div class="column3">
                                <div class="lab"><label for="name">Acción</label></div>
                                <div class="inp">
                                    <input required
                                        [ngClass]="{'border-error': planForm.controls['nombre'].touched && !planForm.controls['nombre'].valid}"
                                        formControlName="nombre" type="text" name="gestion-plan-accion"
                                        id="gestion-plan-accion" class="inp-modal" placeholder="Nombre acción" />
                                </div>
                            </div>
                            <div class="column3">
                                <div class="lab"><label for="name">Responsable</label></div>
                                <div class="inp">
                                    <input type="text" name="reclamos-origen" id="reclamos-origen" class="inp-modal"
                                        disabled="true" placeholder="{{user.nombre}} {{user.apellido}}">
                                </div>
                            </div>
                            <div class="column3">
                                <div class="lab"><label for="name">Fecha término</label></div>
                                <div class="inp"> <input required [minDate]="minDate"
                                        [ngClass]="{'border-error': planForm.controls['fechaTerminoPlan'].touched && !planForm.controls['fechaTerminoPlan'].valid}"
                                        formControlName="fechaTerminoPlan" type="text" placeholder="Seleccione fecha"
                                        class="inp-modal" bsDatepicker [bsConfig]="{ isAnimated: true }"><i aria-hidden="true"
                                        class="far fa-calendar-alt ico-input"></i>
                                </div>
                            </div>
                            <div class="column3" formGroupName="archivos">
                                <div class="lab"><label for="name">Adjuntar archivos</label></div>
                                <div class="inp">
                                    <label for="gestion-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                                    <input #fileInput type="file" formControlName="file" name="gestion-adjuntararchivo"
                                        id="gestion-adjuntararchivo" class="inp-modal" (change)="subirArchivo($event, i)"/>
                                    <i (click)="fileInput.click()" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                                </div>
                                <div *ngIf="getAdjuntos(i).length > 0" class="column-consult-7 vertical-scroll">
                                    <div class="lab-consult">Archivos adjuntos</div>
                                    <div class="back-img" ngxViewer
                                        *ngFor="let adjunto of getAdjuntos(i); let j = index">
                                        <div class="img-show"> <img [src]="adjunto.value.url" width="100%"
                                                alt="foto">
                                        </div>
                                        <div class="back-img-line">
                                            <i class="fas fa-times-circle red delete-archive" aria-hidden="true"
                                                (click)="removeAdjunto(i,j)" aria-hidden="true"></i>
                                            <a href="#" class="minimage-link">
                                                <div class="ico-image"><i class="fas fa-image"
                                                        aria-hidden="true"></i>
                                                </div>
                                                {{adjunto.value.name}}
                                            </a>
                                        </div>
                                    </div>
                             
                                </div>

                                <div *ngIf="deshacerEliminar" class="archive-back-delete"> <a (click)="deshacer()"
                                        class="link-archive-delete"><i class="fas fa-undo" aria-hidden="true"></i>
                                        Deshacer eliminar
                                        archivo "{{adjuntoRemovido.filesSource.name}}"</a></div>

                            </div>
                            <div class="column5">
                                <div class="lab"><label for="name">Comentarios</label></div>
                                <div class="inp"><textarea formControlName="comentarios" name="textarea" rows="5"
                                        cols="20" placeholder="Escriba un comentario..."></textarea></div>
                            </div>
                            <i *ngIf="i==0" (click)="addPlan()" class="fas fa-plus-circle add-material"
                                aria-hidden="true"></i> <br>
                            <i *ngIf="planes.controls.length > 1" (click)="deletePlan(i)"
                                class="fas fa-minus-circle delete-material" aria-hidden="true"></i>
                        </div>


                    </div>


                </div>
                

                <div class="section1">
                    <button (click)="regresar()" type="button"
                        class="button-black two-buttons">Cancelar</button>
                    <button (click)="crearPlanDeAccion()" type="button" class="btn-two two-buttons">Crear
                        plan</button>
                </div>

            </div>

        </div>


    </form>
</div>

<div class="full-height">&nbsp;</div>