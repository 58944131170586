import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AreaDTO } from 'src/dtos/gestion/area.dto';
import { CategoriaDTO } from 'src/dtos/gestion/categoria.dto';
import { EstadoActividadDTO } from 'src/dtos/gestion/estadoActividad.dto';
import { SitioDTO } from 'src/dtos/gestion/sitio.dto';
import { SubareaDTO } from 'src/dtos/gestion/subarea.dto';
import { UsuarioActividadDTO } from 'src/dtos/gestion/usuarioActividad.dto';
import { UsuarioDTO } from 'src/dtos/mantenedor/usuario.dto';
import { GetListadoCategoriasResponseDTO } from 'src/dtos/response/gestion/getListadoCategorias.response.dto';
import { GetListadoEstadosResponseDTO } from 'src/dtos/response/gestion/getListadoEstados.response.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { GetListadoUsuarioResponseDTO } from 'src/dtos/response/mantenedor/getListadoUsuarios.response.dto';

@Component({
  selector: 'app-gestion-filtros',
  templateUrl: './gestion-filtros.component.html',
  styleUrls: ['./gestion-filtros.component.scss']
})
export class GestionFiltrosComponent implements OnInit {

  viewFilter: boolean = false;
  listadoEstados: EstadoActividadDTO[] = [];
  listadoCategorias: CategoriaDTO[] = [];
  listadoUsuarioCreador: UsuarioDTO[] = [];
  listadoAreas: AreaDTO[] = [];
  listadoSubAreas: SubareaDTO[] = [];
  listadoSitios: SitioDTO[] = [];
  listadoUsuarioResponsable: UsuarioDTO[] = [];
  checkedExportar: boolean = false;

  filterForm: FormGroup;

  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();
  @Input() filtrosForm: any;
  @Output() filterRequest: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private apiGestion: ApiGestionService,
    private apiMantenedor: ApiMantenedorService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initFilters();
  }

  ngOnChanges() {
    this.initFilters();
    this.getListadoCreadores();
    this.getListadoAreas();
    this.getListadoCategorias();
    this.getListadoResponsables();
    this.getEstados();
  }

  initFilters() {
    this.filterForm = this.formBuilder.group({
      id: new FormControl(null),
      uuidEstado: new FormControl(null),
      uuidCategoria: new FormControl(null),
      uuidCreador: new FormControl(null),
      fechaVencimiento: new FormControl(this.filtrosForm.fechaVencimiento ? [new Date(moment(this.filtrosForm.fechaVencimiento[0]).format()), new Date(moment(this.filtrosForm.fechaVencimiento[1]).format())] : null),
      fechaCreacion: new FormControl(this.filtrosForm.fechaCreacion ? [new Date(moment(this.filtrosForm.fechaCreacion[0]).format()), new Date(moment(this.filtrosForm.fechaCreacion[1]).format())] : null),
      uuidArea: new FormControl(null),
      uuidSubArea: new FormControl(null),
      uuidSitio: new FormControl(null),
      uuidResponsable: new FormControl(null),
      exportar: new FormControl(false)
    });
  }

  hideShowFilter(event: boolean) {
    this.viewFilter = event;
  }

  cerrarFiltros() {
    this.cerrar.emit(false);
  }

  getEstados() {
    this.apiGestion.getListadoDeEstados().subscribe((resp) => this.getEstadosSuccess(resp),
      (error) => this.getEstadosError(error));
  }

  getEstadosSuccess(resp: GetListadoEstadosResponseDTO) {
    this.listadoEstados = resp.data;
    this.filterForm.patchValue({ uuidEstado: this.filtrosForm.uuidEstado });
  }

  getEstadosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_ESTADOS;
        this.toastr.error(msg);
  }

  getListadoCreadores() {
    this.apiMantenedor.getListadoUsuariosMantenedor().subscribe((resp) => this.getListadoCreadoresSuccess(resp),
    (error) => this.getListadoCreadoresError(error))
  }

  getListadoCreadoresSuccess(resp: GetListadoUsuarioResponseDTO) {
    this.listadoUsuarioCreador = resp.data;
    this.filterForm.patchValue({ uuidCreador: this.filtrosForm.uuidCreador });
  }

  getListadoCreadoresError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_USUARIOS;
        this.toastr.error(msg);
  }

  getListadoCategorias() {
    this.apiGestion.getListadoCategorias().subscribe((resp) => this.getListadoCategoriasSuccess(resp),
      (error) => this.getListadoCategoriasError(error));
  }

  getListadoCategoriasSuccess(resp: GetListadoCategoriasResponseDTO) {
    this.listadoCategorias = resp.data;
    this.filterForm.patchValue({ uuidCategoria: this.filtrosForm.uuidCategoria });
  }

  getListadoCategoriasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_CATEGORIAS;
        this.toastr.error(msg);
  }

  getListadoAreas() {
    this.listadoAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listadoAreas = resp.data;
    this.filterForm.patchValue({ uuidArea: this.filtrosForm.uuidArea });
  }

  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
  }

  getListadoSubAreas() {
    this.filterForm.patchValue({ uuidSubArea: null, uuidSitio: null });
    this.listadoSubAreas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor().subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listadoSubAreas = resp.data;
    this.filterForm.patchValue({ uuidSubArea: this.filtrosForm.uuidSubArea });
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
  }

  getListadoSitios() {
    this.filterForm.patchValue({ uuidSitio: null });
    this.listadoSitios = []
    this.apiMantenedor.getListadoSitiosMantenedor().subscribe((resp) => this.getListadoSitiosSuccess(resp),
      (error) => this.getListadoSitiosError(error));
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitios = resp.data;
    this.filterForm.patchValue({ uuidSitio: this.filtrosForm.uuidSitio });
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
        this.toastr.error(msg);
  }

  getListadoResponsables() {
    this.apiMantenedor.getListadoUsuariosMantenedor().subscribe((resp) => this.getListadoResponsablesSuccess(resp),
      (error) => this.getListadoResponsablesError(error));
  }

  getListadoResponsablesSuccess(resp: GetListadoUsuarioResponseDTO) {
    this.listadoUsuarioResponsable = resp.data;
    this.filterForm.patchValue({ uuidResponsable: this.filtrosForm.uuidResponsable });
  }

  getListadoResponsablesError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_USUARIOS;
        this.toastr.error(msg);
  }

  filtrar() {
    let aux: any = {};
    const form = this.filterForm.value;

    aux = {
      id: form.id,
      uuidEstado: form.uuidEstado,
      uuidCategoria: form.uuidCategoria,
      uuidCreador: form.uuidCreador,
      fechaVencimiento: form.fechaVencimiento ? [moment(form.fechaVencimiento[0]).format('YYYY-MM-DD'), moment(form.fechaVencimiento[1]).format('YYYY-MM-DD')] : null,
      fechaCreacion: form.fechaCreacion ? [moment(form.fechaCreacion[0]).format('YYYY-MM-DD'), moment(form.fechaCreacion[1]).format('YYYY-MM-DD')] : null,
      uuidArea: form.uuidArea,
      uuidSubArea: form.uuidSubArea,
      uuidSitio: form.uuidSitio,
      uuidResponsable: form.uuidResponsable,
      exportar: form.exportar
    }

    this.filterRequest.emit(aux);
  }

}
