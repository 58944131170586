import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { UsuarioModel } from 'src/models/usuario.model';
import { AppMessage } from '../app.message';
import { ApiMantenedorService } from '../services/api-mantenedor.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  menuActive: boolean = true;
  opcionesRol: any = [];
  user: UsuarioModel;
  listadoAreas: AreaDTO[] = [];

  private menu = [
    {
      rol: 'superadmin',
      opciones: ['home', 'documental', 'gestion', 'reclamo', 'servicio', 'mantenedor', 'areas']
    },
    {
      rol: 'customer service',
      opciones: ['home', 'documental', 'gestion', 'reclamo', 'servicio', 'areas']
    },
    {
      rol: 'general',
      opciones: ['home', 'documental', 'gestion', 'reclamo', 'areas']
    }
  ];

  constructor(
    private userService: UserService,
    private apiMantenedorService: ApiMantenedorService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.opcionesRol = this.menu.filter(opcR => opcR.rol === this.user.rol.nombre.toLowerCase());
    this.getListadoAreas();
  }

  habilitarOpcion(opcion: string) {
    return this.opcionesRol[0].opciones.some(opc => opc === opcion);
  }

  getListadoAreas() {
    this.apiMantenedorService.getListadoAreasMantenedor().subscribe((resp) => {
      this.listadoAreas = resp.data;
    },
      (error) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
      });
  }
}