import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { DeleteEliminarActividadResponseDTO } from 'src/dtos/response/gestion/deleteEliminarActividad.response.dto';
import { GetDetalleActividadResponseDTO } from 'src/dtos/response/gestion/getDetalleActividad.response.dto';  
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-gestion-eliminar',
  templateUrl: './modal-gestion-eliminar.component.html',
  styleUrls: ['./modal-gestion-eliminar.component.scss']
})
export class ModalGestionEliminarComponent {
  uuid: string;
  loader: boolean = false;
  detalleActividad: ActividadDTO;
  onClose: Subject<string>;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }
  
  ngOnInit() {
    this.onClose = new Subject();
    this.getDetalleActividad();
  }

  getDetalleActividad() {
    this.loader = true;
    this.apiGestion.getDetalleActividad(this.uuid).subscribe((resp) => this.getDetalleActividadSuccess(resp),
      (error) => this.getDetalleActividadError(error));
  }

  getDetalleActividadSuccess(resp: GetDetalleActividadResponseDTO) {
    this.detalleActividad = resp.data;
    this.loader = false;
  }

  getDetalleActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_ACTIVIDAD;
    this.toastr.error(msg);
    this.loader = false;
  }

  eliminarActividad() {
    this.loader = true;

    this.apiGestion.deleteEliminarActividad(this.uuid).subscribe((resp) => this.deleteEliminarActividadSuccess(resp),
      (error) => this.deleteEliminarActividadError(error));
  }

  deleteEliminarActividadSuccess(resp: DeleteEliminarActividadResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_ELIMINAR_ACTIVIDAD);
    this.loader = false;
    this.onClose.next('ELIMINAR');
  }

  deleteEliminarActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_ACTIVIDAD;
    this.toastr.error(msg);
  }
 }
