import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';

@Component({
  selector: 'app-modal-j-deshabilitar',
  templateUrl: './modal-j-deshabilitar.component.html',
  styleUrls: ['./modal-j-deshabilitar.component.scss']
})
export class ModalJDeshabilitarComponent {
  titleModal: string;
  nombreTipo: string;
  uuid: string;
  mensaje: string;

  cantidadAsociada: number;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.initMensaje();
  }

  initMensaje() {
    this.mensaje = 'Está apunto de deshabilitar ';
    this.mensaje += this.titleModal === 'Subárea' ? 'la ' : 'el ';
    this.mensaje += this.titleModal + ' <strong>' + this.nombreTipo + '</strong>.';
    if (this.cantidadAsociada > 0) {
      this.mensaje += this.titleModal.includes('roceso') ? ' Este ' : ' Esta ';
      this.mensaje += this.titleModal.toLowerCase() + ' tiene "' + this.cantidadAsociada;

      const subMensaje = {
        'Macroproceso': [
          ' proceso asociado", el cual se verá afectado por esta acción. Te recomendamos revisar el proceso antes de continuar.',
          ' procesos asociados", los cuales se verán afectados por esta acción. Te recomendamos revisar los procesos antes de continuar.'
        ],
        'Proceso': [
          ' área asociada", la cual se verá afectada por esta acción. Te recomendamos revisar el área antes de continuar.',
          ' áreas asociadas", las cuales se verán afectadas por esta acción. Te recomendamos revisar las áreas antes de continuar.'
        ],
        'Área': [
          ' subárea asociada", la cual se verá afectada por esta acción. Te recomendamos revisar la subárea antes de continuar.',
          ' subáreas asociadas", las cuales se verán afectadas por esta acción. Te recomendamos revisar las subáreas antes de continuar.'
        ],
        'Subárea': [
          ' sitio asociado", el cual se verá afectado por esta acción. Te recomendamos revisar el sitio antes de continuar.',
          ' sitios asociados", los cuales se verán afectados por esta acción. Te recomendamos revisar los sitios antes de continuar.'
        ]
      };
      this.mensaje += subMensaje[this.titleModal][this.cantidadAsociada === 1 ? 0 : 1];
    }

    this.mensaje += '<br><strong>¿Desea continuar?</strong>';
  }

  deshabilitarRegistro() {
    switch (this.titleModal) {
      case 'Macroproceso':
        this.deshabilitarMacroproceso();
        break;
      case 'Proceso':
        this.deshabilitarProceso();
        break;
      case 'Área':
        this.deshabilitarArea();
        break;
      case 'Subárea':
        this.deshabilitarSubarea();
        break;
      case 'Sitio':
        this.deshabilitarSitio();
        break;
      default:
        break;
    }
  }

  deshabilitarMacroproceso() {
    this.apiServiceMantenedor.putDeshabilitarMacroprocesoMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_MACROPROCESOS);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_MACROPROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  deshabilitarProceso() {
    this.apiServiceMantenedor.putDeshabilitarProcesoMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_PROCESOS);
        this.onClose.next('1');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_PROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  deshabilitarArea() {
    this.apiServiceMantenedor.putDeshabilitarAreaMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_AREAS);
        this.onClose.next('2');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  deshabilitarSubarea() {
    this.apiServiceMantenedor.putDeshabilitarSubareaMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_SUBAREAS);
        this.onClose.next('3');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_SUBAREAS;
        this.toastr.error(msg);
      }
    );
  }

  deshabilitarSitio() {
    this.apiServiceMantenedor.putDeshabilitarSitioMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_SITIOS);
        this.onClose.next('4');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_SITIOS;
        this.toastr.error(msg);
      }
    );
  }
}
