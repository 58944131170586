<div class="modal-right-content vertical-scroll" *ngIf="detalleDocumento">
    <div class="details-title">{{detalleDocumento.nombre}}</div>
    <button class="btn-details close-details" (click)="bsModalRef.hide()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div class="details-buttons">
        <div class="details-estado" [ngClass]="{
                'purple-back-light': estadoDetalle === 'ELIMINAR' || estadoDetalle === 'HISTORIAL',
                'yellow-back-light': detalleDocumento.estado === 'Por Vencer' && estadoDetalle === 'ACTUAL', 
                'green-back-light' : detalleDocumento.estado === 'Vigente' && estadoDetalle === 'ACTUAL', 
                'red-back-light' : detalleDocumento.estado === 'Vencido' && estadoDetalle === 'ACTUAL'}">
            <i class="fas ico-doc-details" aria-hidden="true"
                [ngClass]="{
                    'fa-times-circle purple': estadoDetalle === 'ELIMINAR' || estadoDetalle === 'HISTORIAL',
                    'fa-clock yellow': detalleDocumento.estado === 'Por Vencer' && estadoDetalle === 'ACTUAL', 
                    'fa-check-square green' : detalleDocumento.estado === 'Vigente' && estadoDetalle === 'ACTUAL', 
                    'fa-calendar-times red' : detalleDocumento.estado === 'Vencido' && estadoDetalle === 'ACTUAL'}"></i>
            <div class="name-status">{{detalleDocumento.estado}}</div>
        </div>
        <div class="details-version">v {{detalleDocumento.version}}</div>
        <div class="buttons-details" *ngIf="estadoDetalle === 'ACTUAL'">
            <button *ngIf="uuidUser === detalleDocumento.usuarioCreador.uuid" type="button" class="btn-ico-tool"
                tooltip="Editar" placement="top" (click)="openModalEditar()">
                <i class="fas fa-pen ico-action" aria-hidden="true"></i>
            </button>
            <button *ngIf="uuidUser !== detalleDocumento.usuarioCreador.uuid" type="button" class="btn-ico-tool"
                tooltip="Actualizar" placement="top" (click)="openModalActualizar()">
                <i class="fas fa-exchange-alt ico-action" aria-hidden="true"></i>
            </button>
            <button *ngIf="uuidUser === detalleDocumento.usuarioCreador.uuid" type="button" class="btn-ico-tool"
                tooltip="Eliminar" placement="top" (click)="openModalEliminar()">
                <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Tipo de documento</div>
        <div class="details-data2">{{detalleDocumento.tipoDocumento.nombre}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">ID</div>
        <div class="details-data2">{{detalleDocumento.idInterno}}</div>
    </div>
    <div class="details-data-row" *ngIf="detalleDocumento.tieneAdjunto">
        <div class="details-data1">Archivo Adjunto</div>
        <div class="details-data2">
            <div class="column-consult-7">
                <div class="back-img">
                    <div class="img-show">
                        <img *ngIf="detalleDocumento.archivoAdjunto.nombreArchivo.includes('.png') || detalleDocumento.archivoAdjunto.nombreArchivo.includes('.jpg') || detalleDocumento.archivoAdjunto.nombreArchivo.includes('.jpeg')"
                            [src]="detalleDocumento.archivoAdjunto?.url" ngxViewer width="145px" height="100px"
                            alt="Archivo adjunto">
                        <img *ngIf="!(detalleDocumento.archivoAdjunto.nombreArchivo.includes('.png') || detalleDocumento.archivoAdjunto.nombreArchivo.includes('.jpg') || detalleDocumento.archivoAdjunto.nombreArchivo.includes('.jpeg'))"
                            [src]="obtenerUrl()" width="145px" height="145px" alt="Archivo adjunto">
                    </div>
                    <div class="back-img-line">
                        <i *ngIf="!detalleDocumento.archivoAdjunto.nombreArchivo.includes('.pdf')"
                            (click)="getDescargarDocumento(detalleDocumento.archivoAdjunto?.url)"
                            class="fas fa-download blue download-archive" aria-hidden="true"></i>
                        <div class="ico-doc" *ngIf="detalleDocumento.archivoAdjunto.nombreArchivo.includes('.pdf')">
                            <i class="fas fa-file-pdf" aria-hidden="true"
                                (click)="getDescargarDocumento(detalleDocumento.archivoAdjunto?.url)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Creador</div>
        <div class="details-data2">
            {{detalleDocumento.usuarioCreador.nombre}} {{detalleDocumento.usuarioCreador.apellido}}
        </div>
    </div>
    <div class="details-data-row" *ngIf="estadoDetalle === 'ACTUAL'">
        <div class="details-data1">Fecha de creación</div>
        <div class="details-data2">{{detalleDocumento.fechaCreacion | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row" *ngIf="estadoDetalle === 'ELIMINAR'">
        <div class="details-data1">Fecha de eliminación</div>
        <div class="details-data2">{{detalleDocumento.fechaEliminacion | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row" *ngIf="estadoDetalle === 'HISTORIAL'">
        <div class="details-data1">Fecha de actualización</div>
        <div class="details-data2">{{detalleDocumento.fechaActualizacion | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Macroproceso</div>
        <div class="details-data2">{{detalleDocumento.macroProceso.nombre}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Proceso</div>
        <div class="details-data2">{{detalleDocumento.proceso.nombre}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Área</div>
        <div class="details-data2">{{detalleDocumento.area.nombre}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Subárea</div>
        <div class="details-data2">{{detalleDocumento.subArea.nombre}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Fecha documento</div>
        <div class="details-data2">{{detalleDocumento.fechaDocumento | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Fecha inicio vigencia</div>
        <div class="details-data2">{{detalleDocumento.fechaInicioVigencia | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row">
        <div class="details-data1">Fecha vencimiento</div>
        <div class="details-data2">{{detalleDocumento.fechaVencimiento | date: 'dd/MM/yyyy'}}</div>
    </div>
    <div class="details-data-row" *ngIf="detalleDocumento.documentosVinculados.length > 0">
        <div class="details-data1">Documentos vinculados</div>
        <div class="details-data2 vertical-scroll">
            <div (click)="openModalDetalle(dv.uuid)" *ngFor="let dv of detalleDocumento.documentosVinculados"
                class="doc-link">{{dv.nombre}}<br></div>
        </div>
    </div>
    <div class="details-data-row" *ngIf="estadoDetalle === 'ELIMINAR'">
        <div class="details-data1">Motivo eliminación</div>
        <div class="details-data2 vertical-scroll">{{detalleDocumento.motivo}}</div>
    </div>
</div>