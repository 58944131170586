import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  title: string = '';
  message: string = '';
  callback: () => {};

  constructor(public bsModalRef: BsModalRef) { }

  cerrar() {
    this.bsModalRef.hide();
    this.callback();
  }

}
