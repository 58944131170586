<div class="met-back-menu">
    <div class="met-col3">
        <label class="select-met">
            <select (change)="redirect5s($event.target.value)" name="menu-metodologia-5s" id="menu-metodologia-5s" class="select-modal">
                <option disabled selected value class="met-option" style="color: #fff !important;">Metodología 5S</option>
                <option *ngFor="let met5S of listadoMetodologias5S" [value]="met5S.uuid">{{met5S.nombre}}</option>
                
            </select>
        </label>
    </div>
    <div class="met-col3">
        <label class="select-met">
            <select (change)="redirect7d($event.target.value)" name="menu-metodologia-7d" id="menu-metodologia-5d" class="select-modal">
                <option disabled selected value class="met-option" style="color: #fff !important;">Metodología 7D desperdicios</option>
                <option *ngFor="let met7D of listadoMetodologias7D" [value]="met7D.uuid">{{met7D.nombre}}</option>
               
            </select>
        </label>
    </div>
    <div class="met-col3">
        <label class="select-met">
            <select (change)="redirectlss($event.target.value)" name="menu-metodologia-lss" id="menu-metodologia-lss" class="select-modal">
                <option disabled selected value class="met-option" style="color: #fff !important;">Metodología LSS</option>
                <option *ngFor="let metLSS of listadoMetodologiasLSS" [value]="metLSS.uuid">{{metLSS.nombre}}</option>
                
            </select>
        </label>
    </div>
</div>