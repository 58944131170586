<div class="modal-content">
    <div class="modal-header">
        <h5 *ngIf="tabName==='categoria'" class="modal-title" id="exampleModalLabel">Crear categoría
         <!--Título Sub-categoría 1: Crear Sub-categoría 1-->
         <!--Título Sub-categoría 2: Crear Sub-categoría 2-->
        </h5>
        <h5 *ngIf="tabName==='subcategoriauno'" class="modal-title" id="exampleModalLabel">Crear Sub-categoría 1</h5>
        <h5 *ngIf="tabName==='subcategoriados'" class="modal-title" id="exampleModalLabel">Crear Sub-categoría 2</h5>

        <button (click)="bsModalRef.hide()" type="button" class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="crearCategoriaForm" *ngIf="crearCategoriaForm">
            <div *ngIf="tabName === 'categoria'" class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp"><input type="text" name="categoria-nombre" id="categoria-nombre" class="inp-modal"
                        placeholder="Nombre" formControlName="nombre"/> </div>
            </div>
        </form>
       
        <form [formGroup]="crearSubcategoriaUnoForm" *ngIf="crearSubcategoriaUnoForm">
            <div *ngIf="tabName === 'subcategoriauno'" class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp"><input formControlName="nombre" type="text" name="sub-categoria1-nombre" id="sub-categoria1" class="inp-modal"
                        placeholder="Nombre" /> 
                    </div>
            </div>
            <div *ngIf="tabName === 'subcategoriauno'" class="field-half">
                <div class="lab"><label for="name">Asociar a Sub-categoría 2</label></div>
                <div class="inp"><label class="select">
                        <ng-select formControlName="subcategoriasDosAsignadas" placeholder="Seleccione" class="select" name="sub-categoria1-asociarsc2" id="sub-categoria1-asociarsc2"
                            class="select-modal" [multiple]="true">
                            <ng-option [value]="subcat2.uuid" *ngFor="let subcat2 of listadoSubcategoriasDos">{{subcat2.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
        </form>

        <form [formGroup]="crearSubcategoriaDosForm" *ngIf="crearSubcategoriaDosForm">
            <div *ngIf="tabName === 'subcategoriados'" class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp"><input formControlName="nombre" type="text" name="sub-categoria2-nombre" id="sub-categoria2" class="inp-modal"
                        placeholder="Nombre"/> </div>
            </div>
            <div *ngIf="tabName === 'subcategoriados'" class="field-half">
                <div class="lab"><label for="name">Asociar a Categoría</label></div>
                <div class="inp"><label class="select">
                        <ng-select placeholder="Seleccione" formControlName="categoriaAsignada" class="select" name="sub-categoria2-asociarc" id="sub-categoria2-asociarc" class="select-modal">
                            <ng-option [value]="cat.uuid" *ngFor="let cat of listadoCategorias">{{cat.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
            <div *ngIf="tabName === 'subcategoriados'" class="field-half">
                <div class="lab"><label for="name">Asociar a Sub-categoría 1</label></div>
                <div class="inp"><label class="select">
                        <ng-select placeholder="Seleccione" formControlName="subcategoriaUnoAsginada" class="select" name="sub-categoria2-asociarsc1" id="sub-categoria2-asociarsc1" class="select-modal">
                            <ng-option [value]="subcat1.uuid" *ngFor="let subcat1 of listadoSubcategoriasUno">{{subcat1.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button (click)="crear()" type="button" class="btn btn-modal">Crear</button>
    </div>
</div>
