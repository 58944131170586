import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { GetObtenerDetalleDocumentoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumento.response.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetListadoTipoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoTipoDocumento.response.dto';
import { GetListadoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoDocumentos.response.dto';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { TipoDocumentoDTO } from 'src/dtos/documental/tipoDocumento.dto';
import { ListaDocumentoDTO } from 'src/dtos/documental/listaDocumento.dto';
import { Base64Util } from 'src/app/util/base64.util';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MacroprocesoDTO } from 'src/dtos/documental/macroproceso.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesos.response.dto';
import { ProcesoDTO } from 'src/dtos/documental/proceso.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/documental/getListadoProcesos.response.dto';
import { AreaDTO } from 'src/dtos/documental/area.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { SubareaDTO } from 'src/dtos/documental/subarea.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/documental/getListadoSubAreas.response.dto';

@Component({
  selector: 'app-modal-editar',
  templateUrl: './modal-editar.component.html',
  styleUrls: ['./modal-editar.component.scss']
})
export class ModalEditarComponent implements OnInit {
  detalleDocumento: DocumentoDTO;
  listaSimpleMacroprocesos: MacroprocesoDTO[] = [];
  listaSimpleTipoDocumento: TipoDocumentoDTO[] = [];
  listaSimpleProcesos: ProcesoDTO[] = [];
  listaSimpleArea: AreaDTO[] = [];
  listaSimpleSubArea: SubareaDTO[] = [];
  listaSimpleDocumentosVicunlados: ListaDocumentoDTO[] = [];

  uuid: string;

  editForm: FormGroup;

  blobFile: Blob;

  onClose: Subject<string>;

  loader: boolean = false;
  loaderTipoDocumento: boolean = false;
  loaderMacroprocesos: boolean = false;
  loaderProcesos: boolean = false;
  loaderArea: boolean = false;
  loaderSubArea: boolean = false;
  loaderDocumentosVicunlados: boolean = false;
  idValido: boolean = true;
  disabledBtn: boolean = false;
  btnClean: boolean = false;

  datepickerConfig = {
    isAnimated: true,
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    showWeekNumbers: false
  };

  datepickerConfigVencimiento = {
    isAnimated: true,
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    minDate: new Date(),
    showWeekNumbers: false
  };

  constructor(
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.uuid) {
      this.getObtenerDetalleDocumento();
    } else {
      this.initForm();
    }
  }

  initForm() {
    this.editForm = this.formBuilder.group({
      tipoDocumento: new FormControl(this.detalleDocumento.tipoDocumento.uuid, [Validators.required]),
      id: new FormControl(this.detalleDocumento.idInterno, [Validators.required]),
      file: new FormControl(this.detalleDocumento.tieneAdjunto ? this.detalleDocumento.archivoAdjunto.nombreArchivo : null, [Validators.required]),
      fileSource: new FormControl(null),
      nombre: new FormControl(this.detalleDocumento.nombre, [Validators.required]),
      version: new FormControl({ value: this.detalleDocumento.version, disabled: true }),
      macroproceso: new FormControl(this.detalleDocumento.macroProceso.uuid, [Validators.required]),
      proceso: new FormControl(this.detalleDocumento.proceso.uuid, [Validators.required]),
      area: new FormControl(this.detalleDocumento.area.uuid, [Validators.required]),
      subarea: new FormControl(this.detalleDocumento.subArea.uuid, [Validators.required]),
      fechaDocumento: new FormControl(new Date(this.detalleDocumento.fechaDocumento), [Validators.required]),
      fechaInicio: new FormControl(new Date(this.detalleDocumento.fechaInicioVigencia), [Validators.required]),
      fechaVencimiento: new FormControl(new Date(this.detalleDocumento.fechaVencimiento), [Validators.required]),
      documentoVinculados: new FormControl(this.detalleDocumento.documentosVinculados.map(x => x.uuid)),
      estado: new FormControl(this.detalleDocumento.estado, [Validators.required])
    });

    this.btnClean = this.detalleDocumento.tieneAdjunto ? true : false;

    this.getListadoMacroprocesos();
    this.getListadoProcesos();
    this.getListadoAreas();
    this.getListadoSubAreas();
    this.getListadoDocumentos();
    this.getListadoTipoDocumentos();
  }

  clearInput() {
    this.btnClean = !this.btnClean;
    this.blobFile = null;
    this.editForm.patchValue({
      file: null,
      fileSource: null
    });
  }

  obtenerVigencia() {
    if (this.editForm.controls.fechaInicio.value && this.editForm.controls.tipoDocumento.value) {
      this.datepickerConfigVencimiento.minDate = new Date(this.editForm.controls.fechaInicio.value);
      const tipoDocSelect = this.listaSimpleTipoDocumento.find(x => x.uuid === this.editForm.controls.tipoDocumento.value);
      const vigencia = tipoDocSelect.periodoVigencia.periodoVigencia.split(' ');
      const date = moment(this.editForm.controls.fechaInicio.value).add(parseInt(vigencia[0], 10), 'months').calendar();
      this.editForm.patchValue({ fechaVencimiento: new Date(date) });
    }
  }

  getObtenerDetalleDocumento() {
    this.loader = true;
    this.apiService.getObtenerDetalleDocumento(this.uuid).subscribe(
      (resp) => this.getObtenerDetalleDocumentoSuccess(resp),
      error => this.getObtenerDetalleDocumentoError(error)
    );
  }

  getObtenerDetalleDocumentoSuccess(response: GetObtenerDetalleDocumentoResponseDTO) {
    this.detalleDocumento = response.data;
    this.initForm();
    this.loader = false;
  }

  getObtenerDetalleDocumentoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_DOCUMENTO;
    this.toastr.error(msg);
    this.loader = false;
  }

  getListadoTipoDocumentos() {
    this.loaderTipoDocumento = true;
    this.apiService.getListadoTipoDocumentos().subscribe(
      (response: GetListadoTipoDocumentosResponseDTO) => {
        this.listaSimpleTipoDocumento = response.data.filter(tipo => tipo.habilitado || tipo.uuid === this.detalleDocumento.tipoDocumento.uuid);
        this.loaderTipoDocumento = false;
      },
      (error: HttpErrorResponse) => {
        this.loaderTipoDocumento = false;
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_TIPO_DOCUMENTOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoMacroprocesos() {
    this.loaderMacroprocesos = true;
    this.apiService.getListadoMacroprocesos().subscribe(
      (response: GetListadoMacroprocesosResponseDTO) => {
        this.listaSimpleMacroprocesos = response.data.filter(macro => macro.habilitado || macro.uuid === this.detalleDocumento.macroProceso.uuid);
        this.loaderMacroprocesos = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS;
        this.toastr.error(msg);
        this.loaderMacroprocesos = false;
      }
    );
  }

  getListadoProcesos(change: boolean = false) {
    this.listaSimpleProcesos = [];
    if (change) {
      this.editForm.patchValue({ proceso: null, area: null, subarea: null });
    }
    this.loaderProcesos = true;
    this.apiService.getListadoProcesos(this.editForm.controls.macroproceso.value).subscribe(
      (resp) => this.getListadoProcesosSuccess(resp),
      error => this.getListadoProcesosError(error)
    );
  }

  getListadoProcesosSuccess(response: GetListadoProcesosResponseDTO) {
    this.listaSimpleProcesos = response.data.filter(proceso => proceso.habilitado || proceso.uuid === this.detalleDocumento.proceso.uuid);
    this.loaderProcesos = false;
  }

  getListadoProcesosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS;
    this.toastr.error(msg);
    this.loaderProcesos = false;
  }

  getListadoAreas(change: boolean = false) {
    this.listaSimpleArea = [];
    if (change) {
      this.editForm.patchValue({ area: null, subarea: null });
    }
    this.loaderArea = true;
    this.apiService.getListadoAreas(this.editForm.controls.proceso.value).subscribe(
      (resp) => this.getListadoAreasSuccess(resp),
      error => this.getListadoAreasError(error)
    );
  }

  getListadoAreasSuccess(response: GetListadoAreasResponseDTO) {
    this.listaSimpleArea = response.data.filter(area => area.habilitado || area.uuid === this.detalleDocumento.area.uuid);
    this.loaderArea = false;
  }

  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.toastr.error(msg);
    this.loaderArea = false;
  }

  getListadoSubAreas(change: boolean = false) {
    this.listaSimpleSubArea = [];
    if (change) {
      this.editForm.patchValue({ subarea: null });
    }
    this.loaderSubArea = true;
    this.apiService.getListadoSubAreas(this.editForm.controls.area.value).subscribe(
      (resp) => this.getListadoSubAreasSuccess(resp),
      error => this.getListadoSubAreasError(error)
    );
  }

  getListadoSubAreasSuccess(response: GetListadoSubAreasResponseDTO) {
    this.listaSimpleSubArea = response.data.filter(sub => sub.habilitado || sub.uuid === this.detalleDocumento.subArea.uuid);
    this.loaderSubArea = false;
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastr.error(msg);
    this.loaderSubArea = false;
  }

  getListadoDocumentos(change: boolean = false) {
    this.listaSimpleDocumentosVicunlados = [];
    if (change) {
      this.editForm.patchValue({ documentoVinculados: null });
    }
    this.loaderDocumentosVicunlados = true;
    this.apiService.getListadoDocumentos(null, this.editForm.value.tipoDocumento).subscribe(
      (resp) => this.getListadoDocumentosSuccess(resp),
      error => this.getListadoDocumentosError(error)
    );
  }

  getListadoDocumentosSuccess(response: GetListadoDocumentosResponseDTO) {
    this.listaSimpleDocumentosVicunlados = response.data.filter(lsdv => lsdv.uuid !== this.uuid);
    this.loaderDocumentosVicunlados = false;
  }

  getListadoDocumentosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS;
    this.toastr.error(msg);
    this.loaderDocumentosVicunlados = false;
  }

  selectArchivo(event: any) {
    this.editForm.controls.file.markAsTouched();
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.blobFile = Base64Util.base64toBlob(reader.result);
      };

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };

      this.editForm.patchValue({
        fileSource: file,
      });
    }
  }

  editarDocumento() {
    if (this.editForm.valid && this.idValido) {
      this.disabledBtn = true;
      const dataEdit = this.editForm.value;
      let formData = new FormData();
      formData.append('idInterno', dataEdit.id);
      formData.append('uuidTipoDocumento', dataEdit.tipoDocumento);
      formData.append('nombre', dataEdit.nombre);
      formData.append('uuidMacroproceso', dataEdit.macroproceso);
      formData.append('uuidProceso', dataEdit.proceso);
      formData.append('uuidArea', dataEdit.area);
      formData.append('uuidSubArea', dataEdit.subarea);
      formData.append('fechaDocumento', this.datepipe.transform(dataEdit.fechaDocumento, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('fechaInicioVigencia', this.datepipe.transform(dataEdit.fechaInicio, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('fechaVencimiento', this.datepipe.transform(dataEdit.fechaVencimiento, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('vincularAOtroDocumento', dataEdit.documentoVinculados);
      formData.append('estado', dataEdit.estado);
      formData.append('file', this.blobFile ? this.blobFile : new Blob(), this.blobFile ? this.editForm.controls.fileSource.value.name : null);

      this.apiService.putEditarDocumento(this.detalleDocumento.uuid, formData).subscribe(
        (resp) => this.putEditarDocumentoSuccess(resp),
        error => this.putEditarDocumentoError(error)
      );
    } else {
      this.validateAllFormFields(this.editForm);
    }
  }

  putEditarDocumentoSuccess(response: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_EDITAR_DOCUMENTO);
    this.disabledBtn = false;
    this.onClose.next('EDITAR');
  }

  putEditarDocumentoError(error: HttpErrorResponse) {
    this.disabledBtn = false;
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_DOCUMENTO;
    this.toastr.error(msg);
  }

  getValidarIdInterno() {
    this.apiService.getValidarIdInterno(this.editForm.value.id).subscribe(
      (response: GeneralResponseDTO) => {
        this.idValido = response.success;
      },
      (error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.idValido = this.detalleDocumento.idInterno === this.editForm.value.id || error.error.success;
        }
        if (!this.idValido || error.status !== 409) {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_VALIDAR_ID_INTERNO;
          this.toastr.error(msg);
        }
      }
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {            
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
