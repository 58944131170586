import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { Base64Util } from 'src/app/util/base64.util';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';

@Component({
  selector: 'app-modal-gestion-adjuntar',
  templateUrl: './modal-gestion-adjuntar.component.html',
  styleUrls: ['./modal-gestion-adjuntar.component.scss']
})
export class ModalGestionAdjuntarComponent {
  uuidActividad: string;
  adjuntarForm: FormGroup;
  blobFile: Blob[] = [];
  onClose: Subject<string>;
  disabledButton: boolean = false;


  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) { }
  
  ngOnInit(): void {
    this.onClose = new Subject();
    this.initForm();
  }


  initForm() {
    this.adjuntarForm = this.formBuilder.group({
      file: new FormControl(null),
      filesSource: new FormArray([], [Validators.required]),
      comentarios: new FormControl('', [Validators.required])
    })
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(reader.result));
      };

      reader.onloadend = async () => {
        file['url'] = reader.result.toString().includes('image') ? reader.result.toString() : this.obtenerUrl(file);
      }

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.adjuntarForm.get('filesSource') as FormArray).push(new FormControl(file));
      this.adjuntarForm.get('file').patchValue(null);
    }
  }

  getAdjuntos() {
    return (this.adjuntarForm.get('filesSource') as FormArray).controls;
  }

  removeAdjunto(i: number) {
    (this.adjuntarForm.get('filesSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        url = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return url;
  }

  adjuntarEvidencia() {
    this.disabledButton = true;
    let value = this.adjuntarForm.value;
    let formData = new FormData();
    formData.append('comentario', value.comentarios);
    const nombreArchivos = (this.adjuntarForm.get('filesSource') as FormArray).value;
    this.blobFile.forEach((file, index) => {
      formData.append('archivos[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    });
    this.apiGestion.postAdjuntarEvidenciaActividad(this.uuidActividad, formData).subscribe(
      (resp) => this.adjuntarEvidenciaSuccess(resp),
      (error) => this.adjuntarEvidenciaError(error));
  }

  adjuntarEvidenciaSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_ADJUNTAR_EVIDENCIA);
    this.onClose.next('ADJUNTAR');
  }

  adjuntarEvidenciaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ADJUNTAR_EVIDENCIA;
    this.toastr.error(msg);
  }
 }
