import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { AnioFiscalDTO } from 'src/dtos/gestion/anioFiscal.dto';


@Component({
  selector: 'app-modal-mc-selectaniofiscal',
  templateUrl: './modal-mc-selectaniofiscal.component.html',
  styleUrls: ['./modal-mc-selectaniofiscal.component.scss']
})
export class ModalMcSelectaniofiscalComponent implements OnInit {

  onClose: Subject<string>;
  listadoAniosFiscal: AnioFiscalDTO[] = [];
  fechaSelected: AnioFiscalDTO;
  uuidFechaSelected: string;


  constructor(
    public bsModalRef: BsModalRef,
    private actividadService: ActividadService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.fechaSelected = this.actividadService.getAnioFiscalSelected();
    this.uuidFechaSelected = this.fechaSelected.uuid;
    this.onClose = new Subject();
    this.getListadoAniosFiscal();
  }

  guardarAnioFiscal() {
    this.fechaSelected = this.listadoAniosFiscal.find(obj => obj.uuid === this.uuidFechaSelected);
    console.log(this.fechaSelected);

    this.actividadService.setAnioFiscalSelected(this.fechaSelected);
    this.onClose.next('GUARDAR');
  }

  getListadoAniosFiscal() {
    this.apiGestion.getObtenerAniosFiscales().subscribe((resp) => {
      this.listadoAniosFiscal = resp.data;
      console.log(this.listadoAniosFiscal);
      for (const iterator of this.listadoAniosFiscal) {
        iterator.fechaInicioDate = new Date(iterator.fechaInicioAnioFiscal);
        iterator.fechaTerminoDate = new Date(iterator.fechaTerminoAnioFiscal);
      }
    }, (error) => {
      this.toastr.error('Error al obtener listado periodos fiscales.');
    })

  }

}
