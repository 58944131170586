import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { SitioDTO } from 'src/dtos/gestion/sitio.dto';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';

@Component({
  selector: 'app-filtros-mc',
  templateUrl: './filtros-mc.component.html',
  styleUrls: ['./filtros-mc.component.scss']
})
export class FiltrosMcComponent { 
  filterForm: FormGroup;
  listadoAreas: AreaDTO[] = [];
  listadoSubareas: SubareaDTO[] = [];
  listadoSitios: SitioDTO[] = [];
  listadoTiposMaterial = ['Foto', 'Documento', 'PPT'];
  listadoMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();
  @Input() filtrosForm: any;
  @Input() modo: string = 'materiales';
  @Output() filterRequest: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private apiMantenedor: ApiMantenedorService,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.initFilters();
  }

  initFilters() {
    if (this.modo === 'materiales') {
      this.filterForm = this.formBuilder.group({
        nombre: new FormControl(null),
        tipo: new FormControl(this.filtrosForm.tipo ? this.filtrosForm.tipo : null),
        fechaCreacion: new FormControl(this.filtrosForm.fechaCreacion ? [new Date(moment(this.filtrosForm.fechaCreacion[0]).format()), new Date(moment(this.filtrosForm.fechaCreacion[1]).format())] : null),
      })
    }
    if (this.modo === 'implementacion') {
      this.filterForm = this.formBuilder.group({
        nombre: new FormControl(null),
        fechaCreacion: new FormControl(null),
        uuidArea: new FormControl(null),
        uuidSubArea: new FormControl(null),
        uuidSitio: new FormControl(null)
      });
      this.getListadoAreas();
    }
    if (this.modo === 'proyecto') {
      this.filterForm = this.formBuilder.group({
        nombre: new FormControl(null),
        fechaCreacion: new FormControl(null),
        uuidArea: new FormControl(null),
        uuidSubArea: new FormControl(null),
        uuidSitio: new FormControl(null)
      });
      this.getListadoAreas();
    }
  }

  getListadoAreas() {
    this.listadoAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listadoAreas = resp.data.filter(area => area.habilitado);
    this.filterForm.patchValue({ uuidArea: this.filtrosForm.uuidArea });
  }
  
  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.toastr.error(msg);
  }

  getListadoSubAreas() {
    this.filterForm.patchValue({ uuidSubArea: null, uuidSitio: null });
    this.listadoSubareas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor(this.filterForm.controls.uuidArea.value).subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listadoSubareas = resp.data.filter(subarea => subarea.habilitado);
    this.filterForm.patchValue({ uuidSubArea: this.filtrosForm.uuidSubArea });
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastr.error(msg);
  }

  getListadoSitios() {
    this.filterForm.patchValue({ uuidSitio: null });
    this.listadoSitios = [];
    this.apiMantenedor.getListadoSitiosMantenedor(this.filterForm.controls.uuidSubArea.value).subscribe((resp) => this.getListadoSitiosSuccess(resp),
      (error) => this.getListadoSitiosError(error));
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitios = resp.data.filter(sitio => sitio.habilitado);
    this.filterForm.patchValue({ uuidSitio: this.filtrosForm.uuidSitio });
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastr.error(msg);
  }

  cerrarFiltros() {
    this.cerrar.emit(false);
  }

  filterMC() {
    let aux: any = {};
    const form = this.filterForm.value;
    if (this.modo === 'materiales') {
      aux = {
        nombre: form.nombre,
        tipo: form.tipo,
        fechaCreacion: form.fechaCreacion ? [moment(form.fechaCreacion[0]).format('YYYY-MM-DD'), moment(form.fechaCreacion[1]).format('YYYY-MM-DD')] : null
      };
    }

    if (this.modo === 'implementacion' || this.modo === 'proyecto') {
      aux = {
        nombre: form.nombre,
        fechaCreacion: form.fechaCreacion ? [moment(form.fechaCreacion[0]).format('YYYY-MM-DD'), moment(form.fechaCreacion[1]).format('YYYY-MM-DD')] : null,
        uuidArea: form.uuidArea,
        uuidSubArea: form.uuidSubArea,
        uuidSitio: form.uuidSitio
      }
    }

    this.filterRequest.emit(aux);

  }

}
