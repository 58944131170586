<div class="container-fluid ">

    <div class="loader-container" [style.visibility]="loader ? 'visible' : 'hidden'">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fa fa-bell bell ico-page" aria-hidden="true"></i><span
                class="title1">Notificaciones</span></div>
    </div>
    <div class="notification" [style.visibility]="loader ? 'hidden' : 'visible'">
        <div class="notification-content">
            <div class="notification-data">
                <div class="section" *ngFor="let notificacion of listadoNotificaciones" (click)="abrirNotificacion(notificacion)">
                    <div class="section1"> <i class="fa fa-exclamation ico-notification" aria-hidden="true"></i> </div>
                    <div class="section2"> 
                        {{notificacion.detalle}}
                    </div>
                    <div class="section3">{{calcularTiempo(notificacion)}}</div>
                </div>
                <div class="sin-resultados" *ngIf="listadoNotificaciones.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
               
            </div>
        </div>
    </div>


</div>

<div class="full-height">&nbsp;</div>