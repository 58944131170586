<div class="filter" *ngIf="viewFilter">
    <app-filtros-mc [modo]="'implementacion'" [filtrosForm]="filtros" (filterRequest)="filterMC($event)"
        (cerrar)="cerrarFiltros($event)">
    </app-filtros-mc>
</div>

<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page">
        <app-menu></app-menu>
    </div>
    <div class="section-page">
        <div class="subtitle">
            Fotos implementacion 5S
        </div>
        <div class="create">
            <button type="button" class="btn btn-secundary" (click)="openModalMcSubirarchivo()">
                <span class="text-create"> SUBIR ARCHIVO </span><i class="far fa-plus-square create-ico"
                    aria-hidden="true"></i>
            </button>
        </div>
        <div class="tools-container">
            <div class="search">
                <input [formControl]="busqueda" id="txtSearch" placeholder="Buscar" class="form-control" /><i class="fas fa-search ico-input1"
                    aria-hidden="true"></i>
            </div>
            <div class="export">
                <button class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">
                    Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                </button>
                <!--<label class="select-met">
                    <select name="filtro-sitio-mc" id="filtro-sitio-mc" class="select-modal">
                        <option>Filtro Sitio</option>
                        <option>Nombre sitio1</option>
                        <option>Nombre sitio2</option>
                    </select>
                </label>-->
            </div>
            <!--<div class="export"><input type="text" placeholder="Seleccione Periodo" class="inp-modal" bsDaterangepicker>
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>-->
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()"> Limpiar Filtros</button>
            </div>
        </div>
    </div>
    <div class="section-page">
        <!--Tabla-->
        <div class="tabla">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Mantenedor implementacion 5s</caption>
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Mes</th>
                            <th scope="col">Área</th>
                            <th scope="col">Subárea</th>
                            <th scope="col">Sitio</th>
                            <th scope="col" class="text-nowrap">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let archivo of listadoFotosImplementacion">
                            <td>{{archivo.nombre}}</td>
                            <td>{{obtenerMesEnPalabras(archivo.fechaCreacion)}}</td>
                            <td>{{archivo.area?.nombre}}</td>
                            <td>{{archivo.subArea?.nombre}}</td>
                            <td>{{archivo.sitio?.nombre}}</td>
                            <td class="text-nowrap"> <button type="button" class="btn-ico-tool" tooltip="Descargar"
                                    placement="top" (click)="descargarListadoAdjuntos(archivo)">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Ver adjuntos" placement="top"
                                    (click)="openModalMcVerarchivos(archivo)">
                                    <i class="fas fa-image ico-action" aria-hidden="true"></i>
                                </button>
                                <!--<button type="button" class="btn-ico-tool" tooltip="Subir adjuntos" placement="top"
                                    (click)="openModalMcSubirfotos()">
                                    <i class="fas fa-camera ico-action" aria-hidden="true"></i>
                                </button>-->
                                <button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                    (click)="openModalMcEliminar(archivo)">
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <!--<tr>
                            <td>Archivo 125</td>
                            <td>Abril</td>
                            <td>Nombre</td>
                            <td>Nombre</td>
                            <td>Nombre</td>
                            <td class="text-nowrap"> <button type="button" class="btn-ico-tool" tooltip="Descargar"
                                    placement="top">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Ver adjuntos" placement="top"
                                    (click)="openModalMcVerarchivos()">
                                    <i class="fas fa-image ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Subir adjuntos" placement="top"
                                    (click)="openModalMcSubirfotos()">
                                    <i class="fas fa-camera ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                    (click)="openModalMcEliminar()">
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>

                        </tr>-->
                    </tbody>
                </table>
            </div>
        </div>

        <!--Pagination-->

    </div>
</div>
<div class="full-height">&nbsp;</div>