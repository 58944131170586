<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Asignar reclamo</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="asignarForm">
        <div class="modal-body">
            <div class="container">
                <div class="row my-3">
                    <div class="col-4">
                        <div class="lab lab-place"><label for="">Área</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="area" placeholder="Seleccione" required
                                (change)="getListadoSubAreas(true)"
                                [ngClass]="{'border-error':  asignarForm.get('area').touched && ! asignarForm.get('area').valid}">
                                <ng-option *ngFor="let area of listaSimpleAreas" [value]="area.uuid">
                                    {{area.nombre}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="lab lab-place"><label for="">Sub-área</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="subarea" placeholder="Seleccione" required
                                (change)="getListadoSitios(true)"
                                [ngClass]="{'border-error':  asignarForm.get('subarea').touched && ! asignarForm.get('subarea').valid}">
                                <ng-option *ngFor="let subarea of listaSimpleSubareas" [value]="subarea.uuid">
                                    {{subarea.nombre}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="lab lab-place"><label for="">Sitio</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="sitio" placeholder="Seleccione">
                                <ng-option *ngFor="let sitio of listadoSitio" [value]="sitio.uuid">
                                    {{sitio.nombre}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <textarea formControlName="observacion" id="asignar-reclamo" name="asignar-reclamo" rows="4"
                            cols="100" placeholder="Señale sus razones para asignar el reclamo..."></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="asignarReclamo()">
                Asignar
            </button>
        </div>
    </form>
</div>