<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar tiempos de respuesta reclamos</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="editarForm">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Tiempo en días</label></div>
                <div class="inp">
                    <input type="number" class="inp-modal" placeholder="0" required formControlName="cantidad" min="1"
                        [ngClass]="{'border-error': editarForm.controls['cantidad'].value > 0 && !editarForm.controls['cantidad'].valid}" />
                </div>
            </div>
            <div class="field-half" *ngIf="editarForm.controls.cantidad.value > 0">
                <span>El tiempo de respuesta estará abierto en un rango de {{(acumulado + 1)}} a {{(acumulado +
                    editarForm.controls['cantidad'].value)}} días.</span>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()">Cancelar</button>
            <button type="button" [disabled]="editarForm.controls.cantidad.value < 1" class="btn btn-modal"
                (click)="putActualizarSemaforoMantenedor()">Editar tiempo</button>
        </div>
    </form>
</div>