import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { UserService } from 'src/app/services/user.service';
import { Base64Util } from 'src/app/util/base64.util';
import { ComentarioDTO } from 'src/dtos/mantenedor/comentario.dto';
import { ReclamoDTO } from 'src/dtos/mantenedor/reclamo.dto';
import { UsuarioDTO } from 'src/dtos/mantenedor/usuario.dto';
import { GetListadoComentariosResponseDTO } from 'src/dtos/response/mantenedor/getListadoComentarios.response.dto';
import { PostEnviarComentarioResponseDTO } from 'src/dtos/response/mantenedor/postEnviarComentario.response.dto';
import { UsuarioModel } from 'src/models/usuario.model';

@Component({
  selector: 'app-modal-reclamos-comentarios',
  templateUrl: './modal-reclamos-comentarios.component.html',
  styleUrls: ['./modal-reclamos-comentarios.component.scss']
})
export class ModalReclamosComentariosComponent {
  responderForm: FormGroup;

  usuario: UsuarioModel;
  informacionComentario: ComentarioDTO;
  listadoUsuarios: UsuarioDTO[] = [];
  reclamo: ReclamoDTO;

  blobFile: Blob[] = [];

  loader: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.usuario = this.userService.getUser();

    this.responderForm = this.formBuilder.group({
      comentario: new FormControl('', [Validators.required]),
      file: new FormControl(null),
      filesSource: new FormArray([])
    });
    this.loader = true;
    this.getObtenerComentarios();
  }

  inicialesUsuario(usuarioComent: UsuarioDTO) {
    let tipoUsuario = { iniciales: '', nombre: '', area: '' };
    if (usuarioComent.rol.nombre.toLowerCase() === 'superadmin') {
      // Usuario servicio al cliente
      tipoUsuario.iniciales = ' A ';
      tipoUsuario.nombre = 'Administrador';
    } else if (usuarioComent.rol.nombre.toLowerCase() === 'customer service') {
      // Usuario servicio al cliente
      tipoUsuario.iniciales = ' SC ';
      tipoUsuario.nombre = 'Servicio al cliente';
    } else if (usuarioComent.uuid === this.reclamo.usuarioCreador.uuid) {
      // Usuario creador del reclamo
      tipoUsuario.iniciales = 'C';
      tipoUsuario.nombre = 'Creador del reclamo';
      tipoUsuario.area = this.reclamo.usuarioCreador.preferencias?.area?.nombre + '/' + this.reclamo.usuarioCreador.preferencias?.subArea?.nombre + '/' + this.reclamo.usuarioCreador.preferencias?.sitio?.nombre;
    } else if (usuarioComent.preferencias) {
      switch (true) {
        case usuarioComent.preferencias?.area?.uuid === this.reclamo.usuarioCreador.preferencias?.area?.uuid
          && usuarioComent.preferencias?.subArea?.uuid === this.reclamo.usuarioCreador.preferencias?.subArea?.uuid
          && usuarioComent.preferencias?.sitio?.uuid === this.reclamo.usuarioCreador.preferencias?.sitio?.uuid:
          // Usuario que pertenece a la misma area del creador del reclamo
          tipoUsuario.iniciales = 'AC';
          tipoUsuario.nombre = 'Área creadora del reclamo';
          tipoUsuario.area = this.reclamo.usuarioCreador.preferencias?.area?.nombre + '/' + this.reclamo.usuarioCreador.preferencias?.subArea?.nombre + '/' + this.reclamo.usuarioCreador.preferencias?.sitio?.nombre;
          break;
        case usuarioComent.preferencias?.area?.uuid === this.reclamo.asignacion?.area?.uuid
          && usuarioComent.preferencias?.subArea?.uuid === this.reclamo.asignacion?.subarea?.uuid
          && usuarioComent.preferencias?.sitio?.uuid === this.reclamo.asignacion?.sitio?.uuid:
          // Usuario que pertenece al area asignada del reclamo
          tipoUsuario.iniciales = 'AR';
          tipoUsuario.nombre = 'Área responsable';
          tipoUsuario.area = this.reclamo.asignacion?.area?.nombre + '/' + this.reclamo.asignacion?.subarea?.nombre + '/' + this.reclamo.asignacion?.sitio?.nombre;
          break;
        default:
          break;
      }
    }
    return tipoUsuario;
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(fileReader.result));
      };

      fileReader.onloadend = async () => {
        file['url'] = fileReader.result.toString();
      }

      fileReader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.responderForm.get('filesSource') as FormArray).push(new FormControl(file));
      this.responderForm.get('file').patchValue(null);
    }
  }

  removeAdjunto(i: number) {
    (this.responderForm.get('filesSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }

  getAdjuntos() {
    return (this.responderForm.get('filesSource') as FormArray).controls;
  }

  obtenerUrl(nombreArchivo: string) {
    let urlImage;
    switch (true) {
      case nombreArchivo.includes('.pdf'):
        urlImage = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case nombreArchivo.includes('.doc'):
        urlImage = '../assets/img/extension-archivo/k-doc.png';
        break;
      case nombreArchivo.includes('.xls'):
        urlImage = '../assets/img/extension-archivo/k-xls.png';
        break;
      case nombreArchivo.includes('.ppt'):
        urlImage = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        urlImage = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return urlImage;
  }

  getObtenerComentarios() {
    this.apiServiceMantenedor.getObtenerComentarios(this.reclamo.uuid).subscribe(
      (response: GetListadoComentariosResponseDTO) => {
        this.informacionComentario = response.data;
        this.informacionComentario?.comentario.forEach(usuario => {
          this.listadoUsuarios.push(usuario.usuario)
        });

        this.listadoUsuarios = [...new Map(this.listadoUsuarios.map(item => {
          return [item.nombre, item]
        })).values()];

        this.loader = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_COMENTARIOS;
        this.toastr.error(msg);
        this.loader = false;
      }
    );
  }

  responderComentario() {
    const value = this.responderForm.value;
    let formData = new FormData();
    formData.append('mensaje', value.comentario);
    formData.append('uuidReclamo', this.reclamo.uuid);

    const nombreArchivos = (this.responderForm.get('filesSource') as FormArray).value;
    this.blobFile.forEach((file, index) => {
      formData.append('archivos[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    });

    this.apiServiceMantenedor.postResponderComentarios(formData).subscribe(
      (response: PostEnviarComentarioResponseDTO) => {
        this.blobFile = [];
        (this.responderForm.get('filesSource') as FormArray).clear();
        this.responderForm.patchValue({
          comentario: null,
          file: null
        });
        this.loader = false;
        this.getObtenerComentarios();
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_RESPONDER_COMENTARIO;
        this.toastr.error(msg);
      }
    );
  }

  getDescargarAdjuntoComentario(url: string) {
    window.open(url, '_self');
  }
}
