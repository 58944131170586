import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { SemaforoDTO } from 'src/dtos/mantenedor/semaforo.dto';
import { PostListadoReclamoPaginadoRequestDTO } from 'src/dtos/request/mantenedor/postListadoReclamoPaginado.request.dto';
import { GetListadoSemaforoResponseDTO } from 'src/dtos/response/mantenedor/getListadoSemaforo.response.dto';
import { ModalReclamosCerrarComponent } from '../modals/modal-reclamos-cerrar/modal-reclamos-cerrar.component';
import { ModalReclamosComentariosComponent } from '../modals/modal-reclamos-comentarios/modal-reclamos-comentarios.component';
import { ModalReclamosHistorialComponent } from '../modals/modal-reclamos-historial/modal-reclamos-historial.component';
import * as moment from 'moment';
import { ReclamoDTO } from 'src/dtos/mantenedor/reclamo.dto';
import { PostExportarReclamosRequestDTO } from 'src/dtos/request/mantenedor/postExportarReclamo.request.dto';
import { saveAs } from 'file-saver';
import { ModalReclamosResponderRechazoComponent } from '../modals/modal-reclamos-responder-rechazo/modal-reclamos-responder-rechazo.component';
import { ModalReclamosAsignarReclamoComponent } from '../modals/modal-reclamos-asignar-reclamo/modal-reclamos-asignar-reclamo.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Base64Util } from 'src/app/util/base64.util';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetPostListadoReclamosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoReclamosPaginados.response.dto';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reclamos-servicio-cliente',
  templateUrl: './reclamos-servicio-cliente.component.html',
  styleUrls: ['./reclamos-servicio-cliente.component.scss']
})
export class ReclamosServicioClienteComponent {
  listadoSemaforo: SemaforoDTO[] = [];
  listadoPagReclamosSC: ReclamoDTO[] = [];
  filtros: any;
  auxListado: ReclamoDTO[] = [];

  totalRegisterSC: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  contCheck: number = 0;

  viewFilter: boolean = false;
  checkReclamosSC: boolean = false;
  loaderPaginado: boolean = false;
  loaderSubirProc: boolean = false;

  subirProcForm: FormGroup;

  busqueda = new FormControl(null);

  blobFile: Blob;

  formCtrlSub: Subscription;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.viewFilter = false;

    this.subirProcForm = this.formBuilder.group({
      file: new FormControl(null, [Validators.required]),
      fileSource: new FormControl(null, [Validators.required]),
    });

    this.initFiltersSC();
    this.getListadoSemaforoMantenedor();

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFiltersSC(false);
        this.listadoPagReclamosSC = [];
        this.postListadoReclamosServicioClientePaginadoMantenedor();
      }
    });
  }

  pageChanged(event: any): void {
    this.checkReclamosSC = true;
    this.seleccionMultipleReclamosSC();
    this.currentPage = event.page;

    if (this.busqueda.value?.length > 0) {
      this.postListadoReclamosServicioClientePaginadoMantenedor();
    } else {
      this.getListadoReclamosPaginadoMantenedorBusqueda();
    }
  }

  initFiltersSC(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      fechaCreacion: null,
      fechaCierre: null,
      uuidSubareaResponsable: null,
      uuidEstado: null,
      uuidTipoReclamo: null,
      uuidCreador: null,
      uuidAreaResponsable: null,
      uuidFilial: null,
      uuidSitioResponsable: null
    };

    if (initData) {
      this.postListadoReclamosServicioClientePaginadoMantenedor();
    }
  }

  filterReclamos(event: any) {
    this.currentPage = 1;
    this.cerrarFiltrosSC(false);

    this.filtros = event;

    this.postListadoReclamosServicioClientePaginadoMantenedor();
  }

  cerrarFiltrosSC(event: boolean) {
    this.viewFilter = event;
  }

  calcularSemaforo(fecha: string) {
    const cantidad = moment().diff(fecha, 'days');
    const color = this.listadoSemaforo.find(ls => cantidad <= ls.cantidad);
    return color ? { color: color.color, cantidad } : { color: 'rojo', cantidad };
  }

  seleccionMultipleReclamosSC() {
    this.auxListado = this.listadoPagReclamosSC;
    this.contCheck = 0;
    for (const iterator of this.auxListado) {
      if (!this.checkReclamosSC) {
        this.contCheck++;
      }
      iterator.checkReclamo = !this.checkReclamosSC;
    }
    this.checkReclamosSC = !this.checkReclamosSC;
  }

  selReclamoSC(uuid: string) {
    const reclamoSCAux = this.listadoPagReclamosSC.find(d => d.uuid === uuid);

    if (reclamoSCAux && reclamoSCAux.checkReclamo) {
      reclamoSCAux.checkReclamo = false;
      if (this.contCheck > 0) {
        this.contCheck--;
      }
    } else {
      reclamoSCAux.checkReclamo = true;
      this.contCheck++;
    }

    this.checkReclamosSC = (this.contCheck > 1) ? true : false;
    this.auxListado = this.listadoPagReclamosSC;
  }

  selectArchivo(event: any) {
    this.subirProcForm.controls.file.markAsTouched();
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.blobFile = Base64Util.base64toBlob(reader.result);
      };

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };

      this.subirProcForm.patchValue({
        fileSource: file,
      });
    }
  }

  subirProcedimiento() {
    this.loaderSubirProc = true;

    let formData = new FormData();
    formData.append('file', this.blobFile, this.subirProcForm.controls.fileSource.value.name);

    this.apiServiceMantenedor.postSubirProcedimmiento(formData).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_SUBIR_PROCEDIMIENTO);
        this.loaderSubirProc = false;
        this.subirProcForm.patchValue({ file: null, fileSource: null });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_SUBIR_PROCEDIMIENTO;
        this.toastr.error(msg);
        this.loaderSubirProc = false;
      }
    );
  }

  getListadoSemaforoMantenedor() {
    this.apiServiceMantenedor.getListadoSemaforoMantenedor().subscribe(
      (response: GetListadoSemaforoResponseDTO) => {
        this.listadoSemaforo = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_SEMAFORO;
        this.toastr.error(msg);
      }
    );
  }

  postListadoReclamosServicioClientePaginadoMantenedor() {
    this.loaderPaginado = true;
    this.busqueda.setValue(null);

    const dataRequest: PostListadoReclamoPaginadoRequestDTO = JSON.parse(JSON.stringify({
      uuidSubareaResponsable: this.filtros.uuidSubareaResponsable,
      uuidAreaResponsable: this.filtros.uuidAreaResponsable,
      uuidSitioResponsable: this.filtros.uuidSitioResponsable,
      uuidCreador: this.filtros.uuidCreador,
      uuidEstado: this.filtros.uuidEstado,
      uuidFilial: this.filtros.uuidFilial,
      uuidTipoReclamo: this.filtros.uuidTipoReclamo,
      fechaCierreInicio: this.filtros.fechaCierre ? this.filtros.fechaCierre[0] : null,
      fechaCierreTermino: this.filtros.fechaCierre ? this.filtros.fechaCierre[1] : null,
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
    this.apiServiceMantenedor.postListadoReclamosPaginadoMantenedor(this.currentPage - 1, this.pageSize, dataRequest, true).subscribe(
      (response) => this.listadoReclamosSCPaginadoSuccess(response),
      (error) => this.listadoReclamosSCPaginadoError(error)
    );
  }

  getListadoReclamosPaginadoMantenedorBusqueda() {
    this.loaderPaginado = true;

    this.apiServiceMantenedor.getListadoReclamosPaginadoMantenedorBusqueda(this.currentPage - 1, this.pageSize, this.busqueda.value, true).subscribe(
      (resp) => this.listadoReclamosSCPaginadoSuccess(resp),
      (error) => this.listadoReclamosSCPaginadoError(error)
    );
  }

  listadoReclamosSCPaginadoSuccess(response: GetPostListadoReclamosPaginadosResponseDTO) {
    this.listadoPagReclamosSC = response.data.content;
    for (const iterator of this.listadoPagReclamosSC) {
      iterator.checkReclamo = false;
      iterator.estadoSemaforo = this.calcularSemaforo(iterator.fechaCreacion);
    }
    this.auxListado = this.listadoPagReclamosSC;
    this.checkReclamosSC = false;
    this.currentPage = response.data.pageable.pageNumber + 1;
    this.totalRegisterSC = response.data.totalElements;
    this.loaderPaginado = false;
  }

  listadoReclamosSCPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_RECLAMOS_PAGINADO;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  postExportarReclamosServicioClienteMantenedor() {
    const reclamosSC: PostExportarReclamosRequestDTO = {
      uuidReclamos: this.auxListado.filter(reclamo => reclamo.checkReclamo).map(reclamo => reclamo.uuid)
    };
    if (reclamosSC.uuidReclamos.length > 0) {
      this.apiServiceMantenedor.postExportarReclamosMantenedor(reclamosSC).subscribe(
        (response: any) => {
          let blob = new Blob([response.body], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, `listaReclamos.xlsx`);
          this.toastr.success(AppMessage.SUCCESS_DESCARGAR_RECLAMOS);
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_RECLAMOS;
          this.toastr.error(msg);
        }
      );
    }
  }

  openModalReclamosCerrar(reclamo: ReclamoDTO) {
    const modalCerrar: BsModalRef = this.modalService.show(ModalReclamosCerrarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid,
        idInterno: reclamo.idInterno,
        idImpacto: reclamo.desviacion.uuid
      }
    });
    this.cerrarModalReclamo(modalCerrar);
  }

  openModalReclamosComentarios(reclamo: ReclamoDTO) {
    this.modalService.show(ModalReclamosComentariosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        reclamo
      }
    });
  }

  openModalReclamosHistorial(reclamo: ReclamoDTO) {
    this.modalService.show(ModalReclamosHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid: reclamo.uuid,
        idReclamo: reclamo.idInterno
      }
    });
  }

  openModalReclamosResponderRechazo(reclamo: ReclamoDTO) {
    const modalResponder: BsModalRef = this.modalService.show(ModalReclamosResponderRechazoComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid,
        idInterno: reclamo.idInterno,
        rechazo: reclamo.rechazo
      }
    });
    this.cerrarModalReclamo(modalResponder);
  }

  openModalReclamosAsignarReclamo(reclamo: ReclamoDTO) {
    if (!reclamo.asignacion) {
      const modalAsignar: BsModalRef = this.modalService.show(ModalReclamosAsignarReclamoComponent, {
        ignoreBackdropClick: false,
        keyboard: false,
        class: "modal-dialog-centered modal-lg",
        initialState: {
          id: reclamo.uuid
        }
      });
      this.cerrarModalReclamo(modalAsignar);
    }
  }

  cerrarModalReclamo(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.postListadoReclamosServicioClientePaginadoMantenedor();
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}
