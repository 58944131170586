<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page">
        <app-menu></app-menu>
    </div>
    <div class="section-page">
        <div class="subtitle">
            Novedades LSS
        </div>
        <div class="create">
            <a routerLink="/web/editar-crear-articulo" [queryParams]="{uuidMetodologia: uuid}" class="link">
                <button type="button" class="btn btn-secundary">
                    <span class="text-create"> NUEVO ARTÍCULO </span><i class="far fa-plus-square create-ico"
                        aria-hidden="true"></i>
                </button></a>
        </div>
        <div class="image-news" [style.background-image]="'url(' + portadaUrl + ')'">
            <div class="create" style="clear:both; display: flex;">
                <button (click)="fileInput.click()" type="button" class="btn-ico-tool" tooltip="Editar portada" placement="top">
                    <i aria-hidden="true" class="fas fa-pen ico-action-blue"></i>
                    <input #fileInput type="file" id="file-input" style="display: none;" (change)="addAdjunto($event)">
                </button>
            </div>
            <div class="column-right">
                <div class="tools-container">
                    <div class="subtitle-a1">
                        Artículos
                    </div>
                    <div class="export">
                        <label class="select-met">
                            <select [(ngModel)]="mesSeleccionado" name="mes-novedades5s" id="mes-novedades5s" class="select-modal"
                            (change)="getListadoArticulos()">
                                <option *ngFor="let mes of meses; let i = index" [value]="mes.valor"
                                >{{mes.nombre}}</option> 
                            </select>
                        </label>
                    </div>
                    <div class="export">
                        <label class="select-met">
                            <select [(ngModel)]="anioSeleccionado" name="year-novedades5s" id="year-novedades5s" class="select-modal"
                            (change)="getListadoArticulos()">
                                <option *ngFor="let anio of anios; let i = index" [value]="anio" [selected]="anio === fechaActual.getFullYear()">{{anio}}</option>
                            </select>
                        </label>
                    </div>
                </div>
                <!--Tabla-->
                <div class="tabla">
                    <div class="table-responsive col">
                        <table id="example" class="display" style="width:100%">
                            <caption>Novedades LSS</caption>
                            <tbody>
                                <thead style="display: none;">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tr *ngFor="let articulo of listadoArticulos">
                                    <td><a routerLink="/web/articulo" [queryParams]="{uuidArticulo: articulo.uuidArticulo}" class="link">{{articulo.titulo}}</a></td>
                                    <td> <button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                        (click)="openModalMcEliminar(articulo)">
                                            <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                        </button></td>
                                </tr>
                                <!--<tr>
                                    <td><a href="web/articulo" class="link"> Nuevos cursos y mejoras</a></td>
                                    <td><button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                            (click)="openModalMcEliminar()">
                                            <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                        </button></td>
                                </tr>-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="section-page">


        <!--Pagination-->

    </div>
</div>
<div class="full-height">&nbsp;</div>