<form [formGroup]="filterForm" *ngIf="filterForm">
    <button class="btn-filter close-filter" (click)="cerrarFiltros()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div *ngIf="modo === 'materiales'">
        <div class="field-half">
            <div class="lab"><label for="name">Tipo</label></div>
            <div class="inp">
                <ng-select class="select-nborder" formControlName="tipo" placeholder="Seleccione">
                    <ng-option *ngFor="let tipo of listadoTiposMaterial" [value]="tipo">
                        {{tipo}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Fecha Creacion</label></div>
            <div class="inp"> <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaCreacion">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
    
    </div>
    <div *ngIf="modo === 'implementacion' || modo === 'proyecto'">
        <div class="field-half">
            <div class="lab"><label for="name">Fecha de creación</label></div>
            <div class="inp"> <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaCreacion"><i class="far fa-calendar-alt ico-input" aria-hidden="true"></i></div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Area</label></div>
            <div class="inp">
                <ng-select (change)="getListadoSubAreas()" formControlName="uuidArea" class="select-nborder"  placeholder="Seleccione">
                    <ng-option *ngFor="let area of listadoAreas" [value]="area.uuid">
                        {{area.nombre}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Subarea</label></div>
            <div class="inp">
                <ng-select (change)="getListadoSitios()" formControlName="uuidSubArea" class="select-nborder"  placeholder="Seleccione">
                    <ng-option *ngFor="let subarea of listadoSubareas" [value]="subarea.uuid">
                        {{subarea.nombre}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Sitio</label></div>
            <div class="inp">
                <ng-select class="select-nborder" formControlName="uuidSitio"  placeholder="Seleccione">
                    <ng-option *ngFor="let sitio of listadoSitios" [value]="sitio.uuid">
                        {{sitio.nombre}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    
    </div>
    <div class="filter-box-btn">
        <button class="btn btn-table btn-filter1" (click)="filterMC()">Filtrar</button>
    </div>
</form>
