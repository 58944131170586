import { Injectable } from '@angular/core';
import { PreferenciaModel } from 'src/models/preferencia.model';
import { UsuarioModel } from "../../models/usuario.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usuario: UsuarioModel;
  private readonly key: string = "usuario";

  constructor() {
    this.init();
  }

  init() {
    this.initUser();
    if (this.usuario === null)
      this.usuario = new UsuarioModel();
  }

  initUser() {
    this.usuario = JSON.parse(localStorage.getItem(this.key));
  }

  isUserLogged(): boolean {
    if (this.usuario === null || this.usuario === undefined || !this.usuario.token || this.usuario.token?.length === 0)
      return false;
    else
      return true;
  }

  setUser(usuario: UsuarioModel) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
    this.usuario = usuario;
  }

  getUser(): UsuarioModel {
    return this.usuario;
  }

  setUserToken(token: string) {
    this.usuario.token = token;
  }

  getUserToken(): string {
    return this.usuario.token;
  }

  setUserPreferencias(preferencia: PreferenciaModel) {
    this.usuario.preferencias = preferencia;
    localStorage.setItem('usuario', JSON.stringify(this.usuario));
  }

  cleanSesion() {
    this.usuario = new UsuarioModel();
    this.persistUser();
  }

  persistUser() {
    localStorage.setItem(this.key, JSON.stringify(this.usuario));
    localStorage.removeItem('usuario');
  }
}
