<div class="modal-content-small">
    <div class="modal-header">
        <h5 *ngIf="tabName === 'categoria'" class="modal-title" id="exampleModalLabel">Deshabilitar Categoría
         <!--Título Sub-categoría 1: Habilitar Sub-categoría 1-->
         <!--Título Sub-categoría 2: Habilitar Sub-categoría 2-->
        </h5>

        <h5 *ngIf="tabName === 'subcategoriauno'" class="modal-title" id="exampleModalLabel">Deshabilitar Sub-categoría 1</h5>
        <h5 *ngIf="tabName === 'subcategoriados'" class="modal-title" id="exampleModalLabel">Deshabilitar Sub-categoría 2</h5>

        <button (click)="bsModalRef.hide()" type="button" class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-text">Está apunto de deshabilitar la categoría "{{nombre}}",
            <br> <strong>¿Desea continuar? 
                <!--Texto Sub-categoría 1: Está apunto de deshabilitar la Sub-categoría 1 "Nombre tipo",
            <br> <strong>¿Desea continuar? -->
                <!--Texto Sub-categoría 2: Está apunto de deshabilitar la Sub-categoría 2 "Nombre tipo",
            <br> <strong>¿Desea continuar? --> </strong>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="bsModalRef.hide()" type="button" class="btn btn-modal-black close-modal" data-dismiss="modal" aria-label="Close">Cancelar</button>
        <button (click)="deshabilitar()" type="button" class="btn btn-modal">Sí, deshabilitar</button>
    </div>
</div>
