<div id="div5" class="details3-1 vertical-scroll">
    <div class="subtitle"><i class="fas fa-history" aria-hidden="true"></i> Historial de acciones</div>
    <button class="btn-details3 close-details1" (click)="bsModalRef.hide()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div class="title_doc">
        {{nombreDoc}}
    </div>
    <div class="see-all">
        <a routerLink="/web/historial" [queryParams]="{uuid: uuid, nombre: nombreDoc}" (click)="bsModalRef.hide()">
            Ver todo
        </a>
    </div>
    <div class="historial-box" *ngFor="let accion of listadoAcciones">
        <div class="historial-from">
            <div class="historial-from-left"><i class="fas fa-file-alt ico-historial" aria-hidden="true"></i></div>
            <div class="historial-data">
                <span class="historial-date-hour">{{accion.fecha + 'Z' | date: 'dd/MM/yyyy HH:mm'}}</span>
                <br>
                {{accion.nombreUsuario}}<br>
            </div>
        </div>
        <div class="historial-action">
            <div class="historial-data" *ngIf="accion.accion === 'CREAR'">
                <strong>Creación</strong><br>
                Se creó el documento.
            </div>
            <div class="historial-data" *ngIf="accion.accion === 'ACTUALIZAR'">
                <strong>Actualización</strong><br>
                Se actualizó especificamente algunos campos del documento.
            </div>
            <div class="historial-data" *ngIf="accion.accion === 'EDITAR'">
                <strong>Edición general</strong><br>
                Se editó uno o varios campos del documento.
            </div>
            <div class="historial-data" *ngIf="accion.accion === 'ELIMINAR'">
                <strong>Eliminación</strong><br>
                Se eliminó el documento en la fecha indicada.
            </div>
        </div>
    </div>
</div>