import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { TipoDocumentoDTO } from 'src/dtos/mantenedor/tipoDocumento.dto';
import { VigenciaDTO } from 'src/dtos/mantenedor/vigencia.dto';
import { PutActualizarTipoDocumentoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarTipoDocumento.request.dto';
import { GetDetalleTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleTipoDocumento.response.dto';
import { GetListadoTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoDocumento.response.dto';
import { GetListadoVigenciasResponseDTO } from 'src/dtos/response/mantenedor/getListadoVigencias.response.dto';
import { PutActualizarTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarTipoDocumento.response.dto';

@Component({
  selector: 'app-modal-td-editar',
  templateUrl: './modal-td-editar.component.html',
  styleUrls: ['./modal-td-editar.component.scss']
})
export class ModalTdEditarComponent {
  uuid: string;

  onClose: Subject<string>;

  editarFormTipoDocumento: FormGroup;

  listaPeriodoVigencia: VigenciaDTO[] = [];
  listaTipoDocumentos: TipoDocumentoDTO[] = [];

  loaderPeriodoVigencia: boolean = false;
  loaderTipoDocumentos: boolean = false;

  constructor(
    private apiServiceMantenedor: ApiMantenedorService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.getListadoVigencias();
    this.getDetalleTipoDocumento();
    this.initForm();
  }

  initForm(data?: TipoDocumentoDTO) {
    this.editarFormTipoDocumento = this.formBuilder.group({
      nombre: new FormControl(data ? data.nombre : null, [Validators.required]),
      periodoVigencia: new FormControl(data ? data.periodoVigencia.uuid : null, [Validators.required]),
      diasPrevios: new FormControl(data ? data.diasPrevios : null, [Validators.required]),
      tiposDocumentos: new FormControl(data?.tipoDocumentosVinculados.length > 0 ? data?.tipoDocumentosVinculados.map(x => x.uuid) : null)
    });
  }

  getDetalleTipoDocumento() {
    this.apiServiceMantenedor.getDetalleTipoDocumentoMantenedor(this.uuid).subscribe(
      (response: GetDetalleTipoDocumentoResponseDTO) => {
        this.getListadoTipoDocumentoMantenedor(response.data.tipoDocumentosVinculados, response.data.uuid);
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }

  editarTipo() {
    const data: PutActualizarTipoDocumentoRequestDTO = {
      uuid: this.uuid,
      nombre: this.editarFormTipoDocumento.value.nombre,
      diasPrevios: this.editarFormTipoDocumento.value.diasPrevios,
      tipoDocumentoVinculados: this.editarFormTipoDocumento.value.tiposDocumentos,
      uuidVigencia: this.editarFormTipoDocumento.value.periodoVigencia
    };
    this.apiServiceMantenedor.putActualizarTipoDocumentoMantenedor(data).subscribe(
      (response: PutActualizarTipoDocumentoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_TIPO_DOCUMENTO);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }

  getListadoTipoDocumentoMantenedor(tipoDocumentosVinculados: TipoDocumentoDTO[], uuid: string) {
    this.loaderTipoDocumentos = true;
    this.apiServiceMantenedor.getListadoTipoDocumentoMantenedor().subscribe(
      (response: GetListadoTipoDocumentoResponseDTO) => {
        this.listaTipoDocumentos = response.data.filter(x => (x.habilitado || tipoDocumentosVinculados.some(y => y.uuid === x.uuid)) && uuid !== x.uuid);
        this.loaderTipoDocumentos = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
        this.loaderTipoDocumentos = false;
      }
    );
  }

  getListadoVigencias() {
    this.loaderPeriodoVigencia = true;
    this.apiServiceMantenedor.getListadoVigencias().subscribe(
      (response: GetListadoVigenciasResponseDTO) => {
        this.listaPeriodoVigencia = response.data;
        this.loaderPeriodoVigencia = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
        this.loaderPeriodoVigencia = false;
      }
    );
  }
}
