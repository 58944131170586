import { BaseResponseDTO } from "../base.response.dto";

export class GetPorcentajesHomeGestionResponseDTO extends BaseResponseDTO {
  success: boolean;
    msg: string;
    data: {
      cumple: number;
      fueraDePlazo: number;
      noCumple: number;
    };
}