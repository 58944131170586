import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { RechazoDTO } from 'src/dtos/mantenedor/rechazo.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { PostRechazarRechazoResponseDTO } from 'src/dtos/response/mantenedor/postRechazarRechazo.response.dto';

@Component({
  selector: 'app-modal-reclamos-responder-rechazo',
  templateUrl: './modal-reclamos-responder-rechazo.component.html',
  styleUrls: []
})
export class ModalReclamosResponderRechazoComponent implements OnInit {
  uuid: string;
  idInterno: string;
  rechazo: RechazoDTO;

  responderRechazoForm: FormGroup;

  onClose: Subject<string>;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.responderRechazoForm = this.formBuilder.group({
      descripcion: new FormControl(null, [Validators.required])
    });
  }

  aceptarRechazo() {
    const value = this.responderRechazoForm.value;

    this.apiServiceMantenedor.postAceptarRechazo(this.uuid, value.descripcion).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_ACEPTAR_RECHAZO);
        this.onClose.next('ACEPTARRECHAZO');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ACEPTAR_RECHAZO;
        this.toastr.error(msg);
      }
    );
  }

  rechazarRechazo() {
    const value = this.responderRechazoForm.value;

    this.apiServiceMantenedor.postRechazarRechazo(this.uuid, value.descripcion).subscribe(
      (response: PostRechazarRechazoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_RECHAZAR_RECHAZO);
        this.onClose.next('RECHAZARRECHAZO');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_RECHAZAR_RECHAZO;
        this.toastr.error(msg);
      }
    );
  }

}
