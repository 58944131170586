import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NotifcacionDTO } from '../../dtos/documental/notificacion.dto'
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacionService } from '../services/notificacion.service';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from '../app.message';
import * as moment from 'moment';

@Component({
  selector: 'app-notification-profile',
  templateUrl: './notification-profile.component.html',
  styleUrls: ['./notification-profile.component.scss']
})
export class NotificationProfileComponent { 

  listadoNotificaciones: NotifcacionDTO[] = [];
  intervaloNotificaiones;
  isOpen: boolean = true;

  @ViewChild('notification', { static: true }) notification: ElementRef;
  @ViewChild('pop', { static: true }) pop: ElementRef;
  @ViewChild('close', { static: true }) close: ElementRef;

  constructor(
    private apiDocumental: ApiService,
    private router: Router,
    private notificacionService: NotificacionService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }
  
  ngOnInit() {
    this.getNotificaciones();
    this.intervaloNotificaiones = setInterval(() => {
      this.getNotificaciones();
    }, 60000);

    this.desplegarMenuNotificaciones()
  }
  


  getNotificaciones() {
    this.apiDocumental.getNotificaciones().subscribe((resp) => {
      this.listadoNotificaciones = resp.data;
      this.listadoNotificaciones.sort(function(a, b) {
        return moment(b.fechaCreacion, "YYYY-MM-DDTHH:mm:ss").valueOf() -
          moment(a.fechaCreacion, "YYYY-MM-DDTHH:mm:ss").valueOf();
      });
      this.notificacionService.setNotificaciones(this.listadoNotificaciones);
    }, (error) => {
      console.log(error);
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_NOTIFICACIONES;
      this.toastr.error(msg);
    })
  }

  calcularTiempo(notificacion: NotifcacionDTO) {
    let date1 = new Date();
    let date2 = new Date(notificacion.fechaCreacion);
    const difference = date1.getTime() - date2.getTime();
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `Hace ${days} días`;
    } else if (hours > 0) {
      return `Hace ${hours} horas`;
    } else if (minutes > 0) {
      return `Hace ${minutes} minutos`;
    } else {
      return `Hace ${seconds} segundos`;
    }
  }

  abrirNotificacion(notificacion: NotifcacionDTO) {
    this.marcarComoLeida(notificacion);
    if (notificacion.modulo.toLowerCase() === 'gestion actividad') {
      if (notificacion.uuidRecurso)
      {
        this.getNotificaciones();
        this.gotToActividad(notificacion.uuidRecurso);
      }
      else
        this.toastr.error('No se detectó el uuid de la actividad.');
    }
    if (notificacion.modulo.toLowerCase() === 'documental') {
      if (notificacion.uuidRecurso) {
        this.getNotificaciones();
        this.notificacionService.setUuidRecurso(notificacion.uuidRecurso);
        this.goToDocumental();
        
      }
      else
        this.toastr.error('No se detectó el uuid del documento.');
    }
    if (notificacion.modulo.toLowerCase() === 'reclamos') {
      if (notificacion.uuidRecurso)
      {
        this.getNotificaciones();
        this.goToReclamo(notificacion.uuidRecurso);
      }
      else
        this.toastr.error('No se detectó el uuid del reclamo.');
    }
    if (notificacion.modulo.toLowerCase() === 'mejora continua') {
      if (notificacion.uuidRecurso) {
        this.getNotificaciones();
        this.notificacionService.setUuidRecurso(notificacion.uuidRecurso);
        this.goToArticulo(notificacion.uuidRecurso);
        
      }
      else
        this.toastr.error('No se detectó el uuid del documento.');
    }
  }

  gotToActividad(uuid: string) {
    this.route.params.subscribe(params => {
        this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() =>
        this.router.navigate(['web/gestion-consultar'], { queryParams: { uuidActividad: uuid } }),
        );
    });
  }

  goToReclamo(uuid: string) {
    this.route.params.subscribe(params => {
      this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() =>
      this.router.navigate(['web/consultar-reclamos'], { queryParams: { uuid: uuid, sc: false } })
      );
  });
  }

  goToDocumental() {
    this.route.params.subscribe(params => {
      this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() =>
        this.router.navigate(['web/documental-modolist']),
      );
  });
  }

  goToArticulo(uuid: string) {
    this.route.params.subscribe(params => {
      this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => 
      this.router.navigate(['web/articulo'], {queryParams : { uuidArticulo: uuid}}))
    })
  }

  marcarComoLeida(notificacion: NotifcacionDTO) {
    this.apiDocumental.putMarcarLectura(notificacion.uuid).subscribe((resp) => {
      console.log('MARCADA COMO LEIDA');
    },
      (error) => {
        console.log('ERROR AL LEER');
    })
  }

  desplegarMenuNotificaciones() {
    const notificationElement = this.notification.nativeElement as HTMLElement;
    const popElement = this.pop.nativeElement as HTMLElement;

    function deselect(element: HTMLElement) {
      popElement.style.display = "none";
      element.classList.remove("selected");
    }

    notificationElement.addEventListener("click", function() {
      if (this.classList.contains("selected")) {
        deselect(this as HTMLElement);
      } else {
        this.classList.add("selected");
        popElement.style.display = "block";
      }
      return false;
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervaloNotificaiones);
  }
}
