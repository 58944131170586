import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetObtenerTokenFinalResponseDTO } from 'src/dtos/response/documental/getObtenerTokenFinal.response.dto';
import { environment } from 'src/environments/environment';
import { AppMessage } from '../app.message';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiService,
    private userService: UserService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getQueryParams();
  }

  initLoginSSO() {
    window.location.href = environment.sso + 'login';
  }

  getQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tmp === '') {
        this.alertService.showAlert('No se encuentra autorizado para ingresar');
      } else if (params.tmp !== undefined) {
        this.getObtenerTokenFinal(params.tmp);
      }
    });
  }

  getObtenerTokenFinal(temporal: string) {
    this.apiService.getObtenerTokenFinal(temporal).subscribe(
      (resp) => this.getObtenerTokenFinalSuccess(resp),
      error => this.getObtenerTokenFinalError(error)
    );
    // const response: GetObtenerTokenFinalResponseDTO = {
    //   "success": true,
    //   "msg": "Inicio de sesión exitoso",
    //   "data": {
    //     "token": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI3NTBmZWZjNS1jODlkLTQxZDctYTY4Yi0wYTJjZjMyOTFlZmMiLCJleHAiOjE2NjU1ODIxOTYsImlhdCI6MTY2NTU4MDM5Nn0.VHpRj9YKP3Dg2eCZm10Y6lnybkJ8Phqp9O6jEydQTub9TBd4aMNqMKFktokHvbl9UBBn8HeUHDpdkyeQ225DKA",
    //     "uuid": "5c75b850-7e78-4d67-9f2a-fadfd0913d40",
    //     "nombre": "Oriana",
    //     "apellido": "Ramirez",
    //     "email": "oramirez_cursor@kccl.cl",
    //     "rol": {
    //       "uuid": "212h12h3",
    //       "nombre": "superadmin"
    //     },
    //     "preferencias": {
    //       "area": {
    //         "uuid": "00cf416a-b267-4248-adc4-95121e0d0ead",
    //         "nombre": "AreaTest",
    //         "habilitado": true
    //       },
    //       "subArea": {
    //         "uuid": "6081a15f-bf4f-45bc-b360-d176b8557ee0",
    //         "nombre": "SubAreaTest",
    //         "habilitado": true
    //       },
    //       "sitio": {
    //         "uuid": "122f92c5-59ae-4b3a-b260-6d4966d61bdf",
    //         "nombre": "SitioTest",
    //         "habilitado": true
    //       }
    //     }
    //   }
    // }
    // this.getObtenerTokenFinalSuccess(response);
  }

  getObtenerTokenFinalSuccess(response: GetObtenerTokenFinalResponseDTO) {
    this.userService.setUser(response.data);
    this.userService.setUserToken(response.data.token);
    if (response.data.preferencias) {
      this.router.navigate(['web/home']);
    } else {
      this.router.navigate(['web/profile']);
    }
  }

  getObtenerTokenFinalError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LOGIN_WS;
    this.toastr.error(msg);
  }
}
