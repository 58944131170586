import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { Base64Util } from 'src/app/util/base64.util';
import { AreaDTO } from 'src/dtos/gestion/area.dto';
import { SubareaDTO } from 'src/dtos/gestion/subarea.dto';
import { SitioDTO } from 'src/dtos/gestion/sitio.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';

@Component({
  selector: 'app-modal-mc-subirarchivo',
  templateUrl: './modal-mc-subirarchivo.component.html',
  styleUrls: ['./modal-mc-subirarchivo.component.scss']
})
export class ModalMcSubirarchivoComponent { 

  uuidMetodologia: string
  tipo: string;
  subirForm: FormGroup;
  onClose: Subject<string>;
  blobFile: Blob[] = [];
  listadoAreas: AreaDTO[] = [];
  listadoSubareas: SubareaDTO[] = [];
  listadoSitios: SitioDTO[] = [];
  

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private apiMantenedor: ApiMantenedorService
  ) { }
  
  ngOnInit() {
    this.onClose = new Subject();
    
    this.subirForm = this.formBuilder.group({
      nombre: new FormControl(null, [Validators.required]),
      uuidArea: new FormControl(null),
      uuidSubarea: new FormControl(null),
      uuidSitio: new FormControl(null),
      uuidMetodologia: new FormControl(this.uuidMetodologia),
      file: new FormControl(null),
      fileSource: new FormArray([]),
    })

    this.getListadoAreas();
  }

  getListadoAreas() {
    this.listadoAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listadoAreas = resp.data.filter(area => area.habilitado);
  }
  
  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.toastr.error(msg);
  }

  getListadoSubAreas() {
    this.subirForm.patchValue({ uuidSubarea: null, uuidSitio: null });
    this.listadoSubareas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor(this.subirForm.controls.uuidArea.value).subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listadoSubareas = resp.data.filter(subarea => subarea.habilitado);
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastr.error(msg);
  }

  getListadoSitios() {
    this.subirForm.patchValue({ uuidSitio: null });
    this.listadoSitios = [];
    this.apiMantenedor.getListadoSitiosMantenedor(this.subirForm.controls.uuidSubarea.value).subscribe((resp) => this.getListadoSitiosSuccess(resp),
      (error) => this.getListadoSitiosError(error));
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitios = resp.data.filter(sitio => sitio.habilitado);
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastr.error(msg);
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(fileReader.result));
      };

      fileReader.onloadend = async () => {
        file['url'] = fileReader.result.toString().includes('image') ? fileReader.result.toString() : this.obtenerUrl(file);
      }

      fileReader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.subirForm.get('fileSource') as FormArray).push(new FormControl(file));
      this.subirForm.get('file').patchValue(null);
    }
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
    }
    return url;
  }

  removeAdjunto(i: number) {
    (this.subirForm.get('fileSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }


  getAdjuntos() {
    return (this.subirForm.get('fileSource') as FormArray).controls;
  }

  subirArchivo() {
    if (this.subirForm.valid) {
      const value = this.subirForm.value;
      let formData = new FormData();
      formData.append('nombre', value.nombre);
      formData.append('uuidMetodologia', value.uuidMetodologia);
      formData.append('uuidArea', value.uuidArea);
      formData.append('uuidSubArea', value.uuidSubarea);
      formData.append('uuidSitio', value.uuidSitio);
      

      if (this.tipo === 'implementacion') {
        const nombreArchivos = (this.subirForm.get('fileSource') as FormArray).value;
        this.blobFile.forEach((file, index) => {
          formData.append('archivos[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        });
        this.subirImplementacion(formData);
      }
      if (this.tipo === 'proyecto') {
        const nombreArchivos = (this.subirForm.get('fileSource') as FormArray).value;
        this.blobFile.forEach((file, index) => {
          formData.append('archivo[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        });
        this.subirProyecto(formData);
      }
    }
  }

  subirImplementacion(formData: FormData) {
    this.apiGestion.postSubirImplementacion(formData).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_SUBIR_IMPLEMENTACION);
      this.onClose.next('CREAR');
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_SUBIR_IMPLEMENTACION;
      this.toastr.error(msg);
    })
  }

  subirProyecto(formData: FormData) {
    this.apiGestion.postSubirProyecto(formData).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_SUBIR_PROYECTO);
      this.onClose.next('CREAR');
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_SUBIR_PROYECTO;
      this.toastr.error(msg);
    })
  }
}
