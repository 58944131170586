import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ArticuloDTO } from 'src/dtos/gestion/articulo.dto';

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.scss']
})
export class ArticuloComponent { 

  uuidArticulo: string;
  detalleArticulo: ArticuloDTO;

  constructor(
    private route: ActivatedRoute,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private location: Location,
    private actividadService: ActividadService,
    private router: Router
  ) { }
  
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuidArticulo = params.uuidArticulo;
      console.log(this.uuidArticulo);
      this.getObtenerArticulo();
    });
  }

  getObtenerArticulo() {
    this.apiGestion.getObtenerArticuloDetalle(this.uuidArticulo).subscribe((resp) => {
      this.detalleArticulo = resp.data;
      console.log(this.detalleArticulo);
    }, (error) => {
      this.toastr.error('Error obtener detalle');
      console.log(error);
    })
  }

  goEditarArticulo(uuid: string) {
    this.actividadService.setUuidArticulo(uuid);
    this.router.navigate(['web/editar-crear-articulo']);
  }

  regresar() {
    this.location.back();
  }
}
