import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';

@Component({
  selector: 'app-modal-mc-eliminar',
  templateUrl: './modal-mc-eliminar.component.html',
  styleUrls: ['./modal-mc-eliminar.component.scss']
})
export class ModalMcEliminarComponent { 

  uuid: string;
  nombre: string;
  onClose: Subject<string>;
  tipo: string;


  constructor(
    private apiGestion: ApiGestionService,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.onClose = new Subject();
  }

  eliminar() {
    if (this.tipo === 'material') {
      this.eliminarMaterialEntrenamiento();
    }
    if (this.tipo === 'implementacion') {
      this.eliminarImplementacion();
    }
    if (this.tipo === 'proyecto') {
      this.eliminarProyecto();
    }
    if (this.tipo === 'articulo') {
      this.eliminarArticulo();
    }
  }

  eliminarMaterialEntrenamiento() {
    this.apiGestion.deleteEliminarMaterial(this.uuid).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_BORRAR_MATERIAL);
      this.onClose.next('ELIMINAR');
    },
      (error) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_BORRAR_MATERIAL;
        this.toastr.error(msg);
    })
  }

  eliminarImplementacion() {
    this.apiGestion.deleteEliminarImplentacion(this.uuid).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_ELIMINAR_IMPLEMENTACION);
      this.onClose.next('ELIMINAR');
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_IMPLEMENTACION;
      this.toastr.error(msg);
    })
  }

  eliminarProyecto() {
    this.apiGestion.deleteEliminarProyecto(this.uuid).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_ELIMINAR_PROYECTO);
      this.onClose.next('ELIMINAR');
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_PROYECTO;
      this.toastr.error(msg);
    })
  }

  eliminarArticulo() {
    this.apiGestion.deleteEliminarArticulo(this.uuid).subscribe((resp) => {
      this.toastr.success(AppMessage.SUCCESS_ELIMINAR_ARTICULO);
      this.onClose.next('ELIMINAR');
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_ARTICULO;
      this.toastr.error(msg);
    })
  }
}
