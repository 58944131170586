export class AppMessage {

  static readonly ERROR_REQUEST_DEFAULT: string = 'Ocurrio un error en la petición';

  // LoginPage
  static readonly ERROR_LOGIN_WS: string = 'Ocurrio un error intentando el login';
  static readonly ERROR_LOGIN_EMAIL: string = 'Debe ingresar un correo valido';
  static readonly ERROR_LOGIN_PASSWORD: string = 'Debe ingresar un password valido';
  static readonly ERROR_LOGIN_INVALID: string = 'Usuario y/o contraseña incorrecta';
  static readonly ERROR_LOGIN_SOCIAL_INVALID: string = 'Token invalido';

  // Recuperar Contrasena
  static readonly ERROR_RECUPERAR_CONTRASENA: string = 'Error iniciando proceso de recuperar contraseña';
  static readonly SUCCESS_INTRUCCIONES_CORREO: string = 'Se ha enviado un mail a su correo con las instrucciones para recuperar su contraseña';

  // Registro Usuario
  static readonly ERROR_POST_REGISTRO_USUARIO_WS: string = 'Error registrando el usuario';

  // Cambiar Contrasena
  static readonly ERROR_CAMBIAR_CONTRASENA: string = 'Error cambiando su contraseña';

  // Cerrar Sesion
  static readonly CLOSE_SESION_POPUP_TITLE: string = 'Logout';
  static readonly CLOSE_SESION_POPUP_MSG: string = 'Are you sure you want to logout?';

  // Listar Documentos
  static readonly ERROR_LISTADO_DOCUMENTOS_PAGINADOS: string = 'Error al obtener el listado de documentos.';
  static readonly ERROR_LISTADO_DOCUMENTOS_PAGINADOS_ELIMINADOS: string = 'Error al obtener el listado de documentos eliminados.';
  static readonly ERROR_LISTADO_HISTORIAL_ACCIONES_PAGINADOS: string = 'Error al obtener el historial de acciones del documento.';
  static readonly ERROR_LISTADO_DOCUMENTOS: string = 'Error al obtener el listado de documentos vinculados.';
  static readonly ERROR_LISTADO_MACROPROCESOS: string = 'Error al obtener el listado de macroprocesos.';
  static readonly ERROR_LISTADO_MACROPROCESOS_PAGINADOS: string = 'Error al obtener el listado de macroprocesos por página.';
  static readonly ERROR_LISTADO_PROCESOS: string = 'Error al obtener el listado de procesos.';
  static readonly ERROR_LISTADO_PROCESOS_PAGINADOS: string = 'Error al obtener el listado de procesos por página.';
  static readonly ERROR_LISTADO_AREAS: string = 'Error al obtener el listado de áreas.';
  static readonly ERROR_LISTADO_AREAS_PAGINADOS: string = 'Error al obtener el listado de áreas por página.';
  static readonly ERROR_LISTADO_SUB_AREAS: string = 'Error al obtener el listado de subáreas.';
  static readonly ERROR_LISTADO_SUBAREAS_PAGINADOS: string = 'Error al obtener el listado de subáreas por página.';
  static readonly ERROR_LISTADO_SITIOS: string = 'Error al obtener el listado de sitios.';
  static readonly ERROR_LISTADO_SITIOS_PAGINADOS: string = 'Error al obtener el listado de sitios por página.';
  static readonly ERROR_LISTADO_TIPO_DOCUMENTOS: string = 'Error al obtener el listado tipos de documentos.';
  static readonly ERROR_LISTADO_USUARIOS: string = 'Error al obtener el listado de usuarios.';
  static readonly ERROR_LISTADO_CARGOS: string = 'Error al obtener el listado de cargos.';
  static readonly ERROR_VALIDAR_ID_INTERNO: string = 'Error al validar id interno.';
  static readonly ERROR_PORCENTAJES_HOME_DOCUMENTAL: string = 'Error al obtener el porcentaje de estados de documentos.';

  // Modulos
  static readonly ERROR_OBTENER_MODULOS: string = 'Error al obtener el listado de modulos.';

  // Eliminar Documento
  static readonly ERROR_ELIMINAR_DOCUMENTO: string = 'Error al eliminar documento.';
  static readonly SUCCESS_ELIMINAR_DOCUMENTO: string = 'Se elimino correctamente el documento';

  //subir Documento
  static readonly SUCCESS_SUBIR_DOCUMENTO: string = 'El documento se cargo correctamente.';
  static readonly ERROR_SUBIR_DOCUMENTO: string = 'Error al cargar el documento.';

  //editar Documento
  static readonly SUCCESS_EDITAR_DOCUMENTO: string = 'El documento se edito correctamente.';
  static readonly ERROR_EDITAR_DOCUMENTO: string = 'Error al editar el documento.';

  //actualizar Documento
  static readonly SUCCESS_ACTUALIZAR_DOCUMENTO: string = 'El documento se actualizo correctamente.';
  static readonly ERROR_ACTUALIZAR_DOCUMENTO: string = 'Error al actualizar el documento.';

  //detalle documento
  static readonly ERROR_DETALLE_DOCUMENTO: string = 'Error al obtener el detalle del documento.';
  static readonly ERROR_DETALLE_DOCUMENTO_ELIMINADO: string = 'Error al obtener el detalle del documento eliminado.';

  // DESACARGAR DOCUMENTO
  static readonly ERROR_DESCARGAR_DOCUMENTO: string = 'Error al descargar el archivo adjunto.';
  static readonly SUCCESS_DESCARGAR_DOCUMENTO: string = 'Se ha descargado el archivo adjunto.';

  static readonly ERROR_CARGAR_ARCHIVO: string = 'Error al cargar archivo.';

  // PREFERENCIAS
  static readonly ERROR_REGISTRAR_PREFERENCIAS: string = 'Error al registrar preferencias.';
  static readonly SUCCESS_REGISTRAR_PREFERENCIAS: string = 'Preferencias registradas correctamente.';

  // MACROPROCESOS
  static readonly ERROR_CREAR_MACROPROCESOS: string = 'Error al crear macroproceso.';
  static readonly ERROR_EDITAR_MACROPROCESOS: string = 'Error al editar macroproceso.';
  static readonly ERROR_DESHABILITAR_MACROPROCESOS: string = 'Error al deshabilitar macroproceso.';
  static readonly ERROR_HABILITAR_MACROPROCESOS: string = 'Error al habilitar macroproceso.';
  static readonly ERROR_DETALLE_MACROPROCESO: string = 'Error al obtener detalle de macroproceso.';
  static readonly ERROR_ELIMINAR_MACROPROCESO: string = 'Error al eliminar el macroproceso.';
  static readonly SUCCESS_CREAR_MACROPROCESOS: string = 'Macroproceso creado correctamente.';
  static readonly SUCCESS_EDITAR_MACROPROCESOS: string = 'Macroproceso editado correctamente.';
  static readonly SUCCESS_DESHABILITAR_MACROPROCESOS: string = 'Macroproceso deshabilitado correctamente.';
  static readonly SUCCESS_HABILITAR_MACROPROCESOS: string = 'Macroproceso habilitado correctamente.';
  static readonly SUCCESS_ELIMINAR_MACROPROCESOS: string = 'Macroproceso eliminado correctamente.';

  // PROCESOS
  static readonly ERROR_CREAR_PROCESOS: string = 'Error al crear proceso.';
  static readonly ERROR_EDITAR_PROCESOS: string = 'Error al editar proceso.';
  static readonly ERROR_DESHABILITAR_PROCESOS: string = 'Error al deshabilitar proceso.';
  static readonly ERROR_HABILITAR_PROCESOS: string = 'Error al habilitar proceso.';
  static readonly ERROR_DETALLE_PROCESO: string = 'Error al obtener detalle de proceso.';
  static readonly SUCCESS_CREAR_PROCESOS: string = 'Proceso creado correctamente.';
  static readonly SUCCESS_EDITAR_PROCESOS: string = 'Proceso editado correctamente.';
  static readonly SUCCESS_DESHABILITAR_PROCESOS: string = 'Proceso deshabilitado correctamente.';
  static readonly SUCCESS_HABILITAR_PROCESOS: string = 'Proceso habilitado correctamente.';

  // Areas
  static readonly ERROR_CREAR_AREAS: string = 'Error al crear área.';
  static readonly ERROR_EDITAR_AREAS: string = 'Error al editar área.';
  static readonly ERROR_DESHABILITAR_AREAS: string = 'Error al deshabilitar área.';
  static readonly ERROR_HABILITAR_AREAS: string = 'Error al habilitar área.';
  static readonly ERROR_DETALLE_AREAS: string = 'Error al obtener detalle de área.';
  static readonly SUCCESS_CREAR_AREAS: string = 'Área creada correctamente.';
  static readonly SUCCESS_EDITAR_AREAS: string = 'Área editada correctamente.';
  static readonly SUCCESS_DESHABILITAR_AREAS: string = 'Área deshabilitada correctamente.';
  static readonly SUCCESS_HABILITAR_AREAS: string = 'Área habilitada correctamente.';

  // Subareas
  static readonly ERROR_CREAR_SUBAREAS: string = 'Error al crear subárea.';
  static readonly ERROR_EDITAR_SUBAREAS: string = 'Error al editar subárea.';
  static readonly ERROR_DESHABILITAR_SUBAREAS: string = 'Error al deshabilitar subárea.';
  static readonly ERROR_HABILITAR_SUBAREAS: string = 'Error al habilitar subárea.';
  static readonly ERROR_DETALLE_SUBAREAS: string = 'Error al obtener detalle de subárea.';
  static readonly SUCCESS_CREAR_SUBAREAS: string = 'Subárea creada correctamente.';
  static readonly SUCCESS_EDITAR_SUBAREAS: string = 'Subárea editada correctamente.';
  static readonly SUCCESS_DESHABILITAR_SUBAREAS: string = 'Subárea deshabilitada correctamente.';
  static readonly SUCCESS_HABILITAR_SUBAREAS: string = 'Subárea habilitada correctamente.';

  // Sitios
  static readonly ERROR_CREAR_SITIOS: string = 'Error al crear sitio.';
  static readonly ERROR_EDITAR_SITIOS: string = 'Error al editar sitio.';
  static readonly ERROR_DESHABILITAR_SITIOS: string = 'Error al deshabilitar sitio.';
  static readonly ERROR_HABILITAR_SITIOS: string = 'Error al habilitar sitio.';
  static readonly ERROR_DETALLE_SITIOS: string = 'Error al obtener detalle de sitio.';
  static readonly SUCCESS_CREAR_SITIOS: string = 'Sitio creado correctamente.';
  static readonly SUCCESS_EDITAR_SITIOS: string = 'Sitio editado correctamente.';
  static readonly SUCCESS_DESHABILITAR_SITIOS: string = 'Sitio deshabilitado correctamente.';
  static readonly SUCCESS_HABILITAR_SITIOS: string = 'Sitio habilitado correctamente.';

  // Dimensiones
  static readonly ERROR_LISTAR_DIMENSIONES: string = 'Error al obtener listado de dimensiones.';

  // Tipo Documento
  static readonly ERROR_LISTAR_TIPO_DOCUMENTO: string = 'Error al obtener listado de tipo de documentos.';
  static readonly ERROR_CREAR_TIPO_DOCUMENTO: string = 'Error al crear tipo de documento.';
  static readonly ERROR_LISTADO_TIPO_DOCUMENTO_PAGINADO: string = 'Error al listar tipo de documento páginado.';
  static readonly ERROR_DESHABILITAR_TIPO_DOCUMENTO: string = 'Error al deshabilitar tipo de documento.';
  static readonly ERROR_DETALLE_TIPO_DOCUMENTO: string = 'Error al obtener el detalle de tipo de documento.';
  static readonly ERROR_HABILITAR_TIPO_DOCUMENTO: string = 'Error al habilitar tipo de documento.';
  static readonly ERROR_EDITAR_TIPO_DOCUMENTO: string = 'Error al editar tipo de documento.';
  static readonly SUCCESS_EDITAR_TIPO_DOCUMENTO: string = 'Tipo de documento editado correctamente.';
  static readonly SUCCESS_HABILITAR_TIPO_DOCUMENTO: string = 'Tipo de documento habilitado correctamente.';
  static readonly SUCCESS_DESHABILITAR_TIPO_DOCUMENTO: string = 'Tipo de documento deshabilitado correctamente.';
  static readonly SUCCESS_CREAR_TIPO_DOCUMENTO: string = 'Tipo de documento creado correctamente.';

  // Usuario
  static readonly SUCCESS_EDITAR_USUARIO: string = 'Usuario editado correctamente';
  static readonly ERROR_EDITAR_USUARIO: string = 'Error al editar usuario.';
  static readonly ERROR_LISTAR_USUARIOS_PAGINADO: string = 'Error al obtener listado de usuarios paginados.';
  static readonly ERROR_LISTAR_USUARIOS: string = 'Error al obtener listado de usuarios.';

  // Roles
  static readonly ERROR_LISTAR_ROLES: string = 'Error al obtener listado de roles.';

  // Semaforo
  static readonly SUCCESS_EDITAR_SEMAFORO: string = 'Tiempo de respuesta editado correctamente';
  static readonly ERROR_EDITAR_SEMAFORO: string = 'Error al editar tiempo de respuesta.';
  static readonly ERROR_LISTAR_SEMAFORO: string = 'Error al obtener listado de tiempos de respuestas.';

  // RECLAMOS
  static readonly ERROR_LISTAR_RECLAMOS_PAGINADO: string = 'Error al obtener listado de reclamos.';
  static readonly ERROR_LISTAR_TIPO_RECLAMOS: string = 'Error al obtener listado de tipo de reclamos.';
  static readonly ERROR_LISTAR_TIPO_PEDIDOS: string = 'Error al obtener listado de tipo de pedidos.';
  static readonly ERROR_LISTAR_ORIGEN: string = 'Error al obtener listado de origen.';
  static readonly ERROR_LISTAR_DESVIACIONES: string = 'Error al obtener listado de desviaciones.';
  static readonly ERROR_CREAR_RECLAMO: string = 'Error al crear nuevo reclamo.';
  static readonly ERROR_ELIMINAR_RECLAMO: string = 'Error al eliminar reclamo.';
  static readonly ERROR_EDITAR_RECLAMO: string = 'Error al editar reclamo.';
  static readonly ERROR_OBTENER_RECLAMO: string = 'Error al obtener reclamo.';
  static readonly ERROR_RECHAZAR_RECLAMO: string = 'Error al rechazar reclamo.';
  static readonly ERROR_ACEPTAR_RECHAZO: string = 'Error al aceptar rechazo.';
  static readonly ERROR_RECHAZAR_RECHAZO: string = 'Error al no aceptar rechazo.';
  static readonly ERROR_CERRAR_RECLAMO: string = 'Error al cerrar reclamo.';
  static readonly SUCCESS_EDITAR_RECLAMO: string = 'Se ha editado un nuevo reclamo correctamente.';
  static readonly SUCCESS_TOMAR_RECLAMO: string = 'Se ha tomado un nuevo reclamo correctamente.';
  static readonly SUCCESS_CREAR_RECLAMO: string = 'Se ha creado un nuevo reclamo correctamente.';
  static readonly SUCCESS_ELIMINAR_RECLAMO: string = 'Se ha eliminado el reclamo correctamente.';
  static readonly SUCCESS_RECHAZAR_RECLAMO: string = 'Se ha rechazado el reclamo correctamente.';
  static readonly SUCCESS_ACEPTAR_RECHAZO: string = 'Rechazo aceptado correctamente.';
  static readonly SUCCESS_RECHAZAR_RECHAZO: string = 'Rechazo no aceptado.';
  static readonly SUCCESS_CERRAR_RECLAMO: string = 'Reclamo cerrado correctamente.';

  // DESACARGAR RECLAMOS
  static readonly ERROR_DESCARGAR_RECLAMOS: string = 'Error al descargar reclamo(s).';
  static readonly SUCCESS_DESCARGAR_RECLAMOS: string = 'Se ha descargado el archivo adjunto.';

  // FILIALES
  static readonly ERROR_LISTAR_FILIAL: string = 'Error al obtener listado de filiales.';

  // PROCEDIMIENTO
  static readonly SUCCESS_SUBIR_PROCEDIMIENTO: string = 'Procedimiento subido correctamente.';
  static readonly ERROR_SUBIR_PROCEDIMIENTO: string = 'Error al subir procedimiento';
  static readonly ERROR_GET_PROCEDIMIENTO: string = 'Error al obtener procedimiento';

  // MATERIALES
  static readonly ERROR_OBTENER_MATERIALES: string = 'Error al obtener material.';

  // ESTADOS
  static readonly ERROR_OBTENER_ESTADOS: string = 'Error al obtener listado de estados.';

  // HISTORIAL RECLAMOS
  static readonly ERROR_OBTENER_LISTADO_ACCIONES: string = 'Error al obtener listado de acciones.';
  static readonly ERROR_LISTADO_HISTORIAL_RECLAMO_PAGINADOS: string = 'Error al obtener el historial del reclamo.';

  // ASIGNAR RECLAMO
  static readonly SUCCESS_ASIGNAR_RECLAMO: string = 'Reclamo asignado correctamente.';
  static readonly ERROR_ASIGNAR_RECLAMO: string = 'Error al asignar reclamo.';

  // RESPUESTA OFICIAL
  static readonly SUCCESS_RESPUESTA_OFICIAL: string = 'Respuesta guardada correctamente.';
  static readonly ERROR_RESPUESTA_OFICIAL: string = 'Error al guardar respuesta.';
  static readonly SUCCESS_ENVIAR_RESPUESTA: string = 'Respuesta enviada correctamente.';
  static readonly ERROR_ENVIAR_RESPUESTA: string = 'Error al enviar respuesta.'
  static readonly SUCCESS_EDITAR_RESPUESTA: string = 'Respuesta editada correctamente.';
  static readonly ERROR_EDITAR_RESPUESTA: string = 'Error al editar respuesta.';

  // COMENTARIOS
  static readonly ERROR_OBTENER_COMENTARIOS: string = 'Error al obtener comentarios.';
  static readonly ERROR_RESPONDER_COMENTARIO: string = 'Error al enviar comentario.';
  static readonly ERROR_DESCARGAR_ADJUNTO: string = 'Error al descargar adjunto.';

  // CATEGORIAS
  static readonly ERROR_OBTENER_CATEGORIAS: string = 'Error al obtener listado de categorias.';
  static readonly SUCCESS_CREAR_CATEGORIA: string = 'La categoria se creo correctamente';
  static readonly ERROR_CREAR_CATEGORIA: string = 'Error al crear la categoria';
  static readonly SUCCESS_EDITAR_CATEGORIA: string = 'Categoria editada correctamente';
  static readonly ERROR_EDITAR_CATEGORIA: string = 'Error al editar la categoria';
  static readonly SUCCESS_DESHABILITAR_CATEGORIA: string = 'Categoria deshabilitada exitosamente';
  static readonly ERROR_DESHABILITAR_CATEGORIA: string = 'Error al deshabilitar la categoria';
  static readonly SUCCESS_HABILITAR_CATEGORIA: string = 'Categoria habilitada exitosamente';
  static readonly ERROR_HABILITAR_CATEGORIA: string = 'Error al habilitar la categoria';


  // SUB-CATEGORIAS-UNO
  static readonly ERROR_OBTENER_SUB_CATEGORIAS_UNO: string = 'Error al obtener listado de sub-categorias 1.';
  static readonly SUCCESS_CREAR_SUB_CATEGORIA_UNO: string = 'La sub-categoria se creo correctamente';
  static readonly ERROR_CREAR_SUB_CATEGORIA_UNO: string = 'Error al crear la sub-categoria';
  static readonly SUCCESS_EDITAR_SUB_CATEGORIA_UNO: string = 'Sub-categoria editada correctamente';
  static readonly ERROR_EDITAR_SUB_CATEGORIA_UNO: string = 'Error al editar la sub-categoria';
  static readonly SUCCESS_DESHABILITAR_SUB_CATEGORIA_UNO: string = 'Sub-categoria deshabilitada exitosamente';
  static readonly ERROR_DESHABILITAR_SUB_CATEGORIA_UNO: string = 'Error al deshabilitar la sub-categoria';
  static readonly SUCCESS_HABILITAR_SUB_CATEGORIA_UNO: string = 'Sub-categoria habilitada exitosamente';
  static readonly ERROR_HABILITAR_SUB_CATEGORIA_UNO: string = 'Error al habilitar la sub-categoria';

  // SUB-CATEGORIAS-DOS
  static readonly ERROR_OBTENER_SUB_CATEGORIAS_DOS: string = 'Error al obtener listado de sub-categorias 2.';

  // FRECUENCIAS
  static readonly ERROR_OBTENER_FRECUENCIAS: string = 'Error al obtener listado de frecuencias.';

  // RANGO DE DURACION
  static readonly ERROR_OBTENER_RANGOS_DURACION: string = 'Error al obtener listado de rangos de duracion';

  // ACTIVIDAD
  static readonly SUCCESS_CREAR_ACTIVIDAD: string = 'La actividad se creo correctamente.';
  static readonly ERROR_CREAR_ACTIVIDAD: string = 'Error al crear la actividad.';
  static readonly ERROR_DETALLE_ACTIVIDAD: string = 'Error al obtener el detalle de la actividad.';
  static readonly ERROR_EDITAR_ACTIVIDAD: string = 'Error al editar la actividad.';
  static readonly SUCCESS_EDITAR_ACTIVIDAD: string = 'Actividad editada correctamente.';
  static readonly ERROR_ELIMINAR_ACTIVIDAD: string = 'Error al eliminar la actividad.';
  static readonly SUCCESS_ELIMINAR_ACTIVIDAD: string = 'Actividad eliminada correctamente.';
  static readonly ERROR_TERMINAR_ACTIVIDAD: string = 'Error al marcar la actividad como terminada.';
  static readonly SUCCESS_TERMINAR_ACTIVIDAD: string = 'Actividad marcada como terminada exitosamente.';
  static readonly SUCCESS_ADJUNTAR_EVIDENCIA: string = 'Evidencia adjuntada correctamente.';
  static readonly ERROR_ADJUNTAR_EVIDENCIA: string = 'Error al adjuntar la evidencia.';
  static readonly ERROR_OBTENER_EVIDENCIA: string = 'Error al obtener la evidencia de la actividad.';
  static readonly SUCCESS_ENVIAR_ACTIVIDAD: string = 'Actividad enviada correctamente.';
  static readonly ERROR_ENVIAR_ACTIVIDAD: string = 'Error al enviar actividad.';
  static readonly ERROR_DESCARGAR_ACTIVIDADES: string = 'Error al descargar actividades.';

  // LISTADO ACTIVIDADES
  static readonly ERROR_LISTADO_ACTIVIDADES: string = 'Error al obtener el listado de actividades.';

  // EXPORTAR ACTIVIDADES
  static readonly ERROR_EXPORTAR_LISTADO_ACTIVIDADES: string = 'Error al exportar el listado de actividades.';
  static readonly SUCCESS_EXPORTAR_LISTADO_ACTIVIDADES: string = 'Archivo exportado correctamente.';

  //PLAN DE ACCION
  static readonly ERROR_CREAR_PLAN_DE_ACCION: string = 'Error al crear el plan de acción.';
  static readonly SUCCESS_CREAR_PLAN_DE_ACCION: string = 'Plan de acción creado correctamente.';
  static readonly ERROR_OBTENER_PLANES_DE_ACCION: string = 'Error al obtener listado de planes de acción.';
  static readonly ERROR_OBTENER_DETALLE_PLAN_DE_ACCION: string = 'Error al obtener el plan de acción.';
  static readonly ERROR_EDITAR_PLAN_DE_ACCION: string = 'Error al editar el plan de acción.';
  static readonly SUCCESS_EDITAR_PLAN_DE_ACCION: string = 'Plan de acción editado correctamente.';
  static readonly ERROR_REALIZAR_PLAN_DE_ACCION: string = 'Error al realizar el plan de acción.';
  static readonly SUCCESS_REALIZAR_PLAN_DE_ACCION: string = 'Plan de acción realizado correctamente.';

  // HISTORIAL ACTIVIDADES
  static readonly ERR0R_OBTENER_LISTADO_HISTORIAL_ACTIVIDADES_PAGINADO: string = 'Error al obtener historial paginado de la actividad';

  // NOTIFICACIONES
  static readonly ERROR_OBTENER_LISTADO_NOTIFICACIONES: string = 'Error al obtener el listado de notificaciones.';

  // METODOLOGIAS
  static readonly ERROR_OBTENER_LISTADO_METODOLOGIAS: string = 'Error al obtener el listado de metodologias.'

  // MATERIAL DE ENTRENAMIENTO
  static readonly ERROR_OBTENER_LISTADO_MATERIALES: string = 'Error al obtener el listado de materiales.';
  static readonly ERROR_BORRAR_MATERIAL: string = 'Error al borrar el material.';
  static readonly SUCCESS_BORRAR_MATERIAL: string = 'Material borrado correctamente.';
  static readonly ERROR_SUBIR_MATERIAL: string = 'Error al subir el material.';
  static readonly SUCCESS_SUBIR_MATERIAL: string = 'Material subido correctamente.';
  static readonly SUCCESS_DESCARGAR_ADJUNTO: string = 'Adjunto descargado correctamente.';

  // FOTOS DE IMPLEMENTACION
  static readonly SUCCESS_SUBIR_IMPLEMENTACION: string = 'Foto de implmentación subida correctamente.';
  static readonly ERROR_SUBIR_IMPLEMENTACION: string = 'Error al subir la foto de implementación.';
  static readonly SUCCESS_ELIMINAR_IMPLEMENTACION: string = 'Foto de implementación eliminada correctamente.'
  static readonly ERROR_ELIMINAR_IMPLEMENTACION: string = 'Error al eliminar la foto de implementación.';

  // PROYECTOS
  static readonly SUCCESS_SUBIR_PROYECTO: string = 'Proyecto subido correctamente.';
  static readonly ERROR_SUBIR_PROYECTO: string = 'Error al subir proyecto.';
  static readonly SUCCESS_ELIMINAR_PROYECTO: string = 'Proyecto eliminado correctamente.';
  static readonly ERROR_ELIMINAR_PROYECTO: string = 'Error al eliminar el proyecto.';

  // ARTICULOS
  static readonly SUCCESS_ELIMINAR_ARTICULO: string = 'Articulo eliminado correctamente.';
  static readonly ERROR_ELIMINAR_ARTICULO: string = 'Error al eliminar el articulo.';
}
