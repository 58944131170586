import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PostListadoDocumentoPaginadoRequestDTO } from 'src/dtos/request/documental/postListadoDocumentoPaginado.request.dto';
import { environment } from 'src/environments/environment';
import { AppMessage } from '../../app.message';
import { ApiService } from '../../services/api.service';
import { ModalActualizarComponent } from '../modals/modal-actualizar/modal-actualizar.component';
import { ModalDetalleComponent } from '../modals/modal-detalle/modal-detalle.component';
import { ModalEditarComponent } from '../modals/modal-editar/modal-editar.component';
import { ModalEliminarComponent } from '../modals/modal-eliminar/modal-eliminar.component';
import { ModalSubirComponent } from '../modals/modal-subir/modal-subir.component';
import { ModalHistorialComponent } from '../modals/modal-historial/modal-historial.component';
import { saveAs } from 'file-saver';
import { PostExportarXLSDocumentosRequestDTO } from 'src/dtos/request/documental/postExportarXLSDocumentos.request.dto';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { UserService } from 'src/app/services/user.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GetPostListadoDocumentosPaginadoResponseDTO } from 'src/dtos/response/documental/getPostListadoDocumentosPaginado.response.dto';
import { NotificacionService } from 'src/app/services/notificacion.service';

@Component({
  selector: 'app-documental-modolist',
  templateUrl: './documental-modolist.component.html',
  styleUrls: ['./documental-modolist.component.scss']
})
export class DocumentalModolistComponent implements OnInit {
  listadoDocumentos: DocumentoDTO[] = [];
  auxListado: DocumentoDTO[] = [];
  filtros: any;

  totalDocumentos: number = 0;
  pageSize: number = 10;
  currentPage: number = 0;
  maxSize: number = environment.paginatorMaxSize;
  contCheck: number = 0;

  checkDocumentos = false;
  viewFilter: boolean = false;
  loader: boolean = false;

  busqueda = new FormControl(null);

  uuidUser: string;

  formCtrlSub: Subscription;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private userService: UserService,
    private modalService: BsModalService,
    private notificacionService: NotificacionService
  ) { }

  ngOnInit(): void {
    const user = this.userService.getUser();
    this.uuidUser = user.uuid;
    this.viewFilter = false;
    this.initFilters();
    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoDocumentos = [];
        this.getListadoDocumentosPaginadoBusqueda();
      }
    });
  }

  ngDoCheck() {
    if (this.notificacionService.getUuidRecurso()) {
      this.openModalDetalle(this.notificacionService.getUuidRecurso());
      this.notificacionService.setUuidRecurso(null);
    }
  }

  pageChanged(event: any): void {
    this.checkDocumentos = true;
    this.seleccionMultiple();
    this.currentPage = event.page;

    if (this.busqueda.value?.length > 0) {
      this.getListadoDocumentosPaginadoBusqueda();
    } else {
      this.postListadoDocumentosPaginado();
    }
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      fechaCreacion: null,
      fechaEdicion: null,
      uuidMacroproceso: null,
      uuidArea: null,
      uuidCreador: null,
      tipoDocumento: null,
      fechaVencimiento: null,
      documentoVinculados: null,
      uuidProceso: null,
      uuidSubarea: null,
      idInterno: null,
      estado: null
    };
    if (initData) {
      this.postListadoDocumentosPaginado();
    }
  }

  filterDoc(event: PostListadoDocumentoPaginadoRequestDTO) {
    this.currentPage = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postListadoDocumentosPaginado();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  seleccionMultiple() {
    this.auxListado = this.listadoDocumentos;
    this.contCheck = 0;
    for (const iterator of this.auxListado) {
      if (!this.checkDocumentos) {
        this.contCheck++;
      }
      iterator.checkDocumento = !this.checkDocumentos;
    }
    this.checkDocumentos = !this.checkDocumentos;
  }

  selDocumento(uuid: string) {
    const documentoAux = this.listadoDocumentos.find(d => d.uuid === uuid);

    if (documentoAux && documentoAux.checkDocumento) {
      documentoAux.checkDocumento = false;
      if (this.contCheck > 0) {
        this.contCheck--;
      }
    } else {
      documentoAux.checkDocumento = true;
      this.contCheck++;
    }

    this.checkDocumentos = (this.contCheck > 1) ? true : false;
    this.auxListado = this.listadoDocumentos;
  }

  exportFile() {
    const documentos: PostExportarXLSDocumentosRequestDTO = {
      uuidDocumentos: this.auxListado.filter(doc => doc.checkDocumento).map(doc => doc.uuid)
    };
    if (documentos.uuidDocumentos.length > 0) {
      this.apiService.postExportarXLSDocumentos(documentos).subscribe(
        res => this.postExportarXLSDocumentosSuccess(res),
        error => this.postExportarXLSDocumentosError(error)
      );
    }
  }

  postExportarXLSDocumentosSuccess(response: any): void {
    let blob = new Blob([response.body], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, `listaDocumentos.xlsx`);
    this.toastr.success(AppMessage.SUCCESS_DESCARGAR_DOCUMENTO);
  }

  postExportarXLSDocumentosError(error: any) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_DOCUMENTO;
    this.toastr.error(msg);
  }

  getListadoDocumentosPaginadoBusqueda() {
    this.loader = true;

    this.apiService.getListadoDocumentosPaginadoBusqueda(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (resp) => this.listadoDocumentosPaginadoSuccess(resp),
      (error) => this.listadoDocumentosPaginadoError(error)
    );
  }

  postListadoDocumentosPaginado() {
    this.loader = true;
    this.busqueda.setValue(null);

    const dataRequest: PostListadoDocumentoPaginadoRequestDTO = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      fechaEdicionInicio: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[0] : null,
      fechaEdicionTermino: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[1] : null,
      fechaVencimientoInicio: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[0] : null,
      fechaVencimientoTermino: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[1] : null,
      uuidSubarea: this.filtros.uuidSubarea,
      uuidProceso: this.filtros.uuidProceso,
      uuidCreador: this.filtros.uuidCreador,
      uuidArea: this.filtros.uuidArea,
      tipoDocumento: this.filtros.tipoDocumento,
      uuidMacroproceso: this.filtros.uuidMacroproceso,
      estado: this.filtros.estado,
      documentoVinculados: this.filtros.documentoVinculados,
      idInterno: this.filtros.idInterno,
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
    this.apiService.postListadoDocumentosPaginado(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
      (resp) => this.listadoDocumentosPaginadoSuccess(resp),
      (error) => this.listadoDocumentosPaginadoError(error)
    );
  }

  listadoDocumentosPaginadoSuccess(response: GetPostListadoDocumentosPaginadoResponseDTO) {
    this.listadoDocumentos = response.data.content;
    this.totalDocumentos = response.data.totalElements;

    this.currentPage = response.data.pageable.pageNumber + 1;
    for (const iterator of this.listadoDocumentos) {
      iterator.checkDocumento = false;
    }
    this.auxListado = this.listadoDocumentos;
    this.checkDocumentos = false;
    this.loader = false;
  }

  listadoDocumentosPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS_PAGINADOS;
    this.toastr.error(msg);
    this.loader = false;
  }

  getDescargarDocumentoModoList(url: string) {
    window.open(url, '_self');
  }

  openModalSubir() {
    const modalCrear: BsModalRef = this.modalService.show(ModalSubirComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg"
    });

    this.cerrarModalCargaData(modalCrear);
  }

  openModalEditar(doc: DocumentoDTO) {
    const modalEditar: BsModalRef = this.modalService.show(ModalEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        uuid: doc.uuid
      }
    });

    this.cerrarModalCargaData(modalEditar);
  }

  openModalEliminar(uuid: string, nombre: string) {
    const modalElimina: BsModalRef = this.modalService.show(ModalEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid,
        nombre
      }
    });

    this.cerrarModalCargaData(modalElimina);
  }

  openModalActualizar(doc: DocumentoDTO) {
    const modalActualizar: BsModalRef = this.modalService.show(ModalActualizarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        uuid: doc.uuid
      }
    });

    this.cerrarModalCargaData(modalActualizar);
  }

  openModalDetalle(uuid: string) {
    const modalDetalle: BsModalRef = this.modalService.show(ModalDetalleComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        estadoDetalle: 'ACTUAL'
      }
    });

    this.cerrarModalCargaData(modalDetalle);
  }

  openModalHistorial(uuid: string, nombreDoc: string) {
    this.modalService.show(ModalHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        nombreDoc
      }
    });
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      if (myData === 'ELIMINAR' || myData === 'EDITAR' || myData === 'ACTUALIZAR') {
        this.postListadoDocumentosPaginado();
      }
      if (myData === 'CREAR') {
        this.initFilters();
      }
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}

