import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';

@Component({
  selector: 'app-modal-td-deshabilitar',
  templateUrl: './modal-td-deshabilitar.component.html',
  styleUrls: ['./modal-td-deshabilitar.component.scss']
})
export class ModalTdDeshabilitarComponent {
  uuid: string;
  nombreTipo: string;

  onClose: Subject<string>;

  constructor(
    private apiServiceMantenedor: ApiMantenedorService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  deshabilitarTipo() {
    this.apiServiceMantenedor.putDeshabilitarTipoDocumentoMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_TIPO_DOCUMENTO);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }
}
