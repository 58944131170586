<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Habilitar Tipo de documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-text">Está apunto de habilitar el Tipo de documento {{nombreTipo}},
            <br><strong>¿Desea continuar?</strong>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal-black close-modal" aria-label="Close"
            (click)="bsModalRef.hide()">Cancelar</button>
        <button type="button" class="btn btn-modal" (click)="habilitarTipo()">Sí, habilitar</button>
    </div>
</div>