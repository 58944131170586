import { TipoDocumentoDTO } from "src/dtos/mantenedor/tipoDocumento.dto";
import { BaseResponseDTO } from "../base.response.dto";

export class GetListadoTipoDocumentoPaginadosResponseDTO extends BaseResponseDTO {
    success: boolean;
    msg: string;
    data: {
        content: Array<TipoDocumentoDTO>;
        pageable: {
            sort: {
                empty: boolean;
                sorted: boolean;
                unsorted: boolean;
            };
            offset: number;
            pageNumber: number;
            pageSize: number;
            paged: boolean;
            unpaged: boolean;
        };
        totalPages: number;
        totalElements: number;
        last: boolean;
        number: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        size: number;
        numberOfElements: number;
        first: boolean;
        empty: boolean;
    };
}
