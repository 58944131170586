import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { DesviacionDTO } from 'src/dtos/mantenedor/desviacion.dto';
import { PostCerrarReclamoRequestDTO } from 'src/dtos/request/mantenedor/postCerrarReclamo.request.dto';
import { GetListadoDesviacionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDesviaciones.response.dto';
import { PostCerrarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postCerrarReclamo.response.dto';

@Component({
  selector: 'app-modal-reclamos-cerrar',
  templateUrl: './modal-reclamos-cerrar.component.html',
  styleUrls: ['./modal-reclamos-cerrar.component.scss']
})
export class ModalReclamosCerrarComponent {
  uuid: string;
  idInterno: string;
  idImpacto: string;

  loaderDesviacion: boolean = false;

  listadoDesviaciones: DesviacionDTO[] = [];

  cerrarForm: FormGroup;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();

    this.cerrarForm = this.formBuilder.group({
      impactoDesviacion: new FormControl(this.idImpacto, [Validators.required]),
      descripcion: new FormControl(null),
      opcionCerrar: new FormControl(null, [Validators.required])
    });
    this.getListadoDesviacionMantenedor();
  }

  cerrarReclamo() {
    const data: PostCerrarReclamoRequestDTO = {
      conforme: this.cerrarForm.value.opcionCerrar,
      uuidImpactoDesviacion: this.cerrarForm.value.impactoDesviacion,
      motivo: this.cerrarForm.value.descripcion
    };
    this.apiServiceMantenedor.postCerrarReclamo(this.uuid, data).subscribe(
      (response: PostCerrarReclamoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CERRAR_RECLAMO);
        this.onClose.next('CERRAR');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CERRAR_RECLAMO;
        this.toastr.error(msg);
      }
    );
  }

  getListadoDesviacionMantenedor() {
    this.loaderDesviacion = true;
    this.apiServiceMantenedor.getListadoDesviacionMantenedor().subscribe(
      (response: GetListadoDesviacionesResponseDTO) => {
        this.listadoDesviaciones = response.data;
        this.loaderDesviacion = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_DESVIACIONES;
        this.toastr.error(msg);
        this.loaderDesviacion = false;
      }
    );
  }
}
