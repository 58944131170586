import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalTdCrearComponent } from './modals/modal-td-crear/modal-td-crear.component';
import { ModalTdEditarComponent } from './modals/modal-td-editar/modal-td-editar.component';
import { ModalTdHabilitarComponent } from './modals/modal-td-habilitar/modal-td-habilitar.component';
import { ModalTdDeshabilitarComponent } from './modals/modal-td-deshabilitar/modal-td-deshabilitar.component';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { GetListadoTipoDocumentoPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoDocumentoPaginados.response.dto';
import { TipoDocumentoDTO } from 'src/dtos/mantenedor/tipoDocumento.dto';

@Component({
  selector: 'app-mantenedor-tipodocumento',
  templateUrl: './mantenedor-tipodocumento.component.html',
  styleUrls: ['./mantenedor-tipodocumento.component.scss']
})
export class MantenedorTipodocumentoComponent {
  listadoPagTipoDocumento: TipoDocumentoDTO[] = [];

  totalRegisterTipoDocumento: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  indexSelect: number;

  busqueda: string;

  loaderPaginado: boolean = false;

  constructor(
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentPage = 1;
    this.getListadoTipoDocumentoPaginado();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getListadoTipoDocumentoPaginado();
  }

  concatVinculados(tipoDocumentosVinculados: TipoDocumentoDTO[]) {
    let nombreVinculados = '';
    tipoDocumentosVinculados.forEach((nombre, index) => {
      nombreVinculados += nombre.nombre;
      if ((index + 1) < tipoDocumentosVinculados.length) {
        nombreVinculados += ', ';
      }
    });
    return nombreVinculados;
  }

  getListadoTipoDocumentoPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoTipoDocumentoPaginadoMantenedor(this.currentPage - 1, this.pageSize).subscribe(
      (response: GetListadoTipoDocumentoPaginadosResponseDTO) => {
        this.listadoPagTipoDocumento = response.data.content;
        this.totalRegisterTipoDocumento = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_TIPO_DOCUMENTO_PAGINADO;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  openModalTdCrear() {
    const modalCrear: BsModalRef = this.modalService.show(ModalTdCrearComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
    });
    this.hideModal(modalCrear);
  }

  openModalTdEditar(tipo: TipoDocumentoDTO) {
    const modalEditar: BsModalRef = this.modalService.show(ModalTdEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: tipo.uuid
      }
    });
    this.hideModal(modalEditar);
  }

  openModalTdHabilitar(tipo: TipoDocumentoDTO) {
    const modalHabilitar: BsModalRef = this.modalService.show(ModalTdHabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: tipo.uuid,
        nombreTipo: tipo.nombre
      }
    });
    this.hideModal(modalHabilitar);
  }

  openModalTdDeshabilitar(tipo: TipoDocumentoDTO) {
    const modalDeshabilitar: BsModalRef = this.modalService.show(ModalTdDeshabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: tipo.uuid,
        nombreTipo: tipo.nombre
      }
    });
    this.hideModal(modalDeshabilitar);
  }

  hideModal(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.getListadoTipoDocumentoPaginado();
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
