import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { PostListadoReclamoPaginadoRequestDTO } from 'src/dtos/request/mantenedor/postListadoReclamoPaginado.request.dto';
import { PostCrearAreaRequestDTO } from 'src/dtos/request/mantenedor/postCrearArea.request.dto';
import { PostCrearProcesoRequestDTO } from 'src/dtos/request/mantenedor/postCrearProceso.request.dto';
import { PostCrearSitioRequestDTO } from 'src/dtos/request/mantenedor/postCrearSitio.request.dto';
import { PostCrearSubareaRequestDTO } from 'src/dtos/request/mantenedor/postCrearSubarea.request.dto';
import { PostCrearTipoDocumentoRequestDTO } from 'src/dtos/request/mantenedor/postCrearTipoDocumento.request.dto';
import { PutActualizarAreaRequestDTO } from 'src/dtos/request/mantenedor/putActualizarArea.request.dto';
import { PutActualizarMacroprocesoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarMacroproceso.request.dto';
import { PutActualizarProcesoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarProceso.request.dto';
import { PutActualizarSemaforoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSemaforo.request.dto';
import { PutActualizarSitioRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSitio.request.dto';
import { PutActualizarSubareaRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSubarea.request.dto';
import { PutActualizarTipoDocumentoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarTipoDocumento.request.dto';
import { PutActualizarUsuarioRequestDTO } from 'src/dtos/request/mantenedor/putActualizarUsuario.request.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetDetalleAreaResponseDTO } from 'src/dtos/response/mantenedor/getDetalleArea.response.dto';
import { GetDetalleMacroprocesoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleMacroproceso.response.dto';
import { GetDetalleProcesoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleProceso.response.dto';
import { GetDetalleSitioResponseDTO } from 'src/dtos/response/mantenedor/getDetalleSitio.response.dto';
import { GetDetalleSubareaResponseDTO } from 'src/dtos/response/mantenedor/getDetalleSubarea.response.dto';
import { GetDetalleTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleTipoDocumento.response.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoAreasPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreasPaginados.response.dto';
import { GetListadoDesviacionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDesviaciones.response.dto';
import { GetListadoDimensionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDimensiones.response.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoMacroprocesos.response.dto';
import { GetListadoMacroprocesosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoMacroprocesosPaginados.response.dto';
import { GetListadoOrigenResponseDTO } from 'src/dtos/response/mantenedor/getListadoOrigen.response.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoProcesos.response.dto';
import { GetListadoProcesosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoProcesosPaginados.response.dto';
import { GetPostListadoReclamosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoReclamosPaginados.response.dto';
import { GetListadoRolesResponseDTO } from 'src/dtos/response/mantenedor/getListadoRoles.response.dto';
import { GetListadoSemaforoResponseDTO } from 'src/dtos/response/mantenedor/getListadoSemaforo.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSitiosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitiosPaginados.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { GetListadoSubareaPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubareasPaginados.response.dto';
import { GetListadoTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoDocumento.response.dto';
import { GetListadoTipoDocumentoPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoDocumentoPaginados.response.dto';
import { GetListadoTipoPedidoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoPedido.response.dto';
import { GetListadoTipoReclamoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoReclamo.response.dto';
import { GetListadoUsuarioResponseDTO } from 'src/dtos/response/mantenedor/getListadoUsuarios.response.dto';
import { GetListadoUsuariosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoUsuariosPaginados.response.dto';
import { GetListadoVigenciasResponseDTO } from 'src/dtos/response/mantenedor/getListadoVigencias.response.dto';
import { PostCrearAreasResponseDTO } from 'src/dtos/response/mantenedor/postCrearAreas.response.dto';
import { PostCrearMacroprocesosResponseDTO } from 'src/dtos/response/mantenedor/postCrearMacroprocesos.response.dto';
import { PostCrearProcesosResponseDTO } from 'src/dtos/response/mantenedor/postCrearProcesos.response.dto';
import { PostCrearSitioResponseDTO } from 'src/dtos/response/mantenedor/postCrearSitio.response.dto';
import { PostCrearSubareasResponseDTO } from 'src/dtos/response/mantenedor/postCrearSubareas.response.dto';
import { PostCrearTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/postCrearTipoDocumento.response.dto';
import { PutActualizarAreaResponseDTO } from 'src/dtos/response/mantenedor/putActualizarArea.response.dto';
import { PutActualizarMacroprocesoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarMacroproceso.response.dto';
import { PutActualizarProcesoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarProceso.response.dto';
import { PutActualizarSemaforoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSemaforo.response.dto';
import { PutActualizarSitioResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSitio.response.dto';
import { PutActualizarSubareaResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSubarea.response.dto';
import { PutActualizarTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarTipoDocumento.response.dto';
import { PutActualizarUsuarioResponseDTO } from 'src/dtos/response/mantenedor/putActualizarUsuario.response.dto';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { PostExportarReclamosRequestDTO } from 'src/dtos/request/mantenedor/postExportarReclamo.request.dto';
import { PutEliminarReclamoResponseDTO } from 'src/dtos/response/mantenedor/putEliminarReclamo.response.dto';
import { PutEditarReclamoResponseDTO } from 'src/dtos/response/mantenedor/putEditarReclamo.response.dto';
import { GetObtenerProcedimientoResponseDTO } from 'src/dtos/response/mantenedor/getObtenerProcedimiento.response.dto';
import { PostIngresarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postIngresarReclamo.response.dto';
import { GetListadoMaterialesResponseDTO } from 'src/dtos/response/mantenedor/getListadoMateriales.response.dto';
import { GetDetalleReclamoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleReclamo.response.dto';
import { GetListadoEstadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoEstados.response.dto';
import { GetPostListadoHistorialReclamoPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoHistorialReclamoPaginados.response.dto';
import { PostHistorialReclamoRequestDTO } from 'src/dtos/request/mantenedor/postHistorialReclamo.request.dto';
import { PostAsignarReclamoRequestDTO } from 'src/dtos/request/mantenedor/postAsignarReclamo.request.dto';
import { PostAsignarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postAsignarReclamo.response.dto';
import { PostRechazarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postRechazarReclamo.response.dto';
import { PostCerrarReclamoRequestDTO } from 'src/dtos/request/mantenedor/postCerrarReclamo.request.dto';
import { PostGuardarRespuestaOficialResponseDTO } from 'src/dtos/response/mantenedor/postGuardarRespuestaOficial.response.dto';
import { PostEnviarRespuestaOficialResponseDTO } from 'src/dtos/response/mantenedor/postEnviarRespuestaOficial.response.dto';
import { PostRechazarRechazoResponseDTO } from 'src/dtos/response/mantenedor/postRechazarRechazo.response.dto';
import { PostCerrarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postCerrarReclamo.response.dto';
import { GetListadoComentariosResponseDTO } from 'src/dtos/response/mantenedor/getListadoComentarios.response.dto';
import { PostEnviarComentarioResponseDTO } from 'src/dtos/response/mantenedor/postEnviarComentario.response.dto';
import { GetPorcentajesHomeRequestDTO } from 'src/dtos/request/documental/getPorcentajesHome.request.dto';
import { GetPorcentajesHomeReclamosResponseDTO } from 'src/dtos/response/mantenedor/getPorcentajesHomeReclamos.response.dto';

@Injectable({
  providedIn: 'root'
})
export class ApiMantenedorService {
  private hostMantenedor: string = environment.hostUrlMantenedor;

  constructor(
    private http: HttpClient,
    private userService: UserService) { }

  // MACROPROCESOS
  // Listado simple de Macroprocesos Mantenedor
  getListadoMacroprocesosMantenedor(): Observable<GetListadoMacroprocesosResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoMacroprocesosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de Macroprocesos paginado Mantenedor con busqueda 
  getListadoMacroprocesosPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string): Observable<GetListadoMacroprocesosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos/busqueda-paginada?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoMacroprocesosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear Macroproceso
  postCrearMacroprocesoMantenedor(nombre: string): Observable<PostCrearMacroprocesosResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos';
    return this.http.post<string>(url, JSON.stringify({ nombre })).pipe(
      map((resp) => new PostCrearMacroprocesosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener Macroproceso
  getDetalleMacroprocesoMantenedor(uuid: string): Observable<GetDetalleMacroprocesoResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos/' + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleMacroprocesoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar macroproceso
  putActualizarMacroprocesoMantenedor(data: PutActualizarMacroprocesoRequestDTO): Observable<PutActualizarMacroprocesoResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos';
    return this.http.put<PutActualizarMacroprocesoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarMacroprocesoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar macroproproceso
  putHabilitarMacroprocesoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar macroproproceso
  putDeshabilitarMacroprocesoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'macroprocesos/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Eliminar macroproceso
  deleteMacroprocesoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    const url = this.hostMantenedor + 'macroprocesos/' + uuid + '/eliminar';
    return this.http.delete<string>(url, {}).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error)));
  }

  // PROCESO
  // Listado simple de Procesos
  getListadoProcesosMantenedor(uuid?: string): Observable<GetListadoProcesosResponseDTO> {
    let url = this.hostMantenedor + 'procesos';
    if (uuid) {
      url += '?uuidMacroproceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoProcesosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de Procesos paginado con busqueda
  getListadoProcesosPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string, uuid?: string): Observable<GetListadoProcesosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'procesos/busqueda-paginada?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    if (uuid) {
      url += '&uuidMacroproceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoProcesosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear
  postCrearProcesoMantenedor(data: PostCrearProcesoRequestDTO): Observable<PostCrearProcesosResponseDTO> {
    let url = this.hostMantenedor + 'procesos';
    return this.http.post<PostCrearProcesoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCrearProcesosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener proceso
  getDetalleProcesoMantenedor(uuid: string): Observable<GetDetalleProcesoResponseDTO> {
    let url = this.hostMantenedor + 'procesos/' + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleProcesoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar proceso
  putActualizarProcesoMantenedor(data: PutActualizarProcesoRequestDTO): Observable<PutActualizarProcesoResponseDTO> {
    let url = this.hostMantenedor + 'procesos';
    return this.http.put<PutActualizarProcesoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarProcesoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar proceso
  putHabilitarProcesoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'procesos/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar proceso
  putDeshabilitarProcesoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'procesos/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // AREA
  // Listado simple de areas
  getListadoAreasMantenedor(uuid?: string): Observable<GetListadoAreasResponseDTO> {
    let url = this.hostMantenedor + 'areas';
    if (uuid) {
      url += '?uuidProceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoAreasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  getListadoAreasReclamosMantenedor(uuid?: string): Observable<GetListadoAreasResponseDTO> {
    let url = this.hostMantenedor + 'areas/reclamos';
    if (uuid) {
      url += '?uuidProceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoAreasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Listado de areas paginado con busqueda
  getListadoAreasPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string, uuid?: string): Observable<GetListadoAreasPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'areas/busqueda-paginada?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    if (uuid) {
      url += '&uuidProceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoAreasPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear area
  postCrearAreaMantenedor(data: PostCrearAreaRequestDTO): Observable<PostCrearAreasResponseDTO> {
    let url = this.hostMantenedor + 'areas';
    return this.http.post<PostCrearAreaRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCrearAreasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener area
  getDetalleAreaMantenedor(uuid: string): Observable<GetDetalleAreaResponseDTO> {
    let url = this.hostMantenedor + 'areas/' + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleAreaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar area
  putActualizarAreaMantenedor(data: PutActualizarAreaRequestDTO): Observable<PutActualizarAreaResponseDTO> {
    let url = this.hostMantenedor + 'areas';
    return this.http.put<PutActualizarAreaRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarAreaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar area
  putHabilitarAreaMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'areas/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar area
  putDeshabilitarAreaMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'areas/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // SUBAREA
  // Listado simple de sub-areas
  getListadoSubAreasMantenedor(uuidArea?: string, uuidProceso?: string): Observable<GetListadoSubAreasResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas';
    if (uuidArea) {
      url += '?uuidArea=' + uuidArea;
    }
    if (uuidProceso) {
      url += '&uuidProceso=' + uuidProceso;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSubAreasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  getListadoSubAreasReclamosMantenedor(uuidArea?: string, uuidProceso?: string): Observable<GetListadoSubAreasResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas/reclamos';
    if (uuidArea) {
      url += '?uuidArea=' + uuidArea;
    }
    if (uuidProceso) {
      url += '&uuidProceso=' + uuidProceso;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSubAreasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Listado de sub-areas paginado con busqueda
  getListadoSubAreasPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string, uuidArea?: string, uuidProceso?: string): Observable<GetListadoSubareaPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas/busqueda-paginada?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    if (uuidArea && uuidProceso) {
      url += '&uuidArea=' + uuidArea + '&uuidProceso=' + uuidProceso;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSubareaPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear subarea
  postCrearSubareaMantenedor(data: PostCrearSubareaRequestDTO): Observable<PostCrearSubareasResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas';
    return this.http.post<PostCrearSubareaRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCrearSubareasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener subarea
  getDetalleSubareaMantenedor(uuid: string): Observable<GetDetalleSubareaResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas/' + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleSubareaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar subarea
  putActualizarSubareaMantenedor(data: PutActualizarSubareaRequestDTO): Observable<PutActualizarSubareaResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas';
    return this.http.put<PutActualizarSubareaRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarSubareaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar subarea
  putHabilitarSubreaMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar subarea
  putDeshabilitarSubareaMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'sub-areas/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // SITIOS
  // Listado de sitios
  getListadoSitiosMantenedor(uuid?: string): Observable<GetListadoSitiosResponseDTO> {
    let url = this.hostMantenedor + 'sitios';
    if (uuid) {
      url += '?uuidSubArea=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSitiosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de sitios paginado con busqueda
  getListadoSitiosPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string): Observable<GetListadoSitiosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'sitios/busqueda-paginada?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSitiosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear sitio
  postCrearSitioMantenedor(data: PostCrearSitioRequestDTO): Observable<PostCrearSitioResponseDTO> {
    let url = this.hostMantenedor + 'sitios';
    return this.http.post<PostCrearSitioRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCrearSitioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener sitio
  getDetalleSitioMantenedor(uuid: string): Observable<GetDetalleSitioResponseDTO> {
    let url = this.hostMantenedor + 'sitios/' + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleSitioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar sitio
  putActualizarSitioMantenedor(data: PutActualizarSitioRequestDTO): Observable<PutActualizarSitioResponseDTO> {
    let url = this.hostMantenedor + 'sitios';
    return this.http.put<PutActualizarSitioRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarSitioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar sitio
  putHabilitarSitioMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'sitios/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar sitio
  putDeshabilitarSitioMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'sitios/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Dimensiones
  getListadoDimensiones(): Observable<GetListadoDimensionesResponseDTO> {
    let url = this.hostMantenedor + 'dimensiones';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoDimensionesResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // TIPOS DE DOCUMENTOS
  // Listado de tipos
  getListadoTipoDocumentoMantenedor(): Observable<GetListadoTipoDocumentoResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/tipos';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoTipoDocumentoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de tipo documento paginado
  getListadoTipoDocumentoPaginadoMantenedor(page: number, pageSize: number): Observable<GetListadoTipoDocumentoPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/paginado?page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(
      map((resp) => new GetListadoTipoDocumentoPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear tipo
  postCrearTipoDocumentoMantenedor(data: PostCrearTipoDocumentoRequestDTO): Observable<PostCrearTipoDocumentoResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/tipos';
    return this.http.post<PostCrearTipoDocumentoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCrearTipoDocumentoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener tipo documento
  getDetalleTipoDocumentoMantenedor(uuid: string): Observable<GetDetalleTipoDocumentoResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/' + uuid + '/detalle';
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleTipoDocumentoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar tipo documento
  putActualizarTipoDocumentoMantenedor(data: PutActualizarTipoDocumentoRequestDTO): Observable<PutActualizarTipoDocumentoResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento';
    return this.http.put<PutActualizarTipoDocumentoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PutActualizarTipoDocumentoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Habilitar tipo documento
  putHabilitarTipoDocumentoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/' + uuid + '/habilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Deshabilitar tipo documento
  putDeshabilitarTipoDocumentoMantenedor(uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/' + uuid + '/deshabilitar';
    return this.http.put(url, {}).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado vigencias
  getListadoVigencias(): Observable<GetListadoVigenciasResponseDTO> {
    let url = this.hostMantenedor + 'tipo-documento/vigencias';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoVigenciasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // USUARIOS
  // Listado de usuarios
  getListadoUsuariosMantenedor(): Observable<GetListadoUsuarioResponseDTO> {
    let url = this.hostMantenedor + 'usuario';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoUsuarioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de usuarios paginado
  getListadoUsuariosPaginadoMantenedor(page: number, pageSize: number, campoBusqueda: string): Observable<GetListadoUsuariosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'usuario/paginado?page=' + page + '&size=' + pageSize;
    if (campoBusqueda) {
      url += '&campoBusqueda=' + campoBusqueda;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoUsuariosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar usuario
  putActualizarUsuarioMantenedor(data: PutActualizarUsuarioRequestDTO): Observable<PutActualizarUsuarioResponseDTO> {
    let url = this.hostMantenedor + 'usuario';
    return this.http.put<PutActualizarUsuarioRequestDTO>(url, data).pipe(
      map((resp) => new PutActualizarUsuarioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // ROLES
  // Listar roles
  getListadoRolesMantenedor(): Observable<GetListadoRolesResponseDTO> {
    let url = this.hostMantenedor + 'usuario/roles';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoRolesResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // SEMAFORO
  // Listado de semaforo
  getListadoSemaforoMantenedor(): Observable<GetListadoSemaforoResponseDTO> {
    let url = this.hostMantenedor + 'semaforo';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSemaforoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Actualizar semaforo
  putActualizarSemaforoMantenedor(data: PutActualizarSemaforoRequestDTO): Observable<PutActualizarSemaforoResponseDTO> {
    let url = this.hostMantenedor + 'semaforo';
    return this.http.put<PutActualizarSemaforoRequestDTO>(url, data).pipe(
      map((resp) => new PutActualizarSemaforoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // RECLAMOS
  // Listado de reclamos paginado
  postListadoReclamosPaginadoMantenedor(page: number, pageSize: number, data: PostListadoReclamoPaginadoRequestDTO, menuSC: boolean = false): Observable<GetPostListadoReclamosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/paginado?page=' + page + '&size=' + pageSize + '&menuSC=' + menuSC;
    return this.http.post<PostListadoReclamoPaginadoRequestDTO>(url, data).pipe(
      map((resp) => new GetPostListadoReclamosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Listado reclamos filtrado para exportar
  postExportarListadoFiltradoMantenedor(page: number, pageSize: number, data: PostListadoReclamoPaginadoRequestDTO, menuSC: boolean = false): Observable<any> {
    let url = this.hostMantenedor + 'reclamos/paginado?page=' + page + '&size=' + pageSize + '&menuSC=' + menuSC;
    let options: any;
    options = {
      observe: 'response',
      responseType: 'blob'
    };
    return this.http.post<PostListadoReclamoPaginadoRequestDTO>(url, data, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Listado de reclamos paginado campo busqueda
  getListadoReclamosPaginadoMantenedorBusqueda(page: number, pageSize: number, campoBusqueda: string, menuSC: boolean = false): Observable<GetPostListadoReclamosPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/busqueda-paginada?page=' + page + '&size=' + pageSize + '&menuSC=' + menuSC + '&campoBusqueda=' + campoBusqueda;
    return this.http.get(url).pipe(
      map((resp) => new GetPostListadoReclamosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // exportar reclamos
  postExportarReclamosMantenedor(data: PostExportarReclamosRequestDTO): Observable<any> {
    let url = this.hostMantenedor + 'reclamos/exportar';
    let options: any;
    options = {
      observe: 'response',
      responseType: 'blob'
    };
    return this.http.post<PostExportarReclamosRequestDTO>(url, data, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de tipo reclamos
  getListadoTipoReclamoMantenedor(): Observable<GetListadoTipoReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/tipos-reclamo';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoTipoReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de tipo pedidos
  getListadoTipoPedidoMantenedor(): Observable<GetListadoTipoPedidoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/tipos-pedido';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoTipoPedidoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de estados
  getListadoEstadosMantenedor(sc: boolean): Observable<GetListadoEstadosResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/estados?sc=' + sc;
    return this.http.get(url).pipe(
      map((resp) => new GetListadoEstadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de origenes
  getListadoOrigenesMantenedor(): Observable<GetListadoOrigenResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/origenes';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoOrigenResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado desviaciones
  getListadoDesviacionMantenedor(): Observable<GetListadoDesviacionesResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/desviaciones';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoDesviacionesResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Crear reclamo
  postCrearReclamo(data: FormData): Observable<PostIngresarReclamoResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.post<FormData>(this.hostMantenedor + 'reclamos', data, { params }).pipe(
      map((resp) => new PostIngresarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Editar Reclamo
  putEditarReclamo(uuid: string, data: FormData): Observable<PutEditarReclamoResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/editar';
    return this.http.put<FormData>(url, data, { params }).pipe(
      map((resp) => new PutEditarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado materiales
  getListadoMaterialesMantenedor(numeroDocumento: string, numeroParte: string): Observable<GetListadoMaterialesResponseDTO> {
    let url = this.hostMantenedor + 'materiales?numeroDocumento=' + numeroDocumento + '&numeroParte=' + numeroParte;
    return this.http.get(url).pipe(
      map((resp) => new GetListadoMaterialesResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener reclamo
  getObtenerReclamoMantenedor(uuid: string, sc: boolean): Observable<GetDetalleReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '?sc=' + sc;
    return this.http.get(url).pipe(
      map((resp) => new GetDetalleReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Eliminar Reclamo
  putEliminarReclamo(uuid: string): Observable<PutEliminarReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/eliminar';
    return this.http.put<string>(url, {}).pipe(
      map((resp) => new PutEliminarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener Procedimiento
  getProcedimientoMantenedor(): Observable<GetObtenerProcedimientoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/procedimiento';
    return this.http.get(url).pipe(
      map((resp) => new GetObtenerProcedimientoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Subir Procedimiento
  postSubirProcedimmiento(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.post<FormData>(this.hostMantenedor + 'reclamos/subir-procedimiento', data, { params }).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de historial reclamo paginado 
  postListadoHistorialReclamoPaginado(page: number, pageSize: number, uuid: string, data?: PostHistorialReclamoRequestDTO): Observable<GetPostListadoHistorialReclamoPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/historial/paginado?page=' + page + '&size=' + pageSize + '&uuidReclamo=' + uuid;
    return this.http.post<PostHistorialReclamoRequestDTO>(url, data).pipe(
      map((resp) => new GetPostListadoHistorialReclamoPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado de historial reclamo paginado por busqueda
  getListadoHistorialReclamoPaginadoBusqueda(page: number, pageSize: number, uuidReclamo: string, campoBusqueda: string): Observable<GetPostListadoHistorialReclamoPaginadosResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/historial/busqueda-paginada?page=' + page + '&size=' + pageSize + '&uuidReclamo=' + uuidReclamo + '&campoBusqueda=' + campoBusqueda;
    return this.http.get(url).pipe(
      map((resp) => new GetPostListadoHistorialReclamoPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Rechazar reclamo
  postRechazarReclamo(uuid: string, motivo: string): Observable<PostRechazarReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/rechazar-reclamo';
    return this.http.post<string>(url, JSON.stringify({ motivo })).pipe(
      map((resp) => new PostRechazarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Asignar reclamo
  postAsignarReclamo(uuid: string, data: PostAsignarReclamoRequestDTO): Observable<PostAsignarReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/asignar-reclamo';
    return this.http.post<PostAsignarReclamoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostAsignarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Aceptar rechazo
  postAceptarRechazo(uuid: string, motivo: string): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/aceptar-rechazo/' + uuid;
    return this.http.post<string>(url, JSON.stringify({ motivo })).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Rechazar rechazo
  postRechazarRechazo(uuid: string, motivo: string): Observable<PostRechazarRechazoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/rechazar-rechazo';
    return this.http.post<string>(url, JSON.stringify({ motivo })).pipe(
      map((resp) => new PostRechazarRechazoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Cerrar reclamo
  postCerrarReclamo(uuid: string, data: PostCerrarReclamoRequestDTO): Observable<PostCerrarReclamoResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/cerrar';
    return this.http.post<PostCerrarReclamoRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new PostCerrarReclamoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Guardar respuesta oficial
  postGuardarRespuestaOficial(uuid: string, respuesta: string): Observable<PostGuardarRespuestaOficialResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/responder';
    return this.http.post<string>(url, JSON.stringify({ respuesta })).pipe(
      map((resp) => new PostGuardarRespuestaOficialResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Editar respuesta oficial

  postEditarRespuestaOficial(uuid: string, respuesta: string): Observable<PostGuardarRespuestaOficialResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/editar-respuesta';
    return this.http.post<string>(url, JSON.stringify({ respuesta })).pipe(map((resp) => new PostGuardarRespuestaOficialResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error)));
  }

  // Enviar respuesta oficial
  postEnviarRespuestaOficial(uuid: string, respuesta: string): Observable<PostEnviarRespuestaOficialResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/' + uuid + '/enviar-respuesta';
    return this.http.post<string>(url, JSON.stringify({ respuesta })).pipe(
      map((resp) => new PostEnviarRespuestaOficialResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Responder comentarios
  postResponderComentarios(data: FormData): Observable<PostEnviarComentarioResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.post<FormData>(this.hostMantenedor + 'reclamo/comentarios/enviar', data, { params }).pipe(
      map((resp) => new PostEnviarComentarioResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Obtener comentarios
  getObtenerComentarios(uuid: string): Observable<GetListadoComentariosResponseDTO> {
    let url = this.hostMantenedor + 'reclamo/comentarios/' + uuid + '/obtener';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoComentariosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }
  // Listado acciones filtro historial
  getListadoAccionesHistorial(): Observable<GeneralResponseDTO> {
    let url = this.hostMantenedor + 'reclamos/historial/acciones';
    return this.http.get(url).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  // Porcentajes Home Reclamos
  getPorcentajesHomeReclamos(data: GetPorcentajesHomeRequestDTO): Observable<GetPorcentajesHomeReclamosResponseDTO> {
    return this.http.post<GetPorcentajesHomeRequestDTO>(this.hostMantenedor + 'reclamos/porcentajes', data).pipe(
      map(resp => new GetPorcentajesHomeReclamosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorMantenedor(error))
    );
  }

  private handleErrorMantenedor(error: any) {
    if (this.userService.isUserLogged() && error.status === 401) {
      this.userService.cleanSesion();
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' + environment.urlRedirectCerrarSesion;

      return throwError(false);
    }

    return throwError(error);
  }
}
