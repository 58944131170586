import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { Base64Util } from 'src/app/util/base64.util';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { ListaDocumentoDTO } from 'src/dtos/documental/listaDocumento.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetListadoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoDocumentos.response.dto';
import { GetObtenerDetalleDocumentoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumento.response.dto';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-actualizar',
  templateUrl: './modal-actualizar.component.html',
  styleUrls: ['./modal-actualizar.component.scss']
})
export class ModalActualizarComponent implements OnInit {
  detalleDocumento: DocumentoDTO;
  listaSimpleDocumentosVicunlados: ListaDocumentoDTO[] = [];

  uuid: string;

  updateForm: FormGroup;

  blobFile: Blob;

  loader: boolean = false;
  loaderDocVicunlados: boolean = false;
  btnClean: boolean = false;

  onClose: Subject<string>;

  datepickerConfigVencimiento = {
    isAnimated: true,
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    minDate: new Date(),
    showWeekNumbers: false
  };

  datepickerConfig = {
    isAnimated: true,
    dateInputFormat: 'DD/MM/YYYY',
    adaptivePosition: true,
    showWeekNumbers: false
  };

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    if (this.uuid) {
      this.getObtenerDetalleDocumento();
    } else {
      this.initForm();
    }
  }

  initForm() {
    this.updateForm = this.formBuilder.group({
      tipoDocumento: new FormControl({ value: this.detalleDocumento.tipoDocumento.nombre, disabled: true }),
      id: new FormControl({ value: this.detalleDocumento.idInterno, disabled: true }),
      file: new FormControl(this.detalleDocumento.tieneAdjunto ? this.detalleDocumento.archivoAdjunto.nombreArchivo : null, [Validators.required]),
      fileSource: new FormControl(null),
      nombre: new FormControl({ value: this.detalleDocumento.nombre, disabled: true }),
      version: new FormControl({ value: this.detalleDocumento.version, disabled: true }),
      macroproceso: new FormControl({ value: this.detalleDocumento.macroProceso.nombre, disabled: true }),
      proceso: new FormControl({ value: this.detalleDocumento.proceso.nombre, disabled: true }),
      area: new FormControl({ value: this.detalleDocumento.area.nombre, disabled: true }),
      subarea: new FormControl({ value: this.detalleDocumento.subArea.nombre, disabled: true }),
      fechaDocumento: new FormControl({ value: new Date(this.detalleDocumento.fechaDocumento), disabled: true }),
      fechaInicio: new FormControl(new Date(this.detalleDocumento.fechaInicioVigencia), [Validators.required]),
      fechaVencimiento: new FormControl(new Date(this.detalleDocumento.fechaVencimiento), [Validators.required]),
      documentoVinculados: new FormControl(this.detalleDocumento.documentosVinculados.map(x => x.uuid)),
      estado: new FormControl({ value: this.detalleDocumento.estado, disabled: true })
    });

    this.btnClean = this.detalleDocumento.tieneAdjunto ? true : false;

    this.getListadoDocumentos();
  }

  clearInput() {
    this.btnClean = !this.btnClean;
    this.blobFile = null;
    this.updateForm.patchValue({
      file: null,
      fileSource: null
    });
  }

  obtenerVigencia() {
    if (this.updateForm.controls.fechaInicio.value && this.updateForm.controls.tipoDocumento.value) {
      this.datepickerConfigVencimiento.minDate = new Date(this.updateForm.controls.fechaInicio.value);
      const vigencia = this.detalleDocumento.tipoDocumento.periodoVigencia.periodoVigencia.split(' ');
      const date = moment(this.updateForm.controls.fechaInicio.value).add(parseInt(vigencia[0], 10), 'months').calendar();
      this.updateForm.patchValue({ fechaVencimiento: new Date(date) });
    }
  }

  getObtenerDetalleDocumento() {
    this.loader = true;
    this.apiService.getObtenerDetalleDocumento(this.uuid).subscribe(
      (resp) => this.getObtenerDetalleDocumentoSuccess(resp),
      error => this.getObtenerDetalleDocumentoError(error)
    );
  }

  getObtenerDetalleDocumentoSuccess(response: GetObtenerDetalleDocumentoResponseDTO) {
    if (response.success) {
      this.detalleDocumento = response.data;
      this.loader = false;
      this.initForm();
    }
  }

  getObtenerDetalleDocumentoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_DOCUMENTO;
    this.toastr.error(msg);
    this.loader = false;
  }

  getListadoDocumentos() {
    this.loaderDocVicunlados = true;

    this.apiService.getListadoDocumentos(null, this.detalleDocumento.tipoDocumento.uuid).subscribe(
      (resp) => this.getListadoDocumentosSuccess(resp),
      error => this.getListadoDocumentosError(error)
    );
  }

  getListadoDocumentosSuccess(response: GetListadoDocumentosResponseDTO) {
    this.listaSimpleDocumentosVicunlados = response.data.filter(lsdv => lsdv.uuid !== this.uuid);
    this.loaderDocVicunlados = false;
  }

  getListadoDocumentosError(error: HttpErrorResponse) {
    this.loaderDocVicunlados = false;
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS;
    this.toastr.error(msg);
  }

  selectArchivo(event: any) {
    this.updateForm.controls.file.markAsTouched();
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.blobFile = Base64Util.base64toBlob(reader.result);
      };

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };

      this.updateForm.patchValue({
        fileSource: file,
      });
    }
  }

  actualizarDocumento() {
    if (this.updateForm.valid) {
      const dataUpdate = this.updateForm.value;
      let formData = new FormData();
      formData.append('vincularAOtroDocumento', dataUpdate.documentoVinculados);
      formData.append('fechaInicioVigencia', this.datepipe.transform(dataUpdate.fechaInicio, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('fechaVencimiento', this.datepipe.transform(dataUpdate.fechaVencimiento, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('file', this.blobFile ? this.blobFile : new Blob(), this.blobFile ? this.updateForm.controls.fileSource.value.name : null);
      formData.append('idInterno', this.detalleDocumento.idInterno);
      formData.append('uuidTipoDocumento', this.detalleDocumento.tipoDocumento.uuid);
      formData.append('nombre', this.detalleDocumento.nombre);
      formData.append('uuidMacroproceso', this.detalleDocumento.macroProceso.uuid);
      formData.append('uuidProceso', this.detalleDocumento.proceso.uuid);
      formData.append('uuidArea', this.detalleDocumento.area.uuid);
      formData.append('uuidSubArea', this.detalleDocumento.subArea.uuid);
      formData.append('fechaDocumento', this.datepipe.transform(this.detalleDocumento.fechaDocumento, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('estado', this.detalleDocumento.estado);
      this.apiService.putActualizarDocumento(this.detalleDocumento.uuid, formData).subscribe(
        (resp) => this.putActualizarDocumentoSuccess(resp),
        error => this.putActualizarDocumentoError(error)
      );
    }
  }

  putActualizarDocumentoSuccess(response: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_ACTUALIZAR_DOCUMENTO);
    this.onClose.next('ACTUALIZAR');
  }

  putActualizarDocumentoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ACTUALIZAR_DOCUMENTO;
    this.toastr.error(msg);
  }
}