import { PreferenciaModel } from "./preferencia.model";

export class UsuarioModel {
  token: string;
  nombre: string;
  apellido: string;
  email: string;
  uuid: string;
  rol: {
    uuid: string;
    nombre: string;
  };
  preferencias?: PreferenciaModel;
}
