<form [formGroup]="filterForm" *ngIf="filterForm">
    <button class="btn-filter close-filter" (click)="cerrarFiltros()"><i class="fas fa-times" aria-hidden="true"></i></button>
    <div *ngIf="modo === 'grid' || modo === 'list'">
        <div class="field-half">
            <div class="lab"><label for="name">Fecha creación</label></div>
            <div class="inp">
                <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaCreacion" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Fecha vencimiento</label></div>
            <div class="inp">
                <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaVencimiento" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Fecha edición</label></div>
            <div class="inp"> <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaEdicion" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Documentos vinculados</label></div>
            <div class="inp" *ngIf="!loaderDocumentosVinculados">
                <ng-select class="select-nborder" formControlName="documentoVinculados" placeholder="Seleccione"
                    [multiple]='true'>
                    <ng-option *ngFor="let dv of listaSimpleDocumentosVicunlados" [value]="dv.uuid">{{dv.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderDocumentosVinculados">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half" *ngIf="modo !== 'grid'">
            <div class="lab"><label for="name">Macroproceso</label></div>
            <div class="inp" *ngIf="!loaderMacroprocesos">
                <ng-select class="select-nborder" formControlName="macroproceso" placeholder="Seleccione"
                    (change)="getListadoProcesosFiltros()">
                    <ng-option *ngFor="let macro of listaSimpleMacroprocesos" [value]="macro.uuid">
                        {{macro.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderMacroprocesos">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half" *ngIf="modo !== 'grid'">
            <div class="lab"><label for="name">Proceso</label></div>
            <div class="inp" *ngIf="!loaderProcesos">
                <ng-select class="select-nborder" formControlName="proceso" placeholder="Seleccione">
                    <ng-option *ngFor="let proceso of listaSimpleProcesos" [value]="proceso.uuid">{{proceso.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderProcesos">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half" *ngIf="modo !== 'grid'">
            <div class="lab"><label for="name">Área</label></div>
            <div class="inp" *ngIf="!loaderArea">
                <ng-select class="select-nborder" formControlName="area" placeholder="Seleccione"
                    (change)="getListadoSubAreasFiltros()">
                    <ng-option *ngFor="let area of listaSimpleArea" [value]="area.uuid">{{area.nombre}}</ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderArea">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half" *ngIf="modo !== 'grid'">
            <div class="lab"><label for="name">Subárea</label></div>
            <div class="inp" *ngIf="!loaderSubArea">
                <ng-select class="select-nborder" formControlName="subarea" placeholder="Seleccione">
                    <ng-option *ngFor="let sub of listaSimpleSubArea" [value]="sub.uuid">{{sub.nombre}}</ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderSubArea">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Creador (persona)</label></div>
            <div class="inp" *ngIf="!loaderCreador">
                <ng-select class="select-nborder" formControlName="creador" placeholder="Seleccione">
                    <ng-option *ngFor="let creador of listadoCreador" [value]="creador.uuid">{{creador.nombre + ' ' +
                        creador.apellido}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderCreador">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">ID</label></div>
            <div class="inp">
                <input type="text" name="documental-filter-id" id="documental-filter-id" class="inp1"
                    placeholder="00000" formControlName="idInterno" />
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Tipo de documento</label></div>
            <div class="inp" *ngIf="!loaderTipoDocumento">
                <ng-select class="select-nborder" formControlName="tipoDocumento" placeholder="Seleccione">
                    <ng-option *ngFor="let tipoDocumento of listaSimpleTipoDocumento" [value]="tipoDocumento.uuid">
                        {{tipoDocumento.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderTipoDocumento">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Estado</label></div>
            <div class="inp">
                <ng-select class="select-nborder" formControlName="estado" placeholder="Seleccione">
                    <ng-option *ngFor="let estado of listadoEstado" [value]="estado.id">
                        {{estado.name}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div *ngIf="modo === 'historial'">
        <div class="field-half">
            <div class="lab"><label for="name">Nombre Usuario</label></div>
            <div class="inp" *ngIf="loaderCreador">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
            <div class="inp" *ngIf="!loaderCreador">
                <ng-select class="select-nborder" formControlName="nombreUsuario" placeholder="Seleccione">
                    <ng-option *ngFor="let creador of listadoCreador" [value]="creador.uuid">{{creador.nombre + ' ' +
                        creador.apellido}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Cargo</label></div>
            <div class="inp" *ngIf="loaderCargo">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
            <div class="inp" *ngIf="!loaderCargo">
                <ng-select class="select-nborder" formControlName="cargo" placeholder="Seleccione">
                    <ng-option *ngFor="let cargo of listadoCargoFiltroDoc" [value]="cargo">
                        {{cargo}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="fechaFiltroDoc">Fecha</label></div>
            <div class="inp">
                <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fecha" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="accionFiltroDoc">Acción</label></div>
            <div class="inp">
                <ng-select class="select-nborder" formControlName="accion" placeholder="Seleccione">
                    <ng-option *ngFor="let accion of listadoAccion" [value]="accion.id">
                        {{accion.name}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="filter-box-btn">
        <button class="btn btn-table btn-filter1" (click)="filters()">Filtrar</button>
    </div>
</form>