import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { PutTerminarActividadRequestDTO } from 'src/dtos/request/gestion/putTerminarActividad.request.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetDetalleActividadResponseDTO } from 'src/dtos/response/gestion/getDetalleActividad.response.dto';

@Component({
  selector: 'app-modal-gestion-terminada',
  templateUrl: './modal-gestion-terminada.component.html',
  styleUrls: ['./modal-gestion-terminada.component.scss']
})
export class ModalGestionTerminadaComponent {
  uuid: string;
  loader: boolean;
  actividad: ActividadDTO;
  detalle: string = '';
  cumplimiento: boolean = true;
  onClose: Subject<string>;


  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef

  ) { }
  
  ngOnInit() {
    this.onClose = new Subject();
    this.getDetalleActividad();
  }

  getDetalleActividad() {
    this.loader = true;
    this.apiGestion.getDetalleActividad(this.uuid).subscribe((resp) => this.getDetalleActividadSuccess(resp),
      (error) => this.getDetalleActividadError(error));
  }

  getDetalleActividadSuccess(resp: GetDetalleActividadResponseDTO) {
    this.actividad = resp.data;
    this.loader = false;
  }

  getDetalleActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_ACTIVIDAD;
    this.toastr.error(msg);
    this.loader = false;
  }

  changeCumplimiento(valor: boolean) {
    this.cumplimiento = valor;
  }

  marcarComoTerminada() {
    this.loader = true;

    let data = new PutTerminarActividadRequestDTO();
    data.huboCumplimiento = this.cumplimiento;
    data.detalle = this.detalle;

    this.apiGestion.putMarcarActividadComoTerminada(this.uuid, data).subscribe(
      (resp) => this.putMarcarActividadComoTerminadaSuccess(resp),
      (error) => this.putMarcarActividadComoTerminadaError(error));
  }

  putMarcarActividadComoTerminadaSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_TERMINAR_ACTIVIDAD);
    this.loader = false;
    this.onClose.next('TERMINAR');
  }

  putMarcarActividadComoTerminadaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_TERMINAR_ACTIVIDAD;
    this.toastr.error(msg);
    this.loader = false;
  }
 }
