import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { PostRechazarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postRechazarReclamo.response.dto';

@Component({
  selector: 'app-modal-reclamos-rechazar',
  templateUrl: './modal-reclamos-rechazar.component.html',
  styleUrls: ['./modal-reclamos-rechazar.component.scss']
})
export class ModalReclamosRechazarComponent {
  uuid: string;
  idInterno: string;

  rechazarForm: FormGroup;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.rechazarForm = this.formBuilder.group({
      descripcion: new FormControl(null, [Validators.required])
    });
  }

  rechazarReclamo() {
    const value = this.rechazarForm.value;

    this.apiServiceMantenedor.postRechazarReclamo(this.uuid, value.descripcion).subscribe(
      (response: PostRechazarReclamoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_RECHAZAR_RECLAMO);
        this.onClose.next('RECHAZAR');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_RECHAZAR_RECLAMO;
        this.toastr.error(msg);
      }
    );
  }
}
