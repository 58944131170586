import { Injectable } from '@angular/core';
import { AnioFiscalDTO } from 'src/dtos/gestion/anioFiscal.dto';

@Injectable({
  providedIn: 'root'
})
export class ActividadService {

  private uuidSelected: string;
  private uuidPlanDeAccion: string;
  private uuidArticulo: string;
  private anioFiscalSelected: AnioFiscalDTO;

  constructor() { }

  getUuidSelected(): string {
    return this.uuidSelected;
  }

  setUuidSelected(uuid: string) {
    this.uuidSelected = uuid;
  }

  getUuidPlanDeAccion(): string {
    return this.uuidPlanDeAccion;
  }

  setUuidPlanDeAccion(uuid: string) {
    this.uuidPlanDeAccion = uuid;
  }

  getUuidArticulo(): string {
    return this.uuidArticulo;
  }

  setUuidArticulo(uuid: string) {
    this.uuidArticulo = uuid;
  }

  getAnioFiscalSelected() {
    return this.anioFiscalSelected;
  }

  setAnioFiscalSelected(anioFiscal: AnioFiscalDTO) {
    this.anioFiscalSelected = anioFiscal;
  }
}
