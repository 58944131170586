import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { PutEliminarReclamoResponseDTO } from 'src/dtos/response/mantenedor/putEliminarReclamo.response.dto';

@Component({
  selector: 'app-modal-reclamos-eliminar',
  templateUrl: './modal-reclamos-eliminar.component.html',
  styleUrls: ['./modal-reclamos-eliminar.component.scss']
})
export class ModalReclamosEliminarComponent {
  uuid: string;
  idInterno: string;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  eliminarReclamo() {
    this.apiServiceMantenedor.putEliminarReclamo(this.uuid).subscribe(
      (response: PutEliminarReclamoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_ELIMINAR_RECLAMO);
        this.onClose.next('ELIMINAR');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_RECLAMO;
        this.toastr.error(msg);
      }
    );
  }
}
