<div class="filter" *ngIf="viewFilter">
    <app-filtros-mc menuSC="false" [filtrosForm]="filtros" (filterRequest)="filterMC($event)"
        (cerrar)="cerrarFiltros($event)">
    </app-filtros-mc>
</div>

<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page">
        <app-menu></app-menu>
    </div>
    <div class="section-page">
        <div class="subtitle">
            Material de entrenamiento 5S
        </div>
        <div class="create">
            <button type="button" class="btn btn-secundary" (click)="openModalMcSubir()">
                <span class="text-create"> SUBIR ARCHIVO </span><i class="far fa-plus-square create-ico"
                    aria-hidden="true"></i>
            </button>
        </div>
        <div class="tools-container">
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda" /><i class="fas fa-search ico-input1"
                    aria-hidden="true"></i>
            </div>
            <div class="export">
                <button class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">
                    Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                </button>
                <!--<label class="select-met">
                    <select name="filtro-categorias-mc" id="filtro-categorias-mc" class="select-modal">
                        <option>Filtro Categorías</option>
                        <option>Incidente</option>
                        <option>Mejora Continua</option>
                        <option>No conformidad</option>
                        <option>Actividades del proceso</option>
                        <option>Oportunidad de mejora</option>
                    </select>
                </label>-->
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()"> Limpiar Filtros</button>
            </div>
        </div>
    </div>
    <div class="section-page">
        <!--Tabla-->
        <div class="tabla">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Material de entrenamiento 5s</caption>
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Fecha de creación</th>
                            <th scope="col" class="text-nowrap">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let material of listadoMateriales">
                            <td>{{material.nombre}}</td>
                            <td>{{material.tipo}}</td>
                            <td>{{material.fechaCreacion | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-nowrap"> <button type="button" class="btn-ico-tool" tooltip="Descargar"
                                    placement="top" (click)="descargarArchivo(material.uuid)">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                    (click)="openModalMcEliminar(material)">
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                        <!--<tr>
                            <td>Archivo 125</td>
                            <td>Documento</td>
                            <td>22/10/2022</td>
                            <td class="text-nowrap"> <button type="button" class="btn-ico-tool" tooltip="Descargar"
                                    placement="top">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                    >
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>-->
                    </tbody>
                </table>
            </div>
        </div>

        <!--Pagination-->

    </div>
</div>
<div class="full-height">&nbsp;</div>