<form [formGroup]="filterForm" *ngIf="filterForm">
    <button class="btn-filter close-filter" (click)="cerrarFiltros()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div class="field-half">
        <div class="lab"><label for="name">ID</label></div>
        <div class="inp"> <input type="text" placeholder="Seleccione" class="inp1" formControlName="id"></div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Estado</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-id" id="gestion-id" formControlName="uuidEstado" placeholder="Seleccione">
                    <ng-option *ngFor="let estado of listadoEstados" [value]="estado.uuid">{{estado.nombre}}</ng-option>
                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Categoría</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-categoria" id="gestion-categoria" formControlName="uuidCategoria"
                    placeholder="Seleccione">
                    <ng-option *ngFor="let categoria of listadoCategorias" [value]="categoria.uuid">{{categoria.nombre}}
                    </ng-option>

                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Creador</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-creador" id="gestion-creador" formControlName="uuidCreador"
                    placeholder="Seleccione">
                    <ng-option *ngFor="let creador of listadoUsuarioCreador" [value]="creador.uuid">
                        {{creador.nombre + ' ' + creador.apellido}}</ng-option>
                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Fecha de creación</label></div>
        <div class="inp"> <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                formControlName="fechaCreacion"><i class="far fa-calendar-alt ico-input" aria-hidden="true"></i></div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Vence antes de</label></div>
        <div class="inp"><input type="text" placeholder="Seleccione fecha" class="inp1" bsDaterangepicker
                formControlName="fechaVencimiento" [bsConfig]="{ isAnimated: true }"><i
                class="far fa-calendar-alt ico-input" aria-hidden="true"></i></div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Área</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-area" id="gestion-area" formControlName="uuidArea" placeholder="Seleccione"
                (change)="getListadoSubAreas()">
                    <ng-option *ngFor="let area of listadoAreas" [value]="area.uuid">{{area.nombre}}</ng-option>
                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Sub-área</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-subarea" id="gestion-subarea" formControlName="uuidSubArea"
                    placeholder="Seleccione" (change)="getListadoSitios()">
                    <ng-option *ngFor="let subarea of listadoSubAreas" [value]="subarea.uuid">{{subarea.nombre}}</ng-option>
                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Sitio</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-sitio" id="gestion-sitio" formControlName="uuidSitio" placeholder="Seleccione">
                    <ng-option *ngFor="let sitio of listadoSitios" [value]="sitio.uuid">{{sitio.nombre}}</ng-option>
                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name">Responsable plan de acción</label></div>
        <div class="inp"><label class="select">
                <ng-select name="gestion-responsableplan" id="gestion-responsableplan" formControlName="uuidResponsable"
                    placeholder="Seleccione">
                    <ng-option *ngFor="let responsable of listadoUsuarioResponsable" [value]="responsable.uuid">
                        {{responsable.nombre + ' ' + responsable.apellido}}</ng-option>

                </ng-select>
            </label> </div>
    </div>
    <div class="field-half">
        <div class="lab"><label for="name"></label></div>
        <div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="checkExportar"
                    name="reclamos" [checked]="(checkedExportar) ? true : false" formControlName="exportar">
                <label class="custom-control-label" for="checkExportar">
                    Exportar excel
                </label>
            </div>
        </div>
    </div>
    <div class="filter-box-btn">
        <button class="btn btn-table btn-filter1" (click)="filtrar()">Filtrar</button>
    </div>
</form>