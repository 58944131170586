import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { MetodologiaDTO } from 'src/dtos/gestion/metodologia.dto';
import { GetMetdologiaResponseDTO } from 'src/dtos/response/gestion/getMetodologia.response.dto';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent { 

  listadoMetodologias5S: MetodologiaDTO[] = [];
  listadoMetodologias7D: MetodologiaDTO[] = [];
  listadoMetodologiasLSS: MetodologiaDTO[] = [];
  
  loader: boolean = false;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private router: Router
  ) { }
  
  ngOnInit() {
    this.getListadoMetodologias5S();
    this.getListadoMetodologias7D();
    this.getListadoMetodologiasLSS();
  }

  getListadoMetodologias5S() {
    this.loader = true;
    this.apiGestion.getObtenerMetodologias5S().subscribe((resp) => this.getMetodologias5SSuccess(resp),
      (error) => this.getMetdologiasError(error));
  }

  getMetodologias5SSuccess(resp: GetMetdologiaResponseDTO) {
    this.listadoMetodologias5S = resp.data;
    this.loader = false;
  }

  getMetdologiasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_METODOLOGIAS;
    this.toastr.error(msg);
    this.loader = false;
  }

  getListadoMetodologias7D() {
    this.loader = true;
    this.apiGestion.getObtenerMetodologias7D().subscribe((resp) => this.getMetodologias7DSuccess(resp),
      (error) => this.getMetdologiasError(error));
  }

  getMetodologias7DSuccess(resp: GetMetdologiaResponseDTO) {
    this.listadoMetodologias7D = resp.data;
    this.loader = false;
  }

  getListadoMetodologiasLSS() {
    this.loader = true;
    this.apiGestion.getObtenerMetodologiasLSS().subscribe((resp) => this.getMetodologiasLSSSuccess(resp),
      (error) => this.getMetdologiasError(error));
  }

  getMetodologiasLSSSuccess(resp: GetMetdologiaResponseDTO) {
    this.listadoMetodologiasLSS = resp.data;
    this.loader = false;
  }
  
  redirect5s(opcionSeleccionada: string) {
    const opcion = this.listadoMetodologias5S.find(opcion => opcion.uuid === opcionSeleccionada); 
    switch (opcion.nombre) {
      case 'Material de entrenamiento 5S':
        this.router.navigate(['web/material-entrenamiento5s'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Fotos de implementación 5S':
        this.router.navigate(['web/fotos-implementacion5s'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Novedades 5S':
        this.router.navigate(['web/novedades5s'], {queryParams: {uuid: opcion.uuid}});
        break;
    }
  }

  redirect7d(opcionSeleccionada: string) {
    const opcion = this.listadoMetodologias7D.find(opcion => opcion.uuid === opcionSeleccionada); 
    switch (opcion.nombre) {
      case 'Material de entrenamiento 7D':
        this.router.navigate(['web/material-entrenamiento7d'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Proyectos 7D en formato A3':
        this.router.navigate(['web/talleres7d'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Novedades 7D':
        this.router.navigate(['web/novedades7d'], {queryParams: {uuid: opcion.uuid}});
        break;
    }
    
  }

  redirectlss(opcionSeleccionada: string) {
    const opcion = this.listadoMetodologiasLSS.find(opcion => opcion.uuid === opcionSeleccionada); 
    switch (opcion.nombre) {
      case 'Material de entrenamiento LSS':
        this.router.navigate(['web/material-entrenamientolss'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Proyectos LSS en formato A3':
        this.router.navigate(['web/tallereslss'], {queryParams: {uuid: opcion.uuid}});
        break;
      case 'Novedades LSS':
        this.router.navigate(['web/novedadeslss'], {queryParams: {uuid: opcion.uuid}});
        break;
    }
  }

}
