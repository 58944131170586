<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Responder Rechazo</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="responderRechazoForm">
        <div class="modal-body">
            <div class="modal-text">
                El reclamo <strong>N° {{idInterno}}</strong>, ha sido rechazado por
                <strong>{{rechazo.usuarioAsignado.nombre + ' ' + rechazo.usuarioAsignado.apellido}}</strong>
                <br><br>
                {{rechazo.motivoRechazo}}
            </div>
            <div class="modal-text">
                <textarea id="responder-rechazo" name="responder-rechazo" rows="4" cols="100"
                    formControlName="descripcion" placeholder="Señale sus razones para aceptar o no el rechazo..."
                    [ngClass]="{'border-error':  responderRechazoForm.get('descripcion').touched && ! responderRechazoForm.get('descripcion').valid}"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal-black close-modal" aria-label="Close" (click)="rechazarRechazo()"
                [disabled]="responderRechazoForm.invalid">
                No aceptar rechazo
            </button>
            <button type="button" class="btn btn-modal" (click)="aceptarRechazo()"
                [disabled]="responderRechazoForm.invalid">
                Aceptar Rechazo
            </button>
        </div>
    </form>
</div>