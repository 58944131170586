import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlanDeAccionDTO } from 'src/dtos/gestion/planDeAccion.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetPlanDeAccionResponseDTO } from 'src/dtos/response/gestion/getPlanDeAccion.response.dto';
import { AppMessage } from '../app.message';
import { ActividadService } from '../services/actividad.service';
import { ApiGestionService } from '../services/api-gestion.service';
import { Base64Util } from '../util/base64.util';

@Component({
  selector: 'app-gestion-editar-plan',
  templateUrl: './gestion-editar-plan.component.html',
  styleUrls: ['./gestion-editar-plan.component.scss']
})
export class GestionEditarPlanComponent {

  constructor(
    private actividadService: ActividadService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  detallePlanDeAccion: PlanDeAccionDTO;
  uuidPlanDeAccion: string;
  loader: boolean = false;
  editarPlanForm: FormGroup;
  btnHabilitado: boolean = false;
  blobFiles: Blob[] = [];
  uuidActividad: string;

  ngOnInit() {
    this.uuidPlanDeAccion = this.actividadService.getUuidPlanDeAccion();
    this.uuidActividad = this.actividadService.getUuidSelected();
    this.initForm();
    this.getDetallePlanDeAccion();
  }

  getDetallePlanDeAccion() {
    this.loader = true;
    this.apiGestion.getPlanDeAccion(this.uuidPlanDeAccion).subscribe(
      (resp) => this.getDetallePlanDeAccionSuccess(resp),
      (error) => this.getDetallePlanDeAccionError(error)
    );
  }

  getDetallePlanDeAccionSuccess(resp: GetPlanDeAccionResponseDTO) {
    this.detallePlanDeAccion = resp.data;

    this.editarPlanForm.patchValue({ uuidPlanAccion: this.detallePlanDeAccion.uuid });
    this.editarPlanForm.patchValue({ comentarios: this.detallePlanDeAccion.comentarios });
    this.editarPlanForm.patchValue({ uuidResponsable: this.detallePlanDeAccion.usuarioResponsable.uuid });
    this.detallePlanDeAccion.adjuntos.forEach(async adjunto => {
      let file = await fetch(adjunto.url).then(r => r.blob());
      this.blobFiles.push(file);
      (this.editarPlanForm.get('filesSource') as FormArray).push(new FormControl({ url: adjunto.url, name: adjunto.nombreArchivo }));
    });

    this.loader = false;
  }

  getDetallePlanDeAccionError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_DETALLE_PLAN_DE_ACCION;
    this.toastr.error(msg);
    this.loader = false;
    this.goToMenuGestion();
  }

  initForm() {
    this.editarPlanForm = this.formBuilder.group({
      uuidPlanAccion: new FormControl(null, [Validators.required]),
      comentarios: new FormControl(null),
      uuidResponsable: new FormControl(null, [Validators.required]),
      file: new FormControl(null),
      filesSource: new FormArray([])
    })
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        this.blobFiles.push(Base64Util.base64toBlob(reader.result));
      };

      reader.onloadend = async () => {
        file['url'] = reader.result.toString().includes('image') ? reader.result.toString() : this.obtenerUrl(file);
      }

      reader.onerror = async (error) => {
        console.error('error al cargar archivo', error);
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.editarPlanForm.get('filesSource') as FormArray).push(new FormControl(file));
      this.editarPlanForm.get('file').patchValue(null);
    }
  }

  getAdjuntos() {
    return (this.editarPlanForm.get('filesSource') as FormArray).controls;
  }

  removeAdjunto(index: number) {
    (this.editarPlanForm.get('filesSource') as FormArray).removeAt(index);
    this.blobFiles.splice(index, 1);
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
    }
    return url;
  }

  goToMenuGestion() {
    this.router.navigate(['web/gestion-actividades'])
  }

  goToDetalle() {
    //this.actividadService.setUuidSelected(this.uuidActividad);
    this.router.navigate(['web/gestion-consultar'],{queryParams:{uuidActividad: this.uuidActividad}});
  }
  

  editarPlan() {
    this.loader = true;
    if (this.editarPlanForm.valid) {
      const form = this.editarPlanForm.value
      let formData = new FormData();
      formData.append('comentarios', form.comentarios);
      formData.append('uuidPlanAccion', form.uuidPlanAccion);
      formData.append('uuidResponsable', form.uuidResponsable);

      const nombreArchivos = (this.editarPlanForm.get('filesSource') as FormArray).value;
      this.blobFiles.forEach((file, index) => {
        formData.append('archivos[' + index + ']', file, nombreArchivos[index].name);
      });

      this.apiGestion.putEditarPlanDeAccion(formData).subscribe((resp) => this.putEditarPlanSuccess(resp),
        (error) => this.putEditarPlanError(error));
    }

  }

  putEditarPlanSuccess(resp: GeneralResponseDTO) {
    this.loader = false;
    this.toastr.success(AppMessage.SUCCESS_EDITAR_PLAN_DE_ACCION);
    this.goToDetalle();
  }

  putEditarPlanError(error: HttpErrorResponse) {
    this.btnHabilitado = false;
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_PLAN_DE_ACCION;
    this.toastr.error(msg);
  }
}
