import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { SemaforoDTO } from 'src/dtos/mantenedor/semaforo.dto';
import { GetListadoSemaforoResponseDTO } from 'src/dtos/response/mantenedor/getListadoSemaforo.response.dto';
import { ModalTrEditarComponent } from './modals/modal-tr-editar/modal-tr-editar.component';

@Component({
  selector: 'app-mantenedor-tiemposrespuesta',
  templateUrl: './mantenedor-tiemposrespuesta.component.html',
  styleUrls: ['./mantenedor-tiemposrespuesta.component.scss']
})
export class MantenedorTiemposrespuestaComponent {
  listadoSemaforo: SemaforoDTO[] = [];

  constructor(
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getListadoSemaforoMantenedor();
  }

  getListadoSemaforoMantenedor() {
    this.apiServiceMantenedor.getListadoSemaforoMantenedor().subscribe(
      (response: GetListadoSemaforoResponseDTO) => {
        this.listadoSemaforo = response.data;
        let inicio = 0;
        this.listadoSemaforo.forEach(color => {
          color.nombre = 'Abierto ' + (inicio + 1) + (color.color === 'rojo' ? ' o +' : '-' + (inicio + color.cantidad)) + ' días';
          inicio += color.cantidad;
        });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_SEMAFORO;
        this.toastr.error(msg);
      }
    );
  }

  openModalTrEditar(data: SemaforoDTO, i: number) {
    let acumulado = 0;
    this.listadoSemaforo.forEach((color, index) => {
      if (index < i)
        acumulado += color.cantidad;
    });
    const modal: BsModalRef = this.modalService.show(ModalTrEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        data,
        acumulado
      }
    });

    modal.content.onClose.subscribe(myData => {
      this.getListadoSemaforoMantenedor();
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
