<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subir archivo</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="subirForm">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Nombre
                    </label></div>
                <div class="inp"><input type="text" id="material-entrenamiento5s-nombre"
                        name="material-entrenamiento5s-nombre" class="inp-modal" placeholder="Nombre"
                        formControlName="nombre"> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Tipo
                    </label></div>
                <div class="inp"><label class="select">
                        <ng-select formControlName="tipo" name="material-entrenamiento5s-tipo" id="material-entrenamiento5s-tipo"
                            class="select-modal" placeholder="Seleccione">
                            <ng-option [value]="tipo" *ngFor="let tipo of listadoTipos">
                                {{tipo}}
                            </ng-option>
                        </ng-select>
                    </label> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Adjuntar archivo</label></div>
                <div class="inp">
                    <input type="file" name="material-entrenamiento5s-adjuntararchivo" formControlName="file"
                        id="material-entrenamiento5s-adjuntararchivo" class="inp-modal" placeholder="Adjunte archivo"
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx"
                        (change)="addAdjunto($event)"/>
                    <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </form>
    
    <div class="modal-footer">
        <button type="button" class="btn btn-modal" (click)="subirArchivo()">Subir</button>
    </div>
</div>