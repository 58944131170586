<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="modal-header" *ngIf="!loader">
        <h5 class="modal-title" id="exampleModalLabel">Eliminar actividad</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!loader">
        <div class="modal-text">Está apunto de eliminar la actividad <strong>"ID{{detalleActividad.id}}"</strong>, que usted ha creado.
            <br>.<strong>¿Está seguro que desea eliminarla?</strong>
        </div>
    </div>
    <div class="modal-footer" *ngIf="!loader">
        <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()" data-dismiss="modal"
            aria-label="Close">Cancelar</button>
        <button type="button" class="btn btn-modal" (click)="eliminarActividad()">Sí, Eliminar</button>
    </div>
</div>
