<div class="loader-container" *ngIf="loaderSubirProc || loaderPaginado">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="filter" *ngIf="viewFilter">
    <app-filtros-reclamos menuSC="false" [filtrosForm]="filtros" (filterRequest)="filterReclamos($event)"
        (cerrar)="cerrarFiltros($event)">
    </app-filtros-reclamos>
</div>
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-exclamation ico-page" aria-hidden="true"></i><span class="title1">Reclamos</span>
        </div>
    </div>
    <div class="create"
        *ngIf="usuario.rol.nombre.toLowerCase() === 'general' || usuario.rol.nombre.toLowerCase() === 'superadmin'">
        <button type="button" class="btn btn-secundary" (click)="goToIngresarReclamo()">
            <span class="text-create">ingresar reclamo</span><i class="far fa-plus-square create-ico"
                aria-hidden="true"></i>
        </button>
    </div>
    <div class="ver-procedimiento">
        <div *ngIf="detalleProcedimiento">
            <label class="link-color" (click)="descargarProcedimiento()">
                <i class=" fas fa-question-circle ico-ver-procedimiento" aria-hidden="true"></i>Ver procedimiento de
                reclamos
            </label>
            <p>Fecha último documento: {{detalleProcedimiento?.fechaCreacion | date: 'dd/MM/yyyy'}}</p>
        </div>
    </div>
    <div class="section-page">
        <div class="tools-table">
            <div class="search-mobile">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="reclamos"
                        [checked]="checkReclamos" (click)="seleccionMultiple()">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export">
                <button class="btn btn-table" (click)="postExportarReclamosMantenedor()">
                    Exportar <i class="fas fa-file-excel ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export">
                <button class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">
                    Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()">Limpiar Filtros</button>
            </div>
        </div>
        <div class="tabla" *ngIf="!loaderPaginado">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Reclamos</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%;">ID</th>
                            <th scope="col" style="width: 10%; text-align: center;">Estado</th>
                            <th scope="col" style="width: 5%; text-align: center;">Tiempo respuesta</th>
                            <th scope="col" style="width: 20%;">Filial | Área de Negocio | Fecha</th>
                            <th scope="col" style="width: 15%;">Área | Subarea | Sitio responsable</th>
                            <th scope="col" style="width: 10%;">Tipo reclamo</th>
                            <th scope="col" style="width: 15%;">Respondido por | Fecha</th>
                            <th scope="col" style="word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listadoPagReclamos.length > 0">
                        <tr *ngFor="let reclamo of listadoPagReclamos; let i = index;">
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{'sel' + reclamo.uuid}}"
                                        name="reclamos" [checked]="(reclamo.checkReclamo) ? true : false"
                                        (click)="selReclamo(reclamo.uuid)">
                                    <label class="custom-control-label" for="{{'sel' + reclamo.uuid}}">
                                        <a routerLink="/web/consultar-reclamos"
                                            [queryParams]="{uuid: reclamo.uuid, sc: false}"
                                            class="link-table">{{reclamo.idInterno}}</a>
                                    </label>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="reclamos-estado"
                                    [ngStyle]="{'background-color': reclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER' ? '#6979d4' : '#393939'}">
                                    {{reclamo.estadoReclamo.nombre}}
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <button type="button" class="btn-ico-tool" placement="right"
                                    *ngIf="!reclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO')"
                                    tooltip="Abierto hace {{reclamo.estadoSemaforo.cantidad}} días">
                                    <i class="fas fa-clock ico-rec" aria-hidden="true" [ngClass]="{
                                            'yellow' : reclamo.estadoSemaforo.color === 'amarillo', 
                                            'green': reclamo.estadoSemaforo.color === 'verde', 
                                            'red': reclamo.estadoSemaforo.color === 'rojo' }"></i>
                                </button>
                                <!--  Botones tiempos de respuesta Morado Cerrado -->
                                <button type="button" class="btn-ico-tool" placement="right" tooltip="Cerrado"
                                    *ngIf="reclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO')">
                                    <i class="fas fa-clock ico-rec purple" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td>
                                {{reclamo.filial.nombre}} | {{reclamo.fechaCreacion | date:'dd/MM/yyyy'}}
                            </td>
                            <td>{{reclamo.asignacion?.area ? reclamo.asignacion.area.nombre : '-'}} | 
                                {{reclamo.asignacion?.subarea ? reclamo.asignacion.subarea.nombre : '-'}} | 
                                {{reclamo.asignacion?.sitio ? reclamo.asignacion.sitio.nombre : '-'}}
                            </td>
                            <td>{{reclamo.tipoReclamo.nombre}}</td>
                            <td>
                                {{reclamo.respuestaEnviada ? reclamo.usuarioRespuesta?.nombre + ' ' +
                                reclamo.usuarioRespuesta?.apellido + ' | ' + (reclamo.fechaRespuesta |
                                date:'dd/MM/yyyy') :
                                '--'}}
                            </td>
                            <td class=" text-nowrap">
                                <button *ngIf="(reclamo.usuarioCreador.preferencias?.area?.uuid === usuario.preferencias?.area?.uuid || usuario.rol.nombre.toLowerCase() === 'superadmin')
                                         && reclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'"
                                    type="button" class="btn-ico-tool mt-1" tooltip="Editar" placement="top"
                                    (click)="openModalReclamosEditar(reclamo)">
                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                </button>
                                <button
                                    *ngIf="!reclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO') 
                                    && (reclamo.usuarioCreador.preferencias?.area?.uuid === usuario.preferencias?.area?.uuid || usuario.rol.nombre.toLowerCase() === 'customer service' || usuario.rol.nombre.toLowerCase() === 'superadmin')"
                                    type="button" class="btn-ico-tool" tooltip="Cerrar reclamo" placement="top"
                                    (click)="openModalReclamosCerrar(reclamo)">
                                    <i class="fas fa-ban ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Comentarios" placement="top"
                                    (click)="openModalReclamosComentarios(reclamo)">
                                    <i class="fas fa-comment-dots ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Historial" placement="top"
                                    (click)="openModalReclamosHistorial(reclamo)">
                                    <i class="fas fa-history ico-action" aria-hidden="true"></i>
                                </button>
                                <button
                                    *ngIf="reclamo.hasOwnProperty('asignacion') && !reclamo.hasOwnProperty('rechazo')
                                            && reclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'
                                            && (usuario.rol.nombre.toLowerCase() === 'superadmin'
                                                || (usuario.rol.nombre.toLowerCase() === 'general' && usuario.preferencias 
                                                    && reclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid
                                                    && reclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid
                                                    && reclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid))"
                                    type="button" class="btn-ico-tool" tooltip="Rechazar reclamo" placement="top"
                                    (click)="openModalReclamosRechazar(reclamo)">
                                    <i class="far fa-times-circle ico-action" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="(reclamo.usuarioCreador.preferencias?.area?.uuid === usuario.preferencias?.area?.uuid || usuario.rol.nombre.toLowerCase() === 'superadmin')
                                         && reclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'"
                                    type="button" class="btn-ico-tool" tooltip="Eliminar reclamo" placement="top"
                                    (click)="openModalReclamosEliminar(reclamo)">
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sin-resultados" *ngIf="listadoPagReclamos.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
            </div>
        </div>
        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalRegister > 10"
            [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalRegister"
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>