<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subir adjuntos</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="field-half">
            <div class="lab"><label for="name">Adjuntar archivos</label></div>
            <div class="inp">
                <input type="file" name="reclamos-adjuntararchivo" id="reclamos-adjuntararchivo" class="inp-modal"
                    placeholder="Adjunte archivo" style="/* border: 2px solid #FF0000 !important ; */ "
                    formControlName="file" />
                <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="column-consult-7 vertical-scroll">
                <div class="lab-consult">Archivos adjuntos</div>
                <div class="input-consult">

                    <div class="back-img">
                        <div class="img-show"> <img src="assets/img/imagen.jpg" width="100%" alt="foto"></div>
                        <div class="back-img-line">
                            <a href="#" class="minimage-link">
                                <div class="ico-image"><i class="fas fa-image" aria-hidden="true"></i></div>
                                Archivo125.jpg <i class="fas fa-times-circle red delete-archive" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal">Subir</button>
    </div>
</div>