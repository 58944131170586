<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subir archivo</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="subirForm">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Nombre
                    </label></div>
                <div class="inp"><input type="text"  class="inp-modal" placeholder="Nombre"
                        formControlName="nombre"> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Área
                    </label></div>
                <div class="inp"><label class="select">
                        <ng-select formControlName="uuidArea" (change)="getListadoSubAreas()" 
                        name="fotos-implementacion-area" id="fotos-implementacion-area" class="select-modal"
                        placeholder="Seleccione">
                            <ng-option *ngFor="let area of listadoAreas" [value]="area.uuid">{{area.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Subárea
                    </label></div>
                <div class="inp"><label class="select">
                        <ng-select formControlName="uuidSubarea" (change)="getListadoSitios()"
                         name="fotos-implementacion-subarea" id="fotos-implementacion-subarea" class="select-modal"
                         placeholder="Seleccione">
                            <ng-option *ngFor="let subarea of listadoSubareas" [value]="subarea.uuid">{{subarea.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Sitio
                    </label></div>
                <div class="inp"><label class="select">
                        <ng-select formControlName="uuidSitio" name="fotos-implementacion-subarea"
                         id="fotos-implementacion-subarea" class="select-modal" placeholder="Seleccione">
                            <ng-option *ngFor="let sitio of listadoSitios" [value]="sitio.uuid">{{sitio.nombre}}</ng-option>
                        </ng-select>
                    </label> </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Adjuntar archivo</label></div>
                <div class="inp">
                    <label for="mc-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                    <input #fileInput type="file" name="mc-adjuntararchivo" id="mc-adjuntararchivo" class="inp-modal"
                        placeholder="Adjunte archivo"
                        accept="image/*"
                        (change)="addAdjunto($event)"
                         formControlName="file" />
                    <i (click)="fileInput.click()" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
                <div class="column-consult-7 vertical-scroll" *ngIf="getAdjuntos().length > 0">
                    <div class="lab-consult">Archivos adjuntos</div>
                    <div class="back-img" ngxViewer *ngFor="let adjunto of getAdjuntos(); let i = index">
                        <img class="img-show" [src]="adjunto.value.url" width="210px" height="150px"
                            alt="Archivo adjunto">
                        <div class="back-img-line">
                            <i class="fas fa-times-circle red delete-archive" aria-hidden="true"
                                (click)="removeAdjunto(i)"></i>
                            <a href="#" class="minimage-link">
                                <div class="ico-image"><i class="fas fa-image" aria-hidden="true"></i></div>
                                {{adjunto.value.name}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </form>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal" (click)="subirArchivo()">Subir</button>
    </div>
</div>