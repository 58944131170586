import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentalModolistComponent } from './documental/documental-modolist/documental-modolist.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { DocumentalHistorialComponent } from './documental/documental-historial/documental-historial.component';
import { DocumentalModogridComponent } from './documental/documental-modogrid/documental-modogrid.component';
import { MantenedoresComponent } from './mantenedores/mantenedores.component';
import { MantenedorTipodocumentoComponent } from './mantenedores/mantenedor-tipodocumento/mantenedor-tipodocumento.component';
import { MantenedorJerarquizacionComponent } from './mantenedores/mantenedor-jerarquizacion/mantenedor-jerarquizacion.component';
import { ReclamosComponent } from './reclamos/reclamos.component';
import { ReclamosIngresarComponent } from './reclamos/reclamos-ingresar/reclamos-ingresar.component';
import { GestionActividadesComponent } from './gestion-actividades/gestion-actividades.component';
import { GestionCrearComponent } from './gestion-crear/gestion-crear.component';
import { HistorialComponent } from './documental/historial/historial.component';
import { ReclamosServicioClienteComponent } from './reclamos/reclamos-servicio-cliente/reclamos-servicio-cliente.component';
import { ConsultarReclamosComponent } from './reclamos/consultar-reclamos/consultar-reclamos.component';
import { GestionConsultarComponent } from './gestion-actividades/gestion-consultar/gestion-consultar.component';
import { GestionCrearPlanComponent } from './gestion-crear-plan/gestion-crear-plan.component';
import { MejoraContinuaComponent } from './mejora-continua/mejora-continua.component';
import { MaterialEntrenamiento5sComponent } from './mejora-continua/material-entrenamiento5s/material-entrenamiento5s.component';
import { FotosImplementacion5sComponent } from './mejora-continua/fotos-implementacion5s/fotos-implementacion5s.component';
import { GestionEditarPlanComponent } from './gestion-editar-plan/gestion-editar-plan.component';
import { Novedades5sComponent } from './mejora-continua/novedades5s/novedades5s.component';
import { ArticuloComponent } from './mejora-continua/articulo/articulo.component';
import { EditarCrearArticuloComponent } from './mejora-continua/editar-crear-articulo/editar-crear-articulo.component';
import { MaterialEntrenamiento7dComponent } from './mejora-continua/material-entrenamiento7d/material-entrenamiento7d.component';
import { Talleres7dComponent } from './mejora-continua/talleres7d/talleres7d.component';
import { Novedades7dComponent } from './mejora-continua/novedades7d/novedades7d.component';
import { MaterialEntrenamientolssComponent } from './mejora-continua/material-entrenamientolss/material-entrenamientolss.component';
import { TallereslssComponent } from './mejora-continua/tallereslss/tallereslss.component';
import { NovedadeslssComponent } from './mejora-continua/novedadeslss/novedadeslss.component';
import { MantenedorCategoriasComponent } from './mantenedores/mantenedor-categorias/mantenedor-categorias.component';
import { MantenedorTiemposrespuestaComponent } from './mantenedores/mantenedor-tiemposrespuesta/mantenedor-tiemposrespuesta.component';
import { MantenedorUsuariosComponent } from './mantenedores/mantenedor-usuarios/mantenedor-usuarios.component';
import { AuthGuard } from './auth.guard';
import { HistorialReclamoComponent } from './reclamos/historial-reclamo/historial-reclamo.component';
import { GestionHistorialComponent } from './gestion-actividades/gestion-historial/gestion-historial.component';


const routes: Routes = [

  { path: 'login', component: LoginComponent },
  {
    path: 'web', component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'profile', component: ProfileComponent },
      { path: 'home', component: HomeComponent },

      { path: 'documental-modolist', component: DocumentalModolistComponent },
      { path: 'documental-historial', component: DocumentalHistorialComponent },
      { path: 'documental-modogrid', component: DocumentalModogridComponent },
      { path: 'historial', component: HistorialComponent },

      { path: 'mantenedores', component: MantenedoresComponent },
      { path: 'mantenedor-tipodocumento', component: MantenedorTipodocumentoComponent },
      { path: 'mantenedor-categorias', component: MantenedorCategoriasComponent },
      { path: 'mantenedor-tiemposrespuesta', component: MantenedorTiemposrespuestaComponent },
      { path: 'mantenedor-usuarios', component: MantenedorUsuariosComponent },
      { path: 'mantenedor-jerarquizacion', component: MantenedorJerarquizacionComponent },

      { path: 'reclamos', component: ReclamosComponent },
      { path: 'reclamos-servicio-cliente', component: ReclamosServicioClienteComponent },
      { path: 'reclamos-ingresar', component: ReclamosIngresarComponent },
      { path: 'consultar-reclamos', component: ConsultarReclamosComponent },
      { path: 'historial-reclamo', component: HistorialReclamoComponent },

      { path: 'gestion-actividades', component: GestionActividadesComponent },
      { path: 'gestion-crear', component: GestionCrearComponent },
      { path: 'gestion-historial', component: GestionHistorialComponent},
      { path: 'gestion-consultar', component: GestionConsultarComponent },
      { path: 'gestion-crear-plan', component: GestionCrearPlanComponent },
      { path: 'mejora-continua', component: MejoraContinuaComponent },
      { path: 'material-entrenamiento5s', component: MaterialEntrenamiento5sComponent },
      { path: 'fotos-implementacion5s', component: FotosImplementacion5sComponent },
      { path: 'gestion-editar-plan', component: GestionEditarPlanComponent },
      { path: 'novedades5s', component: Novedades5sComponent },
      { path: 'articulo', component: ArticuloComponent },
      { path: 'editar-crear-articulo', component: EditarCrearArticuloComponent },
      { path: 'material-entrenamiento7d', component: MaterialEntrenamiento7dComponent },
      { path: 'talleres7d', component: Talleres7dComponent },
      { path: 'novedades7d', component: Novedades7dComponent },
      { path: 'material-entrenamientolss', component: MaterialEntrenamientolssComponent },
      { path: 'tallereslss', component: TallereslssComponent },
      { path: 'novedadeslss', component: NovedadeslssComponent },

      { path: 'notificaciones', component: NotificacionesComponent },
    ]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
