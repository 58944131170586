import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { UsuarioDTO } from 'src/dtos/mantenedor/usuario.dto';
import { GetListadoUsuariosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoUsuariosPaginados.response.dto';
import { ModalUsuariosEditarComponent } from './modals/modal-usuarios-editar/modal-usuarios-editar.component';

@Component({
  selector: 'app-mantenedor-usuarios',
  templateUrl: './mantenedor-usuarios.component.html',
  styleUrls: ['./mantenedor-usuarios.component.scss']
})
export class MantenedorUsuariosComponent {
  listadoPagUsuarios: UsuarioDTO[] = [];

  totalRegisterUsuarios: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  indexSelect: number;

  busqueda = new FormControl(null);

  loaderPaginado: boolean = false;

  formCtrlSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.currentPage = 1;
    this.getListadoUsuariosPaginadoMantenedor();

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.listadoPagUsuarios = [];
        this.getListadoUsuariosPaginadoMantenedor();
      }
    });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getListadoUsuariosPaginadoMantenedor();
  }

  getListadoUsuariosPaginadoMantenedor() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoUsuariosPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (resp) => this.getListadoUsuariosPaginadosSuccess(resp),
      (error) => this.getListadoUsuariosPaginadosError(error));
  }

  getListadoUsuariosPaginadosSuccess(resp: GetListadoUsuariosPaginadosResponseDTO) {
    this.listadoPagUsuarios = resp.data.content;
    this.totalRegisterUsuarios = resp.data.totalElements;
    this.loaderPaginado = false;
  }

  getListadoUsuariosPaginadosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_USUARIOS_PAGINADO;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  openModalUsuariosEditar(data: UsuarioDTO) {
    const modal: BsModalRef = this.modalService.show(ModalUsuariosEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        data
      }
    });

    modal.content.onClose.subscribe(myData => {
      this.getListadoUsuariosPaginadoMantenedor();
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}
