import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { ApiService } from 'src/app/services/api.service';
import { FilialDTO } from 'src/dtos/documental/filial.dto';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { EstadoDTO } from 'src/dtos/mantenedor/estado.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { TipoReclamoDTO } from 'src/dtos/mantenedor/tipoReclamo.dto';
import { UsuarioDTO } from 'src/dtos/mantenedor/usuario.dto';
import { GetListadoFilialesResponseDTO } from 'src/dtos/response/documental/getListadoFiliales.response.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoEstadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoEstados.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { GetListadoTipoReclamoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoReclamo.response.dto';
import { GetListadoUsuarioResponseDTO } from 'src/dtos/response/mantenedor/getListadoUsuarios.response.dto';

@Component({
  selector: 'app-filtros-reclamos',
  templateUrl: './filtros-reclamos.component.html',
  styleUrls: []
})
export class FiltrosReclamosComponent implements OnInit {
  filterForm: FormGroup;

  loaderTipoReclamo: boolean = false;
  loaderUsuarioCreador: boolean = false;
  loaderAreaResponsable: boolean = false;
  loaderAreaFilial: boolean = false;
  loaderEstados: boolean = false;
  loaderCargo: boolean = false;
  loaderSubareaResponsable: boolean = false;
  loaderSitioResponsable: boolean = false;

  listadoTipoReclamos: TipoReclamoDTO[] = [];
  listadoUsuarioCreador: UsuarioDTO[] = [];
  listadoAreaResponsable: AreaDTO[] = [];
  listadoSubareaResponsable: SubareaDTO[] = [];
  listadoSitioResponsable: SitiosDTO[] = [];
  listadoAreaFilial: FilialDTO[] = [];
  listadoEstados: EstadoDTO[] = [];
  listadoCargo: string[] = [];
  listadoAccion: any[] = [];

  checkedExportar: boolean = false;

  datepickerConfig = {
    isAnimated: true,
    adaptivePosition: true,
    showWeekNumbers: false,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
  };

  @Input() filtrosForm: any;
  @Input() modo: string = 'list';
  @Input() menuSC: boolean = false;
  @Output() filterRequest: EventEmitter<any> = new EventEmitter();
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnChanges() {
    this.initFilters(this.filtrosForm);
    if (this.modo === 'list') {
      this.getListadoTipoReclamoMantenedor();
      this.getListadoAreasMantenedor();
      this.getListadoAreasFiliales();
      this.getListadoEstadosMantenedor();
    } else {
      this.getListadoCargos();
      this.getListadoAccionesHistorial()
    }
    this.getListadoUsuariosMantenedor();
  }

  ngOnInit(): void {
    this.initFilters(this.filtrosForm);
  }

  initFilters(filtrosValue: any) {
    if (this.modo === 'historial') {
      this.filterForm = this.formBuilder.group({
        nombreUsuario: new FormControl(null),
        fecha: new FormControl(filtrosValue.fecha ? [new Date(moment(filtrosValue?.fecha[0]).format()), new Date(moment(filtrosValue?.fecha[1]).format())] : null),
        accion: new FormControl(filtrosValue.accion ? filtrosValue.accion : null),
        cargo: new FormControl(null)
      });
    } else {
      this.filterForm = this.formBuilder.group({
        fechaCreacion: new FormControl(this.filtrosForm.fechaCreacion ? [new Date(moment(this.filtrosForm.fechaCreacion[0]).format()), new Date(moment(this.filtrosForm.fechaCreacion[1]).format())] : null),
        fechaCierre: new FormControl(this.filtrosForm.fechaCierre ? [new Date(moment(this.filtrosForm.fechaCierre[0]).format()), new Date(moment(this.filtrosForm.fechaCierre[1]).format())] : null),
        uuidTipoReclamo: new FormControl(null),
        uuidCreador: new FormControl(null),
        uuidAreaResponsable: new FormControl(null),
        uuidFilial: new FormControl(null),
        uuidEstado: new FormControl(null),
        uuidSubareaResponsable: new FormControl(null),
        uuidSitioResponsable: new FormControl(null)
      });
    }
  }

  filtersReclamo() {
    let aux: any = {};
    const form = this.filterForm.value;
    if (this.modo === 'historial') {
      aux = {
        nombreUsuario: form.nombreUsuario,
        accion: form.accion,
        cargo: form.cargo,
        fecha: form.fecha ? [moment(form.fecha[0]).format('YYYY-MM-DD'), moment(form.fecha[1]).format('YYYY-MM-DD')] : null
      };
    } else {
      aux = {
        fechaCreacion: form.fechaCreacion ? [moment(form.fechaCreacion[0]).format('YYYY-MM-DD'), moment(form.fechaCreacion[1]).format('YYYY-MM-DD')] : null,
        fechaCierre: form.fechaCierre ? [moment(form.fechaCierre[0]).format('YYYY-MM-DD'), moment(form.fechaCierre[1]).format('YYYY-MM-DD')] : null,
        uuidTipoReclamo: form.uuidTipoReclamo,
        uuidCreador: form.uuidCreador,
        uuidAreaResponsable: form.uuidAreaResponsable,
        uuidFilial: form.uuidFilial,
        uuidEstado: form.uuidEstado,
        uuidSubareaResponsable: form.uuidSubareaResponsable,
        uuidSitioResponsable: form.uuidSitioResponsable,
        exportar: this.checkedExportar,
      };
    }

    this.filterRequest.emit(aux);
  }

  cerrarFiltros() {
    this.cerrar.emit(false);
  }

  getListadoTipoReclamoMantenedor() {
    this.loaderTipoReclamo = true;
    this.apiServiceMantenedor.getListadoTipoReclamoMantenedor().subscribe(
      (response: GetListadoTipoReclamoResponseDTO) => {
        this.listadoTipoReclamos = response.data;
        this.loaderTipoReclamo = false;
        this.filterForm.patchValue({ uuidTipoReclamo: this.filtrosForm.uuidTipoReclamo });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_RECLAMOS;
        this.toastr.error(msg);
        this.loaderTipoReclamo = false;
      }
    );
  }

  getListadoUsuariosMantenedor() {
    this.loaderUsuarioCreador = true;
    this.apiServiceMantenedor.getListadoUsuariosMantenedor().subscribe(
      (response: GetListadoUsuarioResponseDTO) => {
        this.listadoUsuarioCreador = response.data;
        this.loaderUsuarioCreador = false;
        if (this.filtrosForm.uuidCreador !== null) {
          this.filterForm.patchValue({ uuidCreador: this.filtrosForm.uuidCreador });
        }
        if (this.filtrosForm.nombreUsuario !== null) {
          this.filterForm.patchValue({ nombreUsuario: this.filtrosForm.nombreUsuario });
        }
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_USUARIOS;
        this.toastr.error(msg);
        this.loaderUsuarioCreador = false;
      }
    );
  }

  getListadoAreasMantenedor() {
    this.loaderAreaResponsable = true;
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listadoAreaResponsable = response.data;
        this.loaderAreaResponsable = false;
        this.filterForm.patchValue({ uuidAreaResponsable: this.filtrosForm.uuidAreaResponsable });

        this.getListadoSubAreas();
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
        this.loaderAreaResponsable = false;
      }
    );
  }

  getListadoSubAreas() {
    this.listadoSubareaResponsable = [];
    if (this.filterForm.controls.uuidAreaResponsable.value) {

      this.loaderSubareaResponsable = true;
      this.filterForm.controls.uuidAreaResponsable.valueChanges.subscribe(value => {
        this.filterForm.patchValue({ uuidSubareaResponsable: null });
      });
      this.apiServiceMantenedor.getListadoSubAreasMantenedor(this.filterForm.value.uuidAreaResponsable).subscribe(
        (response: GetListadoSubAreasResponseDTO) => {
          this.listadoSubareaResponsable = response.data.filter(sub => sub.habilitado);
          this.loaderSubareaResponsable = false;
          if (this.filtrosForm.uuidSubareaResponsable !== null) {
            this.filterForm.patchValue({ uuidSubareaResponsable: this.filtrosForm.uuidSubareaResponsable });
            this.filtrosForm.uuidSubareaResponsable = null;
            this.getListadoSitios();
          }
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
          this.toastr.error(msg);
          this.loaderSubareaResponsable = false;
        }
      );
    }
  }

  getListadoSitios() {
    this.listadoSitioResponsable = [];
    if (this.filterForm.controls.uuidSubareaResponsable.value) {
      this.loaderSitioResponsable = true;
      this.filterForm.controls.uuidSubareaResponsable.valueChanges.subscribe(value => {
        this.filterForm.patchValue({ uuidSitioResponsable: null });
      });
      this.apiServiceMantenedor.getListadoSitiosMantenedor(this.filterForm.value.uuidSubareaResponsable).subscribe(
        (resp) => this.getListadoSitiosSuccess(resp), (error) => this.getListadoSitiosError(error)
      );
    };
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitioResponsable = resp.data.filter(sitio => sitio.habilitado);
    this.loaderSitioResponsable = false;
    console.log('lsitado sitios success');
    if (this.filtrosForm.uuidSitioResponsable !== null) {
      console.log('entro if');
      this.filterForm.patchValue({ uuidSitioResponsable: this.filtrosForm.uuidSitioResponsable });
      this.filtrosForm.uuidSitioResponsable = null;
    };
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastr.error(msg);
    this.loaderSubareaResponsable = false;
  }

  getListadoAreasFiliales() {
    this.loaderAreaFilial = true;
    this.apiService.getListadoFiliales().subscribe(
      (response: GetListadoFilialesResponseDTO) => {
        this.listadoAreaFilial = response.data;
        this.loaderAreaFilial = false;
        this.filterForm.patchValue({ uuidFilial: this.filtrosForm.uuidFilial });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_FILIAL;
        this.toastr.error(msg);
        this.loaderAreaFilial = false;
      }
    );
  }

  getListadoEstadosMantenedor() {
    this.loaderEstados = true;
    this.apiServiceMantenedor.getListadoEstadosMantenedor(this.menuSC).subscribe(
      (response: GetListadoEstadosResponseDTO) => {
        this.listadoEstados = response.data;
        this.loaderEstados = false;
        this.filterForm.patchValue({ uuidEstado: this.filtrosForm.uuidEstado });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_ESTADOS;
        this.toastr.error(msg);
        this.loaderEstados = false;
      }
    );
  }

  getListadoCargos() {
    this.loaderCargo = true;
    this.apiService.getListadoCargos().subscribe(
      (response: GeneralResponseDTO) => {
        this.listadoCargo = response.data;
        this.loaderCargo = false;
        this.filterForm.patchValue({ cargo: this.filtrosForm.cargo });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_CARGOS;
        this.toastr.error(msg);
        this.loaderCargo = false;
      }
    );
  }

  getListadoAccionesHistorial() {
    this.apiServiceMantenedor.getListadoAccionesHistorial().subscribe(
      (response: GeneralResponseDTO) => {
        this.listadoAccion = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_ACCIONES;
        this.toastr.error(msg);
      }
    );
  }

  checkExportar() {
    this.checkedExportar = !this.checkedExportar;
  }
}
