import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalGestionEditarComponent } from './modal-gestion-editar/modal-gestion-editar.component';
import { ModalGestionHistorialComponent } from './modal-gestion-historial/modal-gestion-historial.component';
import { ModalGestionTerminadaComponent } from './modal-gestion-terminada/modal-gestion-terminada.component';
import { ModalGestionAdjuntarComponent } from './modal-gestion-adjuntar/modal-gestion-adjuntar.component';
import { ModalGestionComentariosComponent } from './modal-gestion-comentarios/modal-gestion-comentarios.component';
import { ModalGestionEliminarComponent } from './modal-gestion-eliminar/modal-gestion-eliminar.component';
import { ApiGestionService } from '../services/api-gestion.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PostListadoActividadesPaginadoResponseDTO } from 'src/dtos/response/gestion/postListadoActividadesPaginado.response.dto';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { UserService } from '../services/user.service';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from '../app.message';
import { saveAs } from 'file-saver';
import { PostExportarActividadesXSLRequestDTO } from 'src/dtos/request/gestion/postExportarXLSActividades.request.dto';
import { ActividadService } from '../services/actividad.service';
import { Router } from '@angular/router';
import { GetPlanesDeAccionResponseDTO } from 'src/dtos/response/gestion/getPlanesDeAccion.response.dto';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PostListadoActividadesPaginadosRequestDTO } from 'src/dtos/request/gestion/postListadoActividadesPaginados.request.dto';
import { UsuarioModel } from 'src/models/usuario.model';

@Component({
  selector: 'app-gestion-actividades',
  templateUrl: './gestion-actividades.component.html',
  styleUrls: ['./gestion-actividades.component.scss']
})
export class GestionActividadesComponent {

  viewFilter: boolean = false;
  contCheck: number = 0;
  checkActividades = false;
  busqueda = new FormControl(null);
  auxListado: ActividadDTO[] = [];
  uuidUser: string;
  uuidAreaUser: string;
  totalActividades: number = 0;
  currentPage: number = 0;
  loader: boolean = false;
  filtros: any;
  actividades = [];
  listadoActividades: ActividadDTO[] = [];
  pageSize: number = 10;
  planDeAccion: boolean = false;
  formCtrlSub: Subscription;
  usuario: UsuarioModel;

  constructor(
    private modalService: BsModalService,
    private apiGestion: ApiGestionService,
    private userService: UserService,
    private toastr: ToastrService,
    private actividadService: ActividadService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }
  
  ngOnInit(): void {
    this.usuario = this.userService.getUser();
    this.uuidUser = this.usuario.uuid;
    if (this.usuario.preferencias) {
      this.uuidAreaUser = this.usuario.preferencias.area.uuid;
    }
    this.initFilters();

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoActividades = [];
        this.getBusquedaPaginadaActividades();
        
      }
    });
  }

  openModalGestionEditar(actividad: ActividadDTO) {
    const modalEditar: BsModalRef = this.modalService.show(ModalGestionEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });

    this.cerrarModalCargaData(modalEditar);
  }

  openModalGestionHistorial(actividad: ActividadDTO) {
    this.modalService.show(ModalGestionHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuidActividad: actividad.uuid,
        idActividad: actividad.id
      }
    });
  }

  openModalGestionTerminada(actividad: ActividadDTO) {
    const modalTerminar: BsModalRef = this.modalService.show(ModalGestionTerminadaComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });

    this.cerrarModalCargaData(modalTerminar);
  }

  openModalGestionAdjuntar(actividad: ActividadDTO) {
    const modalAdjuntar: BsModalRef = this.modalService.show(ModalGestionAdjuntarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuidActividad: actividad.uuid,
      }
    });
    this.cerrarModalCargaData(modalAdjuntar);
  }

  openModalGestionComentarios(actividad: ActividadDTO) {
    this.modalService.show(ModalGestionComentariosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        actividad: actividad
      }
    });
  }

  openModalGestionEliminar(actividad: ActividadDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalGestionEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });

    this.cerrarModalCargaData(modalEliminar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      if (myData === 'ELIMINAR' || myData === 'EDITAR' || myData === 'TERMINAR') {
        this.postListadoActividadesPaginado();
      }
      if (myData === 'CREAR') {
        this.initFilters();
      }
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  hideShowFilter(event: boolean) {
    this.viewFilter = event;
  }

  selDocumento(uuid: string) {
    const actividadAux = this.listadoActividades.find(d => d.uuid === uuid);

    if (actividadAux && actividadAux.checkActividad) {
      actividadAux.checkActividad = false;
      if (this.contCheck > 0) {
        this.contCheck--;
      }
    } else {
      actividadAux.checkActividad = true;
      this.contCheck++;
    }

    this.checkActividades = (this.contCheck >= this.auxListado.length) ? true : false;
    this.auxListado = this.listadoActividades;
  }

  pageChanged(event: any): void {
    this.checkActividades = true;
    this.seleccionMultiple();
    this.currentPage = event.page;
    if (this.busqueda.value?.length > 0)
      this.getBusquedaPaginadaActividades();
    else
      this.postListadoActividadesPaginado();
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      id: null,
      uuidEstado: null,
      uuidCategoria: null,
      uuidCreador: null,
      fechaVencimiento: null,
      fechaCreacion: null,
      uuidArea: null,
      uuidSubArea: null,
      uuidSitio: null,
      uuidResponsable: null,
      exportar: false
    }
    if (initData)
      this.postListadoActividadesPaginado();
  }

  filterActividad(event: any) {
    this.currentPage = 1;
    this.hideShowFilter(false);
    this.filtros = event;

    this.postListadoActividadesPaginado();
  }

  seleccionMultiple() {
    this.auxListado = this.listadoActividades;
    this.contCheck = 0;
    for (const iterator of this.auxListado) {
      if (!this.checkActividades) {
        this.contCheck++;
      }
      iterator.checkActividad = !this.checkActividades;
    }
    this.checkActividades = !this.checkActividades;
  }

  getBusquedaPaginadaActividades() {
    this.loader = true;
    this.apiGestion.getBusquedaPaginadaActividades(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (resp) => this.listadoActividadesPaginadoSuccess(resp),
      (error) => this.listadoActividadesPaginadoError(error));
  }

  postListadoActividadesPaginado() {
    this.loader = true;
    this.busqueda.setValue(null);

    const dataRequest: PostListadoActividadesPaginadosRequestDTO = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      fechaVencimientoInicio: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[0] : null,
      fechaVencimientoTermino: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[1] : null,
      id: this.filtros.id,
      uuidArea: this.filtros.uuidArea,
      uuidCategoria: this.filtros.uuidCategoria,
      uuidCreador: this.filtros.uuidCreador,
      uuidEstado: this.filtros.uuidEstado,
      uuidResponsable: this.filtros.uuidResponsable,
      uuidSitio: this.filtros.uuidSitio,
      uuidSubArea: this.filtros.uuidSubArea,
      exportar: this.filtros.exportar
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
    console.log(dataRequest);
    if (dataRequest.exportar) {
      this.apiGestion.postExportarListadoFiltradoActividades(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
        (response) => {
          console.log(response);
          let blob = new Blob([response.body], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          saveAs(blob, `listaActividades.xlsx`);
          this.toastr.success(AppMessage.SUCCESS_DESCARGAR_RECLAMOS);
          this.loader = false;
          this.initFilters();
        },
        (error) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_ACTIVIDADES;
          this.toastr.error(msg);
          this.loader = false;
          this.initFilters();
        }
      )
    }
    else {
      this.apiGestion.postListadoActividadesPaginado(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
        (resp) => this.listadoActividadesPaginadoSuccess(resp),
        (error) => this.listadoActividadesPaginadoError(error));
    }

  }

  listadoActividadesPaginadoSuccess(resp: PostListadoActividadesPaginadoResponseDTO) {
    this.listadoActividades = resp.data.content;
    this.totalActividades = resp.data.totalElements;

    for (const iterator of this.listadoActividades ) {
      iterator.checkActividad = false;
    }

    this.currentPage = resp.data.pageable.pageNumber + 1;

    this.auxListado = this.listadoActividades;
    this.checkActividades = false;
    this.loader = false;
    this.getPlanesDeAccion();
  }

  listadoActividadesPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_ACTIVIDADES;
    this.toastr.error(msg);
    this.loader = false;
  }

  exportarActividades() {
    const actividades: PostExportarActividadesXSLRequestDTO = {
      uuidActividades: this.auxListado.filter(act => act.checkActividad).map(act => act.uuid)
    };
    if (actividades.uuidActividades.length > 0) {
      this.apiGestion.postExportarXSLActividades(actividades).subscribe(
        (resp) => this.postXSLActividadesSuccess(resp),
        (error) => this.postXSLActividadesError(error));
    }

  }

  postXSLActividadesSuccess(resp: any): void {
    let blob = new Blob([resp.body], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, `listaActividades.xlsx`);
    this.toastr.success(AppMessage.SUCCESS_EXPORTAR_LISTADO_ACTIVIDADES);
  }

  postXSLActividadesError(error: any) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EXPORTAR_LISTADO_ACTIVIDADES;
    this.toastr.error(msg);
  }

  getDetalleActividad(uuidActividad: string) {
    this.actividadService.setUuidSelected(uuidActividad);
    this.router.navigate(['web/gestion-consultar']);
    
  }

  goToCrearActividad() {
    if (this.usuario.preferencias) {
      if (this.usuario.preferencias.area && this.usuario.preferencias.sitio && this.usuario.preferencias.subArea) {
        this.router.navigate(['web/gestion-crear']);
      }
      else {
        this.toastr.error('Debe seleccionar sus preferencias para poder crear una actividad');
      }
    }
    else {
      this.toastr.error('Debe seleccionar sus preferencias para poder crear una actividad');
    }
    
  }

  goToCrearPlanDeAccion(uuidActividad: string) {
    this.actividadService.setUuidSelected(uuidActividad);
    this.router.navigate(['web/gestion-crear-plan'])
  }

  getPlanesDeAccion() {
    if (this.listadoActividades.length > 0) {
      for (let i = 0; i < this.listadoActividades.length; i++){
        this.loader = true;
        this.apiGestion.getPlanesDeAccion(this.listadoActividades[i].uuid).subscribe(
          (resp) => this.getPlanesDeAccionSuccess(resp, i),
          (error) => this.getPlanesDeAccionError(error));
      }
    }
  }

  getPlanesDeAccionSuccess(resp: GetPlanesDeAccionResponseDTO, index: number) {
    this.listadoActividades[index].planesDeAccion = resp.data;
    this.loader = false;
  }

  getPlanesDeAccionError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_PLANES_DE_ACCION;
    this.toastr.error(msg);
  }
  
  goToEditarPlanDeAccion(uuidPlan: string, uuidActividad: string) {
    this.actividadService.setUuidPlanDeAccion(uuidPlan);
    this.actividadService.setUuidSelected(uuidActividad);
    this.router.navigate(['web/gestion-editar-plan']);
  }

}
