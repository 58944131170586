import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { Base64Util } from 'src/app/util/base64.util';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-mc-subir',
  templateUrl: './modal-mc-subir.component.html',
  styleUrls: ['./modal-mc-subir.component.scss']
})
export class ModalMcSubirComponent { 

  subirForm: FormGroup;
  listadoTipos = ['Foto', 'Documento', 'PPT'];
  uuidMetodologia: string;
  blobFile: Blob;
  onClose: Subject<string>;


  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef
  ) { }
  
  ngOnInit() {
    this.onClose = new Subject();

    this.subirForm = this.formBuilder.group({
      nombre: new FormControl(null, [Validators.required]),
      tipo: new FormControl(null),
      uuidMetodologia: new FormControl(this.uuidMetodologia),
      file: new FormControl(null, [Validators.required]),
      fileSource: new FormControl(null, [Validators.required]),
    })
  }

  addAdjunto(event: any) {
    this.subirForm.controls.file.markAsTouched();
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.blobFile = Base64Util.base64toBlob(reader.result);
      };

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };

      this.subirForm.patchValue({
        fileSource: file,
      });
    }
  }


  getAdjuntos() {
    return (this.subirForm.get('fileSource') as FormArray).controls;
  }


  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
    }
    return url;
  }

  subirArchivo() {
    if (this.subirForm.valid) {
      const value = this.subirForm.value;
      let formData = new FormData();
      formData.append('nombre', value.nombre);
      formData.append('tipo', value.tipo);
      formData.append('uuidMetodologia', value.uuidMetodologia);
      formData.append('archivo', this.blobFile, this.subirForm.controls.fileSource.value.name);

      this.apiGestion.postSubirMaterial(formData).subscribe((resp) => {
        this.toastr.success(AppMessage.SUCCESS_SUBIR_MATERIAL);
        this.onClose.next('CREAR');
      }, (error) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_SUBIR_MATERIAL;
        this.toastr.error(msg);
      })

    }
  }
}
