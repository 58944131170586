<div class="loader-container" *ngIf="loaderPaginado">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Boton menu en vista mobile -->
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-wrench ico-page" aria-hidden="true"></i><span
                class="title1">Jerarquización</span></div>
    </div>
    <div class="section-page">
        <div class="nav-container">
            <tabset class="tab-container" #staticTabs>
                <ng-container *ngFor="let jer of listaJerarquizacion; let i = index;">
                    <tab [heading]="jer.title  + 's'" [id]="jer.id" (selectTab)="selectTab(i)">
                        <div class="subtitle">{{jer.title + 's'}}</div>
                        <div class="search-create">
                            <div class="create-mantenedor">
                                <button type="button" class="btn btn-secundary" (click)="openModalJCrear(jer.title)">
                                    <span class="text-create">Crear nuevo</span>
                                    <i class="far fa-plus-square create-ico" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="search-mantenedor">
                                <input type="text" id="txtSearch" placeholder="Buscar" class="form-control"
                                    [formControl]="busqueda" autocomplete="off" />
                                <i class="fas fa-search ico-input-search" aria-hidden="true"></i>
                            </div>
                        </div>
                        <!-- Listar Macroprocesos -->
                        <div *ngIf="indexSelect === 0 && !loaderPaginado && listadoPagMacroprocesos.length > 0"
                            class="back-jeraquizacion">
                            <div class="card-jeraquizacion" [ngClass]="{'disable-card': !lpm.habilitado}"
                                *ngFor="let lpm of listadoPagMacroprocesos">
                                <div class="titles">
                                    <div class="title-jeraquizacion">Nombre</div>
                                    <div class="title-jeraquizacion-principal">{{lpm.nombre}}</div>
                                </div>
                                <div class="actions">
                                    <button *ngIf="lpm.habilitado" type="button" class="btn-ico-tool"
                                        (click)="openModalJEditar(jer.title, lpm.uuid)">
                                        <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="lpm.habilitado" type="button" class="btn-ico-tool"
                                        (click)="openModalJDeshabilitar(jer.title, lpm.nombre, lpm.uuid, lpm.cantidad)">
                                        <i class="fas fa-minus-circle ico-action-blue" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="lpm.eliminable && lpm.habilitado" type="button" class="btn-ico-tool"
                                        (click)="openModalJEliminar(jer.title, lpm.nombre, lpm.uuid)">
                                        <i class="fas fa-trash-alt ico-action-blue-trash" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="!lpm.habilitado" type="button" class="btn btn-enable"
                                        (click)="openModalJHabilitar(jer.title, lpm.nombre, lpm.uuid)">
                                        Habilitar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="sin-resultados" *ngIf="indexSelect === 0 && listadoPagMacroprocesos.length === 0">
                            <h2 class="text-center">Sin resultados.</h2>
                        </div>
                        <!-- Listar Procesos - Areas - Subareas -->
                        <div *ngIf="(indexSelect === 1 || indexSelect === 2 || indexSelect === 3 || indexSelect === 4) && !loaderPaginado && listadoAuxPaginado.length > 0"
                            class="table" style="padding-left: 0 !important;">
                            <div class="table-responsive">
                                <table id="example" class="display" style="width:100%">
                                    <caption>Mantenedor jerarquización</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col"
                                                [ngStyle]="{'width': indexSelect === 1 || indexSelect === 4 ? '45%' : '30%'}">
                                                Nombre</th>
                                            <th scope="col" style="width: 30%;"
                                                *ngIf="indexSelect === 2 || indexSelect === 3">
                                                Dimensiones asociadas</th>
                                            <th scope="col"
                                                [ngStyle]="{'width': indexSelect === 1 || indexSelect === 4 ? '45%' : '30%'}">
                                                {{listaJerarquizacion[indexSelect-1].title}} asociado</th>
                                            <th scope="col" style="width: 10%; word-break:normal;">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lap of listadoAuxPaginado"
                                            [ngClass]="{'disable-tr': !lap.habilitado}">
                                            <td>{{lap.nombre}}</td>
                                            <td *ngIf="indexSelect === 2 || indexSelect === 3">{{lap.dimensiones}}
                                            </td>
                                            <td>{{lap[listaJerarquizacion[indexSelect-1].name]}}</td>
                                            <td class="text-nowrap">
                                                <button *ngIf="lap.habilitado" type="button" class="btn-ico-tool"
                                                    (click)="openModalJEditar(jer.title, lap.uuid)">
                                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                                </button>
                                                <button *ngIf="lap.habilitado" type="button" class="btn-ico-tool"
                                                    (click)="openModalJDeshabilitar(jer.title, lap.nombre, lap.uuid, lap.cantidad)">
                                                    <i class="fas fa-minus-circle ico-action-blue"
                                                        aria-hidden="true"></i>
                                                </button>
                                                <button *ngIf="!lap.habilitado" type="button" class="btn btn-enable"
                                                    (click)="openModalJHabilitar(jer.title, lap.nombre, lap.uuid)">
                                                    Habilitar
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="sin-resultados" *ngIf="indexSelect > 0 && listadoAuxPaginado.length === 0">
                            <h2 class="text-center">Sin resultados.</h2>
                        </div>
                    </tab>
                </ng-container>
            </tabset>
            <!--Pagination -->
            <div class="mt-3 row" *ngIf="totalRegister > 10">
                <div class="col text-right">
                    <div class="pagination">
                        <pagination [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}"
                            class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                            [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                            [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalRegister"
                            (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="full-height">&nbsp;</div>