<div class="container-fluid ">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fa fa-user ico-page" aria-hidden="true"></i><span class="title1">Mi perfil</span>
        </div>
    </div>
    <div class="profile">
        <div class="profile-data">
            <div class="profile-user">
                <div class="profile1">
                    <div class="profile-picture" style="background-image:url(assets/img/asignado.jpg)"></div>
                    <i class="fas fa-camera change-picture" aria-hidden="true"></i>
                </div>
                <div class="profile2" [innerHTML]="titulo"></div>
            </div>
            <div class="closeSesion">
                <button class="btn btn-cierreSesion" (click)="cerrarSesion()">
                    <i class="fas fa-solid fa-door-open" aria-hidden="true"></i>Cerrar Sesión
                </button>
            </div>
        </div>
        <form class="profile-preferences" [formGroup]="preferenceForm" *ngIf="preferenceForm">
            <div class="profile3">Preferencias de Ubicación</div>
            <div class="profile4">{{subTitulo}}</div>
            <div class="field-preferences">
                <div class="inp" *ngIf="!loaderAreas">
                    <ng-select class="select-nborder" formControlName="area" placeholder="Seleccione Área"
                        (change)="getListadoSubAreas(true)">
                        <ng-option *ngFor="let area of listadoArea" [value]="area.uuid">{{area.nombre}}</ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderAreas">
                    <input class="inp-modal" type="text" placeholder="Seleccione Área" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-preferences">
                <div class="inp" *ngIf="!loaderSubareas">
                    <ng-select class="select-nborder" formControlName="subarea" placeholder="Seleccione Subárea"
                        (change)="getListadoSitios(true)">
                        <ng-option *ngFor="let sub of listadoSubArea" [value]="sub.uuid">{{sub.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSubareas">
                    <input class="inp-modal" type="text" placeholder="Seleccione Subárea" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-preferences">
                <div class="inp" *ngIf="!loaderSitios">
                    <ng-select class="select-nborder" formControlName="sitio" placeholder="Seleccione Sitio">
                        <ng-option *ngFor="let sitio of listadoSitio" [value]="sitio.uuid">{{sitio.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSitios">
                    <input class="inp-modal" type="text" placeholder="Seleccione Sitio" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <div class="field-notification-preferences">
                <div class="inp" *ngIf="!loaderModulos">
                    <ng-select class="select-nborder" formControlName="modulos" placeholder="Seleccione Modulos" [multiple]="true">
                        <ng-option *ngFor="let modulo of listadoModulos" [value]="modulo.uuid">{{modulo.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderModulos">
                    <input class="inp-modal" type="text" placeholder="Seleccione Modulos" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="profile5">Por favor seleccione un modulo para recibir notifiaciones de ese modulo</div>
            <div class="btn-preferences">
                <button class="btn btn-primary btn-profile" (click)="savePreference()"
                    [disabled]="!preferenceForm.valid">Guardar Preferencias</button>
            </div>
        </form>

    </div>
</div>
<div class="back"></div>