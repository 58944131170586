<!-- FILTROS -->
<div id="div1" class="filter" [style.visibility]="viewFilter ? 'visible' : 'hidden'">
    <app-gestion-filtros (cerrar)="hideShowFilter($event)" [filtrosForm]="filtros" (filterRequest)="filterActividad($event)">
    </app-gestion-filtros>
</div>

<div class="container-fluid">
    <div class="loader-container" [style.visibility]="loader ? 'visible' : 'hidden'">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-thumbs-up ico-page" aria-hidden="true"></i><span class="title1">Gestión de
                Actividades</span>
        </div>
    </div>
    <div class="create"><a (click)="goToCrearActividad()"><button type="button" class="btn btn-secundary" data-toggle="modal"
                data-target="#subirdocumento"> <span class="text-create"> crear actividad </span><i
                    class="far fa-plus-square create-ico" aria-hidden="true"></i>
            </button></a></div>
    <div class="section-page">
        <div class="tools-table">
            <div class="search-mobile"><input id="txtSearch" placeholder="Buscar" class="form-control" /><i
                    class="fas fa-search ico-input1" aria-hidden="true"></i></div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="reclamos"
                        [checked]="checkActividades" (click)="seleccionMultiple()">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export"><button class="btn btn-table" (click)="exportarActividades()">Exportar <i
                        class="fas fa-file-excel ico-export" aria-hidden="true"></i></button></div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export"><button class="btn btn-table btn-filter" (click)="hideShowFilter(!viewFilter)">Filtrar
                    <i class="fas fa-filter ico-export" aria-hidden="true"></i></button>
            </div>
            <div class="clean"><button (click)="initFilters()" class="clean-filters"> Limpiar Filtros</button></div>
        </div>
        <div class="tabla" [style.visibility]="!loader ? 'visible' : 'hidden'">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Gestión de actividades</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 12%;">ID</th>
                            <th scope="col" style="text-align: center; width: 13%;">Estado</th>
                            <th scope="col" style="text-align: center; width: 10%;">Actividad</th>
                            <th scope="col" style="width: 10%;">Categoría</th>
                            <th scope="col" style="width: 10%;">Sub-categoría 2</th>
                            <th scope="col" style="width: 12%;">Sitio</th>
                            <th scope="col" style="width: 8%;">Fecha de vencimiento</th>
                            <th scope="col" style="width: 25%; word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let actividad of listadoActividades; let i = index">
                            <td colspan="8" class="innerFullCell">
                                <table aria-hidden="true" style="table-layout: fixed; width: 100%;">
                                    <caption>Listado de actividades</caption>
                                    <tbody>
                                        <tr class="no-border">
                                            <td style="width: 12%">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="{{'sel' + actividad.uuid}}" name="gestion"
                                                        [checked]="(actividad.checkActividad) ? true : false"
                                                        (click)="selDocumento(actividad.uuid)">
                                                    <label class="custom-control-label"
                                                        for="{{'sel' + actividad.uuid}}"> </label>
                                                    <button [disabled]="actividad?.planesDeAccion?.length === 0"
                                                        type="button" class="btn-details4" id="{{'plan-1-'+ i}}"
                                                        data-toggle="modal"
                                                        data-target="#historialactividad">ID{{actividad.id}}
                                                        <i aria-hidden="true" *ngIf="actividad?.planesDeAccion?.length > 0"
                                                            class="fas fa-chevron-down ico-id"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td style="text-align: center; width: 13%">
                                                <div class="estadoActividad fueraPlazo"
                                                    *ngIf="actividad.estadoActividad.nombre === 'Fuera de plazo'">
                                                    
                                                    <div class="details-text-estado"> Fuera de plazo</div>
                                                </div>
                                                <div class="estadoActividad cumple"
                                                    *ngIf="actividad.estadoActividad.nombre === 'Cumple'">
                                                   
                                                    <div class="details-text-estado"> Cumple</div>
                                                </div>
                                                <div class="estadoActividad conFaltantes"
                                                    *ngIf="actividad.estadoActividad.nombre === 'Con faltantes'">
                                                  
                                                    <div class="details-text-estado"> Con faltantes</div>
                                                </div>
                                                <div class="estadoActividad noCumple"
                                                    *ngIf="actividad.estadoActividad.nombre === 'No cumple'">
                                                   
                                                    <div class="details-text-estado"> No cumple</div>
                                                </div>
                                                <div class="estadoActividad"
                                                    *ngIf="actividad.estadoActividad.nombre === 'En curso'">
                                                    <i aria-hidden="true" class="far fa-check-square ga-icon-blue"></i>
                                                    <div class="details-text-estado"> En curso</div>
                                                </div>
                                                <div class="estadoActividad"
                                                    *ngIf="actividad.estadoActividad.nombre === 'Futuras'">
                                                    <i aria-hidden="true" class="fas fa-calendar ga-icon-grey"></i>
                                                    <div class="details-text-estado"> Futuras</div>
                                                </div>
                                                <div class="estadoActividad"
                                                    *ngIf="actividad.estadoActividad.nombre === 'Revisar'">
                                                    <i aria-hidden="true" class="fas fa-clipboard-check ga-icon-red"></i>
                                                    <div class="details-text-estado"> Revisar</div>
                                                </div>
                                            </td>
                                            <td style="width:10%">
                                                <div class="height-td-gestion">{{actividad.nombre}}</div>
                                            </td>
                                            <td style="width:10%">
                                                <div class="height-td-gestion">{{actividad.categoria.nombre}}</div>
                                            </td>
                                            <td style="width:10%">
                                                <div class="height-td-gestion">{{actividad.subCategoriaDos.nombre}}
                                                </div>
                                            </td>
                                            <td style="width:12%">
                                                <div class="height-td-gestion">{{actividad.asignacion?.sitio?.nombre}}
                                                </div>
                                            </td>
                                            <td style="width:8%">
                                                <div class="height-td-gestion">{{actividad.fechaVencimiento | date:
                                                    'dd/MM/yyyy'}}</div>
                                            </td>
                                            <td style="width:25%" class=" text-nowrap">
                                                <div class="btn-container" style="max-width: 100%;">
                                                    <button type="button" class="btn-ico-tool" tooltip="Consultar Actividad"
                                                    placement="top" routerLink="/web/gestion-consultar"
                                                    [queryParams]="{uuidActividad: actividad.uuid}">
                                                    <i aria-hidden="true" class="far fa-eye ico-action"></i>
                                                </button>
                                                <button *ngIf="uuidUser === actividad.usuario?.uuid
                                                && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                                                actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'" type="button"
                                                    class="btn-ico-tool" tooltip="Editar" placement="top"
                                                    (click)="openModalGestionEditar(actividad)">
                                                    <i aria-hidden="true" class="fas fa-pen ico-action"></i>
                                                </button>
                                                <button *ngIf="uuidUser === actividad.usuario?.uuid 
                                                && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                                                actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'" type="button"
                                                    class="btn-ico-tool" tooltip="Validar actividad" placement="top"
                                                    (click)="openModalGestionTerminada(actividad)">
                                                    <i aria-hidden="true" class="fas fa-power-off ico-action"></i>
                                                </button>
                                                <a><button (click)="goToCrearPlanDeAccion(actividad.uuid)"
                                                        *ngIf="uuidAreaUser === actividad.asignacion?.area?.uuid && actividad.esperaPlanAccion === true
                                                        && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                                                        actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'"
                                                        type="button" class="btn-ico-tool"
                                                        tooltip="Crear Plan de Acción" placement="top">
                                                        <i aria-hidden="true" class="far fa-calendar-check ico-action"></i>
                                                    </button></a>
                                                <button *ngIf="uuidAreaUser === actividad.asignacion?.area?.uuid
                                                && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                                                actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'"
                                                    type="button" class="btn-ico-tool show-mobile"
                                                    tooltip="Adjuntar evidencia" placement="top"
                                                    (click)="openModalGestionAdjuntar(actividad)">
                                                    <i aria-hidden="true" class="fas fa-paperclip ico-action"></i>
                                                </button>
                                                <button type="button" class="btn-ico-tool" tooltip="Comentarios"
                                                    placement="top" (click)="openModalGestionComentarios(actividad)">
                                                    <i aria-hidden="true" class="fas fa-comment-dots ico-action"></i>
                                                </button>
                                                <button type="button" class="btn-ico-tool" tooltip="Historial"
                                                    placement="top" (click)="openModalGestionHistorial(actividad)">
                                                    <i aria-hidden="true" class="fas fa-history ico-action"></i>
                                                </button>
                                                <button *ngIf="uuidUser === actividad.usuario?.uuid" type="button"
                                                    class="btn-ico-tool" tooltip="Eliminar actividad" placement="top"
                                                    (click)="openModalGestionEliminar(actividad)">
                                                    <i aria-hidden="true" class="fas fa-trash-alt ico-action-blue"></i>
                                                </button>
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="subListPlanAccion" *ngIf="actividad?.planesDeAccion?.length > 0"
                                    class="{{'plan-1-'+ i + ' plan'}}">
                                    <table style="width: 100%">
                                        <caption>Listado planes de accion</caption>
                                        <thead>
                                            <tr>
                                                <th>Plan de acción</th>
                                                <th>Responsable</th>
                                                <th>Fecha término</th>
                                                <th>Estado</th>
                                                <th>Comentarios</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let planAccion of actividad.planesDeAccion">
                                                <td>{{planAccion.nombre}}</td>
                                                <td>{{planAccion.usuarioResponsable.nombre}}
                                                    {{planAccion.usuarioResponsable.apellido}}</td>
                                                <td>{{planAccion.fechaTermino | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{planAccion.estadoPlanAccion.nombre}}</td>
                                                <td>{{planAccion.comentarios}}</td>
                                                <td>
                                                    <button *ngIf="uuidUser === planAccion.usuarioResponsable.uuid"
                                                        type="button" class="btn-ico-tool"
                                                        tooltip="Editar Plan de Acción"
                                                        (click)="goToEditarPlanDeAccion(planAccion.uuid, actividad.uuid)"
                                                        placement="top">
                                                        <i aria-hidden="true" class="fas fa-pen ico-action"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>


                    </tbody>
                    <tfoot style="display: none;">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Responsable</th>
                            <th scope="col">Subárea Responsable</th>
                            <th scope="col">Fecha vencimiento</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </tfoot>
                </table>
                <div class="sin-resultados" *ngIf="listadoActividades.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>

                <!--Table-->

            </div>

            <!--Section: Live preview-->
        </div>
        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalActividades > 10" [ngStyle]="{'visibility': loader ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalActividades"
                        (pageChanged)="pageChanged($event)" [maxSize]="4">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>