import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { GetPorcentajesHomeRequestDTO } from 'src/dtos/request/documental/getPorcentajesHome.request.dto';
import { EditarActividadRequestDTO } from 'src/dtos/request/gestion/editarActividad.request.dto';
import { PostAvanceAnualPorAreaRequestDTO } from 'src/dtos/request/gestion/postAvanceAnualPorArea.request.dto';
import { PostCrearActividadRequestDTO } from 'src/dtos/request/gestion/postCrearActividad.request.dto';
import { PostCrearSubcategoriaDosRequestDTO } from 'src/dtos/request/gestion/postCrearSubategoriaDos.request.dto';
import { PostCrearSubCategoriaUnoRequestDTO } from 'src/dtos/request/gestion/postCrearSubcategoriaUno.request.dto';
import { PostExportarActividadesXSLRequestDTO } from 'src/dtos/request/gestion/postExportarXLSActividades.request.dto';
import { PostListadoActividadesPaginadosRequestDTO } from 'src/dtos/request/gestion/postListadoActividadesPaginados.request.dto';
import { PostObtenerImplementacionPaginadoRequestDTO } from 'src/dtos/request/gestion/postObtenerImplementacionPaginado.request.dto';
import { PostObtenerMaterialPaginadoRequestDTO } from 'src/dtos/request/gestion/postObtenerMaterialPaginado.request.dto';
import { PutTerminarActividadRequestDTO } from 'src/dtos/request/gestion/putTerminarActividad.request.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { DeleteEliminarActividadResponseDTO } from 'src/dtos/response/gestion/deleteEliminarActividad.response.dto';
import { GetBusquedaPaginadaFotosImplementacionResponseDTO } from 'src/dtos/response/gestion/getBusquedaPaginadaFotosImplementacion.response.dto';
import { GetCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getCategoriasMantenedor.response.dto';
import { GetComentariosGestionResponseDTO } from 'src/dtos/response/gestion/getComentariosGestion.response.dto';
import { GetDetalleActividadResponseDTO } from 'src/dtos/response/gestion/getDetalleActividad.response.dto';
import { GetEvidenciaActividadResponseDTO } from 'src/dtos/response/gestion/getEvidenciaActividad.response.dto';
import { GetListadoCategoriasResponseDTO } from 'src/dtos/response/gestion/getListadoCategorias.response.dto';
import { GetListadoEstadosResponseDTO } from 'src/dtos/response/gestion/getListadoEstados.response.dto';
import { GetListadoFrecuenciasResponseDTO } from 'src/dtos/response/gestion/getListadoFrecuencias.response.dto';
import { GetListadoHistorialActividadesPaginadoResponseDTO } from 'src/dtos/response/gestion/getListadoHistorialActividadesPaginado.response.dto';
import { GetListadoPaginadoCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoCategoriasMantenedor.response.dto';
import { GetListadoPaginadoMaterialEntrenamientoResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoMaterialEntrenamiento.response.dto';
import { GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoSubcategoriasUnoMantenedor.response.dto';
import { GetListadoRangoDuracionResponseDTO } from 'src/dtos/response/gestion/getListadoRangoDuracion.response.dto';
import { GetListadoSimpleHistorialActividadesResponseDTO } from 'src/dtos/response/gestion/getListadoSimpleHistorialActividades.response.dto';
import { GetListadoSubCategoriasDosResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasDos.response.dto';
import { GetListadoSubCategoriasUnoResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasUno.response.dto';
import { GetMetdologiaResponseDTO } from 'src/dtos/response/gestion/getMetodologia.response.dto';
import { GetPlanDeAccionResponseDTO } from 'src/dtos/response/gestion/getPlanDeAccion.response.dto';
import { GetPlanesDeAccionResponseDTO } from 'src/dtos/response/gestion/getPlanesDeAccion.response.dto';
import { GetPorcentajesHomeGestionResponseDTO } from 'src/dtos/response/gestion/getPorcentajesHomeGestion.response.dto';
import { PostCrearCategoriaResponseDTO } from 'src/dtos/response/gestion/postCrearCategoria.response.dto';
import { PostCrearSubCategoriaDosResponseDTO } from 'src/dtos/response/gestion/postCrearSubCategoriaDos.response.dto';
import { PostCrearSubCategoriaUnoResponseDTO } from 'src/dtos/response/gestion/postCrearSubCategoriaUno.response.dto';
import { PostEnviarComentarioGestionResponseDTO } from 'src/dtos/response/gestion/postEnviarComentarioGestion.response.dto';
import { PostListadoActividadesPaginadoResponseDTO } from 'src/dtos/response/gestion/postListadoActividadesPaginado.response.dto';
import { PutEditarActividadResponseDTO } from 'src/dtos/response/gestion/putEditarActividad.response.dto';
import { PutEditarCategoriaMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarCatgoriaMantenedor.response.dto';
import { PutEditarSubCategoriaDosMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarSubCategoriaDosMantenedor.response.dto';
import { PutEditarSubCategoriaUnoMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarSubCategoriaUnoMantenedor.response.dto';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ApiGestionService {

  private hostGestion: string = environment.hostUrlGestion;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  //CATEGORIAS
  //Get listado Categorias

  getListadoCategorias(): Observable<GetListadoCategoriasResponseDTO> {
    let url = this.hostGestion + 'categorias';
    return this.http.get(url).pipe(map((resp) => new GetListadoCategoriasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  //Get listado Sub-categorias uno

  getListadoSubCategoriasUno(): Observable<GetListadoSubCategoriasUnoResponseDTO> {
    let url = this.hostGestion + 'sub-categorias-uno';
    return this.http.get(url).pipe(map((resp) => new GetListadoSubCategoriasUnoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  //Get listado sub-categorias dos

  getListadoSubCategoriasDos(): Observable<GetListadoSubCategoriasDosResponseDTO> {
    let url = this.hostGestion + 'sub-categorias-dos';
    return this.http.get(url).pipe(map((resp) => new GetListadoSubCategoriasDosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  //FRECUENCIAS
  //Get listado frecuencias

  getListadoFrecuencias(): Observable<GetListadoFrecuenciasResponseDTO> {
    let url = this.hostGestion + 'frecuencias';
    return this.http.get(url).pipe(map((resp) => new GetListadoFrecuenciasResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  //RANGO DE DURACION
  //Get listado rango de duracion

  getListadoRangoDuracion(): Observable<GetListadoRangoDuracionResponseDTO> {
    let url = this.hostGestion + 'rangos_duracion';
    return this.http.get(url).pipe(map((resp) => new GetListadoRangoDuracionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  //ESTADOS
  //Get listado de estados

  getListadoDeEstados(): Observable<GetListadoEstadosResponseDTO> {
    let url = this.hostGestion + 'estados';
    return this.http.get(url).pipe(map((resp) => new GetListadoEstadosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // ACTIVIDADES
  // Crear Actividad

  postCrearActividad(data: PostCrearActividadRequestDTO) {
    let url = this.hostGestion + 'crear';
    return this.http.post<PostCrearActividadRequestDTO>(url, JSON.stringify(data)).pipe(
      map((resp) => new GeneralResponseDTO(resp)), catchError((error: any) => this.handleErrorGestion(error))
    );
  }

  // Listado Paginado

  postListadoActividadesPaginado(page: number, pageSize: number, data: PostListadoActividadesPaginadosRequestDTO): Observable<PostListadoActividadesPaginadoResponseDTO> {
    let url = this.hostGestion + 'paginado?page=' + page + '&size=' + pageSize;

    return this.http.post(url,data).pipe(map((resp) => new PostListadoActividadesPaginadoResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  // Listado reclamos filtrado exportar
  postExportarListadoFiltradoActividades(page: number, pageSize: number, data: PostListadoActividadesPaginadosRequestDTO): Observable<any> {
    let url = this.hostGestion + 'paginado?page=' + page + '&size=' + pageSize;
    let options: any;
    options = {
      observe: 'response',
      responseType: 'blob'
    };
    return this.http.post<PostListadoActividadesPaginadosRequestDTO>(url, data, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorGestion(error))
    );
  }

  // Listado paginado con busqueda

  getBusquedaPaginadaActividades(page: number, pageSize: number, campoBusqueda: string): Observable<PostListadoActividadesPaginadoResponseDTO> {
    let url = this.hostGestion + 'busqueda-paginada?campoBusqueda=' + campoBusqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map((resp) => new PostListadoActividadesPaginadoResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  postExportarXSLActividades(uuidActividades: PostExportarActividadesXSLRequestDTO):Observable<any> {
    const url = this.hostGestion + 'exportar';
    let options: any;
    options = {
      observe: 'response',
      responseType: 'blob'
    };

    return this.http.post<Array<string>>(url, uuidActividades, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorGestion(error))
    ); 
  }

  getDetalleActividad(uuid: string): Observable<GetDetalleActividadResponseDTO> {
    const url = this.hostGestion + 'detalle/' + uuid;
    return this.http.get(url).pipe(map((resp) => new GetDetalleActividadResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarActividad(uuid: string, data: EditarActividadRequestDTO): Observable<PutEditarActividadResponseDTO> {
    const url = this.hostGestion + 'editar/' + uuid;
    return this.http.put<EditarActividadRequestDTO>(url, data).pipe(map((resp) => new PutEditarActividadResponseDTO(resp)),
    catchError((error:any) => this.handleErrorGestion(error)));
  }

  deleteEliminarActividad(uuid: string): Observable<DeleteEliminarActividadResponseDTO>{
    const url = this.hostGestion + 'eliminar/' + uuid;
    return this.http.delete<string>(url, {}).pipe(map(resp => new DeleteEliminarActividadResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putMarcarActividadComoTerminada(uuid: string, data: PutTerminarActividadRequestDTO): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'terminar/' + uuid;
    return this.http.put(url, data).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postAdjuntarEvidenciaActividad(uuidActividad: string, data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    const url = this.hostGestion + 'adjuntar-evidencia/' + uuidActividad;
    return this.http.post<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));

  }

  getEnviarActividad(uuidActividad: string): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'enviar-actividad/' + uuidActividad;
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerEvidencia(uuidActividad: string): Observable<GetEvidenciaActividadResponseDTO> {
    const url = this.hostGestion + 'obtener-evidencia/' + uuidActividad;
    return this.http.get(url).pipe(map(resp => new GetEvidenciaActividadResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // PLANES DE ACCION 

  postCrearPlanDeAccion(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'plan-accion';
    return this.http.post<FormData>(url, data, {params}).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getPlanesDeAccion(uuid: string): Observable<GetPlanesDeAccionResponseDTO> {
    const url = this.hostGestion + 'plan-accion/' + uuid + '/obtener-planes';
    return this.http.get(url).pipe(map(resp => new GetPlanesDeAccionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getPlanDeAccion(uuid: string): Observable <GetPlanDeAccionResponseDTO> {
    const url = this.hostGestion + 'plan-accion/' + uuid;
    return this.http.get(url).pipe(map(resp => new GetPlanDeAccionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarPlanDeAccion(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    const url = this.hostGestion + 'plan-accion';
    return this.http.put<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postAdjuntarEvidenciaPlanDeAccion(uuidPlan: string, data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    const url = this.hostGestion + 'plan-accion/adjuntar-evidencia/' + uuidPlan;
    return this.http.post<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getAccionRealizada(uuid: string): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'plan-accion/accion-realizada/' + uuid;
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // HISTORIAL 

  getListadoSimpleHistorialActividades(uuidActividad: string): Observable<GetListadoSimpleHistorialActividadesResponseDTO>{
    const url = this.hostGestion + 'historial?uuidGestionActividad=' + uuidActividad;
    return this.http.get(url).pipe(map(resp => new GetListadoSimpleHistorialActividadesResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getListadoHistorialActividadesPaginadoBusqueda(page: number, pageSize: number, uuidActividad: string, campoBusqueda?: string): Observable<GetListadoHistorialActividadesPaginadoResponseDTO> {
    const url = this.hostGestion + 'historial/busqueda-paginada?campoBusqueda=' + campoBusqueda + '&page=' + page + '&size=' + pageSize + '&uuidGestionActividad=' + uuidActividad;
    return this.http.get(url).pipe(map(resp => new GetListadoHistorialActividadesPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // COMENTARIOS

  postGuardarComentario(data: FormData): Observable<PostEnviarComentarioGestionResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    const url = this.hostGestion + 'comentarios/enviar';
    return this.http.post<FormData>(url, data, { params }).pipe(map((resp) => new PostEnviarComentarioGestionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getComentarios(uuid: string): Observable<GetComentariosGestionResponseDTO> {
    const url = this.hostGestion + 'comentarios/' + uuid + '/obtener';
    return this.http.get(url).pipe(map(resp => new GetComentariosGestionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }


  // MANTENEDORES CATEGORIAS

  // CATEGORIA

  getCategoriasMantenedor(): Observable<GetCategoriasMantenedorResponseDTO> {
    const url = this.hostGestion + 'categorias';
    return this.http.get(url).pipe(map(resp => new GetCategoriasMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getCategoriasListadoPaginado(page: number, pageSize: number, busqueda: string): Observable<GetListadoPaginadoCategoriasMantenedorResponseDTO>{
    const url = this.hostGestion + 'categorias/busqueda-paginada?campoBusqueda=' + busqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map(resp => new GetListadoPaginadoCategoriasMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postCrearCategoriaMantenedor(nombre: string): Observable<PostCrearCategoriaResponseDTO> {
    const url = this.hostGestion + 'categorias';
    return this.http.post<string>(url, JSON.stringify({ nombre })).pipe(map(resp => new PostCrearCategoriaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarCategoriaMantenedor(uuidCategoria: string, nombreCategoria: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'categorias/' + uuidCategoria;
    return this.http.put<string>(url, JSON.stringify({ nombre: nombreCategoria })).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteDeshabilitarCategoriaMantenedor(uuidCategoria: string):Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'categorias/' + uuidCategoria;
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteHabilitarCategoriaMantenedor(uuidCategoria: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'categorias/' + uuidCategoria + '/habilitar';
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // SUB-CATEGORIA 1

  postCrearSubCategoriaUno(data: PostCrearSubCategoriaUnoRequestDTO): Observable<PostCrearSubCategoriaUnoResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-uno';
    return this.http.post<PostCrearSubCategoriaUnoRequestDTO>(url, JSON.stringify(data)).pipe(map(resp => new PostCrearSubCategoriaUnoResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getListadoPaginadoSubCategoriaUno(page: number, pageSize: number, busqueda: string): Observable<GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-uno/busqueda-paginada?campoBusqueda=' + busqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map(resp => new GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteDeshabilitarSubCategoriaUno(uuidSubcategoriaUno: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-uno/' + uuidSubcategoriaUno;
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteHabilitarSubCategoriaUnoMantenedor(uuidSubcategoriaUno: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-uno/' + uuidSubcategoriaUno + '/habilitar';
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarSubCategoriaUnoMantenedor(uuid: string, data: PostCrearSubCategoriaUnoRequestDTO): Observable<PutEditarSubCategoriaUnoMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-uno/' + uuid;
    return this.http.put<PostCrearSubCategoriaUnoRequestDTO>(url, JSON.stringify(data)).pipe(map(resp => new PutEditarSubCategoriaUnoMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }


  // SUB-CATEGORIA 2

  getListadoPaginadoSubCategoriaDos(page: number, pageSize: number, busqueda: string): Observable<GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-dos/busqueda-paginada?campoBusqueda=' + busqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map(resp => new GetListadoPaginadoSubcategoriasUnoMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postCrearSubCategoriaDos(data: PostCrearSubcategoriaDosRequestDTO): Observable<PostCrearSubCategoriaDosResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-dos';
    return this.http.post<PostCrearSubcategoriaDosRequestDTO>(url, JSON.stringify(data)).pipe(map(resp => new PostCrearSubCategoriaDosResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteDeshabilitarSubCategoriaDos(uuidSubCategoriaDos: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-dos/' + uuidSubCategoriaDos;
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteHabilitarSubCategoriaDosMantenedor(uuidSubcategoriaDos: string): Observable<PutEditarCategoriaMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-dos/' + uuidSubcategoriaDos + '/habilitar';
    return this.http.delete(url).pipe(map(resp => new PutEditarCategoriaMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarSubCategoriaDosMantenedor(uuid: string, data: PostCrearSubcategoriaDosRequestDTO): Observable<PutEditarSubCategoriaDosMantenedorResponseDTO> {
    const url = this.hostGestion + 'sub-categoria-dos/' + uuid;
    return this.http.put<PostCrearSubcategoriaDosRequestDTO>(url, JSON.stringify(data)).pipe(map(resp => new PutEditarSubCategoriaDosMantenedorResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // Porcentajes Gestion Dashboard
  getPorcentajesHomeGestion(data: GetPorcentajesHomeRequestDTO): Observable<GetPorcentajesHomeGestionResponseDTO> {
    return this.http.post<GetPorcentajesHomeRequestDTO>(this.hostGestion + 'porcentajes', data).pipe(
      map(resp => new GetPorcentajesHomeGestionResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error))
    );
  }

  // MEJORA CONTINUA

  // AVANCE ANUAL
  getAvancePorMetodologia(fechaInicio: string, fechaTermino: string): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'avance-por-metodologia?fechaInicioAnioFscal=' + fechaInicio + '&fechaTerminoAnioFscal=' + fechaTermino;
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postObtenerAvanceAnualPorArea(data: PostAvanceAnualPorAreaRequestDTO): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'avance-por-metodologia/avance-por-area';
    return this.http.post(url, data).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  postObtenerAvanceEsperado(data: PostAvanceAnualPorAreaRequestDTO): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'avance-por-metodologia/avance-esperado-por-area';
    return this.http.post(url, data).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // Anios Fiscales

  getObtenerAniosFiscales(): Observable<GeneralResponseDTO> {
    const url = this.hostGestion + 'anio-fiscal/listado';
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  
  // Metodologias
  getObtenerMetodologias5S(): Observable<GetMetdologiaResponseDTO> {
    const url = this.hostGestion + 'mejora-continua/metodologias-5S';
    return this.http.get(url).pipe(map(resp => new GetMetdologiaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerMetodologias7D(): Observable<GetMetdologiaResponseDTO> {
    const url = this.hostGestion + 'mejora-continua/metodologias-7D';
    return this.http.get(url).pipe(map(resp => new GetMetdologiaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerMetodologiasLSS(): Observable<GetMetdologiaResponseDTO> {
    const url = this.hostGestion + 'mejora-continua/metodologias-LSS';
    return this.http.get(url).pipe(map(resp => new GetMetdologiaResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // Material de entrenamiento
  getObtenerMaterialPorMetodologiaConCampo(uuid: string, page: number, pageSize: number, campoBusqueda: string): Observable<GetListadoPaginadoMaterialEntrenamientoResponseDTO> {
    let url = this.hostGestion + 'material-entrenamiento/' + uuid + '/obtener-material/busqueda-paginada?campoBusqueda=' + campoBusqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map((resp) => new GetListadoPaginadoMaterialEntrenamientoResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  postObtenerMaterialPaginadoFiltro(uuid: string, page: number, pageSize: number, data: PostObtenerMaterialPaginadoRequestDTO): Observable<GetListadoPaginadoMaterialEntrenamientoResponseDTO> {
    let url = this.hostGestion + 'material-entrenamiento/' + uuid + '/obtener-material/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post(url,data).pipe(map((resp) => new GetListadoPaginadoMaterialEntrenamientoResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  deleteEliminarMaterial(uuid: string): Observable<GeneralResponseDTO>{
    let url = this.hostGestion + 'material-entrenamiento/' + uuid + '/eliminar-material';
    return this.http.delete(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  getDescargarAdjuntoMaterialEntrenamiento(uuid: string): Observable<any> {
    let url = this.hostGestion + 'material-entrenamiento/descargar-adjunto/' + uuid;
    let options;
    options = {
      observe: 'response',
      responseType: 'blob'
    };

    return this.http.get(url, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorGestion(error))
    );
  }

  postSubirMaterial(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'material-entrenamiento/subir-material';
    return this.http.post<FormData>(url, data, {params}).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // Fotos de implementacion

  postSubirImplementacion(data: FormData) {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'implementacion/subir';
    return this.http.post<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getBusquedaPaginadaImplementacion(page: number, pageSize: number, campoBusqueda: string): Observable<GetBusquedaPaginadaFotosImplementacionResponseDTO> {
    let url = this.hostGestion + 'implementacion/busqueda-paginada?campoBusqueda=' + campoBusqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map((resp) => new GetBusquedaPaginadaFotosImplementacionResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  postObtenerImplementacionFiltro(page: number, pageSize: number, data: PostObtenerImplementacionPaginadoRequestDTO): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'implementacion/obtener-implementacion/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post(url,data).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  getDescargarAdjuntoImplementacion(uuid: string): Observable<any> {
    let url = this.hostGestion + 'implementacion/descargar-adjunto/' + uuid;
    let options;
    options = {
      observe: 'response',
      responseType: 'blob'
    };

    return this.http.get(url, options).pipe(
      retry(1),
      catchError((error: any) => this.handleErrorGestion(error))
    );
  }

  deleteEliminarImplentacion(uuid: string) {
    let url = this.hostGestion + 'implementacion/' + uuid;
    return this.http.delete(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }


  // Articulos

  postSubirArticulo(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'articulos/subir-articulo';
    return this.http.post<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerArticulos(uuidMetodologia: string, page?: number, pageSize?: number): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'articulos/' + uuidMetodologia + '/obtener-articulos/paginado?page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerArticulosFiltro(uuidMetodologia: string, page?: number, pageSize?: number, anio?: number, mes?: number): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'articulos/' + uuidMetodologia +
      '/obtener-articulos/paginado?anio='+ anio + '&mes=' + mes + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  getObtenerArticuloDetalle(uuidArticulo: string): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'articulos/' + uuidArticulo + '/obtener-articulo';
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putEditarArticulo(data: FormData, uuidArticulo: string): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    const url = this.hostGestion + 'articulos/' + uuidArticulo;
    return this.http.put<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteEliminarArticulo(uuid: string) {
    let url = this.hostGestion + 'articulos/' + uuid;
    return this.http.delete(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  getImagenPortada(uuidMetodologia: string) {
    let url = this.hostGestion + 'articulos/' + uuidMetodologia + '/imagen-portada';
    return this.http.get(url).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  putSubirImagenPortada(uuidMetodologia: string, data: FormData) {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'articulos/' + uuidMetodologia + '/imagen-portada';
    return this.http.put<FormData>(url, data, {params}).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  // Proyectos

  getBusquedaPaginadaProyecto(uuid: string, page: number, pageSize: number, campoBusqueda: string): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'proyectos/' + uuid + '/obtener-proyectos/busqueda-paginada?campoBusqueda=' + campoBusqueda + '&page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  postObtenerProyectosFiltro(uuid: string, page: number, pageSize: number, data: PostObtenerImplementacionPaginadoRequestDTO): Observable<GeneralResponseDTO> {
    let url = this.hostGestion + 'proyectos/'+ uuid + '/obtener-proyectos/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post(url,data).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  postSubirProyecto(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    let url = this.hostGestion + 'proyectos/subir';
    return this.http.post<FormData>(url, data, { params }).pipe(map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleErrorGestion(error)));
  }

  deleteEliminarProyecto(uuid: string) {
    let url = this.hostGestion + 'proyectos/' + uuid;
    return this.http.delete(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error) => this.handleErrorGestion(error)));
  }

  private handleErrorGestion(error: any) {
    if (this.userService.isUserLogged() && error.status === 401) {
      this.userService.cleanSesion();
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' + environment.urlRedirectCerrarSesion;

      return throwError(false);
    }

    return throwError(error);
  }

}
