import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { GeneralResponseDTO } from "../../dtos/response/general.response.dto";
import { catchError, map, retry } from "rxjs/operators";
import { GetObtenerTokenFinalResponseDTO } from 'src/dtos/response/documental/getObtenerTokenFinal.response.dto';
import { UserService } from './user.service';
import { DeleteEliminarDocumentoRequestDTO } from 'src/dtos/request/documental/deleteEliminarDocumento.request.dto';
import { PostListadoDocumentoPaginadoRequestDTO } from 'src/dtos/request/documental/postListadoDocumentoPaginado.request.dto';
import { GetListadoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoDocumentos.response.dto';
import { GetListadoTipoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoTipoDocumento.response.dto';
import { GetObtenerDetalleDocumentoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumento.response.dto';
import { PostRegistrarPreferenciasRequestDTO } from 'src/dtos/request/documental/postRegistrarPreferencias.request.dto';
import { GetObtenerDetalleDocumentoEliminadoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumentoEliminado.response.dto';
import { PostHistorialAccionRequestDTO } from 'src/dtos/request/documental/postHistorialAccion.request.dto';
import { PostExportarXLSDocumentosRequestDTO } from 'src/dtos/request/documental/postExportarXLSDocumentos.request.dto';
import { GetObtenerDetalleAccionResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleAccion.response.dto';
import { GetObtenerListadoUsuariosResponseDTO } from 'src/dtos/response/documental/getObtenerListadoUsuarios.response.dto';
import { GetPorcentajesHomeDocumentalResponseDTO } from 'src/dtos/response/documental/getPorcentajesHomeDocumental.response.dto';
import { GetListadoFilialesResponseDTO } from 'src/dtos/response/documental/getListadoFiliales.response.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesos.response.dto';
import { GetListadoMacroprocesosPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesosPaginados.response.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/documental/getListadoProcesos.response.dto';
import { GetListadoProcesosPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoProcesosPaginados.response.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetListadoAreasPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoAreasPaginados.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/documental/getListadoSubAreas.response.dto';
import { GetListadoSubareaPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoSubareasPaginados.response.dto';
import { GetPorcentajesHomeRequestDTO } from 'src/dtos/request/documental/getPorcentajesHome.request.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetPostListadoDocumentosEliminadosPaginadoResponseDTO } from 'src/dtos/response/documental/getPostListadoDocumentosEliminadosPaginado.response.dto';
import { GetPostHistorialAccionesPaginadoResponseDTO } from 'src/dtos/response/documental/getPostHistorialAccionesPaginado.response.dto';
import { GetPostListadoDocumentosPaginadoResponseDTO } from 'src/dtos/response/documental/getPostListadoDocumentosPaginado.response.dto';
import { GetObtenerModuloResponseDTO } from 'src/dtos/response/documental/getObtenerModulos.response.dto';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private hostDocumental: string = environment.hostUrlDocumental;
  private sso: string = environment.sso;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  // SSO
  // Obtener token final
  getObtenerTokenFinal(code: string): Observable<GetObtenerTokenFinalResponseDTO> {
    let url = this.sso + 'final/' + code;
    return this.http.get<GetObtenerTokenFinalResponseDTO>(url).pipe(
      map((resp) => new GetObtenerTokenFinalResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  postLogout(): Observable<GeneralResponseDTO> {
    return this.http.post<GeneralResponseDTO>(this.sso + 'logout', {}).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // DOCUMENTAL
  // Listado de documentos
  getListadoDocumentos(uuidSubArea?: string, uuidTipoDocumento?: string): Observable<GetListadoDocumentosResponseDTO> {
    let url = this.hostDocumental + 'listado';
    if (uuidSubArea) {
      url += '?uuidSubArea=' + uuidSubArea;
    }
    if (uuidTipoDocumento) {
      url += '?uuidTipoDocumento=' + uuidTipoDocumento;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoDocumentosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de documentos Paginado
  postListadoDocumentosPaginado(page: number, pageSize: number, dataRequest: PostListadoDocumentoPaginadoRequestDTO): Observable<GetPostListadoDocumentosPaginadoResponseDTO> {
    let url = this.hostDocumental + 'listado/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post<PostListadoDocumentoPaginadoRequestDTO>(url, dataRequest).pipe(
      map((resp) => new GetPostListadoDocumentosPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de documentos Paginado filtrado por campo busqueda
  getListadoDocumentosPaginadoBusqueda(page: number, pageSize: number, busqueda: string): Observable<GetPostListadoDocumentosPaginadoResponseDTO> {
    let url = this.hostDocumental + 'listado/paginado?page=' + page + '&size=' + pageSize + '&campoBusqueda=' + busqueda;

    return this.http.get(url).pipe(
      map((resp) => new GetPostListadoDocumentosPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Obtener detalle de documento
  getObtenerDetalleDocumento(uuid: string): Observable<GetObtenerDetalleDocumentoResponseDTO> {
    let url = this.hostDocumental + uuid;
    return this.http.get(url).pipe(
      map((resp) => new GetObtenerDetalleDocumentoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Subir documento
  postSubirDocumento(data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.post<FormData>(this.hostDocumental + 'subir/documento', data, { params }).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Actualizar documento
  putActualizarDocumento(uuid: string, data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.put<FormData>(this.hostDocumental + 'documento/' + uuid + '/actualizar', data, { params }).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }
  // Editar documento
  putEditarDocumento(uuid: string, data: FormData): Observable<GeneralResponseDTO> {
    const params = new HttpParams().set('multipart', 'true');
    return this.http.put<FormData>(this.hostDocumental + 'documento/' + uuid + '/editar', data, { params }).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }
  // Eliminar documento
  deleteEliminarDocumento(uuid: string, data: DeleteEliminarDocumentoRequestDTO): Observable<GeneralResponseDTO> {
    const url = this.hostDocumental + 'documento/' + uuid + '/eliminar';
    return this.http.delete<DeleteEliminarDocumentoRequestDTO>(url, { params: { motivo: data.motivo } }).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }
  // Descargar documento
  getDescargarDocumento(uuid: string): Observable<any> {
    const url = this.hostDocumental + uuid + '/descargar';
    let options;
    options = {
      observe: 'response',
      responseType: 'blob'
    };

    return this.http.get(url, options).pipe(
      retry(1),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Exportar Docuemntos XLS
  postExportarXLSDocumentos(data: PostExportarXLSDocumentosRequestDTO): Observable<any> {
    const url = this.hostDocumental + 'exportar';
    let options: any;
    options = {
      observe: 'response',
      responseType: 'blob'
    };
    return this.http.post<PostExportarXLSDocumentosRequestDTO>(url, data, options).pipe(
      retry(1),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de tipo de documentos
  getListadoTipoDocumentos(): Observable<GetListadoTipoDocumentosResponseDTO> {
    return this.http.get(this.hostDocumental + 'tipos').pipe(
      map(resp => new GetListadoTipoDocumentosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Validar Id Interno
  getValidarIdInterno(id: string): Observable<GeneralResponseDTO> {
    return this.http.get(this.hostDocumental + 'valida?id_interno=' + id).pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Porcentajes Home Documental
  getPorcentajesHomeDocumental(data: GetPorcentajesHomeRequestDTO): Observable<GetPorcentajesHomeDocumentalResponseDTO> {
    return this.http.post<GetPorcentajesHomeRequestDTO>(this.hostDocumental + 'porcentajes', data).pipe(
      map(resp => new GetPorcentajesHomeDocumentalResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // HISTORIAL
  // Obtener detalle documento eliminado
  getDetalleAccion(uuidDocumento: string): Observable<GetObtenerDetalleAccionResponseDTO> {
    return this.http.get(this.hostDocumental + 'historial/' + uuidDocumento).pipe(
      map(resp => new GetObtenerDetalleAccionResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Obtener historial de acciones
  postHistorialAccionesPaginado(page: number, pageSize: number, uuid: string, dataRequest?: PostHistorialAccionRequestDTO): Observable<GetPostHistorialAccionesPaginadoResponseDTO> {
    let url = this.hostDocumental + 'historial/' + uuid + '/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post<PostHistorialAccionRequestDTO>(url, JSON.stringify(dataRequest)).pipe(
      map(resp => new GetPostHistorialAccionesPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Obtener historial de acciones por el campo de busqueda
  gettHistorialAccionesPaginadoBusqueda(page: number, pageSize: number, uuid: string, campoBusqueda: string): Observable<GetPostHistorialAccionesPaginadoResponseDTO> {
    let url = this.hostDocumental + 'historial/' + uuid + '/busqueda-paginada?page=' + page + '&size=' + pageSize + '&campoBusqueda=' + campoBusqueda;
    return this.http.get(url).pipe(
      map(resp => new GetPostHistorialAccionesPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de documentos eliminados paginado
  postListadoDocumentosEliminadosPaginado(page: number, pageSize: number, dataRequest: PostListadoDocumentoPaginadoRequestDTO): Observable<GetPostListadoDocumentosEliminadosPaginadoResponseDTO> {
    let url = this.hostDocumental + 'historial/listado-eliminados/paginado?page=' + page + '&size=' + pageSize;

    return this.http.post<PostListadoDocumentoPaginadoRequestDTO>(url, JSON.stringify(dataRequest)).pipe(
      map((resp) => new GetPostListadoDocumentosEliminadosPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de documentos eliminados paginado filtrados por el campo busqueda
  getDocumentosEliminadosBusqueda(page: number, pageSize: number, campoBusqueda: string): Observable<GetPostListadoDocumentosEliminadosPaginadoResponseDTO> {
    let url = this.hostDocumental + 'historial/listado-eliminados/paginado?page=' + page + '&size=' + pageSize + '&campoBusqueda=' + campoBusqueda;

    return this.http.get(url).pipe(
      map((resp) => new GetPostListadoDocumentosEliminadosPaginadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Obtener detalle documento eliminado
  getDetalleEliminado(uuid: string): Observable<GetObtenerDetalleDocumentoEliminadoResponseDTO> {
    return this.http.get(this.hostDocumental + uuid + '/eliminado').pipe(
      map(resp => new GetObtenerDetalleDocumentoEliminadoResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // USUARIOS
  // Listado Usuarios
  getListadoUsuarios(): Observable<GetObtenerListadoUsuariosResponseDTO> {
    return this.http.get(this.hostDocumental + 'usuarios/listado').pipe(
      map(resp => new GetObtenerListadoUsuariosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado Cargos
  getListadoCargos(): Observable<GeneralResponseDTO> {
    return this.http.get(this.hostDocumental + 'usuarios/listado-cargos').pipe(
      map(resp => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado Filiales
  getListadoFiliales(): Observable<GetListadoFilialesResponseDTO> {
    return this.http.get(this.hostDocumental + 'usuarios/filiales').pipe(
      map(resp => new GetListadoFilialesResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // MACROPROCESOS
  // Listado simple de Macroprocesos Mantenedor
  getListadoMacroprocesos(): Observable<GetListadoMacroprocesosResponseDTO> {
    let url = this.hostDocumental + 'macroprocesos';
    return this.http.get(url).pipe(
      map((resp) => new GetListadoMacroprocesosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de Macroprocesos paginado Mantenedor
  getListadoMacroprocesosPaginado(page: number, pageSize: number): Observable<GetListadoMacroprocesosPaginadosResponseDTO> {
    let url = this.hostDocumental + 'macroprocesos/paginado?page=' + page + '&size=' + pageSize;
    return this.http.get(url).pipe(
      map((resp) => new GetListadoMacroprocesosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // PROCESO
  // Listado simple de Procesos
  getListadoProcesos(uuid?: string): Observable<GetListadoProcesosResponseDTO> {
    let url = this.hostDocumental + 'procesos';
    if (uuid) {
      url += '?uuidMacroproceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoProcesosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de Procesos paginado
  getListadoProcesosPaginado(page: number, pageSize: number, uuid?: string): Observable<GetListadoProcesosPaginadosResponseDTO> {
    let url = this.hostDocumental + 'procesos/paginado?page=' + page + '&size=' + pageSize;
    if (uuid) {
      url += '&uuidMacroproceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoProcesosPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // AREA
  // Listado simple de areas
  getListadoAreas(uuid?: string): Observable<GetListadoAreasResponseDTO> {
    let url = this.hostDocumental + 'areas';
    if (uuid) {
      url += '?uuidProceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoAreasResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de areas paginado
  getListadoAreasPaginado(page: number, pageSize: number, uuid?: string): Observable<GetListadoAreasPaginadosResponseDTO> {
    let url = this.hostDocumental + 'areas/paginado?page=' + page + '&size=' + pageSize;
    if (uuid) {
      url += '&uuidProceso=' + uuid;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoAreasPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // SUBAREAS
  // Listado simple de sub-areas
  getListadoSubAreas(uuidArea?: string, uuidProceso?: string): Observable<GetListadoSubAreasResponseDTO> {
    let url = this.hostDocumental + 'sub-areas';
    if (uuidArea) {
      url += '?uuidArea=' + uuidArea;
    }
    if (uuidProceso) {
      url += '&uuidProceso=' + uuidProceso;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSubAreasResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }
  // Listado de sub-areas paginado
  getListadoSubAreasPaginado(page: number, pageSize: number, uuidArea?: string, uuidProceso?: string): Observable<GetListadoSubareaPaginadosResponseDTO> {
    let url = this.hostDocumental + 'sub-areas/paginado?page=' + page + '&size=' + pageSize;
    if (uuidArea && uuidProceso) {
      url += '&uuidArea=' + uuidArea + '&uuidProceso=' + uuidProceso;
    }
    return this.http.get(url).pipe(
      map((resp) => new GetListadoSubareaPaginadosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // Preferencias
  // Registrar preferencias
  postRegistrarPreferencias(dataRequest: PostRegistrarPreferenciasRequestDTO, uuid: string): Observable<GeneralResponseDTO> {
    let url = this.hostDocumental + 'preferencias/' + uuid + '/registrar';

    return this.http.post<PostRegistrarPreferenciasRequestDTO>(url, JSON.stringify(dataRequest)).pipe(
      map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error))
    );
  }

  // Obtener modulos
  getObtenerModulos(): Observable<GetObtenerModuloResponseDTO> {
    let url = this.hostDocumental + 'preferencias/modulos/obtener';
    return this.http.get(url).pipe(map((resp) => new GetObtenerModuloResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }

  // Sitios
  // Listado simple de Sitios

  getListadoSitios(uuidSubArea?: string): Observable<GetListadoSitiosResponseDTO> {
    let url = this.hostDocumental + 'sitios';
    if (uuidSubArea) {
      url += '?uuidSubArea=' + uuidSubArea;
    }

    return this.http.get(url).pipe(map((resp) => new GetListadoSitiosResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }

  // Notificaciones
  // Get listado notificaciones

  getNotificaciones(): Observable<GeneralResponseDTO> {
    let url = this.hostDocumental + 'notificaciones/obtener-notificaciones';
    return this.http.get(url).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }

  // Marcar como leida

  putMarcarLectura(uuidNotificacion: string): Observable<GeneralResponseDTO> {
    let url = this.hostDocumental + 'notificaciones/marcar-lectura/' + uuidNotificacion;
    return this.http.put(url, {}).pipe(map((resp) => new GeneralResponseDTO(resp)),
      catchError((error: any) => this.handleError(error)));
  }

  private handleError(error: any) {
    if (this.userService.isUserLogged() && error.status === 401) {
      this.userService.cleanSesion();
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' + environment.urlRedirectCerrarSesion;

      return throwError(false);
    }

    return throwError(error);
  }
}
