<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Deshabilitar {{titleModal}}</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-text" [innerHtml]="mensaje">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()"
            aria-label="Close">Cancelar</button>
        <button type="button" class="btn btn-modal" (click)="deshabilitarRegistro()">Sí, deshabilitar</button>
    </div>
</div>