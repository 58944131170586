<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar usuario</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="editarForm">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Rol</label></div>
                <div class="inp" *ngIf="!loaderRoles">
                    <ng-select class="select" formControlName="rol" placeholder="Seleccione">
                        <ng-option *ngFor="let rol of listadoRoles" [value]="rol.uuid">
                            {{rol.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderRoles">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()">Cancelar</button>
            <button type="button" class="btn btn-modal" [disabled]="editarForm.controls['rol'].invalid"
                (click)="putActualizarUsuarioMantenedor()">Editar</button>
        </div>
    </form>
</div>