<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccionar año fiscal</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
        <div class="modal-body">
            <div class="field-max">
                <div class="lab"><label for="name">Periodo</label></div>
                <div class="inp"><label class="select">
                        <ng-select  name="material-entrenamiento5s-tipo" id="material-entrenamiento5s-tipo"
                            class="select-modal" placeholder="Seleccione" [(ngModel)]="uuidFechaSelected">
                            <ng-option *ngFor="let anioFiscal of listadoAniosFiscal" [value]="anioFiscal.uuid">
                                {{anioFiscal.nombre}} (1 Abril {{anioFiscal.fechaInicioDate.getFullYear()}} 
                                a 31 Marzo {{anioFiscal.fechaTerminoDate.getFullYear()}})
                            </ng-option>
                        </ng-select>
                    </label> </div>
            </div>

        </div>

    
    <div class="modal-footer">
        <button type="button" class="btn btn-modal" (click)="guardarAnioFiscal()">Guardar</button>
    </div>
</div>
