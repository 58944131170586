import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { PutEditarCategoriaMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarCatgoriaMantenedor.response.dto';

@Component({
  selector: 'app-modal-c-habilitar',
  templateUrl: './modal-c-habilitar.component.html',
  styleUrls: ['./modal-c-habilitar.component.scss']
})
export class ModalCHabilitarComponent {

  uuid: string;
  nombre: string;
  tabName: string;

  onClose: Subject<string>;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }
  
  ngOnInit():void {
    this.onClose = new Subject();
  }

  deleteHabilitarCategoriaMantenedor() {
    this.apiGestion.deleteHabilitarCategoriaMantenedor(this.uuid).subscribe((resp) => this.habilitarCategoriaSuccess(resp),
      (error) => this.habilitarCategoriaError(error));
  }

  habilitarCategoriaSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_HABILITAR_CATEGORIA);
    this.onClose.next('0');
  }

  habilitarCategoriaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_CATEGORIA;
    this.toastr.error(msg);
  }

  deleteHabilitarSubcategoriaUnoMantenedor() {
    this.apiGestion.deleteHabilitarSubCategoriaUnoMantenedor(this.uuid).subscribe((resp) => this.habilitarSubcategoriaUnoSuccess(resp),
      (error) => this.habilitarSubcategoriaUnoError(error));
  }

  habilitarSubcategoriaUnoSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_HABILITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  habilitarSubcategoriaUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  deleteHabilitarSubcategoriaDosMantenedor() {
    this.apiGestion.deleteHabilitarSubCategoriaDosMantenedor(this.uuid).subscribe((resp) => this.habilitarSubcategoriaDosSuccess(resp),
      (error) => this.habilitarSubcategoriaUnoError(error));
  }

  habilitarSubcategoriaDosSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_HABILITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  habilitarSubcategoriaDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  habilitar() {
    if (this.tabName === 'categoria')
      this.deleteHabilitarCategoriaMantenedor()
    if (this.tabName === 'subcategoriauno')
      this.deleteHabilitarSubcategoriaUnoMantenedor();
    if (this.tabName === 'subcategoriados')
      this.deleteHabilitarSubcategoriaDosMantenedor();
  }
 }
