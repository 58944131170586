<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page">
        <app-menu></app-menu>
    </div>
    <div class="section-page">
        <div *ngIf="!uuidArticulo" class="subtitle">
            Nuevo Artículo
        </div>
        <div *ngIf="uuidArticulo" class="subtitle">
            Editar Artículo
        </div>
        <div class="row1">
            <div class="column3">
                <div class="lab"><label for="titulo-articulo">Título</label></div>
                <div class="inp">
                    <input type="text" name="titulo-articulo" id="titulo-articulo" class="inp-modal"
                        placeholder="Título" [(ngModel)]="tituloArticulo"/>
                </div>
            </div>
            <div class="column3">
                <div class="lab"><label for="archivo-articulo">Imagen principal</label></div>
                <div class="inp">
                    <label *ngIf="!imagenPrincipal" for="archivo-articulo" class="overFileLabel">Subir archivo</label>
                    <label *ngIf="imagenPrincipal" for="archivo-articulo" class="overFileLabel">{{imagenPrincipal.name}}</label>
                    <input #fileInput type="file" name="archivo-articulo" id="archivo-articulo"
                        class="inp-modal"
                        accept="image/*" (change)="addAdjunto($event)"/>
                    <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
                
            </div> 
            <div class="column4">
                <img [src]="imageUrl" *ngIf="imageUrl" class="image-preview">
                <i style="margin-left: 10px;" *ngIf="imageUrl" class="fas fa-times-circle red delete-archive" aria-hidden="true" (click)="removeImage()"></i>
            </div> 
            <div class="column3">
                
            </div> 
        </div>
        <div class="text-editor">
            <quill-editor [(ngModel)]="cuerpoArticulo"
            [modules]="quillConfig"
            placeholder="Ingrese texto..."></quill-editor>
        </div>
        <div class="section1">
            <button type="button" class="button-black two-buttons" (click)="regresar()">Cancelar</button>
            <button type="button" class="btn-two two-buttons" (click)="subirArticulo()">Crear</button>
        </div>
    </div>
</div>
<div class="full-height">&nbsp;</div>