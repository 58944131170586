<!-- Modal actualizar-->
<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="modal-header" *ngIf="updateForm && !loader">
        <h5 class="modal-title" id="exampleModalLabel">Actualizar documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="obligatory" *ngIf="updateForm && !loader">*Todos los campos son obligatorios</div>
    <form [formGroup]="updateForm" *ngIf="updateForm && !loader">
        <div class="modal-body">
            <div class="field-three">
                <div class="lab"><label for="name">Tipo de documento</label></div>
                <div class="inp">
                    <input type="text" formControlName="tipoDocumento" name="documental-id" id="documental-id"
                        class="inp-modal" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">ID</label></div>
                <div class="inp">
                    <input type="text" name="documental-id" id="documental-id" class="inp-modal" formControlName="id" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Adjuntar archivo</label></div>
                <div class="inp">
                    <input [type]="btnClean ? 'text' : 'file'" name="documental-adjuntararchivo"
                        id="documental-adjuntararchivo" class="inp-modal" placeholder="Adjunte archivo"
                        formControlName="file" required (change)="selectArchivo($event)" [readonly]="btnClean"
                        [ngClass]="{'border-error': updateForm.controls['file'].touched && !updateForm.controls['file'].valid}"
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx" />
                    <i *ngIf="!btnClean" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                    <i *ngIf="btnClean" class="fas fa-times ico-input" aria-hidden="true" (click)="clearInput()"></i>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" name="documental-nombre" id="documental-nombre" class="inp-modal"
                        formControlName="nombre" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Versión</label></div>
                <div class="inp">
                    <input type="text" name="documental-version" id="documental-version" class="inp-modal"
                        formControlName="version" disabled />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Macroporceso</label></div>
                <div class="inp">
                    <input type="text" formControlName="macroproceso" name="documental-id" id="documental-id"
                        class="inp-modal" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Proceso</label></div>
                <div class="inp">
                    <input type="text" formControlName="proceso" name="documental-id" id="documental-id"
                        class="inp-modal" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Área</label></div>
                <div class="inp">
                    <input type="text" formControlName="area" name="documental-id" id="documental-id"
                        class="inp-modal" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Subárea</label></div>
                <div class="inp">
                    <input type="text" formControlName="subarea" name="documental-id" id="documental-id"
                        class="inp-modal" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de documento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaDocumento" [bsConfig]="datepickerConfig">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha inicio vigencia</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaInicio" [bsConfig]="datepickerConfig" required
                        [ngClass]="{'border-error': updateForm.controls['fechaInicio'].touched && !updateForm.controls['fechaInicio'].valid}"
                        (onHidden)="obtenerVigencia()">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de vencimiento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaVencimiento" [bsConfig]="datepickerConfigVencimiento" required
                        [ngClass]="{'border-error': updateForm.controls['fechaInicio'].touched && !updateForm.controls['fechaInicio'].valid}">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Vincular a otros documentos</label></div>
                <div class="inp" *ngIf="!loaderDocVicunlados">
                    <ng-select class="select" formControlName="documentoVinculados" placeholder="Seleccione"
                        [multiple]="true">
                        <ng-option *ngFor="let dv of listaSimpleDocumentosVicunlados" [value]="dv.uuid">
                            {{dv.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderDocVicunlados">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Estado</label></div>
                <div class="inp">
                    <input type="text" name="documental-estado" id="documental-estado" class="inp-modal"
                        placeholder="Vigente" formControlName="estado" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" [disabled]="!updateForm.valid"
                (click)="actualizarDocumento()">Actualizar documento</button>
        </div>
    </form>
</div>