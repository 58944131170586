<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="modal-header" *ngIf="!loader">
        <h5 class="modal-title" id="exampleModalLabel">Comentarios</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!loader">
        <div class="modal-text">
            <strong>Reclamo N° {{reclamo.idInterno}}</strong>
        </div>
        <div class="comments">
            <div class="container">
                <div class="row">
                    <div id="left-UsersInConversation" class="col-md-4 col-sm-12 pr-md-1">
                        <ul class="nav nav-tabs ">
                            <li class="nav-item" *ngFor="let usuarioComent of listadoUsuarios">
                                <a class="nav-link">
                                    <div class="profile-comments">
                                        {{inicialesUsuario(usuarioComent)?.iniciales}}
                                    </div>
                                    <div class="comments-user-details">
                                        <strong>{{usuarioComent.nombre + ' ' + usuarioComent.apellido}}</strong><br>
                                        {{inicialesUsuario(usuarioComent)?.nombre}}<br>
                                        {{inicialesUsuario(usuarioComent)?.area}}
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id="right-TextSide" class="col-md-8 col-sm-12 pl-md-1">
                        <div class="tab-content">
                            <div class="card tab-pane fade show active">
                                <div class="card-header1"></div>
                                <div class="collapse show">
                                    <div class="card-body vertical-scroll1">
                                        <div class="comments-row"
                                            *ngFor="let comentario of informacionComentario?.comentario">
                                            <div
                                                [ngClass]="comentario.usuario.uuid === usuario.uuid ? 'box-right' : 'box-left'">
                                                <div
                                                    [ngClass]="comentario.usuario.uuid === usuario.uuid ? 'comments-my' : 'comments-other'">
                                                    <div class="from">
                                                        {{comentario.usuario.nombre + ' ' +
                                                        comentario.usuario.apellido}} <br>
                                                        {{comentario.usuario.email}} <br>
                                                        {{(comentario.fechaMensaje + 'Z' | date:'HH:mm') + ' | '
                                                        + (comentario.fechaMensaje | date:'dd/MM/yyyy')}}
                                                    </div>
                                                    {{comentario.mensaje !== "null" ? comentario.mensaje : ''}}
                                                </div>
                                                <div class="input-consult" *ngIf="comentario.adjuntos.length > 0">
                                                    <div class="back-img" ngxViewer
                                                        *ngFor="let adjunto of comentario.adjuntos">
                                                        <div class="img-show">
                                                            <img *ngIf="adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpeg')"
                                                                [src]="adjunto.url" ngxViewer width="145px"
                                                                height="100px" alt="Archivo adjunto">
                                                            <img *ngIf="!(adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg'))"
                                                                [src]="obtenerUrl(adjunto.nombreArchivo)" width="145px"
                                                                height="145px" alt="Archivo adjunto">
                                                        </div>
                                                        <div class="back-img-line">
                                                            <div class="ico-image">
                                                                <i class="fas fa-download blue download-archive"
                                                                    aria-hidden="true"
                                                                    (click)="getDescargarAdjuntoComentario(adjunto.url)"></i>
                                                            </div>
                                                            <a>{{adjunto.nombreArchivo}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form [formGroup]="responderForm" *ngIf="!reclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO')
                                    && (usuario.rol.nombre.toLowerCase() === 'customer service'
                                        || usuario.rol.nombre.toLowerCase() === 'superadmin'
                                        || usuario.uuid === reclamo.usuarioCreador.uuid
                                        || (usuario.preferencias
                                            && usuario.preferencias?.area.uuid === reclamo.usuarioCreador.preferencias?.area.uuid
                                            && usuario.preferencias?.subArea.uuid === reclamo.usuarioCreador.preferencias?.subArea.uuid
                                            && usuario.preferencias?.sitio.uuid === reclamo.usuarioCreador.preferencias?.sitio.uuid)
                                        || (usuario.preferencias
                                            && usuario.preferencias?.area.uuid === reclamo.asignacion?.area.uuid
                                            && usuario.preferencias?.subArea.uuid === reclamo.asignacion?.subarea.uuid))">
                                        <div class="write">
                                            <div class="comments">
                                                <div class="card-body-writte">
                                                    <textarea name="textarea" rows="10" cols="50"
                                                        formControlName="comentario"
                                                        placeholder="Escriba un comentario..."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="filesAndSubmit">
                                            <div class="btn-upload">
                                                <input type="file" formControlName="file" (change)="addAdjunto($event)"
                                                    accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx" />
                                                <div class="iconOverFiles">
                                                    <i class="fas fa-paperclip" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="consultarArchivos vertical-scroll"
                                                *ngIf="getAdjuntos().length > 0">
                                                <h6>Archivos que se adjuntarán:</h6>
                                                <div class="back-img" ngxViewer
                                                    *ngFor="let adjunto of getAdjuntos(); let i = index">
                                                    <div class="back-img-line">
                                                        <i class="fas fa-times-circle red delete-archive"
                                                            aria-hidden="true" (click)="removeAdjunto(i)"></i>
                                                        {{adjunto.value.name}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer send-comments">
                                                <button type="button" class="btn btn-modal btn-send-comments"
                                                    (click)="responderComentario()">responder</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>