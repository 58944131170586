<!-- Modal subir-->
<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subir documento</h5>
        <button type="button" class="close-modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="obligatory">*Todos los campos son obligatorios</div>
    <form [formGroup]="createForm">
        <div class="modal-body">
            <div class="field-three">
                <div class="lab"><label for="name">Tipo de documento</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="tipoDocumento" required placeholder="Seleccione"
                        (change)="obtenerVigencia(); getListadoDocumentos()"
                        [ngClass]="{
                            'border-error': createForm.controls['tipoDocumento'].touched && !createForm.controls['tipoDocumento'].valid}">
                        <ng-option *ngFor="let tipoDoc of listaSimpleTipoDocumento" [value]="tipoDoc.uuid">
                            {{tipoDoc.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">ID</label></div>
                <div class="inp">
                    <input type="text" name="documental-id" id="documental-id" class="inp-modal" placeholder="000"
                        [ngClass]="{
                        'border-error': (createForm.controls['id'].touched && !createForm.controls['id'].valid) || !idValido}"
                        formControlName="id" autocomplete="off" required (focusout)="getValidarIdInterno()" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Adjuntar archivo</label></div>
                <div class="inp">
                    <input type="file" name="documental-adjuntararchivo" id="documental-adjuntararchivo"
                        class="inp-modal" placeholder="Adjunte archivo" [ngClass]="{
                            'border-error': createForm.controls['file'].touched && !createForm.controls['file'].valid}"
                        formControlName="file" required
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx"
                        (change)="selectArchivo($event)" />
                    <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" name="documental-nombre" id="documental-nombre" class="inp-modal"
                        placeholder="Nombre" formControlName="nombre" autocomplete="off" required
                        [ngClass]="{
                            'border-error': createForm.controls['nombre'].touched && !createForm.controls['nombre'].valid}" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Versión</label></div>
                <div class="inp">
                    <input type="text" name="documental-version" id="documental-version" class="inp-modal"
                        placeholder="000" formControlName="version" autocomplete="off" required pattern="[0-9]+"
                        [ngClass]="{
                            'border-error': createForm.controls['version'].touched && !createForm.controls['version'].valid}" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Macroproceso</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="macroproceso" required placeholder="Seleccione"
                        (change)="getListadoProcesos()"
                        [ngClass]="{
                            'border-error': createForm.controls['macroproceso'].touched && !createForm.controls['macroproceso'].valid}">
                        <ng-option *ngFor="let macro of listaSimpleMacroprocesos" [value]="macro.uuid">
                            {{macro.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Proceso</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="proceso" required placeholder="Seleccione"
                        (change)="getListadoAreas()"
                        [ngClass]="{
                            'border-error': createForm.controls['proceso'].touched && !createForm.controls['proceso'].valid}">
                        <ng-option *ngFor="let proceso of listaSimpleProcesos" [value]="proceso.uuid">
                            {{proceso.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Área</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="area" required placeholder="Seleccione" [ngClass]="{
                        'border-error': createForm.controls['area'].touched && !createForm.controls['area'].valid}"
                        (change)="getListadoSubAreas()">
                        <ng-option *ngFor="let area of listaSimpleArea" [value]="area.uuid">
                            {{area.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Subárea</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="subarea" required placeholder="Seleccione"
                        [ngClass]="{
                        'border-error': createForm.controls['subarea'].touched && !createForm.controls['subarea'].valid}">
                        <ng-option *ngFor="let sub of listaSimpleSubArea" [value]="sub.uuid">
                            {{sub.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de documento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaDocumento" [bsConfig]="datepickerConfig">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha inicio vigencia</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaInicio" [bsConfig]="datepickerConfig" required
                        (onHidden)="obtenerVigencia()"
                        [ngClass]="{
                            'border-error': createForm.controls['fechaInicio'].touched && !createForm.controls['fechaInicio'].valid}">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de vencimiento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaVencimiento" [bsConfig]="datepickerConfigVencimiento" required
                        [ngClass]="{
                            'border-error': createForm.controls['fechaVencimiento'].touched && !createForm.controls['fechaVencimiento'].valid}">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Vincular a otros documentos</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="documentoVinculados" placeholder="Seleccione"
                        [multiple]="true">
                        <ng-option *ngFor="let dv of listaSimpleDocumentosVicunlados" [value]="dv.uuid">
                            {{dv.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Estado</label></div>
                <div class="inp">
                    <input type="text" name="documental-estado" id="documental-estado" class="inp-modal disabled"
                        placeholder="Vigente" disabled formControlName="estado" required />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="subirDocumento()">Subir
                documento</button>
        </div>
    </form>
</div>