<div id="div3" class="details-1-1 vertical-scroll">
    <div class="subtitle">
        <i class="fas fa-history" aria-hidden="true"></i> Historial de acciones
    </div>
    <button class="btn-details1 close-details" (click)="bsModalRef.hide()">
        <i class="fas fa-times" aria-hidden="true"></i>
    </button>
    <div class="title_doc">
        Reclamo {{idReclamo}}
    </div>
    <div class="see-all">
        <a routerLink="/web/historial-reclamo" [queryParams]="{uuid: uuid, idReclamo: idReclamo}"
            (click)="bsModalRef.hide()" style="color:#6979D4 !important;">Ver todo</a>
    </div>
    <div class="historial-box" *ngFor="let historial of listadoPagHistorial">
        <div class="historial-from">
            <div class="historial-from-left">
                <i class="fas fa-exclamation ico-historial" aria-hidden="true"></i>
            </div>
            <div class="historial-data">
                <span class="historial-date-hour">{{historial.fechaCreacion + 'Z' | date: 'dd/MM/yyyy HH:mm'}}</span>
                <br>{{historial.usuario.nombre + ' ' + historial.usuario.apellido}}
                <br>{{historial.usuario.cargo}}
            </div>
        </div>
        <div class="historial-action">
            <div class="historial-data">
                <strong>{{historial.accion.toUpperCase()}}</strong>
                <br>{{historial.motivo !== '' ? historial.motivo : '--'}}
            </div>
        </div>
    </div>
</div>