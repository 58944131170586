<div class="loader-container" [ngStyle]="{'visibility': loaderPaginado ? 'visible' : 'hidden'}">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-wrench ico-page" aria-hidden="true"></i><span
                class="title1">Categorías</span></div>
    </div>
    <div class="section-page">
        <div class="nav-container">
            <tabset class="tab-container" #staticTabs>
                <tab [active]="activeTab === 'categoria'" (selectTab)="activeTab = 'categoria'; onTabChange()" heading="Categoría" id="categoria">
                    <div class="create-mantenedor"><button type="button" class="btn btn-secundary"
                            (click)="openModalCCrear('categoria')"> <span class="text-create"> crear nueva </span><i
                                class="far fa-plus-square create-ico" aria-hidden="true"></i>
                        </button></div>
                    <div class="search-mantenedor"><input id="txtSearch" placeholder="Buscar" class="form-control" /><i
                            class="fas fa-search ico-input-search" aria-hidden="true"></i></div>
                    <div class="table">
                        <div class="table-responsive">
                            <!--Table-->
                            <table [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}" id="example" class="display" style="width:100%">
                                <caption>Mantenedor categorías</caption>
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 80%;">Nombre</th>
                                        <th scope="col" style="word-break:normal;">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let categoria of listadoCategorias" [ngClass]="{'disable-tr': !categoria.habilitado}">
                                        <td>{{categoria.nombre}}</td>
                                        <td class=" text-nowrap"><button *ngIf="categoria.habilitado" type="button" class="btn-ico-tool"
                                                (click)="openModalCEditar('categoria', categoria)">
                                                <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button *ngIf="categoria.habilitado" type="button" class="btn-ico-tool"
                                                (click)="openModalCDeshabilitar('categoria', categoria.uuid, categoria.nombre)">
                                                <i class="fas fa-minus-circle ico-action-blue" aria-hidden="true"></i>
                                            </button>
                                            <button *ngIf="!categoria.habilitado" type="button" class="btn btn-enable"
                                                (click)="openModalCHabilitar('categoria', categoria.uuid, categoria.nombre)">
                                                Habilitar
                                            </button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                            <!--Table-->

                        </div>

                        <!--Section: Live preview-->
                    </div>

                </tab>
                <tab tab [active]="activeTab === 'sub-categoria1'" (selectTab)="activeTab = 'sub-categoria1'; onTabChange()" heading="Sub-categoría1" id="sub-categoria1">
                    <div class="create-mantenedor"><button type="button" class="btn btn-secundary"
                            (click)="openModalCCrear('subcategoriauno')"> <span class="text-create"> crear nueva </span><i
                                class="far fa-plus-square create-ico" aria-hidden="true"></i>
                        </button></div>
                    <div class="search-mantenedor"><input [(ngModel)]="busqueda" id="txtSearch" placeholder="Buscar" class="form-control" /><i
                            class="fas fa-search ico-input-search" aria-hidden="true"></i></div>
                    <div class="table">
                        <div class="table-responsive">
                            <!--Table-->
                            <table id="example" class="display" style="width:100%">
                                <caption>Mantenedor categorías</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Sub-categoría 2 asociadas</th>
                                        <th scope="col" style="word-break:normal;">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subc1 of listadoSubCategoriasUno" [ngClass]="{'disable-tr': !subc1.habilitado}">
                                        <td>{{subc1.nombre}}</td>
                                        <td>
                                            <span *ngFor="let sc2 of subc1.subCategoriasDos; last as isLast">
                                                {{sc2.nombre}}<span *ngIf="!isLast">, </span>
                                            </span>
                                        </td>
                                        <td class=" text-nowrap">
                                            <button (click)="openModalCEditar('subcategoriauno', null, subc1)" *ngIf="subc1.habilitado" type="button" class="btn-ico-tool">
                                                <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button (click)="openModalCDeshabilitar('subcategoriauno', subc1.uuid, subc1.nombre)" 
                                            *ngIf="subc1.habilitado" type="button" class="btn-ico-tool">
                                                <i class="fas fa-minus-circle ico-action-blue" aria-hidden="true"></i>
                                            </button>
                                            <button (click)="openModalCHabilitar('subcategoriauno', subc1.uuid, subc1.nombre)" 
                                            *ngIf="!subc1.habilitado" type="button" class="btn btn-enable">Habilitar</button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                            <!--Table-->

                        </div>

                        <!--Section: Live preview-->
                    </div>

                </tab>
                <tab [active]="activeTab === 'sub-categoria2'" (selectTab)="activeTab = 'sub-categoria2'; onTabChange()" heading="Sub-categoría2" id="sub-categoria2">
                    <div class="create-mantenedor"><button type="button" class="btn btn-secundary"
                            (click)="openModalCCrear('subcategoriados')"> <span class="text-create"> crear nueva </span><i
                                class="far fa-plus-square create-ico" aria-hidden="true"></i>
                        </button></div>
                    <div class="search-mantenedor"><input id="txtSearch" placeholder="Buscar" class="form-control" /><i
                            class="fas fa-search ico-input-search" aria-hidden="true"></i></div>
                    <div class="table">
                        <div class="table-responsive">
                            <!--Table-->
                            <table [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}" id="example" class="display" style="width:100%">
                                <caption>Mantenedor categorías</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Categoria asociadas</th>
                                        <th scope="col">Sub-categoría 1 asociadas</th>
                                        <th scope="col" style="word-break:normal;">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subc2 of listadoSubCategoriasDos" [ngClass]="{'disable-tr': !subc2.habilitado}">
                                        <td>{{subc2.nombre}}</td>
                                        <td>{{subc2.categoria?.nombre}}</td>
                                        <td>{{subc2.subCategoriaUno?.nombre}}</td>
                                        <td class=" text-nowrap">
                                            <button (click)="openModalCEditar('subcategoriados', null, null, subc2)" *ngIf="subc2.habilitado" type="button" class="btn-ico-tool">
                                            <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button (click)="openModalCDeshabilitar('subcategoriados', subc2.uuid, subc2.nombre)" *ngIf="subc2.habilitado" type="button" class="btn-ico-tool">
                                                <i class="fas fa-minus-circle ico-action-blue" aria-hidden="true"></i>
                                            </button>
                                            <button (click)="openModalCHabilitar('subcategoriados', subc2.uuid, subc2.nombre)" *ngIf="!subc2.habilitado" type="button" class="btn btn-enable">Habilitar</button>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                            <!--Table-->

                        </div>

                        <!--Section: Live preview-->
                    </div>

                </tab>
            </tabset>

            <!--Pagination -->
            <div class="mt-3 row" *ngIf="totalRegister > 10">
                <div class="col text-right">
                    <div class="pagination">
                        <pagination [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}"
                            class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                            [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                            [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalRegister"
                            (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="full-height">&nbsp;</div>