<div class="modal-content-small">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="modal-header" *ngIf="!loader">
        <h5 class="modal-title" id="exampleModalLabel">Editar reclamo Nº {{detalleReclamo.idInterno}}</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!loader">
        <form [formGroup]="editarForm">
            <div class="field-half">
                <div class="lab"><label for="name">Adjuntar archivos</label></div>
                <div class="inp">
                    <label for="reclamos-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                    <input type="file" name="reclamos-adjuntararchivo" id="reclamos-adjuntararchivo" class="inp-modal"
                        placeholder="Adjunte archivo" formControlName="file" (change)="addAdjunto($event)"
                        [ngClass]="{'border-error': editarForm.get('file').touched && ! editarForm.get('file').valid}"
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx" />
                    <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
            </div>
            <div class="field-half">
                <div class="lab">
                    <label for="name">Impacto de la desviación
                        <span class="impacto-desviacion">
                            <i class="fas fa-question-circle ico-ver-procedimiento blue impacto" aria-hidden="true"></i>
                        </span>
                        <div class="hide">
                            <span class="impacto-line" *ngFor="let desviacion of listadoDesviaciones">
                                <i class="fas fa-question-circle ico-ver-procedimiento impacto-color" aria-hidden="true"
                                    [ngClass]="{'red': desviacion.nombre === 'Alto', 'yellow':  desviacion.nombre === 'Medio', 'green':  desviacion.nombre === 'Bajo'}"></i>
                                <strong>{{desviacion.nombre}}:</strong> {{desviacion.descripcion}}
                            </span>
                        </div>
                    </label>
                </div>
                <div class="inp">
                    <ng-select class="select" formControlName="impactoDesviacion" placeholder="Seleccione" required
                        [ngClass]="{'border-error': editarForm.get('impactoDesviacion').touched && ! editarForm.get('impactoDesviacion').valid}">
                        <ng-option *ngFor="let desviacion of listadoDesviaciones" [value]="desviacion.uuid">
                            {{desviacion.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-half">
                <div class="inp">
                    <div class="column-consult-7 vertical-scroll" *ngIf="getAdjuntos().length > 0">
                        <div class="lab-consult">Archivos adjuntos</div>
                        <div class="back-img" ngxViewer *ngFor="let adjunto of getAdjuntos(); let i = index">
                            <img *ngIf="adjunto.value.name.includes('.png') || adjunto.value.name.includes('.jpg') || adjunto.value.name.includes('.jpeg')"
                                 class="img-show" [src]="adjunto.value.url" width="210px" height="150px"
                                alt="Archivo adjunto">
                            <img *ngIf="!(adjunto.value.name.includes('.png') || adjunto.value.name.includes('.jpg') || adjunto.value.name.includes('.jpeg'))"
                                [src]="obtenerUrl(adjunto.value)" width="145px" height="145px"
                                alt="Archivo adjunto" class="img-show">
                            <div class="back-img-line">
                                <i class="fas fa-times-circle red delete-archive" aria-hidden="true"
                                    (click)="removeAdjunto(i)"></i>
                                <a href="#" class="minimage-link">
                                    <div class="ico-image"><i class="fas fa-image" aria-hidden="true"></i></div>
                                    {{adjunto.value.name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="!loader">
        <button type="button" class="btn btn-modal" (click)="editarReclamo()">Editar reclamo</button>
    </div>
</div>