import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { HistorialDTO } from 'src/dtos/mantenedor/historial.dto';
import { GetPostListadoHistorialReclamoPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoHistorialReclamoPaginados.response.dto';

@Component({
  selector: 'app-modal-reclamos-historial',
  templateUrl: './modal-reclamos-historial.component.html',
  styleUrls: ['./modal-reclamos-historial.component.scss']
})
export class ModalReclamosHistorialComponent {
  uuid: string;
  idReclamo: string;

  listadoPagHistorial: HistorialDTO[] = [];

  loaderPaginado: boolean = false;

  constructor(
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.postListadoHistorialReclamoPaginado();
  }

  postListadoHistorialReclamoPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.postListadoHistorialReclamoPaginado(0, 5, this.uuid).subscribe(
      (response: GetPostListadoHistorialReclamoPaginadosResponseDTO) => {
        this.listadoPagHistorial = response.data.content;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_HISTORIAL_RECLAMO_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }
}
