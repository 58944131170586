import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { PostAsignarReclamoRequestDTO } from 'src/dtos/request/mantenedor/postAsignarReclamo.request.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { PostAsignarReclamoResponseDTO } from 'src/dtos/response/mantenedor/postAsignarReclamo.response.dto';

@Component({
  selector: 'app-modal-reclamos-asignar-reclamo',
  templateUrl: './modal-reclamos-asignar-reclamo.component.html',
  styleUrls: []
})
export class ModalReclamosAsignarReclamoComponent implements OnInit {
  listaSimpleAreas: AreaDTO[] = [];
  listaSimpleSubareas: SubareaDTO[] = [];
  listadoSitio: SitiosDTO[] = [];

  asignarForm: FormGroup;

  id: string;

  onClose: Subject<string>;

  constructor(
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();

    this.asignarForm = this.formBuilder.group({
      area: new FormControl(null, [Validators.required]),
      subarea: new FormControl(null, [Validators.required]),
      sitio: new FormControl(null),
      observacion: new FormControl(null)
    });
    this.getListadoAreas();
  }

  getListadoAreas() {
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleAreas = response.data.filter(area => area.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSubAreas(change: boolean = false) {
    if (change) {
      this.asignarForm.patchValue({ subarea: null, sitio: null });
    }

    this.apiServiceMantenedor.getListadoSubAreasMantenedor(this.asignarForm.value.area).subscribe(
      (response: GetListadoSubAreasResponseDTO) => {
        this.listaSimpleSubareas = response.data.filter(sub => sub.habilitado);

      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSitios(change: boolean = false) {
    if (change) {
      this.asignarForm.patchValue({ sitio: null });
    }
    this.apiServiceMantenedor.getListadoSitiosMantenedor(this.asignarForm.value.subarea).subscribe(
      (response: GetListadoSitiosResponseDTO) => {
        this.listadoSitio = response.data.filter(sitio => sitio.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
        this.toastr.error(msg);
      }
    );
  }

  asignarReclamo() {
    if (this.asignarForm.valid) {
      const value = this.asignarForm.value;
      const data: PostAsignarReclamoRequestDTO = {
        area: value.area,
        subarea: value.subarea,
        sitio: value.sitio,
        observacion: value.observacion
      };
  
      this.apiServiceMantenedor.postAsignarReclamo(this.id, data).subscribe(
        (response: PostAsignarReclamoResponseDTO) => {
          this.toastr.success(AppMessage.SUCCESS_ASIGNAR_RECLAMO);
          this.onClose.next('ASIGNAR');
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ASIGNAR_RECLAMO;
          this.toastr.error(msg);
        }
      );
    }
    else {
      this.validateAllFormFields(this.asignarForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {            
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
