<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-thumbs-up ico-page" aria-hidden="true"></i>
            <span class="title1">Gestión de Actividades</span>
        </div>
    </div>
    <div class="section-page">
        <div class="subtitle">Crear actividad</div>
    </div>
    <div class="section-page">
        <form [formGroup]="crearActividadForm">
        <div class="column1">
        
            <div class="section-data">
                <div class="row row1">
                    <div class="col-sm-12 col-md-8">
                        <div class="lab"><label for="gestion-actividad">Actividad</label></div>
                        <div class="inp">
                            <input formControlName="nombre" type="text" name="gestion-actividad" id="gestion-actividad" class="inp-modal"
                                placeholder="Nombre" />
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="custom-control custom-checkbox">
                            <input formControlName="esperaPlanAccion" (click)="checkPlanDeAccion(!planDeAccion)" [checked]="(planDeAccion) ? true : false" type="checkbox" class="custom-control-input" id="customCheck1" name="reclamos-acepto">
                            <label class="custom-control-label" for="customCheck1">Espero plan de acción</label>
                        </div>
                    </div>
                </div>

                <div class="row row1">
                    <div class="col-sm-12 col-md-4">
                        <div class="lab"><label for="gestion-categoria">Categoría</label></div>
                        <div class="inp">
                                <ng-select formControlName="uuidCategoria" required placeholder="Seleccione" name="gestion-categoria" id="gestion-categoria" 
                                class="select" (change)="getListadoSubCategoriasUno()"
                                [ngClass]="{'border-error': crearActividadForm.controls['uuidCategoria'].touched && !crearActividadForm.controls['uuidCategoria'].valid}">
                                    <ng-option *ngFor="let categoria of listadoCategorias" [value]="categoria.uuid">{{categoria.nombre}}</ng-option>
                                </ng-select>
                            </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Sub-categoría 1</label></div>
                        <div class="inp"><label class="select">
                                <ng-select formControlName="uuidSubcategoriaUno" (change)="getListadoSubCategoriasDos(); asignarNombreActividad()" 
                                placeholder="Seleccione" name="gestion-subcategoria1" id="gestion-subcategoria1" class="select-modal">
                                    <ng-option *ngFor="let subc of listadoSubcategoriasUno" [value]="subc.uuid">{{subc.nombre}}</ng-option>
                                </ng-select>
                            </label> </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Sub-categoría 2</label></div>
                        <div class="inp"><label class="select">
                                <ng-select formControlName="uuidSubcategoriaDos" placeholder="Seleccione" name="gestion-subcategoria2" id="gestion-subcategoria2" class="select-modal">
                                    <ng-option *ngFor="let subc2 of listadoSubcategoriasDos" [value]="subc2.uuid">{{subc2.nombre}}</ng-option>
                                </ng-select>
                            </label> </div>
                    </div>
                </div>

                <div class="row row1">
                    <div class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Fecha de inicio actividad</label></div>
                        <div class="inp"> <input formControlName="fechaInicioActividad" type="text" placeholder="Seleccione fecha" class="inp-modal"
                            bsDatepicker [minDate]="minDate" [bsConfig]="datepickerConfig"><em class="far fa-calendar-alt ico-input"></em>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Fecha de término</label></div>
                        <div class="inp"> <input formControlName="fechaTerminoActividad" [minDate]="minDate" type="text" placeholder="Seleccione fecha" class="inp-modal"
                            bsDatepicker [bsConfig]="{ isAnimated: true }"><em class="far fa-calendar-alt ico-input"></em>
                        </div>
                    </div>
                </div>

                

                <div class="row row1 frecuency">
                    <div class="col-md-2 separadorFrecuencia">
                        <h5>Frecuencia</h5>
                        <ul id="tabs" class="nav nav-tabs" role="tablist">
                            <li class="frecSelector" *ngFor="let frecuencia of listadoFrecuencias">
                                <a *ngIf="frecuencia.nombre.toLowerCase() !== 'anual'" (click)="checkFrecuencia(frecuencia)" href="{{'#pane-' + frecuencia.paneid}}" data-toggle="tab" role="tab">
                                        <input (focus)="checkFrecuencia(frecuencia)" name="frecuencia.nombre" [checked]="(frecuencia.checked)"
                                         type="radio" [attr.id]="frecuencia.nombre">
                                        <label [attr.for]="frecuencia.nombre">{{frecuencia.nombre}}</label>
                                </a>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="col-md-10 frecFields">
                        <div class="tab-content" role="tablist">
                            
                            <div id="pane-A" class="tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                                <div class="indicadorRepeticion">
                                    <img src="assets/img/feather-repeat.svg" alt="repeticion">
                                    <p>No se repetirá</p>
                                </div>
                            </div>
                            <div id="pane-B" class="tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
                                <div class="indicadorRepeticion">
                                    <img src="assets/img/feather-repeat.svg" alt="repeticion">
                                    <p>Se repetirá diariamente cada {{crearActividadForm.controls.intervaloRepeticionCadaXDias.value}} dias hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="container">
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <h4>Intervalo</h4>
                                            <div class="d-flex">
                                                <p>Repetir cada</p>
                                                <input formControlName="intervaloRepeticionCadaXDias" type="number" min="1" name="intervalo-diario" id="intervalo-diario" class="inp-modal inp-intervalo"
                                                         />
                                                <p>días</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="pane-C" class="tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
                                <div class="indicadorRepeticion">
                                    <img src="assets/img/feather-repeat.svg" alt="repeticion">
                                    <p *ngIf="nombresDiasSeleccionaods.length === 0">Se repetirá semanalmente cada {{crearActividadForm.controls.intervaloRepeticionCadaXSemanas.value}} semanas hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="nombresDiasSeleccionaods.length > 0">Se repetirá semanalmente cada 
                                        {{crearActividadForm.controls.intervaloRepeticionCadaXSemanas.value}} semanas los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="container">
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <h4>Intervalo</h4>
                                            <div class="d-flex">
                                                <p>Repetir cada</p>
                                                <input formControlName="intervaloRepeticionCadaXSemanas" type="number" min="1" name="intervalo-diario" id="intervalo-diario" class="inp-modal inp-intervalo"/>
                                                <p>semanas</p>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h4>Repetir en días</h4>
                                            <!--Formulario para selección días de la semana-->
                                             <div class="mb-6">
                                                 <div class="form-group row">
                                                   <div class="d-inline-flex">
                                                     <div *ngFor="let day of diasSemana; index as i" class="mr-2 d-flex flex-column form-check">
                                                       <label class="align-self-center form-check-label" for="customCheck-{{ i }}">{{day.nombre}}</label>
                                                       <input [disabled]="day.disabled" (click)="checkDia(day)" [checked]="day.checked" (change)="actualizarDiaSeleccionado(day); disableDay()" type="checkbox" id="customCheck-{{ i }}"/>
                                                     </div>
                                                   </div>
                                                 </div>
                                                 
                                             </div>
                                        </div>
                                        <div class="col-12">
                                            <h4>Duración en días</h4>
                                            <div class="d-flex mb-4">
                                                <div class="mt-2">
                                                    <div class="inp">
                                                        <input (change)="habilitarCheckboxDay(); setDuracionValue()" type="number" min="1" max="6" formControlName="diasDuracion" class="inp-modal" id="checkbox-duracion-semana">
                                                    </div>
                                                </div>
                                                <div class="ml-3">
                                                    <div class="form-group row">
                                                      <div class="col d-inline-flex mt-2">
                                                        <input formControlName="isTodaLaSemana" type="checkbox" (change)="checkTodoElMes()">
                                                        <label class="ml-2" for="checkbox-duracion-semana" >Toda la semana</label>
                                                      </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="pane-D" class="tab-pane fade" role="tabpanel" aria-labelledby="tab-D">
                                <div class="indicadorRepeticion">
                                    <img src="assets/img/feather-repeat.svg" alt="repeticion">
                                    <p *ngIf="getSelectedMonthsFrecMensual().length === 0 
                                    && crearActividadForm.controls.diasEnElMesFrecMensual.value.length === 0 
                                    && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length === 0
                                    && nombresDiasSeleccionaods.length === 0">
                                    Se repetirá mensualmente hasta el 
                                    {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="getSelectedMonthsFrecMensual().length > 0 
                                        && crearActividadForm.controls.diasEnElMesFrecMensual.value.length === 0
                                        && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length === 0
                                        && nombresDiasSeleccionaods.length === 0">
                                        Se repetirá mensualmente los meses {{getSelectedMonthsFrecMensual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="getSelectedMonthsFrecMensual().length > 0 
                                        && crearActividadForm.controls.diasEnElMesFrecMensual.value.length === 0
                                        && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length === 0
                                        && nombresDiasSeleccionaods.length > 0">
                                        Se repetirá mensualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de los meses {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="crearActividadForm.controls.diasEnElMesFrecMensual.value.length > 0 
                                            && getSelectedMonthsFrecMensual().length === 0">
                                            Se repetirá mensualmente los dias {{crearActividadForm.controls.diasEnElMesFrecMensual.value.join(', ').toLowerCase()}} hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="crearActividadForm.controls.diasEnElMesFrecMensual.value.length > 0 
                                        && getSelectedMonthsFrecMensual().length > 0">
                                        Se repetirá mensualmente los dias {{crearActividadForm.controls.diasEnElMesFrecMensual.value.join(', ').toLowerCase()}} de los meses {{getSelectedMonthsFrecMensual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>

                                        <p *ngIf="getSelectedMonthsFrecMensual().length === 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length > 0
                                            && nombresDiasSeleccionaods.length === 0">
                                            Se repetirá mensualmente la {{crearActividadForm.controls.semanasEnElMesFrecMensual.value.join(', ').toLowerCase()}} semana del mes hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecMensual().length > 0 
                                        && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length > 0 
                                        && nombresDiasSeleccionaods.length === 0">
                                        Se repetirá mensualmente la {{crearActividadForm.controls.semanasEnElMesFrecMensual.value.join(', ').toLowerCase()}} semana de los meses {{getSelectedMonthsFrecMensual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecMensual().length > 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length > 0 
                                            && nombresDiasSeleccionaods.length > 0">
                                            Se repetirá mensualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de la 
                                            {{crearActividadForm.controls.semanasEnElMesFrecMensual.value.join(', ').toLowerCase()}} 
                                            semana de los meses {{getSelectedMonthsFrecMensual().toLowerCase()}} hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecMensual().length === 0 
                                                && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length === 0 
                                                && nombresDiasSeleccionaods.length > 0">
                                                Se repetirá mensualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} hasta el 
                                                {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                         <p *ngIf="getSelectedMonthsFrecMensual().length === 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecMensual.value.length > 0
                                            && nombresDiasSeleccionaods.length > 0">
                                            Se repetirá mensualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de la 
                                            {{crearActividadForm.controls.semanasEnElMesFrecMensual.value.join(', ').toLowerCase()}} semana del mes hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                            
                                </div>
                                <div class="container">
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <div class="lab"><label for="">Meses</label></div>
                                                    <div class="inp"><!--multiselect-->
                                                        <ng-select formControlName="mesesFrecMensual" class="select" name="" id="" placeholder="Seleccione" [multiple]="true">
                                                            <ng-option *ngFor="let mes of meses" [value]="mes.id">{{mes.nombre}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex mb-4">
                                                
                                                <div class="mx-3">
                                                    <div class="lab"><label for="m-dias">Días</label></div>
                                                    <div class="inp"><!--seleccion de 31 dias + ultimo con checkbox-->
                                                        <input (change)="habilitarCheckboxDay(); setDuracionValue()" formControlName="diasDuracionFrecMensual" min="1" max="30" type="number" class="inp-modal" id="checkbox-duracion-semana">
                                                    </div>
                                                </div>
                                                <div class="ml-3">
                                                    <div class="form-group row">
                                                      <div class="col d-inline-flex mt-4">
                                                        <input (change)="checkTodoElMes()" type="checkbox" formControlName="isTodoElMes">
                                                        <label class="ml-2" for="checkbox-duracion-semana" >Todo el mes</label>
                                                      </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                
                                                <div class="mx-3">
                                                    <div class="lab"><label for="m-semana">Semana</label></div>
                                                    <div class="inp"><!--multiselect-->
                                                        <ng-select formControlName="semanasEnElMesFrecMensual" class="select" placeholder="Seleccione" name="" id="" [multiple]="true">
                                                            <ng-option *ngFor="let semana of semanas" [value]="semana">{{semana}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                    <!--Formulario para selección días de la semana-->
                                                </div>
                                                <div class="ml-3">
                                                    <div class="form-group row">
                                                      <div class="col d-inline-flex">
                                                        <div *ngFor="let day of diasSemana; index as i" class="custom-control mr-2 d-flex flex-column form-check">
                                                          <label class="align-self-center form-check-label" for="customCheck-{{ i }}">{{day.nombre}}</label>
                                                          <input [disabled]="day.disabled" (click)="checkDia(day)" [checked]="day.checked" (change)="actualizarDiaSeleccionado(day); disableDay()" type="checkbox" id="customCheck-{{ i }}"/>
                                                        </div>
                                                      </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--<div id="pane-E" class="tab-pane fade" role="tabpanel" aria-labelledby="tab-E">
                                <div class="indicadorRepeticion">
                                    <img src="assets/img/feather-repeat.svg" alt="repeticion">
                                    <p *ngIf="getSelectedMonthsFrecAnual().length === 0 
                                    && crearActividadForm.controls.diasEnElMesFrecAnual.value.length === 0 
                                    && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length === 0
                                    && nombresDiasSeleccionaods.length === 0">
                                    Se repetirá anualmente hasta el 
                                    {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="getSelectedMonthsFrecAnual().length > 0 
                                        && crearActividadForm.controls.diasEnElMesFrecAnual.value.length === 0
                                        && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length === 0
                                        && nombresDiasSeleccionaods.length === 0">
                                        Se repetirá anualmente los meses {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="getSelectedMonthsFrecAnual().length > 0 
                                        && crearActividadForm.controls.diasEnElMesFrecAnual.value.length === 0
                                        && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length === 0
                                        && nombresDiasSeleccionaods.length > 0">
                                        Se repetirá anualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de los meses {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="crearActividadForm.controls.diasEnElMesFrecAnual.value.length > 0 
                                            && getSelectedMonthsFrecAnual().length === 0">
                                            Se repetirá anualmente los dias {{crearActividadForm.controls.diasEnElMesFrecAnual.value.join(', ').toLowerCase()}} hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                    <p *ngIf="crearActividadForm.controls.diasEnElMesFrecAnual.value.length > 0 
                                        && getSelectedMonthsFrecAnual().length > 0">
                                        Se repetirá anualmente los dias {{crearActividadForm.controls.diasEnElMesFrecAnual.value.join(', ').toLowerCase()}} de los meses {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>

                                        <p *ngIf="getSelectedMonthsFrecAnual().length === 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length > 0
                                            && nombresDiasSeleccionaods.length === 0">
                                            Se repetirá anualmente la {{crearActividadForm.controls.semanasEnElMesFrecAnual.value.join(', ').toLowerCase()}} semana del mes hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecAnual().length > 0 
                                        && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length > 0 
                                        && nombresDiasSeleccionaods.length === 0">
                                        Se repetirá anualmente la {{crearActividadForm.controls.semanasEnElMesFrecAnual.value.join(', ').toLowerCase()}} semana de los meses {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                        {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecAnual().length > 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length > 0 
                                            && nombresDiasSeleccionaods.length > 0">
                                            Se repetirá anualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de la 
                                            {{crearActividadForm.controls.semanasEnElMesFrecAnual.value.join(', ').toLowerCase()}} semana de los meses 
                                            {{getSelectedMonthsFrecAnual().toLowerCase()}} hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                        <p *ngIf="getSelectedMonthsFrecAnual().length === 0 
                                                && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length === 0 
                                                && nombresDiasSeleccionaods.length > 0">
                                                Se repetirá anualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} hasta el 
                                                {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                         <p *ngIf="getSelectedMonthsFrecAnual().length === 0 
                                            && crearActividadForm.controls.semanasEnElMesFrecAnual.value.length > 0
                                            && nombresDiasSeleccionaods.length > 0">
                                            Se repetirá anualmente los dias {{nombresDiasSeleccionaods.join(', ').toLowerCase()}} de la 
                                            {{crearActividadForm.controls.semanasEnElMesFrecAnual.value.join(', ').toLowerCase()}} semana del mes hasta el 
                                            {{crearActividadForm.controls.fechaTerminoActividad.value | date: 'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="container">
                                    <div class="row my-3">
                                        <div class="col-12">
                                            <div class="lab"><label for="">Meses</label></div>
                                                    <div class="inp">
                                                        <ng-select formControlName="mesesFrecAnual" class="select" placeholder="Seleccione" name="" id="" [multiple]="true">
                                                            <ng-option *ngFor="let mes of meses" [value]="mes.id">{{mes.nombre}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex mb-4">
                                                <input type="radio" id="m-dias" name="repanual" value="m-dias" [checked]="checkedDias" (click)="checkDiaSemana(true)">
                                                <div class="mx-3">
                                                    <div class="lab"><label for="m-dias">Días</label></div>
                                                    <div class="inp">
                                                        <ng-select [readonly]="!checkedDias" formControlName="diasEnElMesFrecAnual" class="select" placeholder="Seleccione" name="" id="" [multiple]="true">
                                                            <ng-option *ngFor="let dia of dias" [value]="dia">{{dia}}</ng-option>
                                                            <ng-option value="32">Ultimo dia</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <input type="radio" id="m-semana" name="repanual" value="m-semana" [checked]="checkedSemana" (click)="checkDiaSemana(false)">
                                                <div class="mx-3">
                                                    <div class="lab"><label for="m-semana">Semana</label></div>
                                                    <div class="inp">
                                                        <ng-select [readonly]="checkedDias" formControlName="semanasEnElMesFrecAnual" class="select" [multiple]="true" placeholder="Seleccione" name="" id="">
                                                            <ng-option *ngFor="let semana of semanas" [value]="semana">{{semana}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                   
                                                    
                                                </div>
                                                <div class="ml-3">
                                                    <div class="form-group row ">
                                                      <div class="col d-inline-flex">
                                                        <div *ngFor="let day of diasSemana; index as i" class="custom-control mr-2 d-flex flex-column form-check">
                                                          <label class="align-self-center form-check-label" for="customCheck-{{ i }}">{{day.nombre}}</label>
                                                          <input [disabled]="checkedDias" (click)="checkDia(day)" [checked]="day.checked" (change)="actualizarDiaSeleccionado(day)" type="checkbox" id="customCheck-{{ i }}" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>

                <!--<div class="row row1">
                    <div [ngStyle]="{'visibility': showDuracion ? 'visible' : 'hidden'}" class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Duración de la actividad</label></div>
                        <div class="inp"><label class="select">
                                <ng-select (change)="validarRango()" formControlName="uuidRangoActividad" placeholder="Seleccione" name="gestion-frecuencia" id="gestion-frecuencia" class="select-modal">
                                    <ng-option *ngFor="let rango of listadoRangoDuracion" [value]="rango.uuid">{{rango.nombre}}</ng-option>
                                </ng-select>
                            </label> </div>
                    </div>
                    <div [ngStyle]="{'visibility': showRango ? 'visible' : 'hidden'}" class="col-sm-12 col-md-4">
                        <div class="lab"><label for="name">Rango actividad</label></div>
                        <div class="inp"> <input [bsConfig]="datepickerConfig" formControlName="fechaRango" type="text" placeholder="Seleccione Periodo" class="inp-modal" bsDaterangepicker>
                            <em class="far fa-calendar-alt ico-input"></em></div>
                    </div>
                </div>-->

            </div><!--FIN SECTION DATA-->
        
           
        </div><!--FIN COLUMN 1? LEFT COLUMN-->
            <div class="column2">
                <div class="section-data back-grey">
                    <div class="subtitle-place">Asignar</div>
                    <div class="lab lab-place"><label for="name">Área</label></div>
                    <div class="inp">
                        <ng-select formControlName="uuidArea" (change)="getListadoSubAreas()"
                            placeholder="Seleccione" name="reclamos-area" id="reclamos-area" class="select"
                            [ngClass]="{'border-error': crearActividadForm.controls['uuidArea'].touched && !crearActividadForm.controls['uuidArea'].valid}">
                            <ng-option [value]="area.uuid" *ngFor="let area of listadoAreas">{{area.nombre}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="lab lab-place"><label for="name">Subárea</label></div>
                    <div class="inp"><label class="select">
                            <ng-select formControlName="uuidSubarea" (change)="getListadoSitios()"
                                placeholder="Seleccione" name="reclamos-subarea" id="reclamos-subarea"
                                class="select-modal">
                                <ng-option [value]="subarea.uuid" *ngFor="let subarea of listadoSubareas">
                                    {{subarea.nombre}}</ng-option>
                            </ng-select>
                        </label> </div>
                    <div class="lab lab-place"><label for="name">Sitio</label></div>
                    <div class="inp"><label class="select">
                            <ng-select formControlName="uuidSitio" placeholder="Seleccione" name="reclamos-sitio"
                                id="reclamos-sitio" class="select-modal">
                                <ng-option [value]="sitio.uuid" *ngFor="let sitio of listadoSitios">{{sitio.nombre}}
                                </ng-option>
                            </ng-select>
                        </label> </div>
                </div>
            </div>
        </form>
        <div class="button-bottom">
            <button (click)="crearActividad()" type="button" class="btn btn-primary">Crear actividad</button>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div> 