<div class="loader-container" *ngIf="loader">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Inicio filtros -->
<div class="filter" *ngIf="viewFilter">
    <app-filtros [modo]="'list'" [filtrosForm]="filtros" (filterRequest)="filterDoc($event)"
        (cerrar)="cerrarFiltros($event)">
    </app-filtros>
</div>
<!-- Boton menu en vista mobile -->
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-file-alt ico-page" aria-hidden="true"></i><span
                class="title1">Documental</span></div>
    </div>
    <div class="create">
        <button type="button" class="btn btn-secundary" (click)="openModalSubir()">
            <span class="text-create"> SUBIR DOCUMENTO </span><i class="far fa-plus-square create-ico"
                aria-hidden="true"></i>
        </button>
    </div>
    <!-- Cambiar a modo GRID -->
    <div class="mode-grid-list">
        <button type="button" id="sidebarCollapse" class="btn btn-grid-list" routerLink="/web/documental-modogrid">
            <span class="text-grid-list">Ver Modo Grid</span>
            <i class="fas fa-th-large ico-grid-list" aria-hidden="true"></i>
        </button>
    </div>
    <div class="section-page">
        <div class="tools-table">
            <div class="search-mobile">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="documental"
                        [checked]="checkDocumentos" (click)="seleccionMultiple()">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export">
                <button class="btn btn-table" (click)="exportFile()">Exportar <i class="fas fa-file-excel ico-export"
                        aria-hidden="true"></i></button>
            </div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export">
                <button type="submit" class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">Filtros
                    Avanzados
                    <i class="fas fa-filter ico-export" aria-hidden="true"></i></button>
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()"> Limpiar Filtros</button>
            </div>
        </div>

        <!--Tabla-->
        <div class="tabla" *ngIf="!loader">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Archivos documental</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 12%">ID</th>
                            <th scope="col" style="text-align: center;">Estado</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Área Responsable</th>
                            <th scope="col">Subárea Responsable</th>
                            <th scope="col">Fecha vencimiento</th>
                            <th scope="col" style="word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listadoDocumentos.length > 0">
                        <tr *ngFor="let documento of listadoDocumentos; let i = index;">
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{'sel' + documento.uuid}}"
                                        name="documental" [checked]="(documento.checkDocumento) ? true : false"
                                        (click)="selDocumento(documento.uuid)">
                                    <label class="custom-control-label"
                                        for="{{'sel' + documento.uuid}}">{{documento.idInterno}}</label>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <button type="button" class="btn-ico-tool" tooltip="{{documento.estado}}"
                                    placement="right">
                                    <i *ngIf="documento?.estado.toUpperCase() === 'POR VENCER'"
                                        class="fas fa-clock ico-doc yellow" aria-hidden="true"></i>
                                    <i *ngIf="documento?.estado.toUpperCase() === 'VIGENTE'"
                                        class="fas fa-check-square ico-doc green" aria-hidden="true"></i>
                                    <i *ngIf="documento?.estado.toUpperCase() === 'VENCIDO'"
                                        class="fas fa-calendar-times ico-doc red" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td>
                                <button class="btn-details"
                                    (click)="openModalDetalle(documento.uuid)">{{documento.nombre}}</button>
                            </td>
                            <td>{{documento.area.nombre}}</td>
                            <td>{{documento.subArea.nombre}}</td>
                            <td>{{documento.fechaVencimiento | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-nowrap">
                                <button type="button" class="btn-ico-tool" tooltip="Descargar" placement="top"
                                    [disabled]="!documento.tieneAdjunto"
                                    (click)="getDescargarDocumentoModoList(documento.archivoAdjunto?.url)">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="uuidUser === documento.usuarioCreador.uuid" type="button"
                                    class="btn-ico-tool" tooltip="Editar" placement="top"
                                    (click)="openModalEditar(documento)">
                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="uuidUser !== documento.usuarioCreador.uuid" type="button"
                                    class="btn-ico-tool" tooltip="Actualizar" placement="top"
                                    (click)="openModalActualizar(documento)">
                                    <i class="fas fa-exchange-alt ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-details3" tooltip="Historial" placement="top"
                                    (click)="openModalHistorial(documento.uuid, documento.nombre)">
                                    <i class="fas fa-history ico-action" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="uuidUser === documento.usuarioCreador.uuid" type="button"
                                    class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                    (click)="openModalEliminar(documento.uuid, documento.nombre)">
                                    <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sin-resultados" *ngIf="listadoDocumentos.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
            </div>
        </div>

        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalDocumentos > 10" [ngStyle]="{'visibility': loader ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalDocumentos"
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="full-height">&nbsp;</div>