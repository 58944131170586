import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { ListaDocumentoDTO } from 'src/dtos/documental/listaDocumento.dto';
import { GetListadoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoDocumentos.response.dto';
import { GetListadoTipoDocumentosResponseDTO } from 'src/dtos/response/documental/getListadoTipoDocumento.response.dto';
import { TipoDocumentoDTO } from 'src/dtos/documental/tipoDocumento.dto';
import { UsuarioCreadorDTO } from 'src/dtos/documental/usuarioCreador.dto';
import { GetObtenerListadoUsuariosResponseDTO } from 'src/dtos/response/documental/getObtenerListadoUsuarios.response.dto';
import * as moment from 'moment';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { MacroprocesoDTO } from 'src/dtos/documental/macroproceso.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesos.response.dto';
import { ProcesoDTO } from 'src/dtos/documental/proceso.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/documental/getListadoProcesos.response.dto';
import { AreaDTO } from 'src/dtos/documental/area.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { SubareaDTO } from 'src/dtos/documental/subarea.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/documental/getListadoSubAreas.response.dto';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent implements OnInit {
  listadoEstado: any[] = [
    { id: 'VIGENTE', name: 'Vigente' },
    { id: 'POR VENCER', name: 'Por vencer' },
    { id: 'VENCIDO', name: 'Vencido' }];
  listadoAccion: any[] = [
    { id: 'CREAR', name: 'Crear' },
    { id: 'EDITAR', name: 'Editar' },
    { id: 'ACTUALIZAR', name: 'Actualizar' }];
  listadoCreador: UsuarioCreadorDTO[] = [];
  listadoCargoFiltroDoc: string[] = [];
  listaSimpleTipoDocumento: TipoDocumentoDTO[] = [];
  listaSimpleArea: AreaDTO[] = [];
  listaSimpleSubArea: SubareaDTO[] = [];
  listaSimpleMacroprocesos: MacroprocesoDTO[] = [];
  listaSimpleProcesos: ProcesoDTO[] = [];
  listaSimpleDocumentosVicunlados: ListaDocumentoDTO[] = [];

  loaderTipoDocumento: boolean = false;
  loaderArea: boolean = false;
  loaderSubArea: boolean = false;
  loaderMacroprocesos: boolean = false;
  loaderProcesos: boolean = false;
  loaderCargo: boolean = false;
  loaderCreador: boolean = false;
  loaderDocumentosVinculados: boolean = false;

  filterForm: FormGroup;

  datepickerConfig = {
    isAnimated: true,
    adaptivePosition: true,
    showWeekNumbers: false,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
  };

  @Input() filtrosForm: any;
  @Input() modo: string = 'list';
  @Output() filterRequest: EventEmitter<any> = new EventEmitter();
  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnChanges() {
    this.initFiltersDocumental(this.filtrosForm);
    if (this.modo === 'grid' || this.modo === 'list') {
      this.getListadoTipoDocumentosFiltros();
      this.getListadoDocumentos();
      this.getListadoMacroprocesosFiltros();
      this.getListadoAreasFiltros();
    }
    this.getListadoUsuariosFiltros();
    this.getListadoCargosFiltroDoc();
  }

  ngOnInit(): void {
    this.initFiltersDocumental(this.filtrosForm);
  }

  initFiltersDocumental(filtrosValue: any) {
    if (this.modo === 'historial') {
      this.filterForm = this.formBuilder.group({
        nombreUsuario: new FormControl(null),
        fecha: new FormControl(filtrosValue.fecha ? [new Date(moment(filtrosValue?.fecha[0]).format()), new Date(moment(filtrosValue?.fecha[1]).format())] : null),
        accion: new FormControl(filtrosValue.accion ? filtrosValue.accion : null),
        cargo: new FormControl(null)
      });
    } else {
      this.listadoAccion.push({ id: 'ELIMINAR', name: 'Eliminar' });
      this.filterForm = this.formBuilder.group({
        fechaVencimiento: new FormControl(filtrosValue.fechaVencimiento ? [new Date(moment(filtrosValue.fechaVencimiento[0]).format()), new Date(moment(filtrosValue.fechaVencimiento[1]).format())] : null),
        fechaCreacion: new FormControl(filtrosValue.fechaCreacion ? [new Date(moment(filtrosValue.fechaCreacion[0]).format()), new Date(moment(filtrosValue.fechaCreacion[1]).format())] : null),
        idInterno: new FormControl(filtrosValue.idInterno),
        estado: new FormControl(filtrosValue.estado),
        fechaEdicion: new FormControl(filtrosValue.fechaEdicion ? [new Date(moment(filtrosValue.fechaEdicion[0]).format()), new Date(moment(filtrosValue.fechaEdicion[1]).format())] : null),
        macroproceso: new FormControl(null),
        proceso: new FormControl(null),
        area: new FormControl(null),
        subarea: new FormControl(null),
        creador: new FormControl(null),
        tipoDocumento: new FormControl(null),
        documentoVinculados: new FormControl(null),
      });
    }
  }

  filters() {
    const form = this.filterForm.value;
    let aux: any = {};
    if (this.modo === 'historial') {
      aux = {
        nombreUsuario: form.nombreUsuario,
        fecha: form.fecha ? [moment(form.fecha[0]).format('YYYY-MM-DD'), moment(form.fecha[1]).format('YYYY-MM-DD')] : null,
        accion: form.accion,
        cargo: form.cargo
      };
    } else {
      aux = {
        idInterno: form.idInterno,
        fechaCreacion: form.fechaCreacion ? [moment(form.fechaCreacion[0]).format('YYYY-MM-DD'), moment(form.fechaCreacion[1]).format('YYYY-MM-DD')] : null,
        fechaEdicion: form.fechaEdicion ? [moment(form.fechaEdicion[0]).format('YYYY-MM-DD'), moment(form.fechaEdicion[1]).format('YYYY-MM-DD')] : null,
        uuidMacroproceso: form.macroproceso,
        uuidArea: form.area,
        uuidCreador: form.creador,
        tipoDocumento: form.tipoDocumento,
        fechaVencimiento: form.fechaVencimiento ? [moment(form.fechaVencimiento[0]).format('YYYY-MM-DD'), moment(form.fechaVencimiento[1]).format('YYYY-MM-DD')] : null,
        documentoVinculados: form.documentoVinculados && form.documentoVinculados.length > 0 ? form.documentoVinculados : null,
        uuidProceso: form.proceso,
        uuidSubarea: form.subarea,
        estado: form.estado
      };
    }
    this.filterRequest.emit(aux);
  }

  cerrarFiltros() {
    this.cerrar.emit(false);
  }

  getListadoTipoDocumentosFiltros() {
    this.loaderTipoDocumento = true;
    this.apiService.getListadoTipoDocumentos().subscribe(
      (response: GetListadoTipoDocumentosResponseDTO) => {
        this.listaSimpleTipoDocumento = response.data;
        this.loaderTipoDocumento = false;
        this.filterForm.patchValue({ tipoDocumento: this.filtrosForm.tipoDocumento });
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_TIPO_DOCUMENTOS;
        this.toastr.error(msg);
        this.loaderTipoDocumento = false;
      }
    );
  }

  getListadoMacroprocesosFiltros() {
    this.loaderMacroprocesos = true;
    this.apiService.getListadoMacroprocesos().subscribe(
      (response: GetListadoMacroprocesosResponseDTO) => {
        this.listaSimpleMacroprocesos = response.data;
        this.loaderMacroprocesos = false;
        this.filterForm.patchValue({ macroproceso: this.filtrosForm.uuidMacroproceso });

        this.getListadoProcesosFiltros();

      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS;
        this.toastr.error(msg);
        this.loaderMacroprocesos = false;
      }
    );
  }

  getListadoProcesosFiltros() {
    this.listaSimpleProcesos = [];
    this.listaSimpleSubArea = [];
    if (this.filterForm.controls.macroproceso.value) {
      this.loaderProcesos = true;
      this.filterForm.controls.macroproceso.valueChanges.subscribe(value => {
        this.filterForm.patchValue({ proceso: null, area: null, subarea: null });
      });

      this.apiService.getListadoProcesos(this.filterForm.controls.macroproceso.value).subscribe(
        (response: GetListadoProcesosResponseDTO) => {
          this.listaSimpleProcesos = response.data;
          this.loaderProcesos = false;
          if (this.filtrosForm.uuidProceso !== null) {
            this.filterForm.patchValue({ proceso: this.filtrosForm.uuidProceso });
            this.filtrosForm.uuidProceso = null;
          }
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS;
          this.toastr.error(msg);
          this.loaderProcesos = false;
        }
      );
    }
  }

  getListadoAreasFiltros() {
    this.listaSimpleSubArea = [];
    this.loaderArea = true;
    this.apiService.getListadoAreas().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleArea = response.data;
        this.loaderArea = false;
        if (this.filtrosForm.uuidArea !== null) {
          this.filterForm.patchValue({ area: this.filtrosForm.uuidArea });
          this.filtrosForm.uuidArea = null;
        }
        this.getListadoSubAreasFiltros();
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
        this.loaderArea = false;
      }
    );
  }

  getListadoSubAreasFiltros() {
    this.listaSimpleSubArea = [];
    if (this.filterForm.controls.area.value) {
      this.loaderSubArea = true;
      this.filterForm.controls.area.valueChanges.subscribe(value => {
        this.filterForm.patchValue({ subarea: null });
      });
      this.apiService.getListadoSubAreas(this.filterForm.controls.area.value).subscribe(
        (response: GetListadoSubAreasResponseDTO) => {
          this.listaSimpleSubArea = response.data;
          this.loaderSubArea = false;
          if (this.filtrosForm.uuidSubarea !== null) {
            this.filterForm.patchValue({ subarea: this.filtrosForm.uuidSubarea });
            this.filtrosForm.uuidSubarea = null;
          }
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
          this.toastr.error(msg);
          this.loaderSubArea = false;
        }
      );
    }
  }

  getListadoUsuariosFiltros() {
    this.loaderCreador = true;
    this.apiService.getListadoUsuarios().subscribe(
      (response: GetObtenerListadoUsuariosResponseDTO) => {
        this.listadoCreador = response.data;
        this.loaderCreador = false;
        if (this.filtrosForm.uuidCreador !== null) {
          this.filterForm.patchValue({ creador: this.filtrosForm.uuidCreador });
          this.filtrosForm.uuidCreador = null;
        }
        if (this.filtrosForm.nombreUsuario !== null) {
          this.filterForm.patchValue({ nombreUsuario: this.filtrosForm.nombreUsuario });
        }
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_USUARIOS;
        this.toastr.error(msg);
        this.loaderCreador = false;
      }
    );
  }

  getListadoDocumentos() {
    this.loaderDocumentosVinculados = true;
    this.apiService.getListadoDocumentos().subscribe(
      (response: GetListadoDocumentosResponseDTO) => {
        this.listaSimpleDocumentosVicunlados = response.data;
        this.loaderDocumentosVinculados = false;
        if (this.filtrosForm.documentoVinculados !== null) {
          this.filterForm.patchValue({ documentoVinculados: this.filtrosForm.documentoVinculados });
          this.filtrosForm.documentoVinculados = null;
        }
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS;
        this.toastr.error(msg);
        this.loaderDocumentosVinculados = false;
      }
    );
  }

  getListadoCargosFiltroDoc() {
    this.loaderCargo = true;
    this.apiService.getListadoCargos().subscribe(
      (response: GeneralResponseDTO) => {
        this.listadoCargoFiltroDoc = response.data;
        this.loaderCargo = false;
        this.filterForm.patchValue({ cargo: this.filtrosForm.cargo });
      },
      (error: HttpErrorResponse) => {
        this.loaderCargo = false;
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_CARGOS;
        this.toastr.error(msg);
      }
    );
  }
}
