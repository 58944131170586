<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-exclamation ico-page" aria-hidden="true"></i>
            <span class="title1">Reclamos</span>
        </div>
    </div>
    <div class="section-page">
        <div class="subtitle">Ingresar reclamo</div>
    </div>
    <div class="section-page">
        <form [formGroup]="crearForm">
            <div class="column1">
                <div class="section-data">
                    <div class="row1">
                        <div class="column4">
                            <div class="lab"><label for="name">Origen del reclamo</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="origen" placeholder="Seleccione" required
                                    [ngClass]="{'border-error': crearForm.controls['origen'].touched && !crearForm.controls['origen'].valid}"
                                    [items]="listadoOrigen"
                                    bindLabel="nombre"
                                    bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="column4">
                            <div class="lab"><label for="name">Tipo de reclamo</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="tipoReclamo" placeholder="Seleccione"
                                    required
                                    [ngClass]="{'border-error': crearForm.controls['tipoReclamo'].touched && !crearForm.controls['tipoReclamo'].valid}"
                                    [items]="listadoTipoReclamos"
                                    bindLabel="nombre"
                                    bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item"
                                        >
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                        
                            </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="column4">
                            <div class="lab"><label for="name">Tipo de pedido</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="tipoPedido" placeholder="Seleccione" required
                                    [ngClass]="{'border-error': crearForm.controls['tipoPedido'].touched && !crearForm.controls['tipoPedido'].valid}"
                                    [items]="listadoTipoPedidos"
                                    bindLabel="nombre"
                                    bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="column4">
                            <div class="lab"><label for="name">N° de documento</label></div>
                            <div class="inp">
                                <input type="text" formControlName="numeroDoc" name="reclamos-numerodoc" required
                                    autocomplete="off" id="documental-numerodoc" class="inp-modal" placeholder="0"
                                    [ngClass]="{'border-error': crearForm.controls['numeroDoc'].touched && !crearForm.controls['numeroDoc'].valid}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-data" *ngIf="crearForm.controls['numeroDoc'].value">
                    <div class="subtitle-a1">Material</div>
                    <div class="btn-add-material">
                        <button type="button" class="btn-ico-tool" tooltip="Añadir" (click)="addMaterial()"
                            [disabled]="crearForm.controls['materiales'].invalid">
                            <i class="fas fa-plus-circle add-material" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="row1 material" *ngFor="let material of getMateriales(); let i = index"
                        formArrayName="materiales">
                        <div [formGroupName]="i">
                            <div class="column4">
                                <div class="lab"><label for="inputNumeroParte-{{i}}">N° de parte</label></div>
                                <div class="inp">
                                    <ng-select class="select" formControlName="numeroParte" id="inputNumeroParte-{{i}}"
                                        placeholder="Seleccione" required #ngselect
                                        (change)="asignarDescripcion($event, i)"
                                        (focus)="getListadoMaterialesMantenedor($event, i, 'focus')"
                                        (search)="getListadoMaterialesMantenedor($event, i, 'search')"
                                        [ngClass]="{'border-error': material.controls['numeroParte'].touched && !material.controls['numeroParte'].valid}">
                                        <ng-option *ngFor="let material of listadoMaterial"
                                        [value]="material.numeroParte">
                                            {{material.numeroParte}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="column5">
                                <div class="lab"><label for="name">Descripción</label></div>
                                <div class="inp">
                                    <textarea name="textarea" rows="5" cols="20" placeholder="Descripción..."
                                        formControlName="descripcion" required
                                        [ngClass]="{'border-error': material.controls['descripcion'].touched && !material.controls['descripcion'].valid}"></textarea>
                                </div>
                            </div>
                            <div class="column3">
                                <div class="lab"><label for="name">Cantidad</label></div>
                                <div class="inp">
                                    <input type="number" name="reclamos-cantidad" id="reclamos-cantidad"
                                        formControlName="cantidad" required min="0" autocomplete="off" class="inp-modal"
                                        placeholder="0"
                                        [ngClass]="{'border-error': material.controls['cantidad'].touched && !material.controls['cantidad'].valid}" />
                                </div>
                            </div>
                            <div class="column5">
                                <div class="lab"><label for="name">Detalle</label></div>
                                <div class="inp">
                                    <textarea name="textarea" rows="5" cols="20" placeholder="Detalle..."
                                        formControlName="detalle" required
                                        [ngClass]="{'border-error': material.controls['detalle'].touched && !material.controls['detalle'].valid}"></textarea>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="getMateriales().length > 1" type="button" class="btn-ico-tool"
                                    tooltip="Quitar" (click)="removeMaterial(i)">
                                    <i class="fas fa-minus-circle delete-material" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-data">
                    <div class="subtitle-a1">Detalles</div>
                    <div class="row1" formGroupName="detalles">
                        <div class="column6">
                            <div class="lab"><label for="name">Ingresar detalle extendido</label></div>
                            <div class="inp">
                                <textarea formControlName="detalleExtendido" name="textarea" rows="5" cols="20"
                                    placeholder="Descripción..."
                                    [ngClass]="{'border-error': crearForm.get('detalles.detalleExtendido').touched && !crearForm.get('detalles.detalleExtendido').valid}"></textarea>
                            </div>
                        </div>
                        <div class="column6">
                            <div class="lab"><label for="name">Adjuntar archivos</label></div>
                            <div class="inp">
                                <label [ngClass]="{'border-error': crearForm.get('detalles.file').touched && !crearForm.get('detalles.filesSource').valid}" for="reclamos-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                                <input #fileInput type="file" name="reclamos-adjuntararchivo" id="reclamos-adjuntararchivo"
                                    class="inp-modal" formControlName="file"
                                    (change)="addAdjunto($event)"
                                    accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx" />
                                <i (click)="fileInput.click()" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                            </div>
                            <div class="column-consult-7 vertical-scroll" *ngIf="getAdjuntos().length > 0">
                                <div class="lab-consult">Archivos adjuntos</div>
                                <div class="back-img" ngxViewer *ngFor="let adjunto of getAdjuntos(); let i = index">
                                    <img class="img-show" [src]="adjunto.value.url" width="210px" height="150px"
                                        alt="Archivo adjunto">
                                    <div class="back-img-line">
                                        <i class="fas fa-times-circle red delete-archive" aria-hidden="true"
                                            (click)="removeAdjunto(i)"></i>
                                        <a href="#" class="minimage-link">
                                            <div class="ico-image"><i class="fas fa-image" aria-hidden="true"></i></div>
                                            {{adjunto.value.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="archive-back-delete" *ngIf="activarDeshacer">
                                <span class="link-archive-delete" (click)="deshacer()">
                                    <i class="fas fa-undo" aria-hidden="true"></i> Deshacer eliminar archivo
                                    {{adjuntoRemovido.filesSource.name}}
                                </span>
                            </div>
                        </div>
                        <div class="column6">
                            <div class="lab">
                                <label for="name">Impacto de la desviación
                                    <span class="impacto-desviacion">
                                        <i class="fas fa-question-circle ico-ver-procedimiento blue impacto"
                                            aria-hidden="true"></i>
                                    </span>
                                    <div class="hide">
                                        <span class="impacto-line" *ngFor="let desviacion of listadoDesviaciones">
                                            <i class="fas fa-question-circle ico-ver-procedimiento impacto-color"
                                                aria-hidden="true"
                                                [ngClass]="{'red': desviacion.nombre === 'Alto', 'yellow':  desviacion.nombre === 'Medio', 'green':  desviacion.nombre === 'Bajo'}"></i>
                                            <strong>{{desviacion.nombre}}:</strong> {{desviacion.descripcion}}
                                        </span>
                                    </div>
                                </label>
                            </div>
                            <div class="inp">
                                <ng-select class="select" formControlName="impactoDesviacion" placeholder="Seleccione"
                                    required
                                    [ngClass]="{'border-error':  crearForm.get('detalles.impactoDesviacion').touched && ! crearForm.get('detalles.impactoDesviacion').valid}">
                                    <ng-option *ngFor="let desviacion of listadoDesviaciones" [value]="desviacion.uuid">
                                        {{desviacion.nombre}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section-data" formGroupName="asignacionHallazgo">
                    <div class="subtitle-a1">Asignación del hallazgo</div>
                    <div class="row row1">
                        <div class="col-md-8 col-sm-12">
                            <div class="text-place">
                                Si conoce el área responsable de dar respuesta a su reclamo, indíquelo, de lo contrario
                                dejar en
                                blanco que será asignado por el área de Servicio al cliente de SCh.
                            </div>
                        </div>
                    </div>
                    <div class="row1">
                        <div class="column6">
                            <div class="lab lab-place"><label for="name">Área</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="area" placeholder="Seleccione"
                                    (change)="getListadoSubAreasReclamos()"
                                    [items]="listaSimpleAreasAsignacion"
                                    bindLabel="nombre"
                                    bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="column6">
                            <div class="lab lab-place"><label for="name">Subárea</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="subarea" placeholder="Seleccione"
                                    (change)="getListadoSitios('asignacion', true)"
                                    [items]="listaSimpleSubareasAsignacion"
                                    bindLabel="nombre"
                                    bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="column6">
                            <div class="lab lab-place"><label for="name">Sitio</label></div>
                            <div class="inp">
                                <ng-select class="select" formControlName="sitio" placeholder="Seleccione"
                                [items]="listadoSitioAsignacion"
                                bindLabel="nombre"
                                bindValue="uuid">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column2-mobile">
                    <div class="section-data back-grey" formGroupName="lugarHallazgo">
                        <div class="subtitle-place">Lugar del hallazgo</div>
                        <div class="lab lab-place"><label for="name">Área</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="area" placeholder="Seleccione" required
                                (change)="getListadoSubAreas('lugar', true)"
                                [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.area').touched && ! crearForm.get('lugarHallazgo.area').valid}"
                                [items]="listaSimpleAreas"
                                bindLabel="nombre"
                                bindValue="uuid">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="lab lab-place"><label for="name">Subárea</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="subarea" placeholder="Seleccione" required
                                (change)="getListadoSitios('lugar', true)"
                                [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.subarea').touched && ! crearForm.get('lugarHallazgo.subarea').valid}"
                                [items]="listaSimpleSubareasLugar"
                                bindLabel="nombre"
                                bindValue="uuid">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="lab lab-place"><label for="name">Sitio</label></div>
                        <div class="inp">
                            <ng-select class="select" formControlName="sitio" placeholder="Seleccione" required
                                [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.sitio').touched && ! crearForm.get('lugarHallazgo.sitio').valid}"
                                [items]="listadoSitioLugar"
                                bindLabel="nombre"
                                bindValue="uuid">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="lab lab-place"><label for="name">Otra ubicación</label></div>
                        <div class="inp">
                            <input type="text" name="reclamos-otraubicacion" id="reclamos-otraubicacion"
                                class="inp-modal" placeholder="Otra ubicación" formControlName="ubicacion" />
                        </div>
                    </div>
                </div>
                <div class="accept">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" name="reclamos-acepto"
                            [checked]="terminos" (change)="terminos = !terminos;">
                        <label class="custom-control-label" for="customCheck1">
                            He leido que la información es correcta y que estoy de acuerdo con enviar el presente
                            reclamo.
                        </label>
                    </div>
                </div>
                <div class="section1">
                    <button type="submit" class="btn-primary" 
                        (click)="ingresarReclamo()" [disabled]="disabledBtn">
                        Ingresar reclamo
                    </button>
                </div>
            </div>
            <div class="column2">
                <div class="section-data back-grey" formGroupName="lugarHallazgo">
                    <div class="subtitle-place">Lugar del hallazgo</div>
                    <div class="lab lab-place"><label for="area">Área</label></div>
                    <div class="inp">
                        <ng-select class="select" formControlName="area" placeholder="Seleccione" required
                            (change)="getListadoSubAreas('lugar', true)"
                            [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.area').touched && ! crearForm.get('lugarHallazgo.area').valid}"
                            [items]="listaSimpleAreas"
                            bindLabel="nombre"
                            bindValue="uuid">
                            <ng-template ng-option-tmp let-item="item">
                                <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="lab lab-place"><label for="subarea">Subárea</label></div>
                    <div class="inp">
                        <ng-select class="select" formControlName="subarea" placeholder="Seleccione" required
                            (change)="getListadoSitios('lugar', true)"
                            [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.subarea').touched && ! crearForm.get('lugarHallazgo.subarea').valid}"
                            [items]="listaSimpleSubareasLugar"
                            bindLabel="nombre"
                            bindValue="uuid">
                            <ng-template ng-option-tmp let-item="item">
                                <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="lab lab-place"><label for="sitio">Sitio</label></div>
                    <div class="inp">
                        <ng-select class="select" formControlName="sitio" placeholder="Seleccione" required
                            [ngClass]="{'border-error':  crearForm.get('lugarHallazgo.sitio').touched && ! crearForm.get('lugarHallazgo.sitio').valid}"
                            [items]="listadoSitioLugar"
                            bindLabel="nombre"
                            bindValue="uuid">
                            <ng-template ng-option-tmp let-item="item">
                                <div class="truncate" title="{{item.nombre}}">{{item.nombre}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="lab lab-place"><label for="ubicacion">Otra ubicación</label></div>
                    <div class="inp">
                        <input type="text" name="reclamos-otraubicacion" id="reclamos-otraubicacion" class="inp-modal"
                            placeholder="Otra ubicación" formControlName="ubicacion" />
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="full-height">&nbsp;</div>