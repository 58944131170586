<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Rechazar reclamo</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="rechazarForm">
        <div class="modal-body">
            <div class="modal-text">
                Está apunto de rechazar el Reclamo <strong>{{idInterno}}</strong>, que le fue asignado a su área. Por
                favor, indique sus razones.<br><br>
            </div>
            <div class="modal-text">
                <textarea id="cerrar-no-conforme" formControlName="descripcion" name="cerrar-no-conforme" rows="4"
                    cols="100" placeholder="Señale sus razones..."
                    [ngClass]="{'border-error':  rechazarForm.get('descripcion').touched && ! rechazarForm.get('descripcion').valid}"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()"
                aria-label="Close">Cancelar</button>
            <button type="button" class="btn btn-modal" (click)="rechazarReclamo()" [disabled]="rechazarForm.invalid">
                Rechazar reclamo
            </button>
        </div>
    </form>
</div>