import { ActividadAsignadoDTO } from "src/dtos/gestion/actividadAsignado.dto";
import { FrecuenciaRequestDTO } from "./frecuencia.request.dto";

export class PostCrearActividadRequestDTO {
  asignacion: ActividadAsignadoDTO = new ActividadAsignadoDTO();
  esperaPlanAccion: boolean;
  fechaInicioActividad: string;
  fechaTerminoActividad: string;
  frecuenciaRequestDTO: FrecuenciaRequestDTO = new FrecuenciaRequestDTO();
  nombre: string;
  uuidCategoria: string;
  uuidSubcategoriaDos: string;
  uuidSubcategoriaUno: string;
}