import { MaterialEntrenamientoDTO } from "src/dtos/gestion/materialEntrenamiento.dto";
import { BaseResponseDTO } from "../base.response.dto";

export class GetListadoPaginadoMaterialEntrenamientoResponseDTO extends BaseResponseDTO {
  success: boolean;
  msg: string;
  data: {
      content: Array<MaterialEntrenamientoDTO>;
      pageable: {
          sort: {
              empty: boolean;
              sorted: boolean;
              unsorted: boolean;
          };
          offset: number;
          pageNumber: number;
          pageSize: number;
          paged: boolean;
          unpaged: boolean;
      };
      totalPages: number;
      totalElements: number;
      last: boolean;
      number: number;
      sort: {
          empty: boolean;
          sorted: boolean;
          unsorted: boolean;
      };
      size: number;
      numberOfElements: number;
      first: boolean;
      empty: boolean;
  };
}