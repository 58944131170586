import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { HistorialActividadDTO } from 'src/dtos/gestion/historialActividad.dto';
import { GetListadoHistorialActividadesPaginadoResponseDTO } from 'src/dtos/response/gestion/getListadoHistorialActividadesPaginado.response.dto';

@Component({
  selector: 'app-modal-gestion-historial',
  templateUrl: './modal-gestion-historial.component.html',
  styleUrls: ['./modal-gestion-historial.component.scss']
})
export class ModalGestionHistorialComponent { 

  uuidActividad: string;
  idActividad: string;

  listadoHistorialActividades: HistorialActividadDTO[] = [];
  loader: boolean = false;

  constructor(
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private apiGestion: ApiGestionService
  ) { }
  

  ngOnInit(): void {
    this.getListadoHistorialActividadesPaginadoBusqueda();
  }

  getListadoHistorialActividadesPaginadoBusqueda() {
    this.loader = true;
    this.apiGestion.getListadoHistorialActividadesPaginadoBusqueda(0, 5, this.uuidActividad, '').subscribe(
      (resp) => this.getListadoHistortialActividadesPaginadoSuccess(resp),
      (error) => this.getListadoHistorialActividadesPaginadoError(error));
  }

  getListadoHistortialActividadesPaginadoSuccess(resp: GetListadoHistorialActividadesPaginadoResponseDTO) {
    this.listadoHistorialActividades = resp.data.content;
    console.log(this.listadoHistorialActividades);
    this.loader = false;
  }

  getListadoHistorialActividadesPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERR0R_OBTENER_LISTADO_HISTORIAL_ACTIVIDADES_PAGINADO;
      this.toastr.error(msg);
      this.loader = false;
  }
}
