import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { DimensionDTO } from 'src/dtos/mantenedor/dimension.dto';
import { DimensionesPorAreasDTO } from 'src/dtos/mantenedor/dimensionesPorArea.dto';
import { MacroprocesoDTO } from 'src/dtos/mantenedor/macroproceso.dto';
import { ProcesoDTO } from 'src/dtos/mantenedor/proceso.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { PostCrearAreaRequestDTO } from 'src/dtos/request/mantenedor/postCrearArea.request.dto';
import { PostCrearProcesoRequestDTO } from 'src/dtos/request/mantenedor/postCrearProceso.request.dto';
import { PostCrearSitioRequestDTO } from 'src/dtos/request/mantenedor/postCrearSitio.request.dto';
import { PostCrearSubareaRequestDTO } from 'src/dtos/request/mantenedor/postCrearSubarea.request.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoDimensionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDimensiones.response.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoMacroprocesos.response.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoProcesos.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { PostCrearAreasResponseDTO } from 'src/dtos/response/mantenedor/postCrearAreas.response.dto';
import { PostCrearMacroprocesosResponseDTO } from 'src/dtos/response/mantenedor/postCrearMacroprocesos.response.dto';
import { PostCrearProcesosResponseDTO } from 'src/dtos/response/mantenedor/postCrearProcesos.response.dto';
import { PostCrearSitioResponseDTO } from 'src/dtos/response/mantenedor/postCrearSitio.response.dto';
import { PostCrearSubareasResponseDTO } from 'src/dtos/response/mantenedor/postCrearSubareas.response.dto';

@Component({
  selector: 'app-modal-j-crear',
  templateUrl: './modal-j-crear.component.html',
  styleUrls: ['./modal-j-crear.component.scss']
})
export class ModalJCrearComponent implements OnInit {
  titleModal: string;

  crearForm: FormGroup;

  listaSimpleMacroprocesos: MacroprocesoDTO[] = [];
  listaSimpleProcesos: ProcesoDTO[] = [];
  listaSimpleArea: AreaDTO[] = [];
  listaSimpleSubarea: SubareaDTO[] = [];
  listaDimensiones: DimensionDTO[] = [];
  dimensionesPorArea: Array<DimensionesPorAreasDTO> = [];

  onClose: Subject<string>;

  constructor(
    private apiServiceMantenedor: ApiMantenedorService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.crearForm = this.formBuilder.group({
      nombre: new FormControl(null, [Validators.required])
    });

    switch (this.titleModal) {
      case 'Proceso':
        this.getListadoMacroprocesos();
        this.crearForm.removeControl('macroprocesoAsociado');
        this.crearForm.removeControl('procesoAsociado');
        this.crearForm.removeControl('areaAsociada');
        this.crearForm.removeControl('subareaAsociada');
        this.crearForm.addControl('macroprocesoAsociado', new FormControl(null, [Validators.required]));
        break;

      case 'Área':
        this.getListadoProcesos();
        this.getListadoDimensiones();
        this.crearForm.removeControl('macroprocesoAsociado');
        this.crearForm.removeControl('areaAsociada');
        this.crearForm.removeControl('subareaAsociada');
        this.crearForm.addControl('procesoAsociado', new FormControl(null, [Validators.required]));
        this.crearForm.addControl('dimensionAsociadas', new FormControl(null, [Validators.required]));
        break;

      case 'Subárea':
        this.getListadoDimensiones();
        this.getListadoAreas();
        this.crearForm.removeControl('macroprocesoAsociado');
        this.crearForm.removeControl('procesoAsociado');
        this.crearForm.removeControl('dimensionAsociadas');
        this.crearForm.removeControl('subareaAsociada');
        this.crearForm.addControl('areaAsociada', new FormControl(null, [Validators.required]));
        break;

      case 'Sitio':
        this.getListadoSubAreas();
        this.crearForm.removeControl('macroprocesoAsociado');
        this.crearForm.removeControl('procesoAsociado');
        this.crearForm.removeControl('dimensionAsociadas');
        this.crearForm.removeControl('areaAsociada');
        this.crearForm.addControl('subareaAsociada', new FormControl(null, [Validators.required]));
        break;

      default:
        break;
    }
  }

  selectArea() {
    this.dimensionesPorArea.forEach(elementArea => {
      const areaSeleccionada = this.crearForm.value.areaAsociada.some(areaSel => areaSel === elementArea.uuid);
      const area = this.listaSimpleArea.find(areaLista => areaLista.uuid === elementArea.uuid);

      elementArea.dimensiones.forEach(elementDimension => {
        if (areaSeleccionada) {
          const result = area.dimensiones.find(ad => ad.uuid === elementDimension.uuid);
          elementDimension.disabled = result ? false : true;
        } else {
          elementDimension.value = false;
          elementDimension.disabled = true;
        }
      });
    });
  }

  checkDimension(i: number, j: number) {
    this.dimensionesPorArea[i].dimensiones[j].value = !this.dimensionesPorArea[i].dimensiones[j].value;
  }

  validarForm() {
    if (this.titleModal === 'Subárea') {
      let validarDimensiones = false;
      this.dimensionesPorArea.forEach(elementDimensionesPorArea => {
        if (elementDimensionesPorArea.dimensiones.some(dimension => dimension.value)) {
          validarDimensiones = true;
        }
      });
      return this.crearForm.valid && validarDimensiones;
    }
    return this.crearForm.valid;
  }

  crearRegistro() {
    if (this.validarForm()) {
      switch (this.titleModal) {
        case 'Macroproceso':
          this.postCrearMacroproceso();
          break;
        case 'Proceso':
          this.postCrearProceso();
          break;
        case 'Área':
          this.postCrearArea();
          break;
        case 'Subárea':
          this.postCrearSubarea();
          break;
        case 'Sitio':
          this.postCrearSitio();
          break;
        default:
          break;
      }
    }
  }

  postCrearMacroproceso() {
    this.apiServiceMantenedor.postCrearMacroprocesoMantenedor(this.crearForm.value.nombre).subscribe(
      (response: PostCrearMacroprocesosResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_MACROPROCESOS);
        this.onClose.next('0');

      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_MACROPROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  postCrearProceso() {
    const data: PostCrearProcesoRequestDTO = {
      nombre: this.crearForm.value.nombre,
      uuidMacroproceso: this.crearForm.value.macroprocesoAsociado
    };
    this.apiServiceMantenedor.postCrearProcesoMantenedor(data).subscribe(
      (response: PostCrearProcesosResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_PROCESOS);
        this.onClose.next('1');

      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_PROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  postCrearArea() {
    const data: PostCrearAreaRequestDTO = {
      nombre: this.crearForm.value.nombre,
      uuidDimensiones: this.crearForm.value.dimensionAsociadas,
      uuidProcesos: this.crearForm.value.procesoAsociado,
    };
    this.apiServiceMantenedor.postCrearAreaMantenedor(data).subscribe(
      (response: PostCrearAreasResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_AREAS);
        this.onClose.next('2');

      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  postCrearSubarea() {
    let aux = [];
    this.dimensionesPorArea.forEach(area => {
      aux.push({
        uuidArea: area.uuid,
        dimensiones: area.dimensiones.filter(fd => fd.value).map(md => md.uuid)
      });
    });
    const data: PostCrearSubareaRequestDTO = {
      nombre: this.crearForm.value.nombre,
      dimensionPorAreas: aux.filter(f => f.dimensiones.length > 0)
    };
    this.apiServiceMantenedor.postCrearSubareaMantenedor(data).subscribe(
      (response: PostCrearSubareasResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_SUBAREAS);
        this.onClose.next('3');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_SUBAREAS;
        this.toastr.error(msg);
      }
    );
  }

  postCrearSitio() {
    const data: PostCrearSitioRequestDTO = {
      nombre: this.crearForm.value.nombre,
      uuidSubArea: this.crearForm.value.subareaAsociada
    };
    this.apiServiceMantenedor.postCrearSitioMantenedor(data).subscribe(
      (response: PostCrearSitioResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_SITIOS);
        this.onClose.next('4');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_SITIOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoMacroprocesos() {
    this.apiServiceMantenedor.getListadoMacroprocesosMantenedor().subscribe(
      (responseMacro: GetListadoMacroprocesosResponseDTO) => {
        this.listaSimpleMacroprocesos = responseMacro.data.filter(macro => macro.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoProcesos() {
    this.apiServiceMantenedor.getListadoProcesosMantenedor().subscribe(
      (responseProceso: GetListadoProcesosResponseDTO) => {
        this.listaSimpleProcesos = responseProceso.data.filter(p => p.habilitado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoAreas() {
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (responseAreasCrearForm: GetListadoAreasResponseDTO) => {
        this.listaSimpleArea = responseAreasCrearForm.data.filter(a => a.habilitado);
        this.listaSimpleArea.forEach(elementArea => {
          const dimensiones = [];
          this.listaDimensiones.forEach(elementDimension => {
            dimensiones.push({
              uuid: elementDimension.uuid,
              value: false,
              disabled: true
            });
          });
          this.dimensionesPorArea.push({
            uuid: elementArea.uuid,
            dimensiones
          });
        });
      },
      (errorAreasCrearForm: HttpErrorResponse) => {
        const msg = errorAreasCrearForm.error && errorAreasCrearForm.error.msg ? errorAreasCrearForm.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSubAreas() {
    this.apiServiceMantenedor.getListadoSubAreasMantenedor().subscribe(
      (responseSubareaCrearForm: GetListadoSubAreasResponseDTO) => {
        this.listaSimpleSubarea = responseSubareaCrearForm.data.filter(sa => sa.habilitado);
      },
      (errorSubAreasCrear: HttpErrorResponse) => {
        const msg = errorSubAreasCrear.error && errorSubAreasCrear.error.msg ? errorSubAreasCrear.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoDimensiones() {
    this.apiServiceMantenedor.getListadoDimensiones().subscribe(
      (responseDimensionesCrearForm: GetListadoDimensionesResponseDTO) => {
        this.listaDimensiones = responseDimensionesCrearForm.data;
      },
      (errorDimensionesCrearForm: HttpErrorResponse) => {
        const msg = errorDimensionesCrearForm.error && errorDimensionesCrearForm.error.msg ? errorDimensionesCrearForm.error.msg : AppMessage.ERROR_LISTAR_DIMENSIONES;
        this.toastr.error(msg);
      }
    );
  }

}
