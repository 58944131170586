<form [formGroup]="filterForm" *ngIf="filterForm">
    <button class="btn-filter close-filter" (click)="cerrarFiltros()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div *ngIf="modo === 'list'">
        <div class="field-half">
            <div class="lab"><label for="name">Fecha creación</label></div>
            <div class="inp">
                <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaCreacion" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Fecha cierre</label></div>
            <div class="inp"> <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fechaCierre" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Tipo de reclamo</label></div>
            <div class="inp" *ngIf="!loaderTipoReclamo">
                <ng-select class="select-nborder" formControlName="uuidTipoReclamo" placeholder="Seleccione">
                    <ng-option *ngFor="let tipoReclamo of listadoTipoReclamos" [value]="tipoReclamo.uuid">
                        {{tipoReclamo.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderTipoReclamo">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Área responsable</label></div>
            <div class="inp" *ngIf="!loaderAreaResponsable">
                <ng-select class="select-nborder" formControlName="uuidAreaResponsable" placeholder="Seleccione"
                    (change)="getListadoSubAreas()">
                    <ng-option *ngFor="let areaResponsable of listadoAreaResponsable" [value]="areaResponsable.uuid">
                        {{areaResponsable.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderAreaResponsable">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Creador del reclamo</label></div>
            <div class="inp" *ngIf="!loaderUsuarioCreador">
                <ng-select class="select-nborder" formControlName="uuidCreador" placeholder="Seleccione">
                    <ng-option *ngFor="let usuarioCreador of listadoUsuarioCreador" [value]="usuarioCreador.uuid">
                        {{usuarioCreador.nombre}} {{usuarioCreador.apellido}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderUsuarioCreador">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Subárea responsable</label></div>
            <div class="inp" *ngIf="!loaderSubareaResponsable">
                <ng-select class="select-nborder" formControlName="uuidSubareaResponsable" placeholder="Seleccione"
                (change)="getListadoSitios()">
                    <ng-option *ngFor="let subareaResponsable of listadoSubareaResponsable"
                        [value]="subareaResponsable.uuid">
                        {{subareaResponsable.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderSubareaResponsable">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Estado</label></div>
            <div class="inp" *ngIf="!loaderEstados">
                <ng-select class="select-nborder" formControlName="uuidEstado" placeholder="Seleccione">
                    <ng-option *ngFor="let estado of listadoEstados" [value]="estado.uuid">
                        {{estado.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderEstados">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Sitio responsable</label></div>
            <div class="inp" *ngIf="!loaderSitioResponsable">
                <ng-select class="select-nborder" formControlName="uuidSitioResponsable" placeholder="Seleccione">
                    <ng-option *ngFor="let sitioResponsable of listadoSitioResponsable"
                        [value]="sitioResponsable.uuid">
                        {{sitioResponsable.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderSitioResponsable">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Filial / Área de negocio</label></div>
            <div class="inp" *ngIf="!loaderAreaFilial">
                <ng-select class="select-nborder" formControlName="uuidFilial" placeholder="Seleccione">
                    <ng-option *ngFor="let areaFilial of listadoAreaFilial" [value]="areaFilial.uuid">
                        {{areaFilial.nombre}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderAreaFilial">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name"></label></div>
            <div>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="checkExportar"
                        name="reclamos" [checked]="(checkedExportar) ? true : false" (click)="checkExportar()">
                    <label class="custom-control-label" for="checkExportar">
                        Exportar excel
                    </label>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="modo === 'historial'">
        <div class="field-half">
            <div class="lab"><label for="name">Nombre Usuario</label></div>
            <div class="inp" *ngIf="!loaderUsuarioCreador">
                <ng-select class="select-nborder" formControlName="nombreUsuario" placeholder="Seleccione">
                    <ng-option *ngFor="let creador of listadoUsuarioCreador" [value]="creador.uuid">
                        {{creador.nombre + ' ' + creador.apellido}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderUsuarioCreador">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Cargo</label></div>
            <div class="inp" *ngIf="!loaderCargo">
                <ng-select class="select-nborder" formControlName="cargo" placeholder="Seleccione">
                    <ng-option *ngFor="let cargo of listadoCargo" [value]="cargo">
                        {{cargo}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="inp" *ngIf="loaderCargo">
                <input class="inp1" type="text" placeholder="Seleccione" />
                <div class="icon-container">
                    <i class="loader" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Fecha</label></div>
            <div class="inp">
                <input type="text" placeholder="Seleccione Periodo" class="inp1" bsDaterangepicker
                    formControlName="fecha" [bsConfig]="datepickerConfig">
                <i class="far fa-calendar-alt ico-input" aria-hidden="true"></i>
            </div>
        </div>
        <div class="field-half">
            <div class="lab"><label for="name">Acción</label></div>
            <div class="inp">
                <ng-select class="select-nborder" formControlName="accion" placeholder="Seleccione">
                    <ng-option *ngFor="let accion of listadoAccion" [value]="accion">
                        {{accion.toUpperCase()}}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="filter-box-btn">
        <button class="btn btn-table btn-filter1" (click)="filtersReclamo()">Filtrar</button>
    </div>
</form>