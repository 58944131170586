import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs'
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { CategoriaDTO } from 'src/dtos/gestion/categoria.dto';
import { SubCategoriaDosDTO } from 'src/dtos/gestion/sub-categoria-dos.dto';
import { SubCategoriaUnoDTO } from 'src/dtos/gestion/sub-categoria-uno.dto';
import { PostCrearSubcategoriaDosRequestDTO } from 'src/dtos/request/gestion/postCrearSubategoriaDos.request.dto';
import { PostCrearSubCategoriaUnoRequestDTO } from 'src/dtos/request/gestion/postCrearSubcategoriaUno.request.dto';
import { GetCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getCategoriasMantenedor.response.dto';
import { GetListadoSubCategoriasDosResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasDos.response.dto';
import { GetListadoSubCategoriasUnoResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasUno.response.dto';
import { PutEditarCategoriaMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarCatgoriaMantenedor.response.dto';
import { PutEditarSubCategoriaDosMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarSubCategoriaDosMantenedor.response.dto';
import { PutEditarSubCategoriaUnoMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarSubCategoriaUnoMantenedor.response.dto';

@Component({
  selector: 'app-modal-c-editar',
  templateUrl: './modal-c-editar.component.html',
  styleUrls: ['./modal-c-editar.component.scss']
})
export class ModalCEditarComponent {

  uuid: string;
  nombre: string;
  tabName: string;
  cat: any;
  onClose: Subject<string>;

  listadoCategorias: CategoriaDTO[] = [];
  listadoSubcategoriasUno: SubCategoriaUnoDTO[] = [];
  listadoSubcategoriasDos: SubCategoriaDosDTO[] = [];

  editarCategoriaForm: FormGroup;
  editarSubcategoriaUnoForm: FormGroup;
  editarSubcategoriaDosForm: FormGroup;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder

  ) { }
  
  ngOnInit(): void{
    console.log(this.tabName);
    console.log(this.cat);
    this.onClose = new Subject();
    this.initform();
  }

  initform() {
    if (this.tabName === 'categoria') {
      this.editarCategoriaForm = this.formBuilder.group({
        nombre: new FormControl(this.cat.nombre, [Validators.required])
      });
    }
    if (this.tabName === 'subcategoriauno') {
      this.getListadoSubCategoriasDos();
      this.editarSubcategoriaUnoForm = this.formBuilder.group({
        nombre: new FormControl(this.cat.nombre, [Validators.required]),
        subcategoriasDosAsignadas: new FormControl(this.cat.subCategoriasDos.map(x => x.uuid), [Validators.required])
      });
    }
    if (this.tabName === 'subcategoriados') {
      this.getListadoCategorias();
      this.getListadoSubcategoriasUno();
      this.editarSubcategoriaDosForm = this.formBuilder.group({
        nombre: new FormControl(this.cat.nombre, [Validators.required]),
        categoriaAsignada: new FormControl(this.cat.categoria ? this.cat.categoria.uuid : null, [Validators.required]),
        subcategoriaUnoAsginada: new FormControl(this.cat.subCategoriaUno? this.cat.subCategoriaUno.uuid : null, [Validators.required])
      });
    }
  }

  getListadoCategorias() {
    this.listadoCategorias = [];
    this.apiGestion.getCategoriasMantenedor().subscribe((resp) => this.getListadoCategoriasSuccess(resp),
      (error) => this.getListadoCategoriasError(error));
  }

  getListadoCategoriasSuccess(resp: GetCategoriasMantenedorResponseDTO) {
    this.listadoCategorias = resp.data;
  }

  getListadoCategoriasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_CATEGORIAS;
    this.toastr.error(msg);
  }

  getListadoSubcategoriasUno() {
    this.listadoSubcategoriasUno = [];
    this.apiGestion.getListadoSubCategoriasUno().subscribe((resp) => this.getSubCategoriasUnoSuccess(resp),
      (error) => this.getSubCategoriasUnoError(error));
  }

  getSubCategoriasUnoSuccess(resp: GetListadoSubCategoriasUnoResponseDTO) {
    this.listadoSubcategoriasUno = resp.data;
  }

  getSubCategoriasUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_UNO;
    this.toastr.error(msg);
  }

  getListadoSubCategoriasDos() {
    this.listadoSubcategoriasDos = [];
    this.apiGestion.getListadoSubCategoriasDos().subscribe((resp) => this.getListadoSubCategoriasDosSuccess(resp),
      (error) => this.getListadoSubCategoriasDosError(error));
  }

  getListadoSubCategoriasDosSuccess(resp: GetListadoSubCategoriasDosResponseDTO) {
    this.listadoSubcategoriasDos = resp.data;
  }

  getListadoSubCategoriasDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_DOS;
    this.toastr.error(msg);
  }

  putEditarCategoriaMantenedor() {
    this.apiGestion.putEditarCategoriaMantenedor(this.cat.uuid, this.editarCategoriaForm.controls.nombre.value).subscribe((resp) => this.putEditarCategoriaSuccess(resp),
      (error) => this.putEditarCategoriaError(error));
  }

  putEditarCategoriaSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_EDITAR_CATEGORIA);
    this.onClose.next('0');
  }

  putEditarCategoriaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_CATEGORIA;
    this.toastr.error(msg);
  }

  putEditarSubCategoriaUno() {
    if (this.editarSubcategoriaUnoForm.valid) {
      const data: PostCrearSubCategoriaUnoRequestDTO = {
        nombre: this.editarSubcategoriaUnoForm.controls.nombre.value,
        uuidSubCategoriasDos: this.editarSubcategoriaUnoForm.controls.subcategoriasDosAsignadas.value
      }
      this.apiGestion.putEditarSubCategoriaUnoMantenedor(this.cat.uuid, data).subscribe(
        (resp) => this.editarSubCategoriaUnoSuccess(resp), (error) => this.editarSubCategoriaUnoError(error));
    }
  }

  editarSubCategoriaUnoSuccess(resp: PutEditarSubCategoriaUnoMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_EDITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  editarSubCategoriaUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  putEditarSubCategoriaDos() {
    if (this.editarSubcategoriaDosForm.valid) {
      const data: PostCrearSubcategoriaDosRequestDTO = {
        nombre: this.editarSubcategoriaDosForm.controls.nombre.value,
        uuidCategoria: this.editarSubcategoriaDosForm.controls.categoriaAsignada.value,
        uuidSubCategoriaUno: this.editarSubcategoriaDosForm.controls.subcategoriaUnoAsginada.value
      }
      this.apiGestion.putEditarSubCategoriaDosMantenedor(this.cat.uuid, data).subscribe(
        (resp) => this.editarSubCategoriaDosSuccess(resp), (error) => this.editarSubCategoriaDosError(error))
    }
  }

  editarSubCategoriaDosSuccess(resp: PutEditarSubCategoriaDosMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_EDITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  editarSubCategoriaDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  editar() {
    if (this.tabName === 'categoria')
      this.putEditarCategoriaMantenedor();
    if (this.tabName === 'subcategoriauno')
      this.putEditarSubCategoriaUno();
    if (this.tabName === 'subcategoriados')
      this.putEditarSubCategoriaDos();
  }

 }
