import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { CategoriaDTO } from 'src/dtos/gestion/categoria.dto';
import { SubCategoriaDosDTO } from 'src/dtos/gestion/sub-categoria-dos.dto';
import { SubCategoriaUnoDTO } from 'src/dtos/gestion/sub-categoria-uno.dto';
import { PostCrearSubcategoriaDosRequestDTO } from 'src/dtos/request/gestion/postCrearSubategoriaDos.request.dto';
import { PostCrearSubCategoriaUnoRequestDTO } from 'src/dtos/request/gestion/postCrearSubcategoriaUno.request.dto';
import { GetCategoriasMantenedorResponseDTO } from 'src/dtos/response/gestion/getCategoriasMantenedor.response.dto';
import { GetListadoSubCategoriasDosResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasDos.response.dto';
import { GetListadoSubCategoriasUnoResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasUno.response.dto';
import { PostCrearCategoriaResponseDTO } from 'src/dtos/response/gestion/postCrearCategoria.response.dto';
import { PostCrearSubCategoriaUnoResponseDTO } from 'src/dtos/response/gestion/postCrearSubCategoriaUno.response.dto';

@Component({
  selector: 'app-modal-c-crear',
  templateUrl: './modal-c-crear.component.html',
  styleUrls: ['./modal-c-crear.component.scss']
})
export class ModalCCrearComponent { 

  tabName: string;
  onClose: Subject<string>;
  listadoCategorias: CategoriaDTO[] = [];
  listadoSubcategoriasUno: SubCategoriaUnoDTO[] = [];
  listadoSubcategoriasDos: SubCategoriaDosDTO[] = [];

  crearCategoriaForm: FormGroup;
  crearSubcategoriaUnoForm: FormGroup;
  crearSubcategoriaDosForm: FormGroup;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) { }
  
  ngOnInit(): void{
    this.onClose = new Subject();
    this.initform();
  }

  initform() {
    if (this.tabName === 'categoria') {
      this.crearCategoriaForm = this.formBuilder.group({
        nombre: new FormControl(null, [Validators.required])
      });
    }
    if (this.tabName === 'subcategoriauno') {
      this.getListadoSubCategoriasDos();
      this.crearSubcategoriaUnoForm = this.formBuilder.group({
        nombre: new FormControl(null, [Validators.required]),
        subcategoriasDosAsignadas: new FormControl(null, [Validators.required])
      });
    }
    if (this.tabName === 'subcategoriados') {
      this.getListadoCategorias();
      this.getListadoSubcategoriasUno();
      this.crearSubcategoriaDosForm = this.formBuilder.group({
        nombre: new FormControl(null, [Validators.required]),
        categoriaAsignada: new FormControl(null, [Validators.required]),
        subcategoriaUnoAsginada: new FormControl(null, [Validators.required])
      });
    }
  }

  postCrearCategoria() {
    if (this.crearCategoriaForm.valid) {
      this.apiGestion.postCrearCategoriaMantenedor(this.crearCategoriaForm.controls.nombre.value).subscribe(
        (resp) => this.postCrearCategoriaSuccess(resp),
        (error) => this.postCrearCategoriaError(error));
    }
  }

  postCrearCategoriaSuccess(resp: PostCrearCategoriaResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_CREAR_CATEGORIA);
    this.onClose.next('0');
  }

  postCrearCategoriaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_CATEGORIA;
    this.toastr.error(msg);
  }

  postCrearSubCategoriaUno() {
    if (this.crearSubcategoriaUnoForm.valid) {
      const data: PostCrearSubCategoriaUnoRequestDTO = {
        nombre: this.crearSubcategoriaUnoForm.controls.nombre.value,
        uuidSubCategoriasDos: this.crearSubcategoriaUnoForm.controls.subcategoriasDosAsignadas.value
      }
      this.apiGestion.postCrearSubCategoriaUno(data).subscribe((resp) => this.postCrearSubcategoriaUnoSuccess(resp),
        (error) => this.postCrearSubcategoriaUnoError(error));
    }
  }

  postCrearSubcategoriaUnoSuccess(resp: PostCrearSubCategoriaUnoResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_CREAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  postCrearSubcategoriaUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  postCrearSubCategoriaDos() {
    if (this.crearSubcategoriaDosForm.valid) {
      const data: PostCrearSubcategoriaDosRequestDTO = {
        nombre: this.crearSubcategoriaDosForm.controls.nombre.value,
        uuidCategoria: this.crearSubcategoriaDosForm.controls.categoriaAsignada.value,
        uuidSubCategoriaUno: this.crearSubcategoriaDosForm.controls.subcategoriaUnoAsginada.value
      }
      this.apiGestion.postCrearSubCategoriaDos(data).subscribe((resp) => this.postCrearSubcategoriaUnoSuccess(resp),
        (error) => this.postCrearSubcategoriaUnoError(error));
    }
  }

  getListadoCategorias() {
    this.listadoCategorias = [];
    this.apiGestion.getCategoriasMantenedor().subscribe((resp) => this.getListadoCategoriasSuccess(resp),
      (error) => this.getListadoCategoriasError(error));
  }

  getListadoCategoriasSuccess(resp: GetCategoriasMantenedorResponseDTO) {
    this.listadoCategorias = resp.data;
  }

  getListadoCategoriasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_CATEGORIAS;
    this.toastr.error(msg);
  }

  getListadoSubcategoriasUno() {
    this.listadoSubcategoriasUno = [];
    this.apiGestion.getListadoSubCategoriasUno().subscribe((resp) => this.getSubCategoriasUnoSuccess(resp),
      (error) => this.getSubCategoriasUnoError(error));
  }

  getSubCategoriasUnoSuccess(resp: GetListadoSubCategoriasUnoResponseDTO) {
    this.listadoSubcategoriasUno = resp.data;
  }

  getSubCategoriasUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_UNO;
    this.toastr.error(msg);
  }

  getListadoSubCategoriasDos() {
    this.listadoSubcategoriasDos = [];
    this.apiGestion.getListadoSubCategoriasDos().subscribe((resp) => this.getListadoSubCategoriasDosSuccess(resp),
      (error) => this.getListadoSubCategoriasDosError(error));
  }

  getListadoSubCategoriasDosSuccess(resp: GetListadoSubCategoriasDosResponseDTO) {
    this.listadoSubcategoriasDos = resp.data;
  }

  getListadoSubCategoriasDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_DOS;
    this.toastr.error(msg);
  }


  crear() {
    if (this.tabName === 'categoria')
      this.postCrearCategoria();
    if (this.tabName === 'subcategoriauno')
      this.postCrearSubCategoriaUno();
    if (this.tabName === 'subcategoriados')
      this.postCrearSubCategoriaDos();
  }
}
