<!-- Modal eliminar-->
<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Eliminar documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="eliminarForm">
        <div class="modal-body">
            <div class="modal-text">El documento <strong>{{nombre}}</strong>, será enviado al historial
                permanentemente y no quedará disponible para su consulta
                <br> <strong>¿Desea continuar? </strong>
            </div>
            <div class="modal-subtitle"> Motivo de eliminación</div>
            <div class="modal-textarea">
                <textarea name="textarea" rows="10" cols="50" placeholder="Detalle..." formControlName="motivo" required
                    [ngClass]="{
                        'border-error': eliminarForm.controls['motivo'].touched && !eliminarForm.controls['motivo'].valid}"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal-black close-modal" (click)="bsModalRef.hide()">Cancelar</button>
            <button type="button" class="btn btn-modal" (click)="aceptar()">Sí, eliminar</button>
        </div>
    </form>
</div>