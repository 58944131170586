import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { Base64Util } from 'src/app/util/base64.util';

@Component({
  selector: 'app-editar-crear-articulo',
  templateUrl: './editar-crear-articulo.component.html',
  styleUrls: ['./editar-crear-articulo.component.scss']
})
export class EditarCrearArticuloComponent {

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private apiGestion: ApiGestionService,
    private location: Location,
    private actividadService: ActividadService
  ) {
    
  }

  uuidArticulo: string;
  blobFile: Blob;
  tituloArticulo: string = '';
  cuerpoArticulo: "<p></p>";
  imagenPrincipal: File = null;
  imageUrl: string;
  uuidMetdodologia: string;
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction
        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        //[{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image'],
        //['link', 'image', 'video']  
      ],
      
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuidMetdodologia = params.uuidMetodologia;
      this.uuidArticulo = this.actividadService.getUuidArticulo();
      if (this.uuidArticulo) {
        this.getDetalleArticulo()
      }
      else {
        console.log('CREAR ARTICULO')
      }
    });
    
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.blobFile = Base64Util.base64toBlob(reader.result);
      };

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };

      this.imagenPrincipal = file;
      console.log(this.imagenPrincipal);
      this.preview();
    }
  }

  preview() {
    if (!this.imagenPrincipal) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
  
    reader.readAsDataURL(this.imagenPrincipal);
  }

  removeImage() {
    this.imagenPrincipal = null;
    this.imageUrl = null;
  }

  regresar() {
    this.actividadService.setUuidArticulo(null);
    this.location.back();
  }

  getDetalleArticulo() {
    this.apiGestion.getObtenerArticuloDetalle(this.uuidArticulo).subscribe((resp) => {
      console.log(resp);
      this.tituloArticulo = resp.data.titulo;
      this.cuerpoArticulo = resp.data.cuerpo;
      this.imageUrl = resp.data.url.url;
      fetch(this.imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], resp.data.url.nombreArchivo, { type: blob.type });
        this.imagenPrincipal = file;
      });
    }, (error) => {
      console.log(error);
    })
  }

  subirArticulo() {
    let formData = new FormData();
    formData.append('cuerpo', this.cuerpoArticulo);
    formData.append('titulo', this.tituloArticulo);
    formData.append('imagenPrincipal', this.imagenPrincipal);
    formData.append('uuidMetodologia', this.uuidMetdodologia);

    if (!this.uuidArticulo) {
      this.postSubirArticulo(formData);
    }
    else {
      this.putEditarArticulo(formData);
    }
    
  }

  postSubirArticulo(formData: FormData) {
    this.apiGestion.postSubirArticulo(formData).subscribe((resp) => {
      this.toastr.success('Se subio correctamente');
      this.regresar();
    },
      (error) => {
        this.toastr.error('Error al subir');
      })
  }

  putEditarArticulo(formData) {
    this.apiGestion.putEditarArticulo(formData, this.uuidArticulo).subscribe((resp) => {
      this.toastr.success('Se editó correctamente');
      this.regresar();
    },
      (error) => {
        this.toastr.error('Error al editar');
      })
  }
  
 }
