import { HttpErrorResponse } from '@angular/common/http';
import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { MaterialEntrenamientoDTO } from 'src/dtos/gestion/materialEntrenamiento.dto';
import { GetListadoPaginadoMaterialEntrenamientoResponseDTO } from 'src/dtos/response/gestion/getListadoPaginadoMaterialEntrenamiento.response.dto';
import { ModalMcEliminarComponent } from '../modals/modal-mc-eliminar/modal-mc-eliminar.component';
import { ModalMcSubirComponent } from '../modals/modal-mc-subir/modal-mc-subir.component';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PostObtenerMaterialPaginadoRequestDTO } from 'src/dtos/request/gestion/postObtenerMaterialPaginado.request.dto';

@Component({
  selector: 'app-material-entrenamiento5s',
  templateUrl: './material-entrenamiento5s.component.html',
  styleUrls: ['./material-entrenamiento5s.component.scss']
})
export class MaterialEntrenamiento5sComponent {

  uuid: string;
  page: number = 1;
  pageSize: number = 10;
  campoBusqueda: string = '';
  listadoMateriales: MaterialEntrenamientoDTO[] = [];
  formCtrlSub: Subscription;
  busqueda = new FormControl('');
  filtros: any;
  viewFilter: boolean = false;
  totalRegister: number;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private apiGestion: ApiGestionService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      if (this.uuid) {
        this.initFilters();
      }
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false)
        this.listadoMateriales = [];
        this.getMaterialesEntrenamientoBusquedaPaginada();
      }
    });
  }

  openModalMcEliminar(material: MaterialEntrenamientoDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalMcEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: material.uuid,
        nombre: material.nombre,
        tipo: 'material'
      },
      class: "modal-dialog-centered",
    });

    this.cerrarModalCargaData(modalEliminar);
  }

  openModalMcSubir() {
    const modalSubir: BsModalRef = this.modalService.show(ModalMcSubirComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuidMetodologia: this.uuid,
      },
      class: "modal-dialog-centered",
    });

    this.cerrarModalCargaData(modalSubir);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.postMaterialesPaginadoFiltro();
      modal.hide();
      this.cdr.detectChanges();
    });
  }



  getMaterialesEntrenamientoBusquedaPaginada() {
    this.apiGestion.getObtenerMaterialPorMetodologiaConCampo(this.uuid, this.page - 1, this.pageSize, this.busqueda.value).
      subscribe((resp) => {
        this.getListadoMaterialesSuccess(resp);
      },
        (error) => {
          this.getListadoMaterialesError(error);
      })
  }

  getListadoMaterialesSuccess(resp: GetListadoPaginadoMaterialEntrenamientoResponseDTO) {
    this.listadoMateriales = resp.data.content;
  }

  getListadoMaterialesError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_MATERIALES;
    this.toastr.error(msg);
  }

  descargarArchivo(uuid: string) {
    this.apiGestion.getDescargarAdjuntoMaterialEntrenamiento(uuid).subscribe((resp) => {
      console.log(resp);
      this.toastr.success(AppMessage.SUCCESS_DESCARGAR_ADJUNTO);
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_ADJUNTO;
      this.toastr.error(msg);
    })
  }

  initFilters(initData: boolean = true) {
    this.page = 1;
    this.filtros = {
      nombre: null,
      tipo: null,
      fechaCreacion: null
    };

    if (initData) {
      this.postMaterialesPaginadoFiltro();
    }
  }

  filterMC(event: any) {
    this.page = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postMaterialesPaginadoFiltro();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  postMaterialesPaginadoFiltro() {
    this.busqueda.setValue(null);

    const dataRequest: PostObtenerMaterialPaginadoRequestDTO = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      nombre: this.filtros.nombre,
      tipo: this.filtros.tipo,
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
      this.apiGestion.postObtenerMaterialPaginadoFiltro(this.uuid ,this.page - 1, this.pageSize, dataRequest).subscribe(
        (response) => {
          console.log(response);
          this.listadoMateriales = response.data.content;
          this.page = response.data.pageable.pageNumber + 1;
          this.totalRegister = response.data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

  }
}