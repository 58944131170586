import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { GetObtenerDetalleAccionResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleAccion.response.dto';
import { GetObtenerDetalleDocumentoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumento.response.dto';
import { GetObtenerDetalleDocumentoEliminadoResponseDTO } from 'src/dtos/response/documental/getObtenerDetalleDocumentoEliminado.response.dto';
import { ModalActualizarComponent } from '../modal-actualizar/modal-actualizar.component';
import { ModalEditarComponent } from '../modal-editar/modal-editar.component';
import { ModalEliminarComponent } from '../modal-eliminar/modal-eliminar.component';

@Component({
  selector: 'app-modal-detalle',
  templateUrl: './modal-detalle.component.html',
  styleUrls: ['./modal-detalle.component.scss']
})
export class ModalDetalleComponent implements OnInit {
  detalleDocumento: DocumentoDTO;

  uuidUser: string;
  uuid: string;
  estadoDetalle: string = 'ACTUAL';

  onClose: Subject<string>;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private apiService: ApiService) { }

  ngOnInit(): void {
    const user = this.userService.getUser();
    this.uuidUser = user.uuid;
    this.onClose = new Subject();

    switch (this.estadoDetalle) {
      case 'ELIMINAR':
        this.getDetalleEliminado();
        break;
      case 'ACTUAL':
        this.getObtenerDetalleDocumento();
        break;
      case 'HISTORIAL':
        this.getDetalleAccion();
        break;
      default:
        break;
    }
  }

  obtenerUrl() {
    let url;
    switch (true) {
      case this.detalleDocumento.archivoAdjunto.nombreArchivo.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case this.detalleDocumento.archivoAdjunto.nombreArchivo.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case this.detalleDocumento.archivoAdjunto.nombreArchivo.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case this.detalleDocumento.archivoAdjunto.nombreArchivo.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        url = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return url;
  }

  getDetalleAccion() {
    this.apiService.getDetalleAccion(this.uuid).subscribe(
      (response: GetObtenerDetalleAccionResponseDTO) => {
        this.detalleDocumento = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_DOCUMENTO_ELIMINADO;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleEliminado() {
    this.apiService.getDetalleEliminado(this.uuid).subscribe(
      (response: GetObtenerDetalleDocumentoEliminadoResponseDTO) => {
        this.detalleDocumento = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_DOCUMENTO_ELIMINADO;
        this.toastr.error(msg);
      }
    );
  }

  getObtenerDetalleDocumento() {
    this.apiService.getObtenerDetalleDocumento(this.uuid).subscribe(
      (response: GetObtenerDetalleDocumentoResponseDTO) => {
        this.detalleDocumento = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }

  getDescargarDocumento(url: string) {
    window.open(url, '_self');
  }

  openModalEditar() {
    const modalEditar: BsModalRef = this.modalService.show(ModalEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        detalleDocumento: this.detalleDocumento
      }
    });

    this.cerrarModalCargaData(modalEditar);
  }

  openModalEliminar() {
    const modalEliminar: BsModalRef = this.modalService.show(ModalEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleDocumento.uuid,
        nombre: this.detalleDocumento.nombre
      }
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  openModalActualizar() {
    const modalActualizar: BsModalRef = this.modalService.show(ModalActualizarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        detalleDocumento: this.detalleDocumento
      }
    });

    this.cerrarModalCargaData(modalActualizar);
  }

  openModalDetalle(uuid: string) {
    this.bsModalRef.hide();
    this.modalService.show(ModalDetalleComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        estadoDetalle: 'ACTUAL'
      }
    });
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      modal.hide();
      if (myData === 'EDITAR' || myData === 'ACTUALIZAR') {
        this.getObtenerDetalleDocumento();
      } else {
        this.onClose.next('ELIMINAR');
      }
      this.cdr.detectChanges();
    });
  }
}
