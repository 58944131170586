<div class="loader-container" *ngIf="loaderSubirProc || loaderPaginado">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="filter" *ngIf="viewFilter">
    <app-filtros-reclamos menuSC="true" [filtrosForm]="filtros" (filterRequest)="filterReclamos($event)"
        (cerrar)="cerrarFiltrosSC($event)">
    </app-filtros-reclamos>
</div>
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-exclamation ico-page" aria-hidden="true"></i>
            <span class="title1">Reclamos</span>
        </div>
    </div>
    <div class="ver-procedimiento">
        <form [formGroup]="subirProcForm">
            <div class="openDoc ">
                <img src="assets/img/ico-file.svg" alt="Subir" />
                <input class="file-proc" type="file" formControlName="file" (change)="selectArchivo($event)"
                    accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx" />
                <button class="btn btn-upload" (click)="subirProcedimiento()"
                    [disabled]="!subirProcForm.controls['fileSource'].valid">
                    Subir <i class="fas fa-file-upload" aria-hidden="true"></i>
                </button>
            </div>
        </form>
    </div>
    <div class="section-page">
        <div class="tools-table">
            <div class="search-mobile">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="reclamos"
                        [checked]="checkReclamosSC" (click)="seleccionMultipleReclamosSC()">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export">
                <button class="btn btn-table" (click)="postExportarReclamosServicioClienteMantenedor()">
                    Exportar <i class="fas fa-file-excel ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export">
                <button class="btn btn-table btn-filter" (click)="cerrarFiltrosSC(!viewFilter)">
                    Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFiltersSC()">Limpiar Filtros</button>
            </div>
        </div>
        <div class="tabla" *ngIf="!loaderPaginado">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Reclamos SC</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%;">ID</th>
                            <th scope="col" style="width: 5%; text-align: center;">Estado</th>
                            <th scope="col" style="width: 5%; text-align: center;">Tiempo de respuesta</th>
                            <th scope="col" style="width: 20%;">Filial | Área de Negocio | Fecha</th>
                            <th scope="col" style="width: 15%;">Área | Subarea | Sitio responsable</th>
                            <th scope="col" style="width: 10%;">Tipo de reclamo</th>
                            <th scope="col" style="width: 15%;">Respondido por | Fecha</th>
                            <th scope="col" style="word-break:normal; text-align: center;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listadoPagReclamosSC.length > 0">
                        <tr *ngFor="let reclamoSC of listadoPagReclamosSC; let i = index;">
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{'sel' + reclamoSC.uuid}}"
                                        name="reclamos" [checked]="(reclamoSC.checkReclamo) ? true : false"
                                        (click)="selReclamoSC(reclamoSC.uuid)">
                                    <label class="custom-control-label" for="{{'sel' + reclamoSC.uuid}}">
                                        <a routerLink="/web/consultar-reclamos"
                                            [queryParams]="{uuid: reclamoSC.uuid, sc: true, idInterno: reclamoSC.idInterno}"
                                            class="link-table">{{reclamoSC.idInterno}}</a>
                                    </label>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="reclamos-estado"
                                    [ngStyle]="{'background-color': reclamoSC.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER' ? '#6979d4' : '#393939'}">
                                    {{reclamoSC.estadoReclamo.nombre}}
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <button type="button" class="btn-ico-tool" placement="right"
                                    *ngIf="!reclamoSC.estadoReclamo.nombre.toUpperCase().includes('CERRADO')"
                                    tooltip="Abierto hace {{reclamoSC.estadoSemaforo.cantidad}} días">
                                    <i class="fas fa-clock ico-rec" aria-hidden="true" [ngClass]="{
                                            'yellow' : reclamoSC.estadoSemaforo.color === 'amarillo', 
                                            'green': reclamoSC.estadoSemaforo.color === 'verde', 
                                            'red': reclamoSC.estadoSemaforo.color === 'rojo' }"></i>
                                </button>
                                <!--  Botones tiempos de respuesta Morado Cerrado -->
                                <button type="button" class="btn-ico-tool" placement="right" tooltip="Cerrado"
                                    *ngIf="reclamoSC.estadoReclamo.nombre.toUpperCase().includes('CERRADO')">
                                    <i class="fas fa-clock ico-rec purple" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td>
                                {{reclamoSC.filial.nombre}} | {{reclamoSC.fechaCreacion | date: 'dd/MM/yyyy'}}
                            </td>
                            <td>{{reclamoSC.asignacion ? reclamoSC.asignacion.area.nombre : '-'}} | 
                                {{reclamoSC.asignacion ? reclamoSC.asignacion.subarea.nombre : '-'}} | 
                                {{reclamoSC.asignacion ? reclamoSC.asignacion.sitio.nombre : '-'}}
                            </td>
                            <td>{{reclamoSC.tipoReclamo.nombre}}</td>
                            <td>
                                {{reclamoSC.usuarioRespuesta ? reclamoSC.usuarioRespuesta?.nombre + ' ' +
                                reclamoSC.usuarioRespuesta?.apellido + ' | ' + (reclamoSC.fechaRespuesta |
                                date:'dd/MM/yyyy') :
                                '--'}}
                            </td>
                            <td class=" text-nowrap">
                                <button
                                    *ngIf="!reclamoSC.asignacion && reclamoSC.estadoReclamo.nombre.toUpperCase() === 'POR ASIGNAR'"
                                    type="button" class="btn-ico-tool" tooltip="Asignar reclamo" placement="top"
                                    (click)="openModalReclamosAsignarReclamo(reclamoSC)">
                                    <i class="fas fa-handshake ico-action" aria-hidden="true"></i>
                                </button>
                                <button
                                    *ngIf="reclamoSC.hasOwnProperty('rechazo') && reclamoSC.estadoReclamo.nombre.toUpperCase().includes('RECHAZADO')"
                                    type="button" class="btn-ico-tool" tooltip="Responder rechazo" placement="top"
                                    (click)="openModalReclamosResponderRechazo(reclamoSC)">
                                    <i class="fas fa-times-circle ico-action" aria-hidden="true"></i>
                                </button>
                                <button type=" button" class="btn-ico-tool" tooltip="Cerrar reclamo" placement="top"
                                    (click)="openModalReclamosCerrar(reclamoSC)">
                                    <i class="fas fa-ban ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Comentarios" placement="top"
                                    (click)="openModalReclamosComentarios(reclamoSC)">
                                    <i class="fas fa-comment-dots ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Historial" placement="top"
                                    (click)="openModalReclamosHistorial(reclamoSC)">
                                    <i class="fas fa-history ico-action" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sin-resultados" *ngIf="listadoPagReclamosSC.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
            </div>
        </div>
        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalRegisterSC > 10"
            [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalRegisterSC"
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>