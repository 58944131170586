<div class="loader-container" *ngIf="loader">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Inicio filtros -->
<div class="filter-history" *ngIf="viewFilter">
    <app-filtros [modo]="'list'" [filtrosForm]="filtros" (filterRequest)="filterDoc($event)"
        (cerrar)="cerrarFiltros($event)">
    </app-filtros>
</div>
<!-- Boton menu en vista mobile -->
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-file-alt ico-page" aria-hidden="true"></i>
            <span class="title1">Historial Documentos Eliminados</span>
        </div>
    </div>
    <div class="section-page">
        <div class="tools-table">
            <div class="search-mobile">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="documental"
                        [checked]="checkDocumentos" (click)="seleccionMultiple()">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export">
                <button class="btn btn-table" (click)="exportFileHistorial()">Exportar <i
                        class="fas fa-file-excel ico-export" aria-hidden="true"></i></button>
            </div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export">
                <button type="submit" class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">Filtros
                    Avanzados
                    <i class="fas fa-filter ico-export" aria-hidden="true"></i></button>
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()">Limpiar Filtros</button>
            </div>
        </div>
        <!--Table -->
        <div class="table" *ngIf="!loader">
            <div class="table-responsive">
                <table id="example" class="display" style="width:100%">
                    <caption>Archivos historial documental</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 8%;">ID</th>
                            <th scope="col" style="width: 10%; text-align: center;">Versión</th>
                            <th scope="col" style="width: 6%; text-align: center;">Estado</th>
                            <th scope="col" style="width: 17%;">Nombre</th>
                            <th scope="col" style="width: 17%;">Área Responsable</th>
                            <th scope="col" style="width: 17%;">Subárea Responsable</th>
                            <th scope="col" style="width: 17%;">Fecha vencimiento</th>
                            <th scope="col" style="width: 15%; word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listadoDocumentosEliminados.length > 0">
                        <tr *ngFor="let documento of listadoDocumentosEliminados; let i = index;">
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{'sel' + documento.uuid}}"
                                        name="documental" [checked]="(documento.checkDocumento) ? true : false"
                                        (click)="selDocumento(documento.uuid)">
                                    <label class="custom-control-label"
                                        for="{{'sel' + documento.uuid}}">{{documento.idInterno}}</label>
                                </div>
                            </td>
                            <td style="text-align: center;">
                                <div class="version">v {{documento.version}}</div>
                            </td>
                            <td style="text-align: center;">
                                <button type="button" class="btn-ico-tool" tooltip="Eliminado" placement="right">
                                    <i class="far fa-times-circle ico-doc purple" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td>
                                <button class="btn-details"
                                    (click)="openModalDetalleHistorial(documento.uuid)">{{documento.nombre}}</button>
                            </td>
                            <td>{{documento.area.nombre}}</td>
                            <td>{{documento.subArea.nombre}}</td>
                            <td>{{documento.fechaVencimiento | date: 'dd/MM/yyyy'}}</td>
                            <td class=" text-nowrap">
                                <button type="button" class="btn-details3" tooltip="Historial" placement="top"
                                    data-toggle="modal"
                                    (click)="openModalHistorialAcciones(documento.uuid, documento.nombre)">
                                    <i class="fas fa-history ico-action" aria-hidden="true"></i>
                                </button>
                                <button type="button" class="btn-ico-tool" tooltip="Descargar" placement="top"
                                    [disabled]="!documento.tieneAdjunto"
                                    (click)="getDescargarDocumentoHistorial(documento.archivoAdjunto?.url)">
                                    <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sin-resultados" *ngIf="listadoDocumentosEliminados.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
            </div>
        </div>

        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalDocumentosElimandos > 10"
            [ngStyle]="{'visibility': loader ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}"
                        [totalItems]="totalDocumentosElimandos" (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>