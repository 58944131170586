<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="modal-header" *ngIf="!loader">
        <h5 class="modal-title" id="exampleModalLabel">Validar actividad</h5>
        <button (click)="bsModalRef.hide()" type="button" class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!loader">
        <div class="modal-text">Está apunto de validar la actividad <strong>"ID{{actividad.id}}"</strong>, que usted creó. Por favor,
            indique si se dio cumplimiento o no.
        </div>
        <div class="accordance">
            <div class="accordance-option">
                <label>
                    <input (click)="changeCumplimiento(true)" type="radio" name="radio-button" value="css" checked/>
                    <span>Si hubo cumplimiento</span>
                </label>
            </div>
            <div class="accordance-option">
                <label>
                    <input (click)="changeCumplimiento(false)" type="radio" name="radio-button" value="no" />
                    <span>No hubo cumplimiento</span>
                </label>
            </div>
        </div>
        <div class="modal-text"><textarea id="cerrar-no-conforme" name="cerrar-no-conforme" rows="4"
                cols="100" placeholder="Detalle..." [(ngModel)]="detalle"></textarea></div>
    </div>
    <div class="modal-footer" *ngIf="!loader">
        <button (click)="marcarComoTerminada()" type="button" class="btn btn-modal">Enviar</button>
    </div>
</div>