import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalMcSubirarchivoComponent } from '../modals/modal-mc-subirarchivo/modal-mc-subirarchivo.component';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppMessage } from 'src/app/app.message';
import { ToastrService } from 'ngx-toastr';
import { ModalMcEliminarComponent } from '../modals/modal-mc-eliminar/modal-mc-eliminar.component';

@Component({
  selector: 'app-talleres7d',
  templateUrl: './talleres7d.component.html',
  styleUrls: ['./talleres7d.component.scss']
})
export class Talleres7dComponent {

  listadoProyectos: any;
  uuid: string;
  filtros: any;
  viewFilter: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  totalRegister: number = 0;
  busqueda = new FormControl('');
  formCtrlSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      this.initFilters();
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false)
        this.listadoProyectos = [];
        this.getProyectosBusquedaPaginada()
      }
    });
  }

  getProyectosBusquedaPaginada() {
    this.apiGestion.getBusquedaPaginadaProyecto(this.uuid, this.page - 1, this.pageSize, this.busqueda.value).
      subscribe((resp) => {
        this.getListadoProyectosSuccess(resp);
      },
        (error) => {
          this.getListadoProyectosError(error);
      })
  }

  getListadoProyectosSuccess(resp: any) {
    this.listadoProyectos = resp.data.content;
    console.log(this.listadoProyectos);
  }

  getListadoProyectosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_MATERIALES;
    this.toastr.error(msg);
  }

  initFilters(initData: boolean = true) {
    this.page = 1;
    this.filtros = {
      nombre: null,
      fechaCreacion: null,
      uuidArea: null,
      uuidSubarea: null,
      uuidSitio: null
    };

    if (initData) {
      this.postProyectosPaginadoFiltro();
    }
  }

  filterMC(event: any) {
    this.page = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postProyectosPaginadoFiltro();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  postProyectosPaginadoFiltro() {
    this.busqueda.setValue(null);

    const dataRequest: any = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      uuidArea: this.filtros.uuidArea,
      uuidSubArea: this.filtros.uuidSubArea,
      uuidSitio: this.filtros.uuidSitio,
      nombre: this.filtros.nombre,
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
    
      this.apiGestion.postObtenerProyectosFiltro(this.uuid, this.page - 1, this.pageSize, dataRequest).subscribe(
        (response) => {
          console.log(response);
          this.listadoProyectos = response.data.content;
          this.page = response.data.pageable.pageNumber + 1;
          this.totalRegister = response.data.totalElements;
        },
        (error) => {
          console.log(error);
        }
      );

  }

  descargarAdjunto(url: string, nombre: string): void {
    const link = document.createElement('a');
    link.download = nombre;
    link.href = url;
    link.click();
    link.remove();
  }
  
  descargarListadoAdjuntos(proyecto: any) {
    if (proyecto.adjunto && proyecto.adjunto.length > 0) {
      proyecto.adjunto.forEach((adjunto, index) => {
        setTimeout(() => {
          this.descargarAdjunto(adjunto.url, adjunto.nombreArchivo);
        }, index * 1000); // 1000 ms = 1 segundo
        });
    }
    else {
      this.toastr.error('No se encuentran archivos para descarga.')
    }
  }
    
  openModalMcSubirarchivo() {
    const modalSubir: BsModalRef = this.modalService.show(ModalMcSubirarchivoComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuidMetodologia: this.uuid,
        tipo: 'proyecto'
      },
      class: "modal-dialog-centered",
    });
    this.cerrarModalCargaData(modalSubir);
  }

  openModalMcEliminar(proyecto: any) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalMcEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: proyecto.uuidImplementacion,
        nombre: proyecto.nombre,
        tipo: 'proyecto'
      },
      class: "modal-dialog-centered",
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.postProyectosPaginadoFiltro()
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
