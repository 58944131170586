<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Adjuntar evidencia</h5>
        <button (click)="bsModalRef.hide()" type="button" class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="adjuntarForm">
            <div class="field-half">
                <div class="lab"><label for="name">Adjuntar archivos</label></div>
                <div class="inp">
                    <label for="gestion-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                    <input #fileInput type="file" name="reclamos-adjuntararchivo" id="gestion-adjuntararchivo" class="inp-modal"
                        placeholder="Adjunte archivo"
                        formControlName="file" (change)="addAdjunto($event)"
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx"/>
                    <i (click)="fileInput.click()" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                </div>
                <div class="inp-files">
                    <div class="column-consult-7 vertical-scroll" *ngIf="getAdjuntos().length > 0">
                        <div class="lab-consult">Archivos adjuntos</div>
                        <div class="back-img" *ngFor="let adjunto of getAdjuntos(); let i = index">
                            <img *ngIf="adjunto.value.name.includes('.png') || adjunto.value.name.includes('.jpg') || adjunto.value.name.includes('.jpeg')"
                                 class="img-show" [src]="adjunto.value.url" width="210px" height="150px"
                                alt="Archivo adjunto">
                            <img *ngIf="!(adjunto.value.name.includes('.png') || adjunto.value.name.includes('.jpg') || adjunto.value.name.includes('.jpeg'))"
                                [src]="obtenerUrl(adjunto.value)" width="145px" height="145px"
                                alt="Archivo adjunto" class="img-show">
                            <div class="back-img-line">
                                <i class="fas fa-times-circle red delete-archive"
                                (click)="removeAdjunto(i)" aria-hidden="true"></i>
                                <a href="#" class="minimage-link">
                                    <div class="ico-image"><i class="fas fa-image" aria-hidden="true"></i></div>
                                    {{adjunto.value.name}}
                                </a>
                            </div>
                        </div>
                        
                    </div>
    
                </div>
            </div>
            
        </form>
        
        

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal" (click)="adjuntarEvidencia()">Adjuntar evidencia</button>
    </div>
</div>
