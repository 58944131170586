import { AmChartsService } from '@amcharts/amcharts3-angular';
import { DatePipe } from '@angular/common';
import { Component, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AnioFiscalDTO } from 'src/dtos/gestion/anioFiscal.dto';
import { AvanceEsperadoPorAreaDTO } from 'src/dtos/gestion/avanceEsperadoPorArea.dto';
import { AvanceMetodologiaDTO } from 'src/dtos/gestion/avanceMetodologia.dto';
import { AvanceRealPorAreaDTO } from 'src/dtos/gestion/avanceRealPorArea.dto';
import { PostAvanceAnualPorAreaRequestDTO } from 'src/dtos/request/gestion/postAvanceAnualPorArea.request.dto';
import { AppMessage } from '../app.message';
import { ActividadService } from '../services/actividad.service';
import { ApiGestionService } from '../services/api-gestion.service';
import { ModalMcSelectaniofiscalComponent } from './modals/modal-mc-selectaniofiscal/modal-mc-selectaniofiscal.component';

@Component({
  selector: 'app-mejora-continua',
  templateUrl: './mejora-continua.component.html',
  styleUrls: ['./mejora-continua.component.scss']
})
export class MejoraContinuaComponent { 

  porcentajes: AvanceMetodologiaDTO[] = [];
  fechaSelected: AnioFiscalDTO;
  listadoAniosFiscal: AnioFiscalDTO[] = [];
  dataDisponible: boolean = false;
  graficos = [];
  chart5s: any;
  chart7d: any;
  chartLss: any;
  loader: boolean = false;
  filtrado: boolean = false;
  viewFilter: boolean = false;
  filtros: any;
  porcentajesAvanceEsperado: AvanceEsperadoPorAreaDTO[] = [];
  porcentajesAvanceReal: AvanceRealPorAreaDTO[] = [];
  titulo: string = '';

  colorClasses = {
    verde: 'kpi-green',
    rojo: 'kpi-red',
    amarillo: 'kpi-yellow',
    naranja: 'kpi-orange',
    blanco: ''
  };



  constructor(
    private amCharts: AmChartsService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private actividadService: ActividadService,
    private datePipe: DatePipe
  ) { }
  
  ngOnInit() {
    this.getListadoAniosFiscal();
    //this.initFilters();
  }


  estructurarGrafico(avance: AvanceMetodologiaDTO) {
    return {
      "type": "serial",
      "theme": "light",
      "dataProvider": this.dataProvider(avance),
      "graphs": [
        {
          "fillAlphas": 0.9,
          "lineAlpha": 0.2,
          "type": "column",
          "valueField": "porcentaje",
          "columnWidth": 1,
          "colorField": "color",
          "labelColorField": "labelColor",
          "labelText": "[[category]]  [[value]]%",
          "labelPosition": "left",
          "balloonFunction": function(graphDataItem, graph) {
            if (graphDataItem.values.value === 0) {
              return "<b>" + graphDataItem.category + ": Sin avance</b>";
            } else {
              return "<b>" + graphDataItem.category + " " + graphDataItem.values.value + "%</b>";
            }
          }
        }
      ],
    "valueAxes": [{
      "axisAlpha": 0,
      "labelsEnabled": false,
      "minimum": 0,
      "maximum": 100,
      "gridThickness": 0,
    }],
    "categoryAxis": {
      "axisAlpha": 0,
      "labelsEnabled": false,
      "gridThickness": 0,
    },
      "categoryField": "nombreAvance",
      "rotate": true,
    }
    
  }

  dataProvider(avance: AvanceMetodologiaDTO) {
    let data = [];
    return data = [
      {
        "nombreAvance": "Avance esperado",
        "porcentaje": avance.avanceEsperado,
        "color": "#001588",
        "labelColor": "#ffffff",
      },
      {
        "nombreAvance": "Avance real",
        "porcentaje": avance.avanceReal.toFixed(1),
        "color": "#c2c2c2",
        "labelColor": "#333333",
      }
    ];

  }

  generarGraficos() {

    for (let i = 0; i < this.porcentajes.length; i++){
      switch (this.porcentajes[i].metodologia) {
        case '5S':
          this.chart5s = this.amCharts.makeChart("chart-5S", this.estructurarGrafico(this.porcentajes[i]));
          break;
        case '7D':
          this.chart7d = this.amCharts.makeChart(`chart-${this.porcentajes[i].metodologia}`, this.estructurarGrafico(this.porcentajes[i]));
          break;
        case 'LSS':
          this.chartLss = this.amCharts.makeChart("chart-LSS", this.estructurarGrafico(this.porcentajes[i]));
          break;
      }
    }
    this.loader = false;
  }

  getAvanceAnual() {
    this.loader = true;
    let fechaInicial = this.datePipe.transform(this.fechaSelected.fechaInicioDate, 'yyyy-MM-dd');
    let fechaTermino = this.datePipe.transform(this.fechaSelected.fechaTerminoDate, 'yyyy-MM-dd');
    console.log('FECHA INICIA: ', fechaInicial);
    console.log('FECHA TERMINO: ', fechaTermino);
    this.apiGestion.getAvancePorMetodologia(fechaInicial, fechaTermino).subscribe((resp) => {
      this.porcentajes = resp.data;
      this.dataDisponible = true;
      this.generarGraficos();
    }, (error) => {
      this.toastr.error('Error obtener porcentajes.'); // Hacerlo con una variable en app message
      this.loader = false;
    })
  }

  getAnioFiscalActual() {
    this.fechaSelected = new AnioFiscalDTO();
    const currentDate = new Date();
    const fechaActual = this.listadoAniosFiscal.find(fecha => {
      const fechaInicio = new Date(fecha.fechaInicioAnioFiscal);
      const fechaTermino = new Date(fecha.fechaTerminoAnioFiscal);
      return currentDate >= fechaInicio && currentDate <= fechaTermino;
    })
    this.fechaSelected = fechaActual;
    console.log(fechaActual);

    this.actividadService.setAnioFiscalSelected(this.fechaSelected);
    //this.getAvanceAnual();
    this.initFilters();
    this.loader = false;

  }

  getListadoAniosFiscal() {
    this.loader = true;
    this.apiGestion.getObtenerAniosFiscales().subscribe((resp) => {
      this.listadoAniosFiscal = resp.data;
      for (const iterator of this.listadoAniosFiscal) {
        iterator.fechaInicioDate = new Date(iterator.fechaInicioAnioFiscal);
        iterator.fechaTerminoDate = new Date(iterator.fechaTerminoAnioFiscal);
      }
      this.getAnioFiscalActual();
    }, (error) => {
      this.toastr.error('Error al obtener listado periodos fiscales.');
    })

  }

  initFilters(initData: boolean = true) {
    this.filtrado = false;
    this.filtros = {
      metodologia: null,
      uuidAnioFiscal: this.fechaSelected.uuid,
      uuidArea: null,
      uuidEstado: null,
      uuidSitio: null,
      uuidSubArea: null
    }
    if (initData) {
      // llamar al post
    }
    this.getAvanceAnual();
  }

  filterAvance(event: any) {
    this.hideShowFilter(false);
    this.filtros = event;
    this.postFiltrarAvancePorArea();

  }

  postFiltrarAvancePorArea() {
    this.loader = true;
    const dataRequest: PostAvanceAnualPorAreaRequestDTO = JSON.parse(JSON.stringify({
      metodologia: this.filtros.metodologia,
      uuidAnioFiscal: this.filtros.uuidAnioFiscal,
      uuidArea: this.filtros.uuidArea,
      uuidEstado: this.filtros.uuidEstado,
      uuidSitio: this.filtros.uuidSitio,
      uuidSubArea: this.filtros.uuidSubArea
    }));
    this.titulo = this.filtros.titulo;

    this.apiGestion.postObtenerAvanceEsperado(dataRequest).subscribe((resp) => {
      console.log(resp);
      this.porcentajesAvanceEsperado = resp.data;
      if (this.porcentajesAvanceEsperado.length === 0) {
        this.toastr.error('No existen registros de avances en el periodo seleccionado.');
        this.loader = false;
      }
      else {
        this.postObtenerAvancePorArea(dataRequest);
      }
    }, (error) => {
      console.log(error);
      this.loader = false;
    })
  }

  postObtenerAvancePorArea(data: PostAvanceAnualPorAreaRequestDTO) {
    this.apiGestion.postObtenerAvanceAnualPorArea(data).subscribe((resp) => {
      console.log(resp);
      this.porcentajesAvanceReal = resp.data;
      this.loader = false;
      this.filtrado = true;
    }, (error) => {
      console.log(error);
      this.loader = false;
    })
  }


  hideShowFilter(event: boolean) {
    this.viewFilter = event;
  }

  openModalMcCambiarAnio() {
    const modalSubir: BsModalRef = this.modalService.show(ModalMcSelectaniofiscalComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
    });

    this.cerrarModalCargaData(modalSubir);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.fechaSelected = this.actividadService.getAnioFiscalSelected();
      this.getAvanceAnual();
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  
  
}
