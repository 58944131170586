import { VigenciaDTO } from "src/dtos/mantenedor/vigencia.dto";
import { BaseResponseDTO } from "../base.response.dto";

export class GetListadoVigenciasResponseDTO extends BaseResponseDTO {
    success: boolean;
    msg: string;
    data: Array<VigenciaDTO>;
}



