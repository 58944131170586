<div class="loader-container" *ngIf="loader">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-exclamation ico-page" aria-hidden="true"></i>
            <span class="title1">Reclamos</span>
        </div>
    </div>
    <div class="section-page" *ngIf="detalleReclamo">
        <div class="container-consult">
            <div class="subtitle">Consultar reclamo</div>
            <div class="buttons">
                <button type="button" class="button-actions" placement="top" (click)="openModalReclamosEditar()" *ngIf="(usuario.rol.nombre.toLowerCase() === 'superadmin' || detalleReclamo.usuarioCreador.uuid === usuario.uuid)
                    && detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'">
                    <i class="fas fa-pen ico-button-actions" aria-hidden="true"></i> Editar
                </button>
                <button *ngIf="(usuario.rol.nombre.toLowerCase() === 'customer service' || usuario.rol.nombre.toLowerCase() === 'superadmin')
                    && !detalleReclamo.hasOwnProperty('asignacion') 
                    && detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR ASIGNAR'" placement="top"
                    type="button" class="button-actions" (click)="openModalReclamosAsignarReclamo()">
                    <i class="fas fa-ban ico-button-actions" aria-hidden="true"></i> Asignar reclamo
                </button>
                <button *ngIf="(usuario.rol.nombre.toLowerCase() === 'customer service' || usuario.rol.nombre.toLowerCase() === 'superadmin')
                    && detalleReclamo.hasOwnProperty('rechazo') 
                    && detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('RECHAZADO')" placement="top"
                    type="button" class="button-actions" (click)="openModalReclamosResponderRechazo()">
                    <i class="fas fa-times-circle ico-button-action" aria-hidden="true"></i> Responder Rechazo
                </button>
                <button type="button" class="button-actions"
                    *ngIf="!detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO') 
                    && (detalleReclamo.usuarioCreador.uuid === usuario.uuid || usuario.rol.nombre.toLowerCase() === 'customer service' || usuario.rol.nombre.toLowerCase() === 'superadmin')"
                    placement="top" (click)="openModalReclamosCerrar()">
                    <i class="fas fa-ban ico-button-actions" aria-hidden="true"></i> Cerrar reclamo
                </button>
                <button type="button" class="button-actions" placement="top" (click)="openModalReclamosComentarios()"
                    *ngIf="!(usuario.preferencias 
                                && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                                && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid 
                                && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid) 
                            || (usuario.preferencias
                                && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                                && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid
                                && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid
                                && (detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO')
                                    || detalleReclamo.hasOwnProperty('rechazo')))">
                    <i class="fas fa-comment-dots ico-button-actions" aria-hidden="true"></i> Comentarios
                </button>
                <button type="button" class="button-actions" placement="top" (click)="openModalReclamosHistorial()">
                    <i class="fas fa-history ico-button-actions" aria-hidden="true"></i> Historial
                </button>
                <button *ngIf="detalleReclamo.hasOwnProperty('asignacion') && !detalleReclamo.hasOwnProperty('rechazo')
                            && detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'
                            && (usuario.rol.nombre.toLowerCase() === 'superadmin' 
                                || (usuario.rol.nombre.toLowerCase() === 'general' && usuario.preferencias
                                    && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                                    && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid 
                                    && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid))"
                    type="button" class="button-actions" placement="top" (click)="openModalReclamosRechazar()">
                    <i class="far fa-times-circle ico-button-actions" aria-hidden="true"></i> Rechazar reclamo
                </button>
                <button type="button" class="button-actions-blue" placement="top" (click)="openModalReclamosEliminar()"
                    *ngIf="(usuario.rol.nombre.toLowerCase() === 'superadmin' || detalleReclamo.usuarioCreador.uuid === usuario.uuid)
                        && detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER'">
                    <i class="fas fa-trash-alt ico-button-actions" aria-hidden="true"></i> Eliminar reclamo
                </button>
            </div>
        </div>
        <div class="section-data">
            <div class="infoReclamo">
                <div *ngIf="!detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO')"
                    class="details-estado" [ngClass]="{
                    'yellow-back-light' : detalleReclamo.estadoSemaforo.color === 'amarillo', 
                    'green-back-light': detalleReclamo.estadoSemaforo.color === 'verde', 
                    'red-back-light': detalleReclamo.estadoSemaforo.color === 'rojo' }">
                    <i class="fas fa-clock ico-doc-details" aria-hidden="true" [ngClass]="{
                        'yellow' : detalleReclamo.estadoSemaforo.color === 'amarillo', 
                        'green': detalleReclamo.estadoSemaforo.color === 'verde', 
                        'red': detalleReclamo.estadoSemaforo.color === 'rojo' }"></i>
                    <div class="details-text-estado"> Abierto hace {{detalleReclamo.estadoSemaforo.cantidad}} días</div>
                </div>
                <div class="reclamos-estado"
                    [ngStyle]="{'background-color': detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER' ? '#6979d4' : '#393939'}">
                    {{detalleReclamo.estadoReclamo.nombre}}
                </div>
                <div class="reclamos-estado" *ngIf="detalleReclamo.fechaCierre" style="background-color: #393939">
                    FECHA CIERRE {{(detalleReclamo.fechaCierre | date:'dd/MM/yyyy')}}
                </div>
                <div class="create-id"><strong>ID reclamo:</strong> {{detalleReclamo.idInterno}}</div>
                <div class="create-person">Creador:
                    <strong>{{detalleReclamo.usuarioCreador.nombre + ' ' +
                        detalleReclamo.usuarioCreador.apellido + ' | ' + detalleReclamo.usuarioCreador.cargo + ' | ' + 
                        detalleReclamo.usuarioCreador.area + ' | ' + detalleReclamo.usuarioCreador.filial.nombre}}</strong>
                </div>
            </div>
            <div class="row row-consult">
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Fecha de creación</div>
                    <div class="input-consult">{{detalleReclamo.fechaCreacion | date:'dd/MM/yyyy'}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Tipo de reclamo</div>
                    <div class="input-consult">{{detalleReclamo.tipoReclamo.nombre}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Origen del reclamo</div>
                    <div class="input-consult">{{detalleReclamo.origenReclamo.nombre}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Tipo de pedido</div>
                    <div class="input-consult">{{detalleReclamo.tipoPedido.nombre}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">N° de documento</div>
                    <div class="input-consult">{{detalleReclamo.numeroDocumento}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Donde se detecta</div>
                    <div class="input-consult">{{lugarHallazgo}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">A quien se le asigna</div>
                    <div class="input-consult">{{asignacionHallazgo}}</div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <div class="lab-consult">Razón asignación</div>
                    <div class="input-consult largeText">
                        {{detalleReclamo.asignacion?.observacion?.length > 0 ? detalleReclamo.asignacion?.observacion :
                        '-'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="section-data">
            <div class="subtitle-a1">Material</div>
            <div class="row-consult" *ngFor="let material of detalleReclamo.materiales">
                <div class="column-consult-7">
                    <div class="lab-consult">N° de parte</div>
                    <div class="input-consult">{{material.material.numeroParte}}</div>
                </div>
                <div class="column-consult-7">
                    <div class="lab-consult">Cantidad</div>
                    <div class="input-consult">{{material.cantidad}}</div>
                </div>
                <div class="column-consult-4">
                    <div class="lab-consult">Descripción</div>
                    <div class="input-consult">{{material.material.descripcion}}</div>
                </div>
                <div class="column-consult-2">
                    <div class="lab-consult">Detalle</div>
                    <div class="input-consult">{{material.detalle}}</div>
                </div>
            </div>
        </div>
        <div class="section-data">
            <div class="subtitle-a1">Detalles</div>
            <div class="row-consult">
                <div class="column-consult-2">
                    <div class="lab-consult">Detalle extendido</div>
                    <div class="input-consult">{{detalleReclamo.detalleExtendido}}</div>
                </div>
                <div class="column-consult-4">
                    <div class="lab-consult">Impacto de la desviación</div>
                    <div class="input-consult">{{detalleReclamo.desviacion.nombre}}</div>
                </div>
                <div class="column-consult-3" *ngIf="detalleReclamo.adjuntos?.length > 0">
                    <div class="lab-consult">Archivos adjuntos</div>
                    <div class="input-consult vertical-scroll">
                        <div class="back-img" *ngFor="let adjunto of detalleReclamo.adjuntos">
                            <div>
                                <img *ngIf="adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg')"
                                    [src]="adjunto?.url" ngxViewer width="145px" height="100px" alt="Archivo adjunto">
                                <img *ngIf="!(adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg'))"
                                    [src]="obtenerUrl(adjunto)" width="145px" height="145px"
                                    alt="Archivo adjunto">
                            </div>
                            <div class="back-img-line">
                                <i *ngIf="!adjunto.nombreArchivo.includes('.pdf')" aria-hidden="true"
                                    (click)="getDescargarAdjunto(adjunto?.url)"
                                    class="fas fa-download blue download-archive"></i>
                                <div class="ico-doc" *ngIf="adjunto.nombreArchivo.includes('.pdf')">
                                    <i class="fas fa-file-pdf" (click)="getDescargarAdjunto(adjunto?.url)"
                                        aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-data" *ngIf="!detalleReclamo.rechazo
            && (detalleReclamo.respuestaEnviada 
                || (usuario.preferencias && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                    && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid 
                    && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid ))">
            <div class="subtitle-a1">Respuesta</div>
            <div class="row-consult visualizaRespuesta">
                <!--ESTA SECCIÓN SE MUESTRA A LOS QUE NO PUEDEN EDITAR (CREADOR,SC,VISUALIZADOR)-->
                <div class="column-consult-1" *ngIf="(detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'RESPONDIDO')
                    || (detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('CERRADO') && detalleReclamo.respuestaEnviada)
                    || (detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER' 
                        && detalleReclamo.hasOwnProperty('respuesta') 
                        && !detalleReclamo.respuestaEnviada )">
                    <div class="lab-consult" *ngIf="detalleReclamo.respuestaEnviada">Respondido por</div>
                    <div class="lab-consult"
                        *ngIf="!detalleReclamo.respuestaEnviada && detalleReclamo.hasOwnProperty('respuesta')">
                        Respuesta guardada por</div>
                    <p class="data-consult">
                        <strong>{{detalleReclamo.usuarioRespuesta?.nombre + ' ' +
                            detalleReclamo.usuarioRespuesta?.apellido}}</strong>
                    </p>
                    <p class="data-consult">{{detalleReclamo.usuarioRespuesta?.email}}</p>
                    <p class="data-consult">
                        {{(detalleReclamo.fechaRespuesta + 'Z' | date:'HH:mm') + ' | ' +
                        (detalleReclamo.fechaRespuesta | date:'dd/MM/yyyy')}}
                    </p>
                </div>
                <div class="column-consult-1" *ngIf="detalleReclamo.respuestaEnviada
                            && !(usuario.preferencias 
                                && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                                && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid 
                                && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid)">
                    <br>
                    <div class="lab-consult">Respuesta</div>
                    <div class="input-consult largeText">{{detalleReclamo.respuesta}}</div>
                </div>
            </div>
            <div class="row-consult respuestaAsignado"
                *ngIf="usuario.preferencias 
                    && detalleReclamo.asignacion?.area?.uuid === usuario.preferencias?.area?.uuid 
                    && detalleReclamo.asignacion?.subarea?.uuid === usuario.preferencias?.subArea?.uuid 
                    && detalleReclamo.asignacion?.sitio?.uuid === usuario.preferencias?.sitio?.uuid 
                    && (detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'POR RESPONDER' || detalleReclamo.estadoReclamo.nombre.toUpperCase() === 'RESPONDIDO')">
                <div class="column-consult-1">
                    <label class="lab-consult" for="asignadoAnswer">Describa la respuesta al reclamo:</label>
                    <textarea id="asignadoAnswer" name="asignadoAnswer" rows="4" cols="50"
                        [formControl]="respuestaOficial"></textarea>
                </div>
                <div class="column-consult-4">
                    <button type="button" class="button-actions" placement="top" style="margin-top: 24px;"
                        (click)="openModalReclamosComentarios()">
                        <i class="fas fa-comment-dots ico-button-actions" aria-hidden="true"></i> Comentarios
                    </button>
                </div>
                <div class="column-consult-1 saveAndSend">
                    <button type="button" class="btn-primary btn-save" (click)="guardarRespuesta()">Guardar</button>
                    <button type="button" class="btn-primary btn-send" [disabled]="!activarEnviar"
                        (click)="enviarRespuesta()">Enviar Respuesta</button>
                </div>
            </div>

        </div>
        <div class="section-data" *ngIf="detalleReclamo.estadoReclamo.nombre.toUpperCase().includes('INCONFORME')">
            <div class="subtitle-a1">Reclamo Cerrado</div>
            <div class="row-consult visualizaRespuesta">
                <div class="column-consult-1">
                    <div class="lab-consult">Cerrado por</div>
                    <p class="data-consult">
                        <strong>{{detalleReclamo.usuarioCierre.nombre + ' ' +
                            detalleReclamo.usuarioCierre.apellido}}</strong>
                    </p>
                    <p class="data-consult">{{detalleReclamo.usuarioCierre.email}}</p>
                    <p class="data-consult">
                        {{(detalleReclamo.fechaCierre + 'Z' | date:'HH:mm') + ' | ' + (detalleReclamo.fechaCierre |
                        date:'dd/MM/yyyy')}}
                    </p>
                </div>
                <div class="column-consult-1">
                    <br>
                    <div class="lab-consult">Cerrado Inconforme - Comentario:</div>
                    <div class="input-consult largeText">{{detalleReclamo.motivoCierre}}</div>
                </div>
            </div>
        </div>
        <div class="section1">
            <button type="button" class="btn-primary" (click)="back()">Regresar</button>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>