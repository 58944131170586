<div class="loader-container" *ngIf="loader">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<!-- FILTROS -->
<div id="div1" class="filter" [style.visibility]="viewFilter ? 'visible' : 'hidden'" *ngIf="!loader">
    <app-filtrar-por-metodologia (cerrar)="hideShowFilter($event)" [filtrosForm]="filtros" (filterRequest)="filterAvance($event)">
    </app-filtrar-por-metodologia>
</div>

<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page" *ngIf="!filtrado">
        <app-menu></app-menu>
        <div class="section-data">

            <div class="container avanceFiscal">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2>Avance anual por Metodología</h2>
                        <div class="d-flex align-items-center justify-content-center">
                            <p class="m-0 mr-3">1 Abril {{fechaSelected?.fechaInicioDate.getFullYear()}}
                                 al 28 Febrero {{fechaSelected?.fechaTerminoDate.getFullYear()}} </p>
                            <em (click)="openModalMcCambiarAnio()" class="fas fa-edit" style="color: #001588;"></em>
                        </div>
                    </div>
                    <div class="col-12 chart">
                        <div class="subtitle-a4">Metodología {{porcentajes[0]?.metodologia}}</div>
                        <div id="chart-5S" class="chart-container" style="width: 100%; height: 100px;"></div>
                    </div>
                    <div class="col-12 chart">
                        <div class="subtitle-a4">Metodología {{porcentajes[1]?.metodologia}}</div>
                        <div id="chart-7D" class="chart-container" style="width: 100%; height: 100px;"></div>
                    </div>
                    <div class="col-12 chart">
                        <div class="subtitle-a4">Metodología {{porcentajes[2]?.metodologia}}</div>
                        <div id="chart-LSS" class="chart-container" style="width: 100%; height: 100px;"></div>
                    </div>
                </div>
            </div>
              
        </div>

        <div class="container-fluid px-4">
            <div class="row py-4">
                <div class="col-12">
                    <div class="export">
                        <button (click)="hideShowFilter(!viewFilter)" class="btn btn-table btn-filter">
                            Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                        </button>
                    </div>

                    <!--<div class="clean">
                        <button class="clean-filters" (click)="initFilters()">Limpiar Filtros</button>
                    </div>-->
                </div>
            </div>
        </div>
        <!-- <div class="section1"><button type="button" class="btn-primary">Filtrar</button></div> -->

    </div>

    <div class="section-page" *ngIf="filtrado">
        <div class="container-fluid px-4">
            <div class="row py-4">
                <div class="col-12">
                    <div class="export">
                        <button (click)="hideShowFilter(!viewFilter)" class="btn btn-table btn-filter">
                            Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="clean">
                        <button class="clean-filters" (click)="initFilters()">Limpiar Filtros</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="subtitle-a3">{{titulo}}</div>
        <div class="tools-table">
            <div class="search-mobile"><input id="txtSearch" placeholder="Buscar" class="form-control" /><i
                    class="fas fa-search ico-input1" aria-hidden="true"></i></div>
            <div class="check-container">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="reclamos">
                    <label class="custom-control-label" for="customCheck"></label>
                </div>
            </div>
            <div class="export">
                <button class="btn btn-table margin-right">Exportar Excel
                    <i class="fas fa-file-excel ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="export">
                <button class="btn btn-table">Exportar PDF
                    <i class="fas fa-file-pdf ico-export" aria-hidden="true"></i>
                </button>
            </div>
        </div>
        <div class="tabla">
            <div class="table-responsive col">
                <table id="example" class="display" style="width:100%">
                    <caption>Mantenedor mejora continua</caption>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Abril</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Mayo</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Junio</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Julio</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Agosto</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Sept.</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Oct.</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Nov.</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Dic.</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Enero</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Feb.</th>
                            <th scope="col" style="text-align: center; width: 8.3%;">Marzo</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="text-align: center;">Avance esperado</td>
                            <td *ngFor="let avanceEsperado of porcentajesAvanceEsperado" 
                            style="text-align: center;">
                                <div class="btn-kpi kpi-green">{{avanceEsperado.porcentaje}}</div>
                            </td>
                            <!--<td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">8,3</div>
                            </td>-->
                        </tr>
                        <tr *ngFor="let avanceReal of porcentajesAvanceReal">
                            <td style="text-align: left;">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck2"
                                        name="reclamos">
                                    <label class="custom-control-label" for="customCheck2">{{avanceReal.sitio}}</label>
                                </div>
                            </td>
                            <td *ngFor="let porcentaje of avanceReal.porcentajes" style="text-align: center;">
                                <div class="btn-kpi" [ngClass]="colorClasses[porcentaje?.color?.toLowerCase()]">{{porcentaje.porcentaje}}%</div>
                            </td>
                            <!--<td style="text-align: center;">
                                <div class="btn-kpi kpi-yellow">50%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi kpi-orange">80%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi kpi-red">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>
                            <td style="text-align: center;">
                                <div class="btn-kpi">0%</div>
                            </td>-->
                        </tr>
                        <tr>
                            <!--<td style="text-align: center;" colspan="13">
                                <div class="tabla">
                                    <div class="tabla-kpi">
                                        <table id="kpi-details" class="display" style="width:100%">
                                            <caption>Mejora continua</caption>
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Actividad</th>
                                                <th scope="col">Categoría</th>
                                                <th scope="col">Sub-categoría</th>
                                                <th scope="col">Sitio</th>
                                                <th scope="col">Fecha de vencimiento</th>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center;">ID5234</td>
                                                <td style="text-align: center;">
                                                    <div class="btn-kpi kpi-green">Cumple</div>
                                                </td>
                                                <td style="text-align: center;">Auditoría</td>
                                                <td style="text-align: center;">Mejora Continua</td>
                                                <td style="text-align: center;">Nombre</td>
                                                <td style="text-align: center;">Caserones</td>
                                                <td style="text-align: center;">22/10/2022</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center;">ID5233</td>
                                                <td style="text-align: center;">
                                                    <div class="btn-kpi kpi-green">Cumple</div>
                                                </td>
                                                <td style="text-align: center;">Auditoría</td>
                                                <td style="text-align: center;">Mejora Continua</td>
                                                <td style="text-align: center;">Nombre</td>
                                                <td style="text-align: center;">Caserones</td>
                                                <td style="text-align: center;">22/10/2022</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </td>-->
                        </tr>
                    </tbody>
                    <tfoot style="display: none;">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Responsable</th>
                            <th scope="col">Subárea Responsable</th>
                            <th scope="col">Fecha vencimiento</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <!--Section: Live preview-->
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>