import { BaseResponseDTO } from "../base.response.dto";

export class GetPorcentajesHomeReclamosResponseDTO extends BaseResponseDTO {
  success: boolean;
  msg: string;
  data: {
    cerrados: number;
    porResponder: number;
    respondidos: number;
    totalReclamos: number;
  };
}