<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar tipo de documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="editarFormTipoDocumento" *ngIf="editarFormTipoDocumento">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" formControlName="nombre" class="inp-modal" placeholder="Nombre" required
                        [ngClass]="{'border-error': editarFormTipoDocumento.controls['nombre'].touched && !editarFormTipoDocumento.controls['nombre'].valid}" />
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Días previos</label></div>
                <div class="inp">
                    <input type="number" formControlName="diasPrevios" class="inp-modal" placeholder="Días previos"
                        required
                        [ngClass]="{'border-error': editarFormTipoDocumento.controls['diasPrevios'].touched && !editarFormTipoDocumento.controls['diasPrevios'].valid}" />
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Periodo de vigencia</label></div>
                <div class="inp" *ngIf="!loaderPeriodoVigencia">
                    <ng-select class="select" formControlName="periodoVigencia" required placeholder="Seleccione"
                        [ngClass]="{'border-error': editarFormTipoDocumento.controls['periodoVigencia'].touched && !editarFormTipoDocumento.controls['periodoVigencia'].valid}">
                        <ng-option *ngFor="let vigencia of listaPeriodoVigencia" [value]="vigencia.uuid">
                            {{vigencia.periodoVigencia}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderPeriodoVigencia">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Tipos de documentos vinculados</label></div>
                <div class="inp" *ngIf="!loaderTipoDocumentos">
                    <ng-select class="select" formControlName="tiposDocumentos" placeholder="Seleccione"
                        [multiple]="true">
                        <ng-option *ngFor="let tipo of listaTipoDocumentos" [value]="tipo.uuid">
                            {{tipo.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderTipoDocumentos">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="editarTipo()"
                [disabled]="this.editarFormTipoDocumento.invalid">Editar tipo documento</button>
        </div>
    </form>
</div>