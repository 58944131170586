import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { MacroprocesoDTO } from 'src/dtos/mantenedor/macroproceso.dto';
import { GetListadoAreasPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreasPaginados.response.dto';
import { GetListadoMacroprocesosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoMacroprocesosPaginados.response.dto';
import { GetListadoProcesosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoProcesosPaginados.response.dto';
import { GetListadoSitiosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitiosPaginados.response.dto';
import { GetListadoSubareaPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubareasPaginados.response.dto';
import { ModalJCrearComponent } from './modals/modal-j-crear/modal-j-crear.component';
import { ModalJDeshabilitarComponent } from './modals/modal-j-deshabilitar/modal-j-deshabilitar.component';
import { ModalJEditarComponent } from './modals/modal-j-editar/modal-j-editar.component';
import { ModalJEliminarComponent } from './modals/modal-j-eliminar/modal-j-eliminar.component';
import { ModalJHabilitarComponent } from './modals/modal-j-habilitar/modal-j-habilitar.component';
@Component({
  selector: 'app-mantenedor-jerarquizacion',
  templateUrl: './mantenedor-jerarquizacion.component.html',
  styleUrls: ['./mantenedor-jerarquizacion.component.scss']
})
export class MantenedorJerarquizacionComponent implements OnInit {
  listaJerarquizacion = [
    { title: 'Macroproceso', name: 'macroprocesos', id: 'tab-macroproceso', totalRegister: 0 },
    { title: 'Proceso', name: 'procesos', id: 'tab-proceso', totalRegister: 0 },
    { title: 'Área', name: 'area', id: 'tab-area', totalRegister: 0 },
    { title: 'Subárea', name: 'subArea', id: 'tab-sub-area', totalRegister: 0 },
    { title: 'Sitio', name: 'sitio', id: 'tab-sitio', totalRegister: 0 },
  ];

  listadoPagMacroprocesos: MacroprocesoDTO[] = [];
  listadoAuxPaginado: any[] = [];

  totalRegister: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  indexSelect: number = 0;

  busqueda = new FormControl(null);

  loaderPaginado: boolean = false;

  formCtrlSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.selectTab(0);

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.currentPage = 1;
        this.obtenerListados(this.indexSelect);
      }
    });
  }

  selectTab(index: number) {
    this.currentPage = 1;
    this.busqueda.setValue(null);
    this.indexSelect = index;
    this.totalRegister = 0;
    this.listadoAuxPaginado = [];

    this.obtenerListados(index);
    this.cdr.detectChanges();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.obtenerListados(this.indexSelect);
  }

  obtenerListados(index: number) {
    switch (index) {
      case 0:
        this.getListadoMacroprocesosPaginado();
        break;
      case 1:
        this.getListadoProcesosPaginado();
        break;
      case 2:
        this.getListadoAreasPaginado();
        break;
      case 3:
        this.getListadoSubAreasPaginado();
        break;
      case 4:
        this.getListadoSitiosPaginado();
        break;
    }
  }

  getListadoMacroprocesosPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoMacroprocesosPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response: GetListadoMacroprocesosPaginadosResponseDTO) => {
        this.listadoPagMacroprocesos = response.data.content;
        this.totalRegister = this.listaJerarquizacion[0].totalRegister = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  getListadoProcesosPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoProcesosPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response: GetListadoProcesosPaginadosResponseDTO) => {
        this.listadoAuxPaginado = response.data.content;
        this.listadoAuxPaginado.forEach((proceso) => {
          const macro = proceso.macroprocesos;
          proceso.macroprocesos = '';
          proceso.macroprocesos += macro.nombre;
        });

        this.totalRegister = this.listaJerarquizacion[1].totalRegister = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  getListadoAreasPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoAreasPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response: GetListadoAreasPaginadosResponseDTO) => {
        this.listadoAuxPaginado = response.data.content;
        this.listadoAuxPaginado.forEach((area) => {
          const procesos = area.procesos;
          area.procesos = '';
          procesos.forEach((proceso, index) => {
            area.procesos += proceso.nombre;
            if ((index + 1) < procesos.length) {
              area.procesos += ', ';
            }
          });
          const dimensiones = area.dimensiones;
          area.dimensiones = '';
          dimensiones.forEach((dimension, index) => {
            area.dimensiones += dimension.nombre;
            if ((index + 1) < dimensiones.length) {
              area.dimensiones += ', ';
            }
          });
        });
        this.totalRegister = this.listaJerarquizacion[2].totalRegister = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  getListadoSubAreasPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoSubAreasPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response: GetListadoSubareaPaginadosResponseDTO) => {
        this.listadoAuxPaginado = response.data.content;
        this.listadoAuxPaginado.forEach((subarea) => {
          subarea.area = '';
          subarea.dimensiones = '';
          subarea.dimensionesPorArea.forEach((elementArea, index) => {
            subarea.area += elementArea.nombre;
            if ((index + 1) < subarea.dimensionesPorArea.length) {
              subarea.area += ', ';
            }
            for (let j = 0; j < elementArea.dimensiones.length; j++) {
              if (!subarea.dimensiones.includes(elementArea.dimensiones[j].nombre)) {
                subarea.dimensiones += elementArea.dimensiones[j].nombre + ', ';
              }
              if ((index + 1) === subarea.dimensionesPorArea.length && (j + 1) === elementArea.dimensiones.length) {
                subarea.dimensiones = subarea.dimensiones.substring(0, subarea.dimensiones.length - 2);
              }
            }
          });
        });
        this.totalRegister = this.listaJerarquizacion[3].totalRegister = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUBAREAS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  getListadoSitiosPaginado() {
    this.loaderPaginado = true;
    this.apiServiceMantenedor.getListadoSitiosPaginadoMantenedor(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response: GetListadoSitiosPaginadosResponseDTO) => {
        this.listadoAuxPaginado = response.data.content;
        this.listadoAuxPaginado.forEach((sitio) => {
          const subarea = sitio.subArea;
          sitio.subArea = '';
          sitio.subArea += subarea.nombre;
        });
        this.totalRegister = this.listaJerarquizacion[4].totalRegister = response.data.totalElements;
        this.loaderPaginado = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  openModalJCrear(nombre: string) {
    const modalCrear: BsModalRef = this.modalService.show(ModalJCrearComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        titleModal: nombre
      }
    });
    this.cerrarModalCargaData(modalCrear);
  }

  openModalJEditar(nombre: string, uuid: string) {
    const modalEditar: BsModalRef = this.modalService.show(ModalJEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        titleModal: nombre,
        uuid
      }
    });
    this.cerrarModalCargaData(modalEditar);
  }

  openModalJHabilitar(titleModal: string, nombreTipo: string, uuid: string) {
    const modalHabilitar: BsModalRef = this.modalService.show(ModalJHabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        titleModal,
        nombreTipo,
        uuid
      }
    });
    this.cerrarModalCargaData(modalHabilitar);
  }

  openModalJDeshabilitar(titleModal: string, nombreTipo: string, uuid: string, cantidadAsociada: number = 0) {
    const modalDeshabilitar: BsModalRef = this.modalService.show(ModalJDeshabilitarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        titleModal,
        nombreTipo,
        uuid,
        cantidadAsociada
      }
    });
    this.cerrarModalCargaData(modalDeshabilitar);
  }

  openModalJEliminar(titleModal: string, nombreTipo: string, uuid: string) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalJEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        titleModal,
        nombreTipo,
        uuid
      }
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.selectTab(parseInt(myData, 10));
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}
