<div class="container-fluid">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-thumbs-up ico-page" aria-hidden="true"></i>
            <span class="title1">Gestión de actividades</span>
        </div>
    </div>
    <div class="section-page">
        <div class="subtitle">Editar plan de acción</div>
    </div>
    <form [formGroup]="editarPlanForm" *ngIf="editarPlanForm && !loader">
        <div class="section-page">
            <div class="section-data">
                <div class="row1">
                    <div class="column3">
                        <div class="lab"><label for="name">Acción</label></div>
                        <div class="inp">
                            <input type="text" name="gestion-plan-accion" id="gestion-plan-accion" class="inp-modal"
                                placeholder="{{detallePlanDeAccion.nombre}}" disabled />
                        </div>
                    </div>
                    <div class="column3">
                        <div class="lab"><label for="name">Responsable</label></div>
                        <div class="inp">
                            <input type="text" name="gestion-plan-accion" id="gestion-plan-accion" class="inp-modal"
                                placeholder="{{detallePlanDeAccion.usuarioResponsable.nombre}} {{detallePlanDeAccion.usuarioResponsable.apellido}}"
                                disabled />
                        </div>
                    </div>
                    <div class="column4">
                        <div class="lab"><label for="name">Adjuntar archivos</label></div>
                        <div class="inp overMouse">
                            <label for="reclamos-adjuntararchivo" class="overFileLabel">Subir archivo</label>
                            <input type="file" name="documental-adjuntararchivo" id="documental-adjuntararchivo"
                                class="inp-modal" placeholder="Adjunte archivo" (change)="addAdjunto($event)" />
                            <i class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                        </div>
                        <div class="column-consult-7 vertical-scroll" *ngIf="getAdjuntos().length > 0">
                            <div class="lab-consult">Archivos adjuntos</div>
    
                            <div class="back-img" ngxViewer *ngFor="let adjunto of getAdjuntos(); let i = index">
                                <div class="img-show"> <img [src]="adjunto.value.url" width="100%" alt="foto"></div>
                                <div class="back-img-line">
                                    <i class="fas fa-times-circle red delete-archive" (click)="removeAdjunto(i)"
                                        aria-hidden="true"></i>
                                    <a href="#" class="minimage-link">
                                        <div class="ico-doc"><i class="fas fa-file-pdf" aria-hidden="true"></i></div>
                                        {{adjunto.value.name}}
                                    </a>
                                </div>
                            </div>
                            <div class="archive-back-delete"> <a href="#" class="link-archive-delete"><i class="fas fa-undo"
                                        aria-hidden="true"></i> Deshacer eliminar archivo "nombre archivo"</a></div>
                        </div>
                       
                    </div>
                    <div class="column5">
                        <div class="lab"><label for="name">Comentarios</label></div>
                        <div class="inp"><textarea formControlName="comentarios" name="textarea" rows="5" cols="20"
                                placeholder="Escriba un comentario..."></textarea></div>
                    </div>
                </div>
                
            </div>
            <div class="section1">
                <button type="button" class="button-black two-buttons" (click)="goToMenuGestion()">Cancelar</button>
                <button type="button" class="btn-two two-buttons" [disabled]="!editarPlanForm.valid ||  btnHabilitado"
                    (click)="editarPlan()">Editar plan</button>
            </div>
        </div>
    </form>
</div>

<div class="full-height">&nbsp;</div>