<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Crear {{titleModal}}</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="crearForm" *ngIf="crearForm">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" formControlName="nombre" class="inp-modal" placeholder="Nombre" required
                        [ngClass]="{'border-error': crearForm.controls['nombre'].touched && !crearForm.controls['nombre'].valid}" />
                </div>
            </div>
            <!-- Campo Crear Proceso  -->
            <div class="field-half" *ngIf="titleModal === 'Proceso'">
                <div class="lab"><label for="name">Asociar a Macroproceso</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="macroprocesoAsociado" required placeholder="Seleccione"
                        [ngClass]="{'border-error': crearForm.controls['macroprocesoAsociado'].touched && !crearForm.controls['macroprocesoAsociado'].valid}">
                        <ng-option *ngFor="let macro of listaSimpleMacroprocesos" [value]="macro.uuid">
                            {{macro.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <!--Campos Crear Área -->
            <div class="field-half" *ngIf="titleModal === 'Área'">
                <div class="lab"><label for="name">Asociar a Dimensiones</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="dimensionAsociadas" required placeholder="Seleccione"
                        [multiple]="true"
                        [ngClass]="{'border-error': crearForm.controls['dimensionAsociadas'].touched && !crearForm.controls['dimensionAsociadas'].valid}">
                        <ng-option *ngFor="let dimension of listaDimensiones" [value]="dimension.uuid">
                            {{dimension.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-half" *ngIf="titleModal === 'Área'">
                <div class="lab"><label for="name">Asociar a Procesos</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="procesoAsociado" required placeholder="Seleccione"
                        [multiple]="true"
                        [ngClass]="{'border-error': crearForm.controls['procesoAsociado'].touched && !crearForm.controls['procesoAsociado'].valid}">
                        <ng-option *ngFor="let proceso of listaSimpleProcesos" [value]="proceso.uuid">
                            {{proceso.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <!--Campos Crear Subárea  -->
            <div class="field-half" *ngIf="titleModal === 'Subárea'">
                <div class="lab"><label for="name">Asociar a Áreas</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="areaAsociada" required placeholder="Seleccione"
                        [multiple]="true" (change)="selectArea()"
                        [ngClass]="{'border-error': crearForm.controls['areaAsociada'].touched && !crearForm.controls['areaAsociada'].valid}">
                        <ng-option *ngFor="let area of listaSimpleArea" [value]="area.uuid">
                            {{area.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="subtitle-createsubarea" *ngIf="titleModal === 'Subárea'">
                definir dimensiones por área seleccionada
            </div>
            <div class="table" style="padding-left: 0 !important;  margin-top: 5px;" *ngIf="titleModal === 'Subárea'">
                <div class="table-responsive">
                    <table class="display" style="width:100%;">
                        <caption>Crear: Dimensiones por área seleccionada</caption>
                        <thead style="background-color: #6979D4; color: #fff; font-size: 12px;">
                            <tr>
                                <th scope="col" style="width: 40%;">&nbsp;</th>
                                <th scope="col" style="width: 20%;" *ngFor="let dimension of listaDimensiones">
                                    {{dimension.nombre}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let area of listaSimpleArea; let i = index;">
                                <td>{{area.nombre}}</td>
                                <td style="text-align: center;"
                                    *ngFor="let check of dimensionesPorArea[i].dimensiones; let j = index;">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="checkbox+{{i}}{{j}}"
                                            (click)="checkDimension(i,j)" [disabled]="check.disabled"
                                            [checked]="check.value">
                                        <label class="custom-control-label" for="checkbox+{{i}}{{j}}"></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Campos Crear Sitio -->
            <div class="field-half" *ngIf="titleModal === 'Sitio'">
                <div class="lab"><label for="name">Asociar a Subárea</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="subareaAsociada" required placeholder="Seleccione"
                        [ngClass]="{'border-error': crearForm.controls['subareaAsociada'].touched && !crearForm.controls['subareaAsociada'].valid}">
                        <ng-option *ngFor="let subarea of listaSimpleSubarea" [value]="subarea.uuid">
                            {{subarea.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" [disabled]="!validarForm()"
                (click)="crearRegistro()">Crear</button>
        </div>
    </form>
</div>