export class FrecuenciaRequestDTO {
  diasDuracion: number;
  diasEnElMes?: number[];
  fechaInicioRango?: string;
  fechaTerminoRango?: string;
  intervaloRepeticionCadaXDias: number;
  intervaloRepeticionCadaXSemanas: number;
  isTodaLaSemana: boolean;
  isTodoElMes: boolean;
  meses: number[];
  repetirEnDias: number[];
  semanasEnElMes: string[];
  uuidFrecuencia: string;
  //uuidRangoActividad: string;
}