

<div class="container-fluid">
    <div class="loader-container" *ngIf="loader">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-thumbs-up ico-page" aria-hidden="true"></i><span class="title1">Gestión de
                Actividades</span>
        </div>
    </div>
    <div class="section-page" *ngIf="!loader">
        <div class="container-consult">
            <div class="subtitle">Consultar Actividad</div>
            <div class="buttons" *ngIf="!loader">
                <button *ngIf="uuidUser === actividad.usuario?.uuid 
                        && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                        actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'" type="button" class="button-actions"
                    placement="top" (click)="openModalGestionEditar(actividad)"><i aria-hidden="true"
                        class="fas fa-pen ico-button-actions"></i> Editar</button>
                <button *ngIf="uuidUser === actividad.usuario?.uuid && 
                actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'" type="button" class="button-actions"
                    placement="top" (click)="openModalGestionTerminada(actividad)"><i aria-hidden="true"
                        class="fas fa-power-off ico-button-actions"></i> Validar actividad</button>
                <a>
                    <button
                        *ngIf="uuidAreaUser === actividad.asignacion?.area?.uuid && actividad.esperaPlanAccion === true
                        && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                        actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'"
                        (click)="goToCrearPlanDeAccion()" type="button" class="button-actions"
                        tooltip="Crear Plan de Acción">
                        <i aria-hidden="true" class="far fa-calendar ico-button-actions"></i>Crear Plan de Acción</button></a>
                <button type="button" *ngIf="uuidAreaUser === actividad.asignacion?.area?.uuid
                        && actividad.estadoActividad.nombre.toLowerCase() !== 'cumple' && 
                        actividad.estadoActividad.nombre.toLowerCase() !== 'no cumple'"
                    class="button-actions show-mobile" placement="top" (click)="openModalGestionAdjuntar(actividad)"><i aria-hidden="true"
                        class="fas fa-paperclip ico-button-actions"></i> Adjuntar
                    evidencia</button>
                <button type="button" class="button-actions"
                    placement="top" (click)="openModalGestionComentarios(actividad)"><i class="fas fa-comment ico-button-actions"
                        aria-hidden="true"></i> Comentarios</button>
                <button type="button" class="button-actions" placement="top" (click)="openModalGestionHistorial(actividad)"><i
                        class="fas fa-history ico-button-actions" aria-hidden="true"></i> Historial</button>
                <button *ngIf="uuidUser === actividad.usuario?.uuid" type="button" class="button-actions-blue"
                    placement="top" (click)="openModalGestionEliminar(actividad)"><i
                        class="fas fa-trash-alt ico-button-actions" aria-hidden="true"></i> Eliminar</button>
            </div>
        </div>
        <div *ngIf="!loader" class="section-data">
            <div class="create-person"> Actividad: <strong>{{actividad.nombre}}</strong></div>

            <div class="infoConsultarActividad">
                <div class="estadoActividad fueraPlazo" *ngIf="actividad.estadoActividad.nombre === 'Fuera de plazo'">
                    <div class="details-text-estado"> Fuera de plazo</div>
                </div>
                <div class="estadoActividad cumple" *ngIf="actividad.estadoActividad.nombre === 'Cumple'">
                    <div class="details-text-estado"> Cumple</div>
                </div>
                <div class="estadoActividad conFaltantes" *ngIf="actividad.estadoActividad.nombre === 'Con faltantes'">
                    <div class="details-text-estado"> Con faltantes</div>
                </div>
                <div class="estadoActividad noCumple" *ngIf="actividad.estadoActividad.nombre === 'No cumple'">
                    <div class="details-text-estado"> No cumple</div>
                </div>
                <div class="estadoActividad" *ngIf="actividad.estadoActividad.nombre === 'En curso'">
                    <i class="far fa-check-square ga-icon-blue" aria-hidden="true"></i>
                    <div class="details-text-estado"> En curso</div>
                </div>
                <div class="estadoActividad" *ngIf="actividad.estadoActividad.nombre === 'Futuras'">
                    <i class="fas fa-calendar ga-icon-grey" aria-hidden="true"></i>
                    <div class="details-text-estado"> Futuras</div>
                </div>
                <div class="estadoActividad" *ngIf="actividad.estadoActividad.nombre === 'Revisar'">
                    <i class="fas fa-clipboard-check ga-icon-red" aria-hidden="true"></i>
                    <div class="details-text-estado"> Revisar</div>
                </div>

                <div class="reclamos-estado">ID: {{actividad.id}} | {{actividad.nombre}}</div>
            </div>


            <div class="row-consult-container-principal">
                <div class="row-consult-container">
                    <div class="row-consult">
                        <div class="column-consult-7">
                            <div class="lab-consult">Categoría</div>
                            <div class="input-consult">{{actividad.categoria.nombre}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Sub-categoría 1</div>
                            <div class="input-consult">{{actividad.subCategoriaUno.nombre}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Sub-categoría 2</div>
                            <div class="input-consult">{{actividad.subCategoriaDos.nombre}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Asignado por:</div>
                            <div class="input-consult">{{actividad.usuario.nombre}} {{actividad.usuario.apellido}}</div>
                        </div>
                    </div>
                    <div class="row-consult">
                        <div class="column-consult-7">
                            <div class="lab-consult">Frecuencia</div>
                            <div class="input-consult">{{actividad.frecuencia.nombre}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Fecha de inicio act.</div>
                            <div class="input-consult">{{actividad.fechaInicio | date: 'dd/MM/yyyy'}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Duración actividad</div>
                            <div class="input-consult">
                                {{actividad.fechaInicioDuracion | date: 'dd/MM/yyyy'}} -
                                {{actividad.fechaTerminoDuracion | date: 'dd/MM/yyyy'}}</div>
                        </div>
                        <div class="column-consult-7">
                            <div class="lab-consult">Fecha de vencimiento</div>
                            <div class="input-consult">{{actividad.fechaVencimiento | date: 'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                </div>
                <div class="column-consult-6 vertical-scroll">
                    <div class="lab-consult">Asignados</div>
                    <div class="input-consult">{{actividad.asignacion?.sitio?.nombre}}</div>
                </div>
                <div class="column-consult-6">
                    <div class="sendActividad">
                        <div class="lab-consult">Enviar Actividad</div>
                        <label class="switch">
                            <input [checked]="switchEnviar" (click)="enviarActividad()" [disabled]="switchEnviar" type="checkbox">
                            <span class="slider round">
                                <span class="on">SÍ</span>
                                <span class="off">NO</span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="evidencia.length > 0">
            <div class="section-data">
                <div class="subtitle-a1">Evidencia Actividad</div>
                <div class="row-consult-container-principal">
                    <div class="row-consult-container">
                        <div class="row-consult">
                            <div class="column-consult-7">
                                <div class="lab-consult">Adjuntos</div>
                                <div class="input-consult vertical-scroll">
                                    <div *ngFor="let ev of evidencia">
                                        <div class="back-img" *ngFor="let adjunto of ev.archivos">
                                            <div>
                                                <img *ngIf="adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg')"
                                                    [src]="adjunto?.url" ngxViewer width="145px" height="100px" alt="Archivo adjunto">
                                                <img *ngIf="!(adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg'))"
                                                    [src]="obtenerUrl(adjunto)" width="145px" height="145px"
                                                    alt="Archivo adjunto">
                                            </div>
                                            <div class="back-img-line">
                                                <i *ngIf="!adjunto.nombreArchivo.includes('.pdf')" aria-hidden="true"
                                                    (click)="getDescargarAdjunto(adjunto?.url)"
                                                    class="fas fa-download blue download-archive"></i>
                                                <div class="ico-doc" *ngIf="adjunto.nombreArchivo.includes('.pdf')">
                                                    <i class="fas fa-file-pdf" (click)="getDescargarAdjunto(adjunto?.url)"
                                                        aria-hidden="true"></i>
                                                </div>
                                                <div class="input-consult">{{adjunto.nombreArchivo}}</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="column-consult-8">
                                <div class="lab-consult">Comentarios</div>
                                <div *ngFor="let ev of evidencia" class="input-consult">
                                    <ng-container *ngIf="ev.comentarios !== ''">
                                    - {{ev.comentarios}}
                                  </ng-container></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngFor="let plan of actividad.planesDeAccion">
            <div class="buttons">
                
            </div>
            <div class="section-data">
                <div class="subtitle-a1">Plan de acción</div>
                <a>
                    <button style="margin-left: 20px;" (click)="goToEditarPlanDeAccion(plan.uuid)" type="button" class="button-actions"
                        tooltip="Editar Plan de Acción">
                        <i class="fas fa-pen ico-button-actions" aria-hidden="true"></i>Editar Plan de
                        Acción
                    </button>
                </a>
                        
                    <button type="button"
                        class="button-actions show-mobile" placement="top" (click)="openModalPlanAdjuntar(plan)"><i aria-hidden="true"
                        class="fas fa-paperclip ico-button-actions"></i> Adjuntar evidencia
                    </button>
                <div class="row-consult-container-principal">
                    <div class="row-consult-container">
                        <div class="row-consult">
                            <div class="column-consult-7">
                                <div class="lab-consult">Acción</div>
                                <div class="input-consult">{{plan.nombre}}</div>
                            </div>
                            <div class="column-consult-7">
                                <div class="lab-consult">Responsable</div>
                                <div class="input-consult">{{plan.usuarioResponsable.nombre}}
                                    {{plan.usuarioResponsable.apellido}}</div>
                            </div>
                            <div class="column-consult-7">
                                <div class="lab-consult">Fecha término</div>
                                <div class="input-consult">{{plan.fechaTermino | date: 'dd/MM/yyyy'}}</div>
                            </div>
                            <div class="column-consult-7 vertical-scroll">
                                <div class="lab-consult">Archivos adjuntos</div>
                                <div class="input-consult">
                                    
                                        <div class="back-img" ngxViewer *ngFor="let adjunto of plan.adjuntos">
                                            <div>
                                                <img *ngIf="adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg')"
                                                    [src]="adjunto?.url" ngxViewer width="145px" height="100px" alt="Archivo adjunto">
                                                <img *ngIf="!(adjunto.nombreArchivo.includes('.png') || adjunto.nombreArchivo.includes('.jpg') || adjunto.nombreArchivo.includes('.jpeg'))"
                                                    [src]="obtenerUrl(adjunto)" width="145px" height="145px"
                                                    alt="Archivo adjunto">
                                            </div>
                                            <div class="back-img-line">
                                                <i *ngIf="!adjunto.nombreArchivo.includes('.pdf')" aria-hidden="true"
                                                    (click)="getDescargarAdjunto(adjunto?.url)"
                                                    class="fas fa-download blue download-archive"></i>
                                                <div class="ico-doc" *ngIf="adjunto.nombreArchivo.includes('.pdf')">
                                                    <i class="fas fa-file-pdf" (click)="getDescargarAdjunto(adjunto?.url)"
                                                        aria-hidden="true"></i>
                                                </div>
                                                <div class="input-consult">{{adjunto.nombreArchivo}}</div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="column-consult-6 vertical-scroll">
                        <div class="lab-consult">Comentarios</div>
                        <div class="input-consult">{{plan.comentarios}} </div>
                    </div>
                    <div class="column-consult-6">
                        <label class="customCheckContainer">
                            <input type="checkbox" data-toggle="toggle" data-on="Si" data-off="No"
                                data-onstyle="success" data-offstyle="danger" [checked]="plan.realizado"
                                 (click)="realizarPlanDeAccion(plan.uuid)" [disabled]="plan.realizado">
                            Acción realizada
                            <span class="checkmark"></span>
                        </label>

                    </div>
                </div>
            </div>
            
        </div>
        <div class="section-data" *ngIf="actividad.usuarioTerminoActividad">
            <div class="subtitle-a1">Actividad Terminada</div>
            <div class="row-consult-container-principal">
                <div class="row-consult-container">
                    <div class="row-consult">
                        <div class="column-consult-9">
                            <div class="lab-consult">{{actividad.usuarioTerminoActividad.nombre + ' ' + actividad.usuarioTerminoActividad.apellido}}</div>
                            <div class="input-consult">{{actividad.usuarioTerminoActividad.email}}</div>
                            <div class="input-consult">{{ actividad.fechaTerminoActividad | date: 'HH:mm' }} | {{ actividad.fechaTerminoActividad | date: 'dd/MM/yyyy' }}</div>
                        </div>

                        <div class="column-consult-7">
                            <div class="lab-consult">Cumplimiento</div>
                            <div *ngIf="actividad.estadoActividad.nombre.toLowerCase() === 'cumple'" class="input-consult">Si hubo cumplimiento</div>
                            <div *ngIf="actividad.estadoActividad.nombre.toLowerCase() === 'no cumple'" class="input-consult">No hubo cumplimiento</div>
                        </div>

                        <div class="column-consult-7">
                            <div class="lab-consult">Detalle</div>
                            <div class="input-consult">{{actividad.detalleTerminoActividad}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section1"><button (click)="regresar()" type="button" class="btn-primary">Regresar</button></div>
    </div>
</div>

 <div class="full-height">&nbsp;</div> 