import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FotoImplementacionDTO } from 'src/dtos/gestion/fotoImplementacion.dto';

@Component({
  selector: 'app-modal-mc-verarchivos',
  templateUrl: './modal-mc-verarchivos.component.html',
  styleUrls: ['./modal-mc-verarchivos.component.scss']
})
export class ModalMcVerarchivosComponent { 

  detalleImplementacion: FotoImplementacionDTO;
  imagenSeleccionada: any;

  constructor(
    public bsModalRef: BsModalRef
  ) { }
  
  ngOnInit() {
    this.imagenSeleccionada = this.detalleImplementacion.adjunto[0].url;
  }

  seleccionarImagen(url: string) {
    this.imagenSeleccionada = url;
  }

}
