import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { TipoDocumentoDTO } from 'src/dtos/mantenedor/tipoDocumento.dto';
import { VigenciaDTO } from 'src/dtos/mantenedor/vigencia.dto';
import { PostCrearTipoDocumentoRequestDTO } from 'src/dtos/request/mantenedor/postCrearTipoDocumento.request.dto';
import { GetListadoTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/getListadoTipoDocumento.response.dto';
import { GetListadoVigenciasResponseDTO } from 'src/dtos/response/mantenedor/getListadoVigencias.response.dto';
import { PostCrearTipoDocumentoResponseDTO } from 'src/dtos/response/mantenedor/postCrearTipoDocumento.response.dto';

@Component({
  selector: 'app-modal-td-crear',
  templateUrl: './modal-td-crear.component.html',
  styleUrls: ['./modal-td-crear.component.scss']
})

export class ModalTdCrearComponent {
  crearFormTipoDocumento: FormGroup;

  listaPeriodoVigencia: VigenciaDTO[] = [];
  listaTipoDocumentos: TipoDocumentoDTO[] = [];

  onClose: Subject<string>;

  constructor(
    private apiServiceMantenedor: ApiMantenedorService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.getListadoTipoDocumentoMantenedor();
    this.getListadoVigencias();
    this.crearFormTipoDocumento = this.formBuilder.group({
      nombre: new FormControl(null, [Validators.required]),
      periodoVigencia: new FormControl(null, [Validators.required]),
      diasPrevios: new FormControl(null, [Validators.required]),
      tiposDocumentos: new FormControl(null)
    });
  }

  crearTipo() {
    const data: PostCrearTipoDocumentoRequestDTO = {
      nombre: this.crearFormTipoDocumento.value.nombre,
      diasPrevios: this.crearFormTipoDocumento.value.diasPrevios,
      tipoDocumentoVinculados: this.crearFormTipoDocumento.value.tiposDocumentos,
      uuidVigencia: this.crearFormTipoDocumento.value.periodoVigencia
    };
    this.apiServiceMantenedor.postCrearTipoDocumentoMantenedor(data).subscribe(
      (response: PostCrearTipoDocumentoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_CREAR_TIPO_DOCUMENTO);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }

  getListadoTipoDocumentoMantenedor() {
    this.apiServiceMantenedor.getListadoTipoDocumentoMantenedor().subscribe(
      (response: GetListadoTipoDocumentoResponseDTO) => {
        this.listaTipoDocumentos = response.data.filter(x => x.habilitado);
      },
      (errorTipoDocumento: HttpErrorResponse) => {
        const msg = errorTipoDocumento.error && errorTipoDocumento.error.msg ? errorTipoDocumento.error.msg : AppMessage.ERROR_LISTAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }

  getListadoVigencias() {
    this.apiServiceMantenedor.getListadoVigencias().subscribe(
      (responseVigencia: GetListadoVigenciasResponseDTO) => {
        this.listaPeriodoVigencia = responseVigencia.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_TIPO_DOCUMENTO;
        this.toastr.error(msg);
      }
    );
  }
}
