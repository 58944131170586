import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalGestionEditarComponent } from '../modal-gestion-editar/modal-gestion-editar.component';
import { ModalGestionHistorialComponent } from '../modal-gestion-historial/modal-gestion-historial.component';
import { ModalGestionTerminadaComponent } from '../modal-gestion-terminada/modal-gestion-terminada.component';
import { ModalGestionAdjuntarComponent } from '../modal-gestion-adjuntar/modal-gestion-adjuntar.component';
import { ModalGestionComentariosComponent } from '../modal-gestion-comentarios/modal-gestion-comentarios.component';
import { ModalGestionEliminarComponent } from '../modal-gestion-eliminar/modal-gestion-eliminar.component';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { GetDetalleActividadResponseDTO } from 'src/dtos/response/gestion/getDetalleActividad.response.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GetPlanesDeAccionResponseDTO } from 'src/dtos/response/gestion/getPlanesDeAccion.response.dto';
import { PlanDeAccionDTO } from 'src/dtos/gestion/planDeAccion.dto';
import { ModalPlanAdjuntarComponent } from '../modal-plan-adjuntar/modal-plan-adjuntar.component';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetEvidenciaActividadResponseDTO } from 'src/dtos/response/gestion/getEvidenciaActividad.response.dto';
import { EvidenciaActividadDTO } from 'src/dtos/gestion/evidenciaActividad.dto';
import { AdjuntoDTO } from 'src/dtos/mantenedor/adjunto.dto';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gestion-consultar',
  templateUrl: './gestion-consultar.component.html',
  styleUrls: ['./gestion-consultar.component.scss']
})
export class GestionConsultarComponent {

  uuidActividad: string;
  actividad: ActividadDTO;
  loader: boolean;
  uuidUser: string;
  uuidAreaUser: string;
  switchEnviar: boolean = false;
  evidencia: Array<EvidenciaActividadDTO>;

  constructor( 
    private modalService: BsModalService,
    private actividadService: ActividadService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private router: Router,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location
  ) { }
  

  ngOnInit() {
    const user = this.userService.getUser();
    this.uuidUser = user.uuid;
    if (user.preferencias) {
      this.uuidAreaUser = user.preferencias.area.uuid;
    }

    this.route.queryParams.subscribe((params) => {
      this.uuidActividad = params.uuidActividad;
      if (this.uuidActividad) {
        this.getDetalleActividad();
      }
    });

  }

  getDetalleActividad() {
    this.loader = true;

    this.apiGestion.getDetalleActividad(this.uuidActividad).subscribe((resp) => this.getDetalleActividadSuccess(resp),
      (error) => this.getDetalleActividadError(error));
  }

  getDetalleActividadSuccess(resp: GetDetalleActividadResponseDTO) {
    this.actividad = resp.data;
    this.getPlanesDeAccion();
    this.estadoSwitch();
  }

  getDetalleActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_ACTIVIDAD;
    this.toastr.error(msg);
    this.loader = false;
    this.router.navigate(['web/gestion-actividades']);

  }

  regresar() {
    this.location.back();
  }

  openModalGestionEditar(actividad: ActividadDTO) {
    const modalEditar: BsModalRef = this.modalService.show(ModalGestionEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });
    this.cerrarModalCargaData(modalEditar);
  }

  openModalGestionHistorial(actividad: ActividadDTO) {
    this.modalService.show(ModalGestionHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuidActividad: actividad.uuid,
      }
    });
  }

  openModalGestionTerminada(actividad: ActividadDTO) {
    const modalTerminada: BsModalRef = this.modalService.show(ModalGestionTerminadaComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });

    this.cerrarModalCargaData(modalTerminada);
  }

  openModalGestionAdjuntar(actividad: ActividadDTO) {
    const modalAdjuntar: BsModalRef = this.modalService.show(ModalGestionAdjuntarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuidActividad: actividad.uuid,
      }
    });

    this.cerrarModalCargaData(modalAdjuntar);
  }

  openModalPlanAdjuntar(plan: PlanDeAccionDTO) {
    const modalAdjuntarPlan: BsModalRef = this.modalService.show(ModalPlanAdjuntarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuidPlan: plan.uuid
      }
    });
    this.cerrarModalCargaData(modalAdjuntarPlan);
  }

  openModalGestionComentarios(actividad: ActividadDTO) {
    this.modalService.show(ModalGestionComentariosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        actividad: actividad
      }
    });
  }

  openModalGestionEliminar(actividad: ActividadDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalGestionEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: actividad.uuid
      },
      class: "modal-dialog-centered"
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      if (myData === 'EDITAR' || myData === 'TERMINAR' || myData === 'ADJUNTAR') {
        this.getDetalleActividad();
      }
      if (myData === 'ELIMINAR') {
        this.regresar();
      }
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  getPlanesDeAccion() {
    this.loader = true;
        this.apiGestion.getPlanesDeAccion(this.uuidActividad).subscribe(
          (resp) => this.getPlanesDeAccionSuccess(resp),
          (error) => this.getPlanesDeAccionError(error));
  }

  getPlanesDeAccionSuccess(resp: GetPlanesDeAccionResponseDTO) {
    this.actividad.planesDeAccion = resp.data;
    this.obtenerEvidencia();
  }

  getPlanesDeAccionError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_PLANES_DE_ACCION;
    this.toastr.error(msg);
    this.loader = false;
  }

  goToCrearPlanDeAccion() {
    this.actividadService.setUuidSelected(this.uuidActividad);
    this.router.navigate(['web/gestion-crear-plan'])
  }

  goToEditarPlanDeAccion(uuidPlan: string) {
    this.actividadService.setUuidSelected(this.uuidActividad)
    this.actividadService.setUuidPlanDeAccion(uuidPlan);
    this.router.navigate(['web/gestion-editar-plan']);
  }

  enviarActividad() {
    if (!this.switchEnviar) {
      this.apiGestion.getEnviarActividad(this.uuidActividad).subscribe((resp) => this.enviarActividadSuccess(resp),
        (error) => this.enviarActividadError(error));
    }
    else {
      console.log('Actividad Enviada');
    }
  }

  enviarActividadSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_ENVIAR_ACTIVIDAD);
    this.getDetalleActividad();
  }

  enviarActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ENVIAR_ACTIVIDAD;
    this.toastr.error(msg);
  }

  obtenerEvidencia() {
    this.loader = true;
    this.apiGestion.getObtenerEvidencia(this.uuidActividad).subscribe((resp) => this.obtenerEvidenciaSuccess(resp),
      (error) => this.obtenerEvidenciaError(error));
  }

  obtenerEvidenciaSuccess(resp: GetEvidenciaActividadResponseDTO) {
    this.evidencia = resp.data;
    this.loader = false;
  }

  obtenerEvidenciaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_EVIDENCIA;
    this.toastr.error(msg);
    this.loader = false;
  }

  realizarPlanDeAccion(uuidPlan: string) {
    this.apiGestion.getAccionRealizada(uuidPlan).subscribe((resp) => this.realizarPlanDeAccionSuccess(resp),
      (error) => this.realizarPlanDeAccionError(error));
  }

  realizarPlanDeAccionSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_REALIZAR_PLAN_DE_ACCION);
    this.getDetalleActividad();
  }

  realizarPlanDeAccionError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_REALIZAR_PLAN_DE_ACCION;
    this.toastr.error(msg);
    this.loader = false;
  }

  obtenerUrl(adjunto: AdjuntoDTO) {
    let url;
    switch (true) {
      case adjunto.nombreArchivo.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.nombreArchivo.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.nombreArchivo.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.nombreArchivo.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        url = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return url;
  }

  getDescargarAdjunto(url: string) {
    window.open(url, '_self');
  }

  switch() {
    this.switchEnviar = !this.switchEnviar
    console.log(this.switchEnviar);
  }

  estadoSwitch() {
    if (this.actividad.estadoActividad.nombre.toLowerCase() === 'en curso' ||
      this.actividad.estadoActividad.nombre.toLowerCase() === 'futuras' ||
      this.actividad.estadoActividad.nombre.toLowerCase() === 'fuera de plazo') {
      this.switchEnviar = false;
    }
    else if (this.actividad.estadoActividad.nombre.toLowerCase() === 'cumple' ||
      this.actividad.estadoActividad.nombre.toLowerCase() === 'no cumple' ||
      this.actividad.estadoActividad.nombre.toLowerCase() === 'revisar') {
      this.switchEnviar = true;
    }
  }
}
