<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Deshabilitar tipo de documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="modal-text">Está apunto de deshabilitar el tipo de documento {{nombreTipo}}. Esta acción,
            deshabilitará este tipo de documento, <strong>y no podrá crear nuevos documentos con esta opción</strong>
            (los anteriores no se verán afectados).
            <br> <strong>¿Desea continuar?</strong>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal-black close-modal" aria-label="Close"
            (click)="bsModalRef.hide()">Cancelar</button>
        <button type="button" class="btn btn-modal" (click)="deshabilitarTipo()">Sí, deshabilitar</button>
    </div>
</div>