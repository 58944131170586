import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { NgxViewerModule } from 'ngx-viewer';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ModalsComponent } from './modals/modals.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationProfileComponent } from './notification-profile/notification-profile.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { ProfileComponent } from './profile/profile.component';

import { httpInterceptorProviders } from "./interceptors";
import { UserService } from "./services/user.service";
import { LoaderService } from "./services/loader.service";
import { AppRoutingModule } from './app-routing.module';

import { AlertService } from './services/alert.service';
import { AlertComponent } from './alert/alert.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

import { DocumentalHistorialComponent } from './documental/documental-historial/documental-historial.component';
import { DocumentalModogridComponent } from './documental/documental-modogrid/documental-modogrid.component';
import { DocumentalModolistComponent } from './documental/documental-modolist/documental-modolist.component';
import { ModalEditarComponent } from './documental/modals/modal-editar/modal-editar.component';
import { ModalEliminarComponent } from './documental/modals/modal-eliminar/modal-eliminar.component';
import { ModalSubirComponent } from './documental/modals/modal-subir/modal-subir.component';
import { ModalActualizarComponent } from './documental/modals/modal-actualizar/modal-actualizar.component';
import { ModalDetalleComponent } from './documental/modals/modal-detalle/modal-detalle.component';
import { ModalHistorialComponent } from './documental/modals/modal-historial/modal-historial.component';
import { FiltrosComponent } from './documental/filtros/filtros.component';
import { HistorialComponent } from './documental/historial/historial.component';

import { MantenedoresComponent } from './mantenedores/mantenedores.component';
import { MantenedorTipodocumentoComponent } from './mantenedores/mantenedor-tipodocumento/mantenedor-tipodocumento.component';
import { MantenedorJerarquizacionComponent } from './mantenedores/mantenedor-jerarquizacion/mantenedor-jerarquizacion.component';
import { ModalTdCrearComponent } from './mantenedores/mantenedor-tipodocumento/modals/modal-td-crear/modal-td-crear.component';
import { ModalTdEditarComponent } from './mantenedores/mantenedor-tipodocumento/modals/modal-td-editar/modal-td-editar.component';
import { ModalTdHabilitarComponent } from './mantenedores/mantenedor-tipodocumento/modals/modal-td-habilitar/modal-td-habilitar.component';
import { ModalTdDeshabilitarComponent } from './mantenedores/mantenedor-tipodocumento/modals/modal-td-deshabilitar/modal-td-deshabilitar.component';
import { MantenedorCategoriasComponent } from './mantenedores/mantenedor-categorias/mantenedor-categorias.component';
import { ModalCCrearComponent } from './mantenedores/mantenedor-categorias/modals/modal-c-crear/modal-c-crear.component';
import { ModalCEditarComponent } from './mantenedores/mantenedor-categorias/modals/modal-c-editar/modal-c-editar.component';
import { ModalCHabilitarComponent } from './mantenedores/mantenedor-categorias/modals/modal-c-habilitar/modal-c-habilitar.component';
import { ModalCDeshabilitarComponent } from './mantenedores/mantenedor-categorias/modals/modal-c-deshabilitar/modal-c-deshabilitar.component';
import { MantenedorTiemposrespuestaComponent } from './mantenedores/mantenedor-tiemposrespuesta/mantenedor-tiemposrespuesta.component';
import { ModalTrEditarComponent } from './mantenedores/mantenedor-tiemposrespuesta/modals/modal-tr-editar/modal-tr-editar.component';
import { MantenedorUsuariosComponent } from './mantenedores/mantenedor-usuarios/mantenedor-usuarios.component';
import { ModalUsuariosEditarComponent } from './mantenedores/mantenedor-usuarios/modals/modal-usuarios-editar/modal-usuarios-editar.component';
import { ModalJCrearComponent } from './mantenedores/mantenedor-jerarquizacion/modals/modal-j-crear/modal-j-crear.component';
import { ModalJEditarComponent } from './mantenedores/mantenedor-jerarquizacion/modals/modal-j-editar/modal-j-editar.component';
import { ModalJHabilitarComponent } from './mantenedores/mantenedor-jerarquizacion/modals/modal-j-habilitar/modal-j-habilitar.component';
import { ModalJDeshabilitarComponent } from './mantenedores/mantenedor-jerarquizacion/modals/modal-j-deshabilitar/modal-j-deshabilitar.component';
import { ModalJEliminarComponent } from './mantenedores/mantenedor-jerarquizacion/modals/modal-j-eliminar/modal-j-eliminar.component';

import { ReclamosComponent } from './reclamos/reclamos.component';
import { ReclamosIngresarComponent } from './reclamos/reclamos-ingresar/reclamos-ingresar.component';
import { ModalReclamosEditarComponent } from './reclamos/modals/modal-reclamos-editar/modal-reclamos-editar.component';
import { ModalReclamosCerrarComponent } from './reclamos/modals/modal-reclamos-cerrar/modal-reclamos-cerrar.component';
import { ModalReclamosComentariosComponent } from './reclamos/modals/modal-reclamos-comentarios/modal-reclamos-comentarios.component';
import { ModalReclamosHistorialComponent } from './reclamos/modals/modal-reclamos-historial/modal-reclamos-historial.component';
import { ModalReclamosRechazarComponent } from './reclamos/modals/modal-reclamos-rechazar/modal-reclamos-rechazar.component';
import { ModalReclamosEliminarComponent } from './reclamos/modals/modal-reclamos-eliminar/modal-reclamos-eliminar.component';
import { ReclamosServicioClienteComponent } from './reclamos/reclamos-servicio-cliente/reclamos-servicio-cliente.component';
import { ConsultarReclamosComponent } from './reclamos/consultar-reclamos/consultar-reclamos.component';
import { FiltrosReclamosComponent } from './reclamos/filtros-reclamos/filtros-reclamos.component';

import { GestionActividadesComponent } from './gestion-actividades/gestion-actividades.component';
import { GestionCrearComponent } from './gestion-crear/gestion-crear.component';
import { ModalGestionEditarComponent } from './gestion-actividades/modal-gestion-editar/modal-gestion-editar.component';
import { ModalGestionHistorialComponent } from './gestion-actividades/modal-gestion-historial/modal-gestion-historial.component';
import { GestionConsultarComponent } from './gestion-actividades/gestion-consultar/gestion-consultar.component';
import { ModalGestionTerminadaComponent } from './gestion-actividades/modal-gestion-terminada/modal-gestion-terminada.component';
import { GestionCrearPlanComponent } from './gestion-crear-plan/gestion-crear-plan.component';
import { GestionEditarPlanComponent } from './gestion-editar-plan/gestion-editar-plan.component';

import { MejoraContinuaComponent } from './mejora-continua/mejora-continua.component';
import { MenuComponent } from './mejora-continua/menu/menu.component';
import { MaterialEntrenamiento5sComponent } from './mejora-continua/material-entrenamiento5s/material-entrenamiento5s.component';
import { ModalMcEliminarComponent } from './mejora-continua/modals/modal-mc-eliminar/modal-mc-eliminar.component';
import { ModalMcSubirComponent } from './mejora-continua/modals/modal-mc-subir/modal-mc-subir.component';
import { FotosImplementacion5sComponent } from './mejora-continua/fotos-implementacion5s/fotos-implementacion5s.component';
import { ModalMcSubirarchivoComponent } from './mejora-continua/modals/modal-mc-subirarchivo/modal-mc-subirarchivo.component';
import { FiltrosMcComponent } from './mejora-continua/filtros-mc/filtros-mc.component';
import { ModalMcVerarchivosComponent } from './mejora-continua/modals/modal-mc-verarchivos/modal-mc-verarchivos.component';
import { ModalMcSubirfotosComponent } from './mejora-continua/modals/modal-mc-subirfotos/modal-mc-subirfotos.component';
import { Novedades5sComponent } from './mejora-continua/novedades5s/novedades5s.component';
import { ArticuloComponent } from './mejora-continua/articulo/articulo.component';
import { EditarCrearArticuloComponent } from './mejora-continua/editar-crear-articulo/editar-crear-articulo.component';
import { MaterialEntrenamiento7dComponent } from './mejora-continua/material-entrenamiento7d/material-entrenamiento7d.component';
import { Talleres7dComponent } from './mejora-continua/talleres7d/talleres7d.component';
import { Novedades7dComponent } from './mejora-continua/novedades7d/novedades7d.component';
import { MaterialEntrenamientolssComponent } from './mejora-continua/material-entrenamientolss/material-entrenamientolss.component';
import { TallereslssComponent } from './mejora-continua/tallereslss/tallereslss.component';
import { NovedadeslssComponent } from './mejora-continua/novedadeslss/novedadeslss.component';
import { GestionFiltrosComponent } from './gestion-actividades/gestion-filtros/gestion-filtros.component';
import { HistorialReclamoComponent } from './reclamos/historial-reclamo/historial-reclamo.component';
import { ModalReclamosAsignarReclamoComponent } from './reclamos/modals/modal-reclamos-asignar-reclamo/modal-reclamos-asignar-reclamo.component';
import { ModalReclamosResponderRechazoComponent } from './reclamos/modals/modal-reclamos-responder-rechazo/modal-reclamos-responder-rechazo.component';
import { ModalGestionEliminarComponent } from './gestion-actividades/modal-gestion-eliminar/modal-gestion-eliminar.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { FiltrarPorMetodologiaComponent } from './mejora-continua/filtrar-por-metodologia/filtrar-por-metodologia.component';
import { GestionHistorialComponent } from './gestion-actividades/gestion-historial/gestion-historial.component';
import { ModalGestionComentariosComponent } from './gestion-actividades/modal-gestion-comentarios/modal-gestion-comentarios.component';
import { ModalGestionAdjuntarComponent } from './gestion-actividades/modal-gestion-adjuntar/modal-gestion-adjuntar.component';
import { ModalPlanAdjuntarComponent } from './gestion-actividades/modal-plan-adjuntar/modal-plan-adjuntar.component';
import { QuillModule } from 'ngx-quill'
import localeEs from '@angular/common/locales/es';
import { ModalMcSelectaniofiscalComponent } from './mejora-continua/modals/modal-mc-selectaniofiscal/modal-mc-selectaniofiscal.component';

registerLocaleData(localeEs);

defineLocale('es', esLocale);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NavigationComponent,
    ModalsComponent,
    SidebarComponent,
    DocumentalModolistComponent,
    NotificationProfileComponent,
    ProfileComponent,
    NotificacionesComponent,
    DocumentalHistorialComponent,
    DocumentalModogridComponent,
    MantenedoresComponent,
    MantenedorTipodocumentoComponent,
    MantenedorJerarquizacionComponent,
    ReclamosComponent,
    ReclamosIngresarComponent,
    GestionActividadesComponent,
    GestionCrearComponent,
    HistorialComponent,
    AlertComponent,
    ConfirmModalComponent,
    ModalEditarComponent,
    ModalEliminarComponent,
    ModalSubirComponent,
    ModalActualizarComponent,
    ModalDetalleComponent,
    ModalReclamosEditarComponent,
    ModalReclamosCerrarComponent,
    ModalReclamosComentariosComponent,
    ModalReclamosHistorialComponent,
    ModalReclamosRechazarComponent,
    ModalReclamosEliminarComponent,
    FiltrosReclamosComponent,
    ReclamosServicioClienteComponent,
    ConsultarReclamosComponent,
    ModalGestionEditarComponent,
    ModalGestionHistorialComponent,
    GestionConsultarComponent,
    ModalGestionTerminadaComponent,
    ModalGestionEliminarComponent,
    GestionCrearPlanComponent,
    ModalHistorialComponent,
    FiltrosComponent,
    MejoraContinuaComponent,
    MenuComponent,
    MaterialEntrenamiento5sComponent,
    ModalMcEliminarComponent,
    ModalMcSubirComponent,
    FotosImplementacion5sComponent,
    ModalMcSubirarchivoComponent,
    FiltrosMcComponent,
    ModalMcVerarchivosComponent,
    ModalMcSubirfotosComponent,
    GestionEditarPlanComponent,
    Novedades5sComponent,
    ArticuloComponent,
    EditarCrearArticuloComponent,
    MaterialEntrenamiento7dComponent,
    Talleres7dComponent,
    Novedades7dComponent,
    MaterialEntrenamientolssComponent,
    TallereslssComponent,
    NovedadeslssComponent,
    ModalTdCrearComponent,
    ModalTdEditarComponent,
    ModalTdHabilitarComponent,
    ModalTdDeshabilitarComponent,
    MantenedorCategoriasComponent,
    ModalCCrearComponent,
    ModalCEditarComponent,
    ModalCHabilitarComponent,
    ModalCDeshabilitarComponent,
    MantenedorTiemposrespuestaComponent,
    ModalTrEditarComponent,
    MantenedorUsuariosComponent,
    ModalUsuariosEditarComponent,
    ModalJCrearComponent,
    ModalJEditarComponent,
    ModalJHabilitarComponent,
    ModalJDeshabilitarComponent,
    ModalJEliminarComponent,
    GestionFiltrosComponent,
    HistorialReclamoComponent,
    ModalReclamosAsignarReclamoComponent,
    ModalReclamosResponderRechazoComponent,
    FiltrarPorMetodologiaComponent,
    GestionHistorialComponent,
    ModalGestionComentariosComponent,
    ModalGestionAdjuntarComponent,
    ModalPlanAdjuntarComponent,
    ModalMcSelectaniofiscalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    AngularEditorModule,
    AmChartsModule,
    NgxViewerModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    CommonModule,
    QuillModule.forRoot(),
  ],
  providers: [
    HttpClient,
    httpInterceptorProviders,
    UserService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es' },
    AlertService,
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('es');
  }
}
