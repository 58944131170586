import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  showAlert(message: string = '', callback: () => any = function () { console.log('showAlert'); }) {
    let config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-sm"
    };
    this.bsModalRef = this.modalService.show(AlertComponent, config);
    this.bsModalRef.content.callback = callback;
    this.bsModalRef.content.message = message;
  }

  showConfirm(message: string, callback: () => any = function () { console.log('showConfirm'); }) {
    setTimeout(() => {
      let config: object = {
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-md'
      };

      this.bsModalRef = this.modalService.show(ConfirmModalComponent, config);
      this.bsModalRef.content.callback = callback;
      this.bsModalRef.content.message = message;
    });
  }
}
