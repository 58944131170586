<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Eliminar {{tipo}}</h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-text" *ngIf="tipo === 'material'">Está apunto de eliminar el material de entrenamiento <strong>"{{nombre}}"</strong>, que usted ha creado.
            <br>.<strong>¿Está seguro que desea eliminarlo?</strong>
        </div>
        <div *ngIf="tipo === 'implementacion'"
         class="modal-text">Está apunto de eliminar la foto de implementación <strong>"{{nombre}}"</strong>, que usted ha creado.
            <br><strong>¿Está seguro que desea eliminarla?</strong>
        </div>
        <div class="modal-text" *ngIf="tipo === 'proyecto'">Está apunto de eliminar el proyecto <strong>"{{nombre}}"</strong>, que usted ha creado.
            <br>.<strong>¿Está seguro que desea eliminarlo?</strong>
        </div>
        <div class="modal-text" *ngIf="tipo === 'articulo'">Está apunto de eliminar el articulo <strong>"{{nombre}}"</strong>, que usted ha creado.
            <br>.<strong>¿Está seguro que desea eliminarlo?</strong>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-modal-black close-modal" data-dismiss="modal"
            aria-label="Close" (click)="bsModalRef.hide()">Cancelar</button>
        <button type="button" class="btn btn-modal" (click)="eliminar()">Sí, Eliminar</button>
    </div>
</div>