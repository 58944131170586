<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
        <div class="modal-header" *ngIf="editarActividadForm && !loader">
            <h5 class="modal-title" id="exampleModalLabel">Editar actividad</h5>
            <button type="button" class="close-modal" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    <form [formGroup]="editarActividadForm" *ngIf="editarActividadForm && !loader">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Área</label></div>
                <div class="inp" *ngIf="!loaderArea">
                    <ng-select (change)="getListadoSubAreas(true)" formControlName="uuidArea" 
                    [ngClass]="{'border-error': editarActividadForm.controls['uuidArea'].touched && !editarActividadForm.controls['uuidArea'].valid}"
                    placeholder="Seleccione" name="gestion-area" id="gestion-area" class="select">
                        <ng-option *ngFor="let area of listaAreas" [value]="area.uuid">{{area.nombre}}</ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderArea">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Sub-área</label></div>
                <div class="inp" *ngIf="!loaderSubarea">
                    <ng-select (change)="getListadoSitios(true)" formControlName="uuidSubarea" 
                    [ngClass]="{'border-error': editarActividadForm.controls['uuidSubarea'].touched && !editarActividadForm.controls['uuidSubarea'].valid}"
                     placeholder="Seleccione" name="gestion-area" id="gestion-subarea" class="select">
                        <ng-option *ngFor="let subArea of listaSubareas" [value]="subArea.uuid">{{subArea.nombre}}</ng-option>
                    </ng-select>
                 </div>
                <div class="inp" *ngIf="loaderSubarea">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Sitio</label></div>
                <div class="inp" *ngIf="!loaderSitio">
                    <ng-select formControlName="uuidSitio" placeholder="Seleccione" name="gestion-area"
                    [ngClass]="{'border-error': editarActividadForm.controls['uuidSitio'].touched && !editarActividadForm.controls['uuidSitio'].valid}" 
                    id="gestion-sitio" class="select">
                        <ng-option *ngFor="let sitio of listaSitios" [value]="sitio.uuid">{{sitio.nombre}}</ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSitio">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Extensión de plazo (días)</label></div>
                <div class="inp">
                    <input formControlName="extensionPlazo" type="number" name="gestion-extensionplazo" id="gestion-extensionplazo" class="inp-modal"
                        placeholder="0"/>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="editarActividad()">Editar actividad</button>
        </div>
    </form>
</div>