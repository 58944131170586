<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Cerrar reclamo</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="cerrarForm">
        <div class="modal-body">
            <div class="modal-text">
                Está apunto de cerrar el Reclamo <strong>{{idInterno}}</strong>, que usted creo. Por favor,
                indique su conformidad al respecto.
            </div>
            <div class="field-half">
                <div class="lab">
                    <label for="name">Impacto de la desviación
                        <span class="impacto-desviacion">
                            <i class="fas fa-question-circle ico-ver-procedimiento blue impacto" aria-hidden="true"></i>
                        </span>
                        <div class="hide">
                            <span class="impacto-line" *ngFor="let desviacionMC of listadoDesviaciones">
                                <i class="fas fa-question-circle ico-ver-procedimiento impacto-color" aria-hidden="true"
                                    [ngClass]="{'red': desviacionMC.nombre === 'Alto', 
                                    'yellow': desviacionMC.nombre === 'Medio', 
                                    'green':  desviacionMC.nombre === 'Bajo'}"></i>
                                <strong>{{desviacionMC.nombre}}:</strong> {{desviacionMC.descripcion}}
                            </span>
                        </div>
                    </label>
                </div>
                <div class="inp" *ngIf="!loaderDesviacion">
                    <ng-select class="select" formControlName="impactoDesviacion" placeholder="Seleccione" required
                        [ngClass]="{'border-error':  cerrarForm.get('impactoDesviacion').touched && ! cerrarForm.get('impactoDesviacion').valid}">
                        <ng-option *ngFor="let desviacion of listadoDesviaciones" [value]="desviacion.uuid">
                            {{desviacion.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderDesviacion">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="accordance">
                <div class="accordance-option">
                    <label>
                        <input type="radio" name="opcionCerrar" value="true" formControlName="opcionCerrar" />
                        <span>Cerrar conforme</span>
                    </label>
                </div>
                <div class="accordance-option">
                    <label>
                        <input type="radio" name="opcionCerrar" value="false" formControlName="opcionCerrar" />
                        <span>Cerrar no conforme</span>
                    </label>
                </div>
            </div>
            <div class="modal-text" *ngIf="cerrarForm.value.opcionCerrar === 'false'">
                <textarea id="cerrar-no-conforme" formControlName="descripcion" name="cerrar-no-conforme" rows="4"
                    cols="100" placeholder="Señale sus razones..."
                    [ngClass]="{'border-error':  cerrarForm.get('descripcion').touched && ! cerrarForm.get('descripcion').valid}"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" [disabled]="cerrarForm.invalid" (click)="cerrarReclamo()">
                cerrar reclamo
            </button>
        </div>
    </form>
</div>