import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoriaDTO } from 'src/dtos/gestion/categoria.dto';
import { FrecuenciaDTO } from 'src/dtos/gestion/frecuencia.dto';
import { RangoDuracionDTO } from 'src/dtos/gestion/rangoDuracion.dto';
import { SubCategoriaDosDTO } from 'src/dtos/gestion/sub-categoria-dos.dto';
import { SubCategoriaUnoDTO } from 'src/dtos/gestion/sub-categoria-uno.dto';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { PostCrearActividadRequestDTO } from 'src/dtos/request/gestion/postCrearActividad.request.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { GetListadoCategoriasResponseDTO } from 'src/dtos/response/gestion/getListadoCategorias.response.dto';
import { GetListadoFrecuenciasResponseDTO } from 'src/dtos/response/gestion/getListadoFrecuencias.response.dto';
import { GetListadoRangoDuracionResponseDTO } from 'src/dtos/response/gestion/getListadoRangoDuracion.response.dto';
import { GetListadoSubCategoriasDosResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasDos.response.dto';
import { GetListadoSubCategoriasUnoResponseDTO } from 'src/dtos/response/gestion/getListadoSubCategoriasUno.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { AppMessage } from '../app.message';
import { ApiGestionService } from '../services/api-gestion.service';
import { ApiMantenedorService } from '../services/api-mantenedor.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-gestion-crear',
  templateUrl: './gestion-crear.component.html',
  styleUrls: ['./gestion-crear.component.scss']
})
export class GestionCrearComponent {

  listadoCategorias: CategoriaDTO[] = [];
  listadoSubcategoriasUno: SubCategoriaUnoDTO[] = [];
  listadoSubcategoriasDos: SubCategoriaDosDTO[] = [];
  listadoFrecuencias: FrecuenciaDTO[] = [];
  listadoRangoDuracion: RangoDuracionDTO[] = [];
  fechaInicioActividad: any[];
  duracionActividad: any[];
  fechaVencimiento: any[];
  planDeAccion: boolean = false;
  crearActividadForm: FormGroup;
  listadoAreas: AreaDTO[] = [];
  listadoSubareas: SubareaDTO[] = [];
  listadoSitios: SitiosDTO[] = [];
  showDuracion: boolean = false;
  showRango: boolean = false;
  disabledDiasDuracion: boolean = false;

  meses = [{ nombre: 'Enero', id: '1' },
    { nombre: 'Febrero', id: '2' },
    { nombre: 'Marzo', id: '3' },
    { nombre: 'Abril', id: '4' },
    { nombre: 'Mayo', id: '5' },
    { nombre: 'Junio', id: '6' },
    { nombre: 'Julio', id: '7' },
    { nombre: 'Agosto', id: '8' },
    { nombre: 'Septiembre', id: '9' },
    { nombre: 'Octubre', id: '10' },
    { nombre: 'Noviembre', id: '11' },
    { nombre: 'Diciembre', id: '12' },
    { nombre: 'Todos los meses', id: '13' }];
  
  dias = [];

  semanas = ['Primera', 'Segunda', 'Tercera', 'Cuarta', 'Ultima'];

  diasSemana = [];
  nombresDiasSeleccionaods: string[] = [];
  nombresMesesSeleccionados: string[] = [];
  nombresSemanasSeleccionadas: string[] = [];

  checkedDias: boolean = true;
  checkedSemana: boolean = false;

  datepickerConfig = {
    isAnimated: true,
    adaptivePosition: true,
    showWeekNumbers: false,
    rangeInputFormat: 'DD/MM/YYYY',
    dateInputFormat: 'DD/MM/YYYY',
  };

  minDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private apiMantenedor: ApiMantenedorService,
    private apiGestion: ApiGestionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getListadoCategorias();
    this.getListadoAreas();
    this.getListadoFrecuencias();
    this.getListadoRangoDuracion();
    this.initForm();
    this.getDays();
    for (let i = 1; i <= 31; i++) {
      this.dias.push(i);
    }
  }

  initForm() {
    this.crearActividadForm = this.formBuilder.group({

      uuidArea: new FormControl(null, [Validators.required]),
      uuidSitio: new FormControl(null),
      uuidSubarea: new FormControl(null),

      esperaPlanAccion: new FormControl(false, [Validators.required]),
      fechaInicioActividad: new FormControl(null),
      fechaTerminoActividad: new FormControl(null),
      
      diasDuracion: new FormControl(1),
      diasDuracionFrecMensual: new FormControl(1),
      diasEnElMesFrecMensual: new FormControl([]),
      diasEnElMesFrecAnual: new FormControl([]),
      fechaRango: new FormControl(null),
      intervaloRepeticionCadaXDias: new FormControl(1, {validators: [Validators.min(1)]}),
      intervaloRepeticionCadaXSemanas: new FormControl(1),
      isTodaLaSemana: new FormControl(false),
      isTodoElMes: new FormControl(false),
      mesesFrecMensual: new FormControl([]),
      mesesFrecAnual: new FormControl([]),
      repetirEnDiasFrecSemanal: new FormControl([]),
      repetirEnDiasFrecMensual: new FormControl(),
      repetirEnDiasFrecAnual: new FormControl([]),
      semanasEnElMesFrecMensual: new FormControl([]),
      semanasEnElMesFrecAnual: new FormControl([]),
      uuidFrecuencia: new FormControl(null),
      //uuidRangoActividad: new FormControl(null),

      nombre: new FormControl(null, [Validators.required]),
      uuidCategoria: new FormControl(null, [Validators.required]),
      uuidSubcategoriaDos: new FormControl(null),
      uuidSubcategoriaUno: new FormControl(null),
    })
  }
  

  getListadoCategorias() {
    this.listadoCategorias = [];
    this.apiGestion.getListadoCategorias().subscribe((resp) => this.getListadoCategoriasSuccess(resp),
    (error) => this.getListadoCategoriasError(error))
  }

  getListadoCategoriasSuccess(resp: GetListadoCategoriasResponseDTO) {
    this.listadoCategorias = resp.data.filter(categoria => categoria.habilitado);
  }

  getListadoCategoriasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_CATEGORIAS;
    this.toastr.error(msg);
  }

  getListadoSubCategoriasUno() {
    this.listadoSubcategoriasUno = [];
    this.apiGestion.getListadoSubCategoriasUno().subscribe((resp) => this.getListadoSubCategoriasUnoSuccess(resp),
      (error) => this.getListadoSubCategoriasUnoError(error));
  }

  getListadoSubCategoriasUnoSuccess(resp: GetListadoSubCategoriasUnoResponseDTO) {
    this.listadoSubcategoriasUno = resp.data.filter(subc => subc.habilitado);
  }

  getListadoSubCategoriasUnoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_UNO;
    this.toastr.error(msg);
  }

  getListadoSubCategoriasDos() {
    this.listadoSubcategoriasDos = [];
    this.apiGestion.getListadoSubCategoriasDos().subscribe((resp) => this.getListadoSubCategoriasDosSuccess(resp),
      (error) => this.getListadoSubCategoriasDosError(error));
  }

  getListadoSubCategoriasDosSuccess(resp: GetListadoSubCategoriasDosResponseDTO) {
    this.listadoSubcategoriasDos = resp.data.filter(subcDos => subcDos.habilitado);
  }

  getListadoSubCategoriasDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_SUB_CATEGORIAS_DOS;
    this.toastr.error(msg);
  }

  getListadoFrecuencias() {
    this.listadoFrecuencias = [];
    this.apiGestion.getListadoFrecuencias().subscribe((resp) => this.getListadoFrecuenciasSuccess(resp),
      (error) => this.getListadoFrecuenciasError(error));
  }

  getListadoFrecuenciasSuccess(resp: GetListadoFrecuenciasResponseDTO) {
    this.listadoFrecuencias = resp.data.map(frec => ({ ...frec, checked: false, paneid: '' }));
    const alphabet = ['A', 'B', 'C', 'D', 'E'];

    this.listadoFrecuencias[0].checked = true;

    for (let i = 0; i < this.listadoFrecuencias.length; i++){
      this.listadoFrecuencias[i].paneid = alphabet[i];
    };

  }

  getListadoFrecuenciasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_FRECUENCIAS;
    this.toastr.error(msg);
  }

  getListadoRangoDuracion() {
    this.listadoRangoDuracion = [];
    this.apiGestion.getListadoRangoDuracion().subscribe((resp) => this.getListadoRangoDuracionSuccess(resp),
      (error) => this.getListadoRangoDuracionError(error));
  }

  getListadoRangoDuracionSuccess(resp: GetListadoRangoDuracionResponseDTO) {
    this.listadoRangoDuracion = resp.data;
  }

  getListadoRangoDuracionError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_RANGOS_DURACION;
    this.toastr.error(msg);
  }

  getListadoAreas() {
    this.listadoAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listadoAreas = resp.data.filter(area => area.habilitado);
  }
  
  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.toastr.error(msg);
  }

  getListadoSubAreas() {
    this.crearActividadForm.patchValue({ uuidSubarea: null, uuidSitio: null });
    this.listadoSubareas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor(this.crearActividadForm.controls.uuidArea.value).subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listadoSubareas = resp.data.filter(subarea => subarea.habilitado);
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastr.error(msg);
  }

  getListadoSitios() {
    this.crearActividadForm.patchValue({ uuidSitio: null });
    this.listadoSitios = [];
    this.apiMantenedor.getListadoSitiosMantenedor(this.crearActividadForm.controls.uuidSubarea.value).subscribe((resp) => this.getListadoSitiosSuccess(resp),
      (error) => this.getListadoSitiosError(error));
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitios = resp.data.filter(sitio => sitio.habilitado);
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastr.error(msg);
  }

  checkPlanDeAccion(event: boolean) {
    this.planDeAccion = event;
  }

  postCrearActividadSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_CREAR_ACTIVIDAD);
    this.router.navigate(['web/gestion-actividades']);

  }

  postCrearActividadError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_ACTIVIDAD;
    this.toastr.error(msg);
  }

  asignarNombreActividad() {
    let subc = this.listadoSubcategoriasUno.find(e => e.uuid === this.crearActividadForm.controls.uuidSubcategoriaUno.value);
    this.crearActividadForm.controls['nombre'].setValue(subc.nombre);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {            
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  checkFrecuencia(frecuencia: any) {
    this.nombresDiasSeleccionaods = [];
    this.nombresSemanasSeleccionadas = [];
    this.nombresSemanasSeleccionadas = [];
    this.crearActividadForm.controls['mesesFrecMensual'].setValue([]);
    this.crearActividadForm.controls['mesesFrecAnual'].setValue([]);
    this.checkDiaSemana(true);
    this.getDays();
    this.listadoFrecuencias.forEach(f => f.checked = false);
    frecuencia.checked = !frecuencia.checked;
    if (frecuencia.nombre.toLowerCase() === 'mensual' || frecuencia.nombre.toLowerCase() === 'anual') {
      //this.crearActividadForm.controls['uuidRangoActividad'].setValue(null);
      this.showDuracion = true;
      //this.validarRango();
    }
    else {
      this.showDuracion = false;
      this.showRango = false;
    }
  }
  
  validarRango() {
    if (this.crearActividadForm.controls.uuidRangoActividad.value) {
      let aux = this.listadoRangoDuracion.find(rango => rango.uuid === this.crearActividadForm.controls.uuidRangoActividad.value);
      if (aux.nombre.toLowerCase() === 'rango') {
        this.showRango = true;
      }
      else {
        this.crearActividadForm.controls['fechaRango'].setValue(null);
        this.showRango = false;
      }
    }
    else {
      this.crearActividadForm.controls['fechaRango'].setValue(null);
      this.showRango = false;
    }
    
  }

  getDays() {
    this.diasSemana = [
      { nombre: 'Lu', fullName: 'Lunes', value: 1, checked: false, disabled: false },
      { nombre: 'Ma', fullName: 'Martes', value: 2, checked: false, disabled: false },
      { nombre: 'Mi', fullName: 'Miércoles', value: 3, checked: false, disabled: false },
      { nombre: 'Ju', fullName: 'Jueves', value: 4, checked: false, disabled: false },
      { nombre: 'Vi', fullName: 'Viernes', value: 5, checked: false, disabled: false },
      { nombre: 'Sa', fullName: 'Sábado', value: 6, checked: false, disabled: false },
      { nombre: 'Do', fullName: 'Domingo', value: 7, checked: false, disabled: false }];
    
  }

  habilitarCheckboxDay() {
    this.nombresDiasSeleccionaods = [];
    for (const iterator of this.diasSemana) {
      iterator.checked = false;
      iterator.disabled = false;
    }
  }

  disableDay() {
    for (const iterator of this.diasSemana) {
      iterator.disabled = false;
    }
    const frecAux = this.listadoFrecuencias.find(frec => frec.checked === true);
    for (const iterator of this.diasSemana) {
      let diasDeshabilitar = 0;
        if (iterator.checked) {
          if (frecAux.nombre.toLowerCase() === 'semanal')
            diasDeshabilitar = this.crearActividadForm.controls.diasDuracion.value - 1;
          if (frecAux.nombre.toLowerCase() === 'mensual')
            diasDeshabilitar = this.crearActividadForm.controls.diasDuracionFrecMensual.value - 1;
          let suma = iterator.value
          let resta = iterator.value
          for (let i = 0; i < diasDeshabilitar; i++){
            suma = suma + 1;
            resta = resta - 1;
            if (suma > 7) {
              suma = 1;
            }
            if (resta < 1) {
              resta = 7;
            }
            if(!this.diasSemana[suma - 1].checked)
              this.diasSemana[suma - 1].disabled = iterator.checked;
            if(!this.diasSemana[resta - 1].checked)
              this.diasSemana[resta - 1].disabled = iterator.checked;
          }
      }
    } 
    
  }

  setDuracionValue() {
    const frecAux = this.listadoFrecuencias.find(frec => frec.checked === true);
    if (frecAux.nombre.toLowerCase() === 'semanal') {
      if (this.crearActividadForm.controls.diasDuracion.value > 6) {
        this.crearActividadForm.controls.diasDuracion.setValue(6);
      }
      if (this.crearActividadForm.controls.diasDuracion.value < 1) {
        this.crearActividadForm.controls.diasDuracion.setValue(1);
      }
    }
    if (frecAux.nombre.toLowerCase() === 'mensual') {
      if (this.crearActividadForm.controls.diasDuracionFrecMensual.value > 30) {
        this.crearActividadForm.controls.diasDuracionFrecMensual.setValue(30);
      }
      if (this.crearActividadForm.controls.diasDuracionFrecMensual.value < 1) {
        this.crearActividadForm.controls.diasDuracionFrecMensual.setValue(1);
      }
    }
  }

  checkTodoElMes() {
    const frecAux = this.listadoFrecuencias.find(frec => frec.checked === true);
    if (frecAux.nombre.toLowerCase() === 'semanal') {
      if (this.crearActividadForm.controls.isTodaLaSemana.value === true) {
        this.crearActividadForm.controls.diasDuracion.setValue('');
        this.crearActividadForm.controls.diasDuracion.disable();
      }
      else {
        this.crearActividadForm.controls.diasDuracion.setValue(1);
        this.crearActividadForm.controls.diasDuracion.enable();
      }
    }
    if (frecAux.nombre.toLowerCase() === 'mensual') {
      if (this.crearActividadForm.controls.isTodoElMes.value === true) {
        this.crearActividadForm.controls.diasDuracionFrecMensual.setValue('');
        this.crearActividadForm.controls.diasDuracionFrecMensual.disable();
      }
      else {
        this.crearActividadForm.controls.diasDuracionFrecMensual.setValue(1);
        this.crearActividadForm.controls.diasDuracionFrecMensual.enable();
      }
    }
  }

  checkDia(dia: any) {
    dia.checked = !dia.checked;
  }

  checkTodaLaSemana(value: boolean) {
    this.crearActividadForm.patchValue({ isTodaLaSemana: value })
  }

  actualizarDiaSeleccionado(dia) {
    if (dia.checked) {
      this.nombresDiasSeleccionaods.push(dia.fullName)
    }
    else {
      const index = this.nombresDiasSeleccionaods.indexOf(dia.fullName);
      this.nombresDiasSeleccionaods.splice(index, 1);
    }
  }

  getSelectedMonthsFrecMensual() {
    return this.meses.filter(month => this.crearActividadForm.controls.mesesFrecMensual.value.includes(month.id)).map(month => month.nombre).join(', ');
  }

  getSelectedMonthsFrecAnual() {
    return this.meses.filter(month => this.crearActividadForm.controls.mesesFrecAnual.value.includes(month.id)).map(month => month.nombre).join(', ');
  }

  checkDiaSemana(check: boolean) {
    this.crearActividadForm.controls['diasEnElMesFrecMensual'].setValue([]);
    this.crearActividadForm.controls['diasEnElMesFrecAnual'].setValue([]);
    this.crearActividadForm.controls['semanasEnElMesFrecMensual'].setValue([]);
    this.crearActividadForm.controls['semanasEnElMesFrecAnual'].setValue([]);
    this.checkedDias = check;
    this.checkedSemana = !check;
  }

  crearActividad() {
    if (this.crearActividadForm.valid) {
      const data = this.crearActividadForm.value;
      let form = new PostCrearActividadRequestDTO();
      form.asignacion.uuidArea = data.uuidArea;
      form.asignacion.uuidSitio = data.uuidSitio;
      form.asignacion.uuidSubarea = data.uuidSubarea;

      form.nombre = data.nombre;
      form.esperaPlanAccion = data.esperaPlanAccion;
      form.uuidCategoria = data.uuidCategoria;
      form.uuidSubcategoriaDos = data.uuidSubcategoriaDos;
      form.uuidSubcategoriaUno = data.uuidSubcategoriaUno;
      form.fechaInicioActividad = data.fechaInicioActividad;
      form.fechaTerminoActividad = data.fechaTerminoActividad;

      const frecAux = this.listadoFrecuencias.find(frec => frec.checked === true);
      form.frecuenciaRequestDTO.uuidFrecuencia = frecAux.uuid;

      // Condiciones para cada check de Frecuencia
      // DIARIA
      if (frecAux.nombre.toLowerCase() === 'diaria') {
        form.frecuenciaRequestDTO.intervaloRepeticionCadaXDias = data.intervaloRepeticionCadaXDias;
      };

      // SEMANAL
      if (frecAux.nombre.toLowerCase() === 'semanal') {
        form.frecuenciaRequestDTO.intervaloRepeticionCadaXSemanas = data.intervaloRepeticionCadaXSemanas;
        form.frecuenciaRequestDTO.repetirEnDias = [];
        form.frecuenciaRequestDTO.isTodaLaSemana = data.isTodaLaSemana;
        form.frecuenciaRequestDTO.diasDuracion = data.diasDuracion;
        this.diasSemana.forEach(day => {
          if (day.checked) {
            form.frecuenciaRequestDTO.repetirEnDias.push(day.value);
          }
        });
      };

      // MENSUAL
      if (frecAux.nombre.toLowerCase() === 'mensual') {
        form.frecuenciaRequestDTO.meses = data.mesesFrecMensual;
        form.frecuenciaRequestDTO.diasDuracion = data.diasDuracionFrecMensual;
        form.frecuenciaRequestDTO.isTodoElMes = data.isTodoElMes;
        form.frecuenciaRequestDTO.semanasEnElMes = data.semanasEnElMesFrecMensual;
          form.frecuenciaRequestDTO.repetirEnDias = [];
          this.diasSemana.forEach(day => {
            if (day.checked) {
              form.frecuenciaRequestDTO.repetirEnDias.push(day.value);
            }
          });
        //form.frecuenciaRequestDTO.uuidRangoActividad = data.uuidRangoActividad;
        /*if (this.checkedDias) {
          form.frecuenciaRequestDTO.diasEnElMes = data.diasEnElMesFrecMensual;
        }
        else if (this.checkedSemana) {
          form.frecuenciaRequestDTO.semanasEnElMes = data.semanasEnElMesFrecMensual;
          form.frecuenciaRequestDTO.repetirEnDias = [];
          this.diasSemana.forEach(day => {
            if (day.checked) {
              form.frecuenciaRequestDTO.repetirEnDias.push(day.value);
            }
          });
        };
        if (this.showRango && data.fechaRango) {
          form.frecuenciaRequestDTO.fechaInicioRango = data.fechaRango[0];
          form.frecuenciaRequestDTO.fechaTerminoRango = data.fechaRango[1];
        }*/
      };

      // ANUAL
      if (frecAux.nombre.toLowerCase() === 'anual') {
        form.frecuenciaRequestDTO.meses = data.mesesFrecAnual;
        //form.frecuenciaRequestDTO.uuidRangoActividad = data.uuidRangoActividad;
        if (this.checkedDias) {
          form.frecuenciaRequestDTO.diasEnElMes = data.diasEnElMesFrecAnual;
        }
        else if (this.checkedSemana) {
          form.frecuenciaRequestDTO.semanasEnElMes = data.semanasEnElMesFrecAnual;
          form.frecuenciaRequestDTO.repetirEnDias = [];
          this.diasSemana.forEach(day => {
            if (day.checked) {
              form.frecuenciaRequestDTO.repetirEnDias.push(day.value);
            }
          });
        };
        if (this.showRango && data.fechaRango) {
          form.frecuenciaRequestDTO.fechaInicioRango = data.fechaRango[0];
          form.frecuenciaRequestDTO.fechaTerminoRango = data.fechaRango[1];
        }
      };

      this.apiGestion.postCrearActividad(form).subscribe((resp) => this.postCrearActividadSuccess(resp),
      (error) => this.postCrearActividadError(error));
    }

    else {
      this.validateAllFormFields(this.crearActividadForm);
    }
    
  }
 }
