<div class="section-login">
    <div class="section1-login">
        <div class="logo-login1">WEB LOGISTIC</div>
        <div class="logo-login2"><img src="assets/img/logo2.jpg" width="100%" alt="Komatsu" /></div>
    </div>
    <div class="section2-login">
        <div class="text-login">Bienvenido a nuestro sistema de MEJORA CONTINUA</div>

    </div>
</div>
<div class="section-login-log">
    <div class="text-login-mobile">Bienvenido a nuestro sistema de MEJORA CONTINUA</div>
    <div class="title-log">INGRESO DE USUARIOS</div>
    <div class="field-log-1">
        <button class="btn btn-primary btn-login" (click)="initLoginSSO()">Ingresar</button>
    </div>
</div>