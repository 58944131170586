import { AmChartsService } from '@amcharts/amcharts3-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { GetPorcentajesHomeRequestDTO } from 'src/dtos/request/documental/getPorcentajesHome.request.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetPorcentajesHomeDocumentalResponseDTO } from 'src/dtos/response/documental/getPorcentajesHomeDocumental.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { UsuarioModel } from 'src/models/usuario.model';
import { AppMessage } from '../app.message';
import { ApiMantenedorService } from '../services/api-mantenedor.service';
import { ApiService } from '../services/api.service';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
import { NotifcacionDTO } from 'src/dtos/documental/notificacion.dto';
import { NotificacionService } from '../services/notificacion.service';
import { GetPorcentajesHomeReclamosResponseDTO } from 'src/dtos/response/mantenedor/getPorcentajesHomeReclamos.response.dto';
import { ApiGestionService } from '../services/api-gestion.service';
import { GetPorcentajesHomeGestionResponseDTO } from 'src/dtos/response/gestion/getPorcentajesHomeGestion.response.dto';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  listadoArea: AreaDTO[] = [];
  listadoSubArea: SubareaDTO[] = [];
  listadoSitio: SitiosDTO[] = [];
  usuario: UsuarioModel;

  loaderAreas: boolean = false;
  loaderSubareas: boolean = false;
  loaderSitios: boolean = false;
  loaderDocumental: boolean = false;
  loaderReclamo: boolean = false;
  loaderGestion: boolean = false;

  filtrosHomeForm: FormGroup;

  charCircleDocumental: any;
  charCircleReclamos: any;
  charCircleGestionActividades: any;
  porcentajes: any;
  porcentajesReclamo: any;
  porcentajesGestion: any;

  rango: string[] = [];

  configbsConfig = {
    rangeInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false
  };

  listadoNotificaciones: NotifcacionDTO[] = [];
  intervaloNotificaiones;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private AmCharts: AmChartsService,
    private apiServiceMantenedor: ApiMantenedorService,
    private notificacionService: NotificacionService,
    private apiGestion: ApiGestionService
  ) { }

  ngOnInit(): void {
    this.usuario = this.userService.getUser();

    this.filtrosHomeForm = this.formBuilder.group({
      periodo: new FormControl([new Date(moment().subtract(1, 'months').format()), new Date()]),
      area: new FormControl(null),
      subarea: new FormControl(null),
      sitio: new FormControl(null)
    });
    this.rango.push(this.filtrosHomeForm.controls.periodo.value[0]);
    this.rango.push(this.filtrosHomeForm.controls.periodo.value[1]);

    this.getListadoAreas();

    this.getPorcentajesHomeDocumental();
    this.getPorcentajesHomeReclamos();
    this.getPorcentajesHomeGestion();

    this.getNotificaciones();
    this.intervaloNotificaiones = setInterval(() => {
      this.listadoNotificaciones = this.notificacionService.getNotificaciones();
    }, 30000);

    
  }

  estructuraGrafico(tipo: string) {
    return {
      "type": "pie",
      "innerRadius": "45%",
      "labelRadius": 0,
      "labelText": "[[percents]]%",
      "minRadius": 90,
      "pullOutRadius": 0,
      "colorField": "color",
      "labelColorField": "labelColor",
      "autoMargins": false,
      "marginTop": 0,
      "marginBottom": 0,
      "marginLeft": 0,
      "marginRight": 0,
      "pullOutEffect": "easeOutSine",
      "startEffect": "easeOutSine",
      "titleField": "status",
      "valueField": "estado",
      "fontSize": 10,
      "theme": "default",
      "allLabels": [],
      "balloon": {},
      "titles": [],
      "dataProvider": this.dataProvider(tipo)
    }
  }

  dataProvider(tipo: string) {
    let data = [];
    switch (tipo) {
      case 'documental':
        data = [
          {
            "status": "Vigentes",
            "color": "#9ad975",
            "labelColor": "#333333",
            "estado": this.porcentajes.vigentes
          },
          {
            "status": "Por vencer",
            "color": "#ffc82f",
            "labelColor": "#333333",
            "estado": this.porcentajes.por_vencer
          },
          {
            "status": "Vencidos",
            "color": "#f30303",
            "labelColor": "#333333",
            "estado": this.porcentajes.vencidos
          }
        ];
        break;
      case 'reclamo':
        data = [
          {
            "status": "Respondido",
            "color": "#9ad975",
            "labelColor": "#333333",
            "estado": this.porcentajesReclamo.respondidos
          },
          {
            "status": "Por responder",
            "color": "#ffc82f",
            "labelColor": "#333333",
            "estado": this.porcentajesReclamo.porResponder
          },
          {
            "status": "Cerrado",
            "color": "#8c95c9",
            "labelColor": "#333333",
            "estado": this.porcentajesReclamo.cerrados
          }
        ];
        break;
      case 'gestion':
        data = [
          {
            "status": "Cumple",
            "color": "#9ad975",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.cumple
          },
          {
            "status": "Fuera de plazo",
            "color": "#ffc82f",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.fueraDePlazo
          },
          {
            "status": "Revisar",
            "color": "#fe8948",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.revisar
          },
          {
            "status": "No cumple",
            "color": "#f30303",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.noCumple
          },
          {
            "status": "Futuras",
            "color": "#b4b4b4",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.futuras
          },
          {
            "status": "En curso",
            "color": "#6979d4",
            "labelColor": "#333333",
            "estado": this.porcentajesGestion.enCurso
          }
        ];
        break;
    }
    return data;
  }

  getPorcentajesHomeDocumental() {
    this.loaderDocumental = true;
    const filtros: GetPorcentajesHomeRequestDTO = JSON.parse(JSON.stringify({
      fechaInicio: moment(this.filtrosHomeForm.controls.periodo.value[0]).format('YYYY-MM-DD'),
      fechaTermino: moment(this.filtrosHomeForm.controls.periodo.value[1]).format('YYYY-MM-DD'),
      uuidArea: this.filtrosHomeForm.controls.area.value,
      uuidSubArea: this.filtrosHomeForm.controls.subarea.value,
      uuidSitio: this.filtrosHomeForm.controls.sitio.value
    }, (indice, valor) => { if (valor !== null) { return valor; } }));

    this.apiService.getPorcentajesHomeDocumental(filtros).subscribe(
      (response: GetPorcentajesHomeDocumentalResponseDTO) => {
        this.porcentajes = response.data;
        this.charCircleDocumental = this.AmCharts.makeChart("chart-documental", this.estructuraGrafico('documental'));
        this.loaderDocumental = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_PORCENTAJES_HOME_DOCUMENTAL;
        this.toastr.error(msg);
        this.loaderDocumental = false;
      }
    );
  }

  getPorcentajesHomeReclamos() {
    this.loaderReclamo = true;
    const filtros: GetPorcentajesHomeRequestDTO = JSON.parse(JSON.stringify({
      fechaInicio: moment(this.filtrosHomeForm.controls.periodo.value[0]).format('YYYY-MM-DD'),
      fechaTermino: moment(this.filtrosHomeForm.controls.periodo.value[1]).format('YYYY-MM-DD'),
      uuidArea: this.filtrosHomeForm.controls.area.value,
      uuidSubArea: this.filtrosHomeForm.controls.subarea.value,
      uuidSitio: this.filtrosHomeForm.controls.sitio.value
    }, (indice, valor) => { if (valor !== null) { return valor; } }));

    this.apiServiceMantenedor.getPorcentajesHomeReclamos(filtros).subscribe(
      (response: GetPorcentajesHomeReclamosResponseDTO) => {
        this.porcentajesReclamo = response.data;
        this.charCircleReclamos = this.AmCharts.makeChart("chart-reclamo", this.estructuraGrafico('reclamo'));
        this.loaderReclamo = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_PORCENTAJES_HOME_DOCUMENTAL;
        this.toastr.error(msg);
        this.loaderReclamo = false;
      }
    );
  }

  getPorcentajesHomeGestion() {
    this.loaderGestion = true;
    const filtros: GetPorcentajesHomeRequestDTO = JSON.parse(JSON.stringify({
      fechaInicio: moment(this.filtrosHomeForm.controls.periodo.value[0]).format('YYYY-MM-DD'),
      fechaTermino: moment(this.filtrosHomeForm.controls.periodo.value[1]).format('YYYY-MM-DD'),
      uuidArea: this.filtrosHomeForm.controls.area.value,
      uuidSubArea: this.filtrosHomeForm.controls.subarea.value,
      uuidSitio: this.filtrosHomeForm.controls.sitio.value
    }, (indice, valor) => { if (valor !== null) { return valor; } }));

    this.apiGestion.getPorcentajesHomeGestion(filtros).subscribe(
      (response: GetPorcentajesHomeGestionResponseDTO) => {
        this.porcentajesGestion = response.data;
        this.charCircleGestionActividades = this.AmCharts.makeChart("chart-gestion-actividades", this.estructuraGrafico('gestion'));
        this.loaderGestion = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_PORCENTAJES_HOME_DOCUMENTAL;
        this.toastr.error(msg);
        this.loaderGestion = false;
      }
    );
  }

  getListadoAreas() {
    this.loaderAreas = true;
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listadoArea = response.data;
        this.loaderAreas = false;
        this.getListadoSubAreas();
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.loaderAreas = false;
        this.toastr.error(msg);
      }
    );
  }

  getListadoSubAreas(change: boolean = false) {
    if (this.filtrosHomeForm.controls.area.value) {
      if (change) {
        this.filtrosHomeForm.patchValue({ subarea: null, sitio: null });
      }
      this.loaderSubareas = true;
      this.apiServiceMantenedor.getListadoSubAreasMantenedor(this.filtrosHomeForm.controls.area.value).subscribe(
        (response: GetListadoSubAreasResponseDTO) => {
          this.listadoSubArea = response.data;
          this.loaderSubareas = false;
          this.filtrosHomeForm.patchValue({ subarea: null });
          this.getListadoSitios();
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
          this.toastr.error(msg);
          this.loaderSubareas = false;
        }
      );
    } else {
      this.loaderSubareas = false;
    }
  }

  getListadoSitios(change: boolean = false) {
    if (this.filtrosHomeForm.controls.subarea.value) {
      if (change) {
        this.filtrosHomeForm.patchValue({ sitio: null });
      }
      this.loaderSitios = true;
      this.apiServiceMantenedor.getListadoSitiosMantenedor(this.filtrosHomeForm.controls.subarea.value).subscribe(
        (response: GetListadoSitiosResponseDTO) => {
          this.listadoSitio = response.data;
          this.loaderSitios = false;
          this.filtrosHomeForm.patchValue({ sitio: null });
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
          this.toastr.error(msg);
          this.loaderSitios = false;
        }
      );
    } else {
      this.loaderSitios = false;
    }
  }

  filtrar() {
    this.rango = [];
    this.rango.push(this.filtrosHomeForm.controls.periodo.value[0]);
    this.rango.push(this.filtrosHomeForm.controls.periodo.value[1]);
    this.getPorcentajesHomeDocumental();
    this.getPorcentajesHomeReclamos();
    this.getPorcentajesHomeGestion();
  }

  getNotificaciones() {
    this.loaderDocumental = true
    this.apiService.getNotificaciones().subscribe((resp) => {
      this.listadoNotificaciones = resp.data;
      this.notificacionService.setNotificaciones(this.listadoNotificaciones);
      this.loaderDocumental = false;
    }, (error) => {
      const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_LISTADO_NOTIFICACIONES;
      this.toastr.error(msg);
      this.loaderDocumental = false;
    })
  }

  ngOnDestroy() {
    clearInterval(this.intervaloNotificaiones);
  }
}

