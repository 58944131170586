import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { PostExportarXLSDocumentosRequestDTO } from 'src/dtos/request/documental/postExportarXLSDocumentos.request.dto';
import { PostListadoDocumentoPaginadoRequestDTO } from 'src/dtos/request/documental/postListadoDocumentoPaginado.request.dto';
import { GetPostListadoDocumentosEliminadosPaginadoResponseDTO } from 'src/dtos/response/documental/getPostListadoDocumentosEliminadosPaginado.response.dto';
import { environment } from 'src/environments/environment';
import { ModalHistorialComponent } from '../modals/modal-historial/modal-historial.component';
import { saveAs } from 'file-saver';
import { ModalDetalleComponent } from '../modals/modal-detalle/modal-detalle.component';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documental-historial',
  templateUrl: './documental-historial.component.html',
  styleUrls: ['./documental-historial.component.scss']
})
export class DocumentalHistorialComponent implements OnInit {
  listadoDocumentosEliminados: DocumentoDTO[] = [];
  auxListado: DocumentoDTO[] = [];
  filtros: any;

  totalDocumentosElimandos: number = 0;
  pageSize: number = 10;
  currentPage: number = 0;
  maxSize: number = environment.paginatorMaxSize;
  contCheck: number = 0;

  checkDocumentos = false;
  viewFilter: boolean = false;
  loader: boolean = false;

  busqueda = new FormControl(null);

  formCtrlSub: Subscription;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.viewFilter = false;
    this.initFilters();
    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoDocumentosEliminados = [];
        this.getDocumentosEliminadosBusqueda();
      }
    });
  }

  pageChanged(event: any): void {
    this.checkDocumentos = true;
    this.seleccionMultiple();
    this.currentPage = event.page;
    if (this.busqueda.value?.length > 0) {
      this.getDocumentosEliminadosBusqueda();
    } else {
      this.postListadoDocumentosEliminadosPaginado();
    }
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      fechaCreacion: null,
      fechaEdicion: null,
      uuidMacroproceso: null,
      uuidArea: null,
      uuidCreador: null,
      tipoDocumento: null,
      fechaVencimiento: null,
      documentoVinculados: null,
      uuidProceso: null,
      uuidSubarea: null,
      idInterno: null,
      estado: null
    };
    if (initData) {
      this.postListadoDocumentosEliminadosPaginado();
    }
  }

  filterDoc(event: any) {
    this.currentPage = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postListadoDocumentosEliminadosPaginado();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  seleccionMultiple() {
    this.auxListado = this.listadoDocumentosEliminados;
    this.contCheck = 0;
    for (const iterator of this.auxListado) {
      if (!this.checkDocumentos) {
        this.contCheck++;
      }
      iterator.checkDocumento = !this.checkDocumentos;
    }

    this.checkDocumentos = !this.checkDocumentos;
  }

  selDocumento(uuid: string) {
    const documentoAux = this.listadoDocumentosEliminados.find(d => d.uuid === uuid);

    if (documentoAux && documentoAux.checkDocumento) {
      documentoAux.checkDocumento = false;
      if (this.contCheck > 0) {
        this.contCheck--;
      }
    } else {
      documentoAux.checkDocumento = true;
      this.contCheck++;
    }

    this.checkDocumentos = (this.contCheck > 1) ? true : false;
    this.auxListado = this.listadoDocumentosEliminados;
  }

  exportFileHistorial() {
    const documentosHistorial: PostExportarXLSDocumentosRequestDTO = {
      uuidDocumentos: this.auxListado.filter(doc => doc.checkDocumento).map(doc => doc.uuid)
    };
    if (documentosHistorial.uuidDocumentos.length > 0) {
      this.apiService.postExportarXLSDocumentos(documentosHistorial).subscribe(
        res => this.postExportarXLSDocumentosHistorialSuccess(res),
        error => this.postExportarXLSDocumentosHistorialError(error)
      );
    }
  }

  postExportarXLSDocumentosHistorialSuccess(response: any): void {
    let blob = new Blob([response.body], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, `listaDocumentosEliminados.xlsx`);
    this.toastr.success(AppMessage.SUCCESS_DESCARGAR_DOCUMENTO);
  }

  postExportarXLSDocumentosHistorialError(error: any) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_DOCUMENTO;
    this.toastr.error(msg);
  }

  getDocumentosEliminadosBusqueda() {
    this.loader = true;

    this.apiService.getDocumentosEliminadosBusqueda(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (resp) => this.listadoDocumentosEliminadosPaginadoSuccess(resp),
      (error) => this.listadoDocumentosEliminadosPaginadoError(error)
    );
  }

  postListadoDocumentosEliminadosPaginado() {
    this.loader = true;
    this.busqueda.setValue(null);

    let dataRequestHistorial: PostListadoDocumentoPaginadoRequestDTO = JSON.parse(JSON.stringify({
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      fechaEdicionInicio: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[0] : null,
      fechaEdicionTermino: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[1] : null,
      fechaVencimientoInicio: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[0] : null,
      fechaVencimientoTermino: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[1] : null,
      uuidCreador: this.filtros.uuidCreador,
      tipoDocumento: this.filtros.tipoDocumento,
      documentoVinculados: this.filtros.documentoVinculados,
      idInterno: this.filtros.idInterno,
      estado: this.filtros.estado,
      uuidMacroproceso: this.filtros.uuidMacroproceso,
      uuidArea: this.filtros.uuidArea,
      uuidProceso: this.filtros.uuidProceso,
      uuidSubarea: this.filtros.uuidSubarea
    }, (indice, valor) => { if (valor !== null) { return valor; } }));

    this.apiService.postListadoDocumentosEliminadosPaginado(this.currentPage - 1, this.pageSize, dataRequestHistorial).subscribe(
      (resp) => this.listadoDocumentosEliminadosPaginadoSuccess(resp),
      (error) => this.listadoDocumentosEliminadosPaginadoError(error)
    );
  }

  listadoDocumentosEliminadosPaginadoSuccess(response: GetPostListadoDocumentosEliminadosPaginadoResponseDTO) {
    this.listadoDocumentosEliminados = response.data.content;
    this.totalDocumentosElimandos = response.data.totalElements;

    this.currentPage = response.data.pageable.pageNumber + 1;
    for (const iterator of this.listadoDocumentosEliminados) {
      iterator.checkDocumento = false;
    }

    this.auxListado = this.listadoDocumentosEliminados;
    this.checkDocumentos = false;
    this.loader = false;
  }

  listadoDocumentosEliminadosPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS_PAGINADOS_ELIMINADOS;
    this.toastr.error(msg);
    this.loader = false;
  }

  getDescargarDocumentoHistorial(url: string) {
    window.open(url, '_self');
  }

  openModalDetalleHistorial(uuid: string) {
    this.modalService.show(ModalDetalleComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        estadoDetalle: 'ELIMINAR'
      }
    });
  }

  openModalHistorialAcciones(uuid: string, nombreDoc: string) {
    this.modalService.show(ModalHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        nombreDoc
      }
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}
