import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { DeleteEliminarDocumentoRequestDTO } from 'src/dtos/request/documental/deleteEliminarDocumento.request.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';

@Component({
  selector: 'app-modal-eliminar',
  templateUrl: './modal-eliminar.component.html',
  styleUrls: ['./modal-eliminar.component.scss']
})
export class ModalEliminarComponent implements OnInit {
  uuid: string;
  nombre: string;
  eliminarForm: FormGroup;

  onClose: Subject<string>;

  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.eliminarForm = this.formBuilder.group({
      motivo: new FormControl(null, Validators.required)
    });
  }

  aceptar() {
    if (this.eliminarForm.valid) {
      const data: DeleteEliminarDocumentoRequestDTO = {
        motivo: this.eliminarForm.get('motivo').value,
      };
      this.apiService.deleteEliminarDocumento(this.uuid, data).subscribe(
        (resp) => this.deleteEliminarDocumentoSuccess(resp),
        (error) => this.deleteEliminarDocumentoError(error)
      );
    }
  }

  deleteEliminarDocumentoSuccess(resp: GeneralResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_ELIMINAR_DOCUMENTO);
    this.onClose.next('ELIMINAR');
  }

  deleteEliminarDocumentoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ELIMINAR_DOCUMENTO;
    this.toastr.error(msg);
  }
}
