import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { AccionDTO } from 'src/dtos/documental/accion.dto';
import { GetPostHistorialAccionesPaginadoResponseDTO } from 'src/dtos/response/documental/getPostHistorialAccionesPaginado.response.dto';

@Component({
  selector: 'app-modal-historial',
  templateUrl: './modal-historial.component.html',
  styleUrls: ['./modal-historial.component.scss']
})
export class ModalHistorialComponent implements OnInit {

  uuid: string;
  nombreDoc: string

  listadoAcciones: AccionDTO[] = [];

  loader: boolean = false;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.postHistorialAccionesLimitado();
  }

  postHistorialAccionesLimitado() {
    this.loader = true;
    this.apiService.postHistorialAccionesPaginado(0, 5, this.uuid).subscribe(
      (response: GetPostHistorialAccionesPaginadoResponseDTO) => {
        this.listadoAcciones = response.data.content;
        this.loader = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_HISTORIAL_ACCIONES_PAGINADOS;
        this.toastr.error(msg);
        this.loader = false;
      }
    );
  }
}
