import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { PutEditarCategoriaMantenedorResponseDTO } from 'src/dtos/response/gestion/putEditarCatgoriaMantenedor.response.dto';

@Component({
  selector: 'app-modal-c-deshabilitar',
  templateUrl: './modal-c-deshabilitar.component.html',
  styleUrls: ['./modal-c-deshabilitar.component.scss']
})
export class ModalCDeshabilitarComponent {

  uuid: string;
  nombre: string;
  tabName: string;

  onClose: Subject<string>;


  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef
  ) { }
  
  ngOnInit(): void{
    this.onClose = new Subject();
  }

  deshabilitarCategoriaMantenedor() {
    this.apiGestion.deleteDeshabilitarCategoriaMantenedor(this.uuid).subscribe(
      (resp) => this.deshabilitarCategoriaSuccess(resp),
      (error) => this.deshabilitarCategoriaError(error));
  }

  deshabilitarCategoriaSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_CATEGORIA);
    this.onClose.next('0');
  }

  deshabilitarCategoriaError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_CATEGORIA;
    this.toastr.error(msg);
  }

  deshabilitarSubCategoriaDos() {
    this.apiGestion.deleteDeshabilitarSubCategoriaDos(this.uuid).subscribe((resp) => this.deshabilitarSubCategoriaDosSuccess(resp),
      (error) => this.deshabilitarSubCategoriaDosError(error));
  }

  deshabilitarSubCategoriaDosSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  deshabilitarSubCategoriaDosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  deshabilitarSubCategoriaUno() {
    this.apiGestion.deleteDeshabilitarSubCategoriaUno(this.uuid).subscribe((resp) => this.deshabilitarSubCategoriaDosSuccess(resp),
      (error) => this.deshabilitarSubCategoriaDosError(error));
  }

  deshabilitarSubCategoriaUnoSuccess(resp: PutEditarCategoriaMantenedorResponseDTO) {
    this.toastr.success(AppMessage.SUCCESS_DESHABILITAR_SUB_CATEGORIA_UNO);
    this.onClose.next('0');
  }

  deshabilitarSubCategoriaunoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESHABILITAR_SUB_CATEGORIA_UNO;
    this.toastr.error(msg);
  }

  deshabilitar() {
    if (this.tabName === 'categoria')
      this.deshabilitarCategoriaMantenedor();
    if (this.tabName === 'subcategoriauno')
      this.deshabilitarSubCategoriaUno();
    if (this.tabName === 'subcategoriados')
      this.deshabilitarSubCategoriaDos();
  }
 }
