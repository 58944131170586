<div class="loader-container" *ngIf="loaderPaginadoHistorial">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Inicio filtros -->
<div class="filter" *ngIf="viewFilter">
    <app-filtros-reclamos menuSC="false" [modo]="'historial'" [filtrosForm]="filtros"
        (filterRequest)="filterReclamoHistorial($event)" (cerrar)="cerrarFiltros($event)">
    </app-filtros-reclamos>
</div>
<!-- Boton menu en vista mobile -->
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-history ico-page" aria-hidden="true"></i>
            <span class="title1">Historial</span>
        </div>
    </div>
    <div class="section-page">
        <div class="container-consult">
            <div class="subtitle">Reclamo {{idReclamo}}</div>
        </div>
        <div class="tools-table">
            <div class="tools-table">
                <div class="search-mobile">
                    <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                        autocomplete="off" />
                    <i class="fas fa-search ico-input1" aria-hidden="true"></i>
                </div>
            </div>
            <div class="search">
                <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                    autocomplete="off" />
                <i class="fas fa-search ico-input1" aria-hidden="true"></i>
            </div>
            <div class="export">
                <button class="btn btn-table btn-filter" (click)="cerrarFiltros(!viewFilter)">
                    Filtrar <i class="fas fa-filter ico-export" aria-hidden="true"></i>
                </button>
            </div>
            <div class="clean">
                <button class="clean-filters" (click)="initFilters()">Limpiar Filtros</button>
            </div>
        </div>
    </div>
    <!--Table-->
    <div class="section-page-historial">
        <div class="table" style="padding-left: 0px;" *ngIf="!loaderPaginadoHistorial">
            <div class="table-responsive">
                <table id="example" class="display" style="width:100%">
                    <caption>Historial</caption>
                    <thead>
                        <tr>
                            <th scope="col">Nombre Usuario</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Fecha | Hora</th>
                            <th scope="col">Acción</th>
                            <th scope="col">Motivo</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listadoPagHistorial.length > 0">
                        <tr *ngFor="let historial of listadoPagHistorial">
                            <td>{{historial.usuario.nombre + ' ' + historial.usuario.apellido}}</td>
                            <td>{{historial.usuario.cargo}}</td>
                            <td>{{historial.fechaCreacion + 'Z' | date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{historial.accion}}</td>
                            <td>{{historial.motivo !== '' ? historial.motivo : '--'}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="sin-resultados" *ngIf="listadoPagHistorial.length === 0">
                    <h1 class="text-center">Sin resultados.</h1>
                </div>
            </div>
        </div>

        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalRegisterHistorial > 10"
            [ngStyle]="{'visibility': loaderPaginadoHistorial ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}"
                        [totalItems]="totalRegisterHistorial" (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>