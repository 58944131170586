import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessage } from 'src/app/app.message';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { HistorialActividadDTO } from 'src/dtos/gestion/historialActividad.dto';
import { GetListadoHistorialActividadesPaginadoResponseDTO } from 'src/dtos/response/gestion/getListadoHistorialActividadesPaginado.response.dto';

@Component({
  selector: 'app-gestion-historial',
  templateUrl: './gestion-historial.component.html',
  styleUrls: ['./gestion-historial.component.scss']
})
export class GestionHistorialComponent implements OnInit {

  uuidActividad: string;
  idActividad: string;


  loader: boolean = false;
  listadoHistorialActividades: HistorialActividadDTO[] = [];
  totalRegistros: number = 0;
  formCtrlSub: Subscription;
  busqueda: FormControl = new FormControl('');
  viewFilter: boolean = false;
  pageSize: number = 10;
  currentPage: number = 1;

  constructor(
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private actividadService: ActividadService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.uuidActividad = params.uuidActividad;
      this.idActividad = params.idActividad;
      if (this.uuidActividad) {
        console.log(this.uuidActividad);
        this.getListadoHistorialActividadesPaginadoBusqueda();
      }
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.listadoHistorialActividades = [];
        this.getListadoHistorialActividadesPaginadoBusqueda();
      }
    });
  }

  getListadoHistorialActividadesPaginadoBusqueda() {
    this.loader = true;
    this.apiGestion.getListadoHistorialActividadesPaginadoBusqueda(this.currentPage - 1, this.pageSize, this.uuidActividad, this.busqueda.value).subscribe(
      (resp) => this.getListadoHistortialActividadesPaginadoSuccess(resp),
      (error) => this.getListadoHistorialActividadesPaginadoError(error));
  }

  getListadoHistortialActividadesPaginadoSuccess(resp: GetListadoHistorialActividadesPaginadoResponseDTO) {
    this.listadoHistorialActividades = resp.data.content;
    this.totalRegistros = resp.data.totalElements;
    this.loader = false;
  }

  getListadoHistorialActividadesPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERR0R_OBTENER_LISTADO_HISTORIAL_ACTIVIDADES_PAGINADO;
      this.toastr.error(msg);
      this.loader = false;
  }

  hideShowFilter(event: boolean) {
    this.viewFilter = event;
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
  }

}
