<nav id="sidebar" class="content-page" [ngClass]="{'active': !menuActive}">
    <div class="top-bar">
        <button class="toggler btn btn-sm" type="button" (click)="menuActive = !menuActive">
            <i class="fas fa-bars btnnav" aria-hidden="true"></i>
        </button>
    </div>
    <div class="closenav">
        <button class="tn btn-info closenav1" type="button" (click)="menuActive = !menuActive">
            <i class="fas fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="sidebar-header">
        <img src="assets/img/wl-kc.svg" width="100%" alt="Komatsu" />
    </div>
    <ul class="nav flex-column flex-nowrap overflow-hidden">
        <!-- HOME -->
        <li class="nav-item" *ngIf="habilitarOpcion('home')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                routerLink="/web/home">
                <i class="fas fa-home ico-menu" aria-hidden="true"></i><span class="mode1 d-sm-inline">Home</span>
            </a>
        </li>
        <!-- DOCUMENTAL -->
        <li class="nav-item" *ngIf="habilitarOpcion('documental')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                data-toggle="collapse" data-target="#submenu1" routerLink="/web/documental-modolist">
                <i class="fas fa-file-alt ico-menu" aria-hidden="true"></i><span
                    class="mode1 d-sm-inline">Documental</span>
            </a>
            <div class="collapse" id="submenu1" aria-expanded="false">
                <ul class="flex-column pl-2 nav">
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/documental-historial">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Documentos Eliminados</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <!-- GESTION ACTIVIDADES -->
        <li class="nav-item" *ngIf="habilitarOpcion('gestion')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                routerLink="/web/gestion-actividades">
                <i class="fas fa-thumbs-up ico-menu" aria-hidden="true"></i><span class="mode1 d-sm-inline">Gestión de
                    Actividades</span>
            </a>
        </li>
        <!-- RECLAMOS -->
        <li class="nav-item" *ngIf="habilitarOpcion('reclamo')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                [attr.data-toggle]="user.rol.nombre.toLowerCase() !== 'general' ? 'collapse' : null"
                data-target="#submenu2" routerLink="/web/reclamos">
                <i class="fas fa-exclamation ico-menu" aria-hidden="true"></i><span
                    class="mode1 d-sm-inline">Reclamos</span>
            </a>
            <div class="collapse" id="submenu2" aria-expanded="false" *ngIf="habilitarOpcion('servicio')">
                <ul class="flex-column pl-2 nav">
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/reclamos-servicio-cliente">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Servicio al cliente</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <!-- MANTENEDOR -->
        <li class="nav-item" *ngIf="habilitarOpcion('mantenedor')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                data-toggle="collapse" data-target="#submenu3" routerLink="/web/mantenedores">
                <i class="fas fa-wrench ico-menu" aria-hidden="true"></i><span
                    class="mode1 d-sm-inline">Mantenedor</span>
            </a>
            <div class="collapse" id="submenu3" aria-expanded="false">
                <ul class="flex-column pl-2 nav">
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/mantenedor-tipodocumento">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Tipo de documento</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                        routerLink="/web/mantenedor-categorias">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Categorías</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/mantenedor-tiemposrespuesta">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Semáforo reclamos</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/mantenedor-usuarios">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="list-group-item list-group-item-action bg-blue text-white"
                            routerLink="/web/mantenedor-jerarquizacion">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>Jerarquización</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <!-- AREAS -->
        <li class="nav-item" *ngIf="habilitarOpcion('areas')">
            <a class="nav-link collapsed text-truncate bg-dark list-group-item list-group-item-action flex-column align-items-start"
                href="#submenu4" data-toggle="collapse" data-target="#submenu4">
                <i class="far fa-building ico-menu" aria-hidden="true"></i><span class="mode1 d-sm-inline">Áreas</span>
            </a>
            <div class="collapse" id="submenu4" aria-expanded="false">
                <ul class="flex-column pl-2 nav">
                    <li class="nav-item" *ngFor="let area of listadoAreas">
                        <div *ngIf="area.nombre.toLowerCase() === 'quality assurance'">
                            <a class="nav-link1 bg-blue list-group-item collapsed" href="#submenu2sub1"
                            data-toggle="collapse" data-target="#submenu2sub1">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span style="margin-top: 10px;">QA</span>
                            </a>
                            <div class="collapse" id="submenu2sub1" aria-expanded="false">
                                <ul class="flex-column nav pl-4"
                                    style="padding: 0px !important; padding-left: 0px !important;">
                                    <li class="nav-item">
                                        <a class="list-group-item list-group-item-action bg-blue-light text-white p-1 text-truncate level3"
                                            routerLink="/web/mejora-continua"> Mejora Continua </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="list-group-item list-group-item-action bg-blue-light text-white p-1 text-truncate level3"
                                            href="#"> Aseguramiento de calidad </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="list-group-item list-group-item-action bg-blue-light text-white p-1 text-truncate level3"
                                            href="#"> Proyecto </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="list-group-item list-group-item-action bg-blue-light text-white p-1 text-truncate level3"
                                            href="#"> Control de gestión y BI </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item" *ngFor="let area of listadoAreas">
                        <a *ngIf="area.nombre.toLowerCase() !== 'quality assurance'" class="list-group-item list-group-item-action bg-blue text-white" href="#">
                            <span class="dot-container"><i class="fas fa-circle dot" aria-hidden="true"></i></span>
                            <span>{{area.nombre}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</nav>