import { ProcesoDTO } from "src/dtos/documental/proceso.dto";
import { BaseResponseDTO } from "../base.response.dto";

export class GetListadoProcesosPaginadosResponseDTO extends BaseResponseDTO {
    success: boolean;
    msg: string;
    data: {
        content: Array<ProcesoDTO>;
        last: boolean;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        totalElements: number;
        totalPages: number;
        number: number;
        size: number;
        first: boolean;
        pageable: {
            sort: {
                empty: boolean;
                sorted: boolean;
                unsorted: boolean;
            };
            offset: number;
            pageNumber: number;
            pageSize: number;
            paged: boolean;
            unpaged: boolean;
        };
        numberOfElements: number;
        empty: boolean;
    };
}
