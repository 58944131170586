import { Injectable } from '@angular/core';
import { NotifcacionDTO } from 'src/dtos/documental/notificacion.dto';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  private listadoNotifiaciones: NotifcacionDTO[] = [];
  private uuidRecurso: string;

  constructor() { }

  getNotificaciones() {
    return this.listadoNotifiaciones;
  }

  setNotificaciones(notificaciones: NotifcacionDTO[]) {
    this.listadoNotifiaciones = notificaciones;
  }

  getUuidRecurso() {
    return this.uuidRecurso;
  }

  setUuidRecurso(uuid: string) {
    this.uuidRecurso = uuid;
  }
}
