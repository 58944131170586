import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { AccionDTO } from 'src/dtos/documental/accion.dto';
import { PostHistorialAccionRequestDTO } from 'src/dtos/request/documental/postHistorialAccion.request.dto';
import { GetPostHistorialAccionesPaginadoResponseDTO } from 'src/dtos/response/documental/getPostHistorialAccionesPaginado.response.dto';
import { environment } from 'src/environments/environment';
import { ModalDetalleComponent } from '../modals/modal-detalle/modal-detalle.component';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  listadoAcciones: AccionDTO[] = [];
  filtros: any;

  totalDocumentosHistorial: number = 0;
  pageSize: number = 10;
  currentPage: number = 0;
  maxSize: number = environment.paginatorMaxSize;

  viewFilter: boolean = false;
  loader: boolean = false;

  uuid: string;
  nombreDoc: string;

  busqueda = new FormControl(null);

  formCtrlSub: Subscription;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.viewFilter = false;

    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      this.nombreDoc = params.nombre;
      if (this.uuid) {
        this.initFilters();
      }
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoAcciones = [];
        this.gettHistorialAccionesPaginadoBusqueda();
      }
    });
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      nombreUsuario: null,
      accion: null,
      fecha: null,
      cargo: null
    };
    if (initData) {
      this.postHistorialAccionesPaginado();
    }
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    if (this.busqueda.value?.length > 0) {
      this.gettHistorialAccionesPaginadoBusqueda();
    } else {
      this.postHistorialAccionesPaginado();
    }
  }

  filterDoc(event: PostHistorialAccionRequestDTO) {
    this.currentPage = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postHistorialAccionesPaginado();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  gettHistorialAccionesPaginadoBusqueda() {
    this.loader = true;

    this.apiService.gettHistorialAccionesPaginadoBusqueda(this.currentPage - 1, this.pageSize, this.uuid, this.busqueda.value).subscribe(
      (resp) => this.historialAccionesPaginadoSuccess(resp),
      (error) => this.historialAccionesPaginadoError(error)
    );
  }

  postHistorialAccionesPaginado() {
    this.loader = true;
    this.busqueda.setValue(null);

    let dataRequest: PostHistorialAccionRequestDTO = {
      nombreUsuario: this.filtros.nombreUsuario,
      fechaInicio: this.filtros.fecha ? this.filtros.fecha[0] : null,
      fechaTermino: this.filtros.fecha ? this.filtros.fecha[1] : null,
      cargo: this.filtros.cargo,
      accion: this.filtros.accion
    }
    this.apiService.postHistorialAccionesPaginado(this.currentPage - 1, this.pageSize, this.uuid, dataRequest).subscribe(
      (resp) => this.historialAccionesPaginadoSuccess(resp),
      (error) => this.historialAccionesPaginadoError(error)
    );
  }

  historialAccionesPaginadoSuccess(response: GetPostHistorialAccionesPaginadoResponseDTO) {
    this.listadoAcciones = response.data.content;
    this.totalDocumentosHistorial = response.data.totalElements;
    this.loader = false;
  }

  historialAccionesPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_HISTORIAL_ACCIONES_PAGINADOS;
    this.toastr.error(msg);
    this.loader = false;
  }

  openModalDetalle(uuid: string) {
    this.modalService.show(ModalDetalleComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        estadoDetalle: 'HISTORIAL'
      }
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}
