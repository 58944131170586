import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { ModalReclamosEditarComponent } from '../modals/modal-reclamos-editar/modal-reclamos-editar.component';
import { ModalReclamosCerrarComponent } from '../modals/modal-reclamos-cerrar/modal-reclamos-cerrar.component';
import { ModalReclamosComentariosComponent } from '../modals/modal-reclamos-comentarios/modal-reclamos-comentarios.component';
import { ModalReclamosHistorialComponent } from '../modals/modal-reclamos-historial/modal-reclamos-historial.component';
import { ModalReclamosRechazarComponent } from '../modals/modal-reclamos-rechazar/modal-reclamos-rechazar.component';
import { ModalReclamosEliminarComponent } from '../modals/modal-reclamos-eliminar/modal-reclamos-eliminar.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AppMessage } from 'src/app/app.message';
import { ActivatedRoute } from '@angular/router';
import { GetDetalleReclamoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleReclamo.response.dto';
import { ReclamoDTO } from 'src/dtos/mantenedor/reclamo.dto';
import * as moment from 'moment';
import { AdjuntoDTO } from 'src/dtos/mantenedor/adjunto.dto';
import { SemaforoDTO } from 'src/dtos/mantenedor/semaforo.dto';
import { GetListadoSemaforoResponseDTO } from 'src/dtos/response/mantenedor/getListadoSemaforo.response.dto';
import { UsuarioModel } from 'src/models/usuario.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PostGuardarRespuestaOficialResponseDTO } from 'src/dtos/response/mantenedor/postGuardarRespuestaOficial.response.dto';
import { PostEnviarRespuestaOficialResponseDTO } from 'src/dtos/response/mantenedor/postEnviarRespuestaOficial.response.dto';
import { ModalReclamosAsignarReclamoComponent } from '../modals/modal-reclamos-asignar-reclamo/modal-reclamos-asignar-reclamo.component';
import { ModalReclamosResponderRechazoComponent } from '../modals/modal-reclamos-responder-rechazo/modal-reclamos-responder-rechazo.component';

@Component({
  selector: 'app-consultar-reclamos',
  templateUrl: './consultar-reclamos.component.html',
  styleUrls: ['./consultar-reclamos.component.scss']
})
export class ConsultarReclamosComponent {
  detalleReclamo: ReclamoDTO;
  listadoSemaforo: SemaforoDTO[] = [];
  usuario: UsuarioModel;

  uuid: string;
  idInterno: string;
  lugarHallazgo: string = '';
  asignacionHallazgo: string = '-';

  respuestaOficial = new FormControl(null);

  activarEnviar: boolean = false;
  sc: boolean = false;

  formCtrlSub: Subscription;
  loader: boolean = false;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.usuario = this.userService.getUser();
    this.route.queryParams.subscribe((params) => {
      this.sc = params.sc;
      this.uuid = params.uuid;
      if (this.uuid) {
        this.getObtenerReclamoMantenedor();
      }
    });
    this.formCtrlSub = this.respuestaOficial.valueChanges.subscribe(value => {
      this.activarEnviar = false;
    });
    this.getListadoSemaforoMantenedor();
  }

  obtenerUrl(adjunto: AdjuntoDTO) {
    let url;
    switch (true) {
      case adjunto.nombreArchivo.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.nombreArchivo.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.nombreArchivo.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.nombreArchivo.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        url = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return url;
  }

  getDescargarAdjunto(url: string) {
    window.open(url, '_self');
  }

  back() {
    this.location.back();
  }

  calcularSemaforo() {
    const cantidad = moment().diff(this.detalleReclamo.fechaCreacion, 'days');
    const color = this.listadoSemaforo.find(ls => cantidad <= ls.cantidad);
    console.log(cantidad);
    console.log(color);
    return color ? { color: color.color, cantidad } : { color: 'rojo', cantidad };
  }

  getListadoSemaforoMantenedor() {
    this.apiServiceMantenedor.getListadoSemaforoMantenedor().subscribe(
      (response: GetListadoSemaforoResponseDTO) => {
        this.listadoSemaforo = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_SEMAFORO;
        this.toastr.error(msg);
      }
    );
  }

  getObtenerReclamoMantenedor() {
    this.loader = true;
    this.apiServiceMantenedor.getObtenerReclamoMantenedor(this.uuid, this.sc).subscribe(
      (response: GetDetalleReclamoResponseDTO) => {
        this.detalleReclamo = response.data;
        this.detalleReclamo.estadoSemaforo = this.calcularSemaforo();
        this.lugarHallazgo = this.detalleReclamo.hallazgo.area.nombre + ' / ' + this.detalleReclamo.hallazgo.subarea.nombre + ' / ' + this.detalleReclamo.hallazgo.sitio.nombre;
        if (this.detalleReclamo.hallazgo.ubicacion) {
          this.lugarHallazgo += ' - ' + this.detalleReclamo.hallazgo.ubicacion;
        }
        if (this.detalleReclamo.asignacion?.area.nombre) {
          this.asignacionHallazgo = this.detalleReclamo.asignacion.area.nombre;
          if (this.detalleReclamo.asignacion.subarea?.nombre) {
            this.asignacionHallazgo += ' / ' + this.detalleReclamo.asignacion.subarea.nombre;
            if (this.detalleReclamo.asignacion.sitio?.nombre) {
              this.asignacionHallazgo += ' / ' + this.detalleReclamo.asignacion.sitio.nombre;
            }
          }
        }
        if (this.detalleReclamo.hasOwnProperty('respuesta')) {
          this.respuestaOficial.setValue(this.detalleReclamo.respuesta);
          this.activarEnviar = true;
        }
        this.idInterno = this.detalleReclamo.idInterno;
        this.loader = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_RECLAMO;
        this.toastr.error(msg);
        this.loader = false;
      }
    );
  }

  guardarRespuesta() {
    if (this.detalleReclamo.respuesta) {
      this.apiServiceMantenedor.postEditarRespuestaOficial(this.uuid, this.respuestaOficial.value).subscribe(
        (response: PostGuardarRespuestaOficialResponseDTO) => {
          this.activarEnviar = true;
          this.toastr.success(AppMessage.SUCCESS_EDITAR_RESPUESTA);
          this.getObtenerReclamoMantenedor();
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_RESPUESTA;
          this.toastr.error(msg); 
        }
      )
    }
    else {
      this.apiServiceMantenedor.postGuardarRespuestaOficial(this.uuid, this.respuestaOficial.value).subscribe(
        (response: PostGuardarRespuestaOficialResponseDTO) => {
          this.activarEnviar = true;
          this.toastr.success(AppMessage.SUCCESS_RESPUESTA_OFICIAL);
          this.getObtenerReclamoMantenedor();
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_RESPUESTA_OFICIAL;
          this.toastr.error(msg);
        }
      );
    }
  }

  enviarRespuesta() {
    this.apiServiceMantenedor.postEnviarRespuestaOficial(this.uuid, this.respuestaOficial.value).subscribe(
      (response: PostEnviarRespuestaOficialResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_ENVIAR_RESPUESTA);
        this.getObtenerReclamoMantenedor();
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_ENVIAR_RESPUESTA;
        this.toastr.error(msg);
      }
    );
    this.activarEnviar = false;
  }

  openModalReclamosEditar() {
    const modalEditar: BsModalRef = this.modalService.show(ModalReclamosEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleReclamo.uuid
      }
    });
    this.cerrarModalReclamo(modalEditar);
  }

  openModalReclamosCerrar() {
    const modalCerrar: BsModalRef = this.modalService.show(ModalReclamosCerrarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleReclamo.uuid,
        idInterno: this.detalleReclamo.idInterno,
        idImpacto: this.detalleReclamo.desviacion.uuid
      }
    });
    this.cerrarModalReclamo(modalCerrar);
  }

  openModalReclamosComentarios() {
    this.modalService.show(ModalReclamosComentariosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        reclamo: this.detalleReclamo
      }
    });
  }

  openModalReclamosHistorial() {
    this.modalService.show(ModalReclamosHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid: this.detalleReclamo.uuid,
        idReclamo: this.detalleReclamo.idInterno
      }
    });
  }

  openModalReclamosRechazar() {
    const modalRechazar: BsModalRef = this.modalService.show(ModalReclamosRechazarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleReclamo.uuid,
        idInterno: this.detalleReclamo.idInterno
      }
    });
    this.cerrarModalReclamo(modalRechazar);
  }

  openModalReclamosEliminar() {
    const modalEliminar: BsModalRef = this.modalService.show(ModalReclamosEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleReclamo.uuid,
        idInterno: this.detalleReclamo.idInterno
      }
    });
    this.cerrarModalReclamo(modalEliminar);
  }


  openModalReclamosAsignarReclamo() {
    if (!this.detalleReclamo.asignacion) {
      const modalAsignar: BsModalRef = this.modalService.show(ModalReclamosAsignarReclamoComponent, {
        ignoreBackdropClick: false,
        keyboard: false,
        class: "modal-dialog-centered modal-lg",
        initialState: {
          id: this.detalleReclamo.uuid
        }
      });
      this.cerrarModalReclamo(modalAsignar);
    }
  }

  openModalReclamosResponderRechazo() {
    const modalResponderRechazo: BsModalRef = this.modalService.show(ModalReclamosResponderRechazoComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: this.detalleReclamo.uuid,
        idInterno: this.detalleReclamo.idInterno,
        rechazo: this.detalleReclamo.rechazo
      }
    });
    this.cerrarModalReclamo(modalResponderRechazo);
  }

  cerrarModalReclamo(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.getObtenerReclamoMantenedor();
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}
