<div style="float: right;">
    <div class="messagepop pop" #pop>
        <i class="fas fa-bell bell1" aria-hidden="true"></i>
        <i class="fas" [ngClass]="{'fas fa-circle dot-red': listadoNotificaciones.length > 0}" aria-hidden="true"></i>
        <span class="title3">Notificaciones</span>
        <div class="see-all">
            <a routerLink="/web/notificaciones">Ver todo</a>
        </div>
        <div class="section" *ngFor="let notificacion of listadoNotificaciones" (click)="abrirNotificacion(notificacion)">
            <div class="section1"> <i class="fa fa-exclamation ico-notification" aria-hidden="true"></i> </div>
            <div class="section2">
                <strong>{{notificacion.detalle}}</strong>
            </div>
            <div class="section3"> {{calcularTiempo(notificacion)}}</div>
        </div>
        <div class="sin-resultados" *ngIf="listadoNotificaciones.length === 0">
            <h1 class="text-center">Sin resultados.</h1>
        </div>
    </div>
    <a id="notification" #notification>
        <div class="notification">
            <i class="fas fa-bell bell" aria-hidden="true"></i>
            <i class="fas" [ngClass]="{'fas fa-circle dot-red': listadoNotificaciones.length > 0}" aria-hidden="true"></i>
        </div>
    </a>
    <a routerLink="/web/profile">
        <div class="profile" style="background-image:url(assets/img/asignado.jpg)"></div>
    </a>