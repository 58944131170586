import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { HistorialDTO } from 'src/dtos/mantenedor/historial.dto';
import { PostHistorialReclamoRequestDTO } from 'src/dtos/request/mantenedor/postHistorialReclamo.request.dto';
import { GetPostListadoHistorialReclamoPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoHistorialReclamoPaginados.response.dto';

@Component({
  selector: 'app-historial-reclamo',
  templateUrl: './historial-reclamo.component.html',
  styleUrls: ['./historial-reclamo.component.scss']
})
export class HistorialReclamoComponent implements OnInit {
  listadoPagHistorial: HistorialDTO[] = [];
  filtros: any;

  totalRegisterHistorial: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;

  viewFilter: boolean = false;
  loaderPaginadoHistorial: boolean = false;

  uuid: string;
  idReclamo: string;
  busqueda = new FormControl(null);

  formCtrlSub: Subscription;

  constructor(
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.viewFilter = false;

    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      this.idReclamo = params.idReclamo;
      if (this.uuid) {
        this.initFilters();
      }
    });

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoPagHistorial = [];
        this.getListadoHistorialReclamoPaginadoBusqueda();
      }
    });
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      nombreUsuario: null,
      accion: null,
      fecha: null,
      cargo: null
    };

    if (initData) {
      this.postListadoHistorialReclamoPaginado();
    }
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    if (this.busqueda.value?.length > 0) {
      this.getListadoHistorialReclamoPaginadoBusqueda();
    } else {
      this.postListadoHistorialReclamoPaginado();
    }
  }

  filterReclamoHistorial(event: PostHistorialReclamoRequestDTO) {
    this.currentPage = 1;
    this.cerrarFiltros(false);

    this.filtros = event;
    this.postListadoHistorialReclamoPaginado();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  getListadoHistorialReclamoPaginadoBusqueda() {
    this.loaderPaginadoHistorial = true;

    this.apiServiceMantenedor.getListadoHistorialReclamoPaginadoBusqueda(this.currentPage - 1, this.pageSize, this.uuid, this.busqueda.value).subscribe(
      (resp) => this.listadoHistorialReclamoPaginadoSuccess(resp),
      (error) => this.listadoHistorialReclamoPaginadoError(error)
    );
  }

  postListadoHistorialReclamoPaginado() {
    this.loaderPaginadoHistorial = true;
    this.busqueda.setValue(null);

    let dataRequest: PostHistorialReclamoRequestDTO = {
      nombreUsuario: this.filtros.nombreUsuario,
      fechaInicio: this.filtros.fecha ? this.filtros.fecha[0] : null,
      fechaTermino: this.filtros.fecha ? this.filtros.fecha[1] : null,
      cargo: this.filtros.cargo,
      accion: this.filtros.accion
    }
    this.apiServiceMantenedor.postListadoHistorialReclamoPaginado(this.currentPage - 1, this.pageSize, this.uuid, dataRequest).subscribe(
      (response) => this.listadoHistorialReclamoPaginadoSuccess(response),
      (error) => this.listadoHistorialReclamoPaginadoError(error)
    );
  }

  listadoHistorialReclamoPaginadoSuccess(response: GetPostListadoHistorialReclamoPaginadosResponseDTO) {
    this.listadoPagHistorial = response.data.content;
    this.currentPage = response.data.pageable.pageNumber + 1;
    this.totalRegisterHistorial = response.data.totalElements;
    this.loaderPaginadoHistorial = false;
  }

  listadoHistorialReclamoPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_HISTORIAL_RECLAMO_PAGINADOS;
    this.toastr.error(msg);
    this.loaderPaginadoHistorial = false;
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }

}
