<div class="loader-container" *ngIf="loaderSimple || loaderPaginado">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Inicio filtros -->
<div id="div1" class="filter-modegrid" *ngIf="viewFilter">
    <app-filtros [modo]="'grid'" [filtrosForm]="filtros" (filterRequest)="filterDocModoGrid($event)"
        (cerrar)="cerrarFiltrosModoGrid($event)">
    </app-filtros>
</div>
<!-- Boton menu en vista mobile -->
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-file-alt ico-page" aria-hidden="true"></i><span
                class="title1">Documental</span></div>
    </div>
    <!-- Cambiar a modo LIST -->
    <div class="mode-grid-list">
        <button type="button" id="sidebarCollapse" class="btn btn-grid-list" routerLink="/web/documental-modolist">
            <span class="text-grid-list">Ver Modo List</span>
            <i class="fas fa-list ico-grid-list" aria-hidden="true"></i>
        </button>
    </div>
    <div [ngClass]="viewListaLateral ? 'section-page-modogrid' : 'section-page-modogrid-documentos'">
        <tabset class="tab-container" #staticTabs>
            <ng-container *ngFor="let jer of listaJerarquizacion; let i = index;">
                <tab *ngIf="jer.visible" [heading]="jer.nameSelect" [id]="jer.id" (selectTab)="selectTab(i)">
                    <div class="subtitle">{{jer.title}}</div>
                    <div class="folder-row" *ngIf="viewListaLateral && !loaderSimple && !loaderPaginado">
                        <div *ngFor="let lap of listadoAuxPaginado">
                            <div class="folder" *ngIf="lap.cantidad > 0 || i === 0 || i === 1"
                                (click)="selectFolder(lap, (i+1))">
                                <div class="image-folder"><img src="assets/img/folder1.png" width="100%"
                                        alt="carpeta" /></div>
                                <div class="title-folder">{{lap.nombre}}</div>
                                <div class="items-folder">{{lap.cantidad}} items</div>
                            </div>
                        </div>
                    </div>
                    <div class="tools-table" *ngIf="!viewListaLateral && !loaderSimple && !loaderPaginado">
                        <div class="search-mobile">
                            <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                                autocomplete="off" />
                            <i class="fas fa-search ico-input1" aria-hidden="true"></i>
                        </div>
                        <div class="search">
                            <input id="txtSearch" placeholder="Buscar" class="form-control" [formControl]="busqueda"
                                autocomplete="off" />
                            <i class="fas fa-search ico-input1" aria-hidden="true"></i>
                        </div>
                        <div class="export">
                            <button class="btn btn-table btn-filter"
                                (click)="cerrarFiltrosModoGrid(!viewFilter)">Filtros Avanzados
                                <i class="fas fa-filter ico-export" aria-hidden="true"></i></button>
                        </div>
                        <div class="clean">
                            <button class="clean-filters" (click)="initFiltersModoGrid()"> Limpiar Filtros</button>
                        </div>
                    </div>
                    <div class="tabla" *ngIf="!viewListaLateral && !loaderSimple && !loaderPaginado">
                        <div class="table-responsive col">
                            <table id="example" class="display" style="width:100%">
                                <caption>Archivos documental</caption>
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 10%;">ID</th>
                                        <th scope="col" style="width: 20%; text-align: center;">Estado</th>
                                        <th scope="col" style="width: 25%;">Nombre</th>
                                        <th scope="col" style="width: 20%;">Fecha vencimiento</th>
                                        <th scope="col" style="width: 25%; word-break:normal;">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let documento of listadoDocumentos;">
                                        <td>{{documento.idInterno}}</td>
                                        <td style="text-align: center;">
                                            <button type="button" class="btn-ico-tool" tooltip="Vigente"
                                                placement="right">
                                                <button type="button" class="btn-ico-tool" tooltip="Por vencer"
                                                    placement="right">
                                                    <i *ngIf="documento.estado.toUpperCase() === 'POR VENCER'"
                                                        class="fas fa-clock ico-doc yellow" aria-hidden="true"></i>
                                                    <i *ngIf="documento.estado.toUpperCase() === 'VIGENTE'"
                                                        class="fas fa-check-square ico-doc green"
                                                        aria-hidden="true"></i>
                                                    <i *ngIf="documento.estado.toUpperCase() === 'VENCIDO'"
                                                        class="fas fa-calendar-times ico-doc red"
                                                        aria-hidden="true"></i>
                                                </button>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn-details1"
                                                (click)="openModalDetalleModoGrid(documento.uuid)">
                                                {{documento.nombre}}
                                            </button>
                                        </td>
                                        <td>{{documento.fechaVencimiento | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-nowrap">
                                            <button type="button" class="btn-ico-tool" tooltip="Descargar"
                                                [disabled]="!documento.tieneAdjunto" placement="top"
                                                (click)="getDescargarDocumento(documento.archivoAdjunto?.url)">
                                                <i class="fas fa-arrow-down ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button *ngIf="uuidUser === documento.usuarioCreador.uuid" type="button"
                                                class="btn-ico-tool" tooltip="Editar" placement="top"
                                                (click)="openModalEditarModoGrid(documento.uuid)">
                                                <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button *ngIf="uuidUser !== documento.usuarioCreador.uuid" type="button"
                                                class="btn-ico-tool" tooltip="Actualizar" placement="top"
                                                (click)="openModalActualizarModoGrid(documento.uuid)">
                                                <i class="fas fa-exchange-alt ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button type="button" class="btn-details3" tooltip="Historial"
                                                placement="top"
                                                (click)="openModalHistorialModoGrid(documento.uuid, documento.nombre)">
                                                <i class="fas fa-history ico-action" aria-hidden="true"></i>
                                            </button>
                                            <button *ngIf="uuidUser === documento.usuarioCreador.uuid" type="button"
                                                class="btn-ico-tool" tooltip="Eliminar" placement="top"
                                                (click)="openModalEliminarModoGrid(documento.uuid, documento.nombre)">
                                                <i class="fas fa-trash-alt ico-action-blue" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </tab>
            </ng-container>
        </tabset>
        <!--Pagination-->
        <div class="row mt-3" *ngIf="totalDocumentos > 10"
            [ngStyle]="{'visibility': loaderSimple || loaderPaginado ? 'hidden' : 'visible'}">
            <div class="col text-right">
                <div class="pagination">
                    <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                        [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                        [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}" [totalItems]="totalDocumentos"
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Listado lateral derecho -->
<div class="section-page-modogrid-panel" *ngIf="viewListaLateral && !loaderSimple && !loaderPaginado">
    <div class="subtitle-panel">{{nameLateral}}</div>
    <div class="search-modogrid">
        <input id="txtSearch" placeholder="Buscar" class="form-control-modogrid" [(ngModel)]="busquedaLateral"
            [ngModelOptions]="{standalone: true}" />
        <i class="fas fa-search ico-input-modogrid" aria-hidden="true"></i>
    </div>
    <div class="folders vertical-scroll-y">
        <div *ngFor="let ll of listaAuxLateral | filter:busquedaLateral;">
            <div class="folder-panel" *ngIf="ll.cantidad > 0 || staticTabs.tabs[0].active  || staticTabs.tabs[1].active"
                (click)="selectFolder(ll)">
                <div class="image-folder-panel"><img src="assets/img/folder1.png" width="100%" alt="carpeta" /></div>
                <div class="title-folder-panel">{{ll.nombre}}</div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>