<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1"><img src="assets/img/komatsu-cummins.svg" width="180px" height="29px"
                    alt="Komatsu" /></div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="far fa-building ico-page" aria-hidden="true"></i><span class="title1">Mejora
                continua</span>
        </div>
    </div>
    <div class="section-page">
        <app-menu></app-menu>
    </div>
    <div class="section-page">
        <div class="container-colum">
            <div class="title-article">{{detalleArticulo?.titulo}}</div>
            <div class="edit"><a (click)="goEditarArticulo(detalleArticulo.uuidArticulo)"><button type="button" class="button-actions"
                        placement="top"><i class="fas fa-pen ico-button-actions" aria-hidden="true"></i>
                        Editar</button></a></div>
            <img [src]="detalleArticulo?.url?.url" alt="foto" width="661px" height="403px"
                style="float: left; margin-right: 25px; margin-bottom: 15px;">
            <div [innerHTML]="detalleArticulo?.cuerpo">

            </div>
        </div>
        <div class="section1"><button (click)="regresar()" type="button" class="btn-primary">Regresar</button></div>
    </div>
</div>
<div class="full-height">&nbsp;</div>