import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  message: string = "";
  callback: () => {};

  constructor(public bsModalRef: BsModalRef) { }

  aceptarConfirm() {
    this.bsModalRef.hide();
    this.callback();
  }

}
