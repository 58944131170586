<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-wrench ico-page" aria-hidden="true"></i><span class="title1">Tipo de
                documento</span></div>
    </div>
    <div class="create-mantenedor">
        <button type="button" class="btn btn-secundary" (click)="openModalTdCrear()">
            <span class="text-create"> crear nuevo </span><i class="far fa-plus-square create-ico"
                aria-hidden="true"></i>
        </button>
    </div>
    <div class="search-mantenedor">
        <input id="txtSearch" placeholder="Buscar" class="form-control" [(ngModel)]="busqueda"
            [ngModelOptions]="{standalone: true}" /><i class="fas fa-search ico-input-search" aria-hidden="true"></i>
    </div>
    <div class="section-page">
        <div class="table">
            <div class="table-responsive">
                <table id="example" class="display" style="width:100%">
                    <caption>Mantenedor tipo documentos</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 30%;">Nombre</th>
                            <th scope="col" style="width: 25%;">Periodo de vigencia</th>
                            <th scope="col" style="width: 30%;">Tipos de documentos vinculados</th>
                            <th scope="col" style="width: 15%; word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lptd of listadoPagTipoDocumento | filter:busqueda;">
                            <td>{{lptd.nombre}}</td>
                            <td>{{lptd.periodoVigencia.periodoVigencia}}</td>
                            <td>{{concatVinculados(lptd.tipoDocumentosVinculados)}}</td>
                            <td class="text-nowrap">
                                <button *ngIf="lptd.habilitado" type="button" class="btn-ico-tool"
                                    (click)="openModalTdEditar(lptd)">
                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="lptd.habilitado" type="button" class="btn-ico-tool"
                                    (click)="openModalTdDeshabilitar(lptd)">
                                    <i class="fas fa-minus-circle ico-action-blue" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="!lptd.habilitado" type="button" class="btn btn-enable"
                                    (click)="openModalTdHabilitar(lptd)">
                                    Habilitar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--Pagination-->
            <div class="row mt-3" *ngIf="totalRegisterTipoDocumento > 10"
                [ngStyle]="{'visibility': loaderPaginado ? 'hidden' : 'visible'}">
                <div class="col text-right">
                    <div class="pagination">
                        <pagination class="justify-content-end" [boundaryLinks]="true" [directionLinks]="true"
                            [firstText]="'Inicio'" [lastText]="'Fin'" previousText="Anterior" nextText="Siguiente"
                            [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}"
                            [totalItems]="totalRegisterTipoDocumento" (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>