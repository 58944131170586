import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiService } from 'src/app/services/api.service';
import { PostListadoDocumentoPaginadoRequestDTO } from 'src/dtos/request/documental/postListadoDocumentoPaginado.request.dto';
import { ModalActualizarComponent } from '../modals/modal-actualizar/modal-actualizar.component';
import { ModalDetalleComponent } from '../modals/modal-detalle/modal-detalle.component';
import { ModalEditarComponent } from '../modals/modal-editar/modal-editar.component';
import { ModalEliminarComponent } from '../modals/modal-eliminar/modal-eliminar.component';
import { ModalHistorialComponent } from '../modals/modal-historial/modal-historial.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { DocumentoDTO } from 'src/dtos/documental/documento.dto';
import { MacroprocesoDTO } from 'src/dtos/documental/macroproceso.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesos.response.dto';
import { GetListadoMacroprocesosPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoMacroprocesosPaginados.response.dto';
import { ProcesoDTO } from 'src/dtos/documental/proceso.dto';
import { GetListadoProcesosPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoProcesosPaginados.response.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/documental/getListadoProcesos.response.dto';
import { AreaDTO } from 'src/dtos/documental/area.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetListadoAreasPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoAreasPaginados.response.dto';
import { SubareaDTO } from 'src/dtos/documental/subarea.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/documental/getListadoSubAreas.response.dto';
import { GetListadoSubareaPaginadosResponseDTO } from 'src/dtos/response/documental/getListadoSubareasPaginados.response.dto';
import { UserService } from 'src/app/services/user.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GetPostListadoDocumentosPaginadoResponseDTO } from 'src/dtos/response/documental/getPostListadoDocumentosPaginado.response.dto';

@Component({
  selector: 'app-documental-modogrid',
  templateUrl: './documental-modogrid.component.html',
  styleUrls: ['./documental-modogrid.component.scss']
})
export class DocumentalModogridComponent implements OnInit {
  listaJerarquizacion = [
    { title: 'Macroprocesos', id: 'tab-macroproceso', nameSelect: 'Macroprocesos', uuidSelect: '', totalDocumentos: 0, visible: true },
    { title: 'Procesos', id: 'tab-proceso', nameSelect: '', uuidSelect: '', totalDocumentos: 0, visible: false },
    { title: 'Áreas', id: 'tab-area', nameSelect: '', uuidSelect: '', totalDocumentos: 0, visible: false },
    { title: 'Subáreas', id: 'tab-sub-area', nameSelect: '', uuidSelect: '', totalDocumentos: 0, visible: false },
    { title: 'Documentos', id: 'tab-documento', nameSelect: '', uuidSelect: '', totalDocumentos: 0, visible: false },
  ];

  listadoDocumentos: DocumentoDTO[] = [];

  listadoPagMacroprocesos: MacroprocesoDTO[] = [];
  listadoPagProcesos: ProcesoDTO[] = [];
  listadoPagAreas: AreaDTO[] = [];
  listadoPagSubareas: SubareaDTO[] = [];
  listadoAuxPaginado: any[] = [];

  listaSimpleMacroprocesos: MacroprocesoDTO[] = [];
  listaSimpleProcesos: ProcesoDTO[] = [];
  listaSimpleAreas: AreaDTO[] = [];
  listaSimpleSubareas: SubareaDTO[] = [];
  listaAuxLateral: any[] = [];

  filtros: any;

  totalDocumentos: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  indexSelect: number = 0;

  viewFilter: boolean = false;
  viewListaLateral: boolean = true;
  loaderSimple: boolean = false;
  loaderPaginado: boolean = false;

  busquedaLateral: string = null;
  uuidUser: string;
  nameLateral: string = 'Macroprocesos';

  busqueda = new FormControl(null);

  formCtrlSub: Subscription;

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private userService: UserService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    const user = this.userService.getUser();
    this.uuidUser = user.uuid;
    this.initFiltersModoGrid();
    this.getListadoMacroprocesos();
    this.getListadoMacroprocesosPaginado();
    this.selectTab(0);
    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFiltersModoGrid(false);
        this.listadoDocumentos = [];
        this.getListadoDocumentosPaginadoBusqueda();
      }
    });
  }

  selectTab(index: number) {
    this.busqueda.setValue(null);
    this.busquedaLateral = null
    this.indexSelect = index;
    this.listaAuxLateral = [];
    this.nameLateral = this.listaJerarquizacion[index].nameSelect;
    this.totalDocumentos = this.listaJerarquizacion[index].totalDocumentos;
    this.viewListaLateral = true;

    switch (index) {
      case 0:
        this.nameLateral = 'Macroprocesos';
        this.listaAuxLateral = this.listaSimpleMacroprocesos;
        this.listadoAuxPaginado = this.listadoPagMacroprocesos;
        break;
      case 1:
        this.listaAuxLateral = this.listaSimpleProcesos;
        this.listadoAuxPaginado = this.listadoPagProcesos;
        break;
      case 2:
        this.listaAuxLateral = this.listaSimpleAreas;
        this.listadoAuxPaginado = this.listadoPagAreas;
        break;
      case 3:
        this.listaAuxLateral = this.listaSimpleSubareas;
        this.listadoAuxPaginado = this.listadoPagSubareas;
        break;
      case 4:
        this.viewListaLateral = false;
        break;
    }
  }

  disabledTab(active: boolean = true) {
    for (let i = 0; i < this.indexSelect; i++) {
      this.staticTabs.tabs[i].disabled = active;
    }
    this.cdr.detectChanges();
  }

  selectFolder(data: any, indexTab?: number) {
    if (data.cantidad > 0) {
      this.indexSelect = indexTab ? indexTab : this.indexSelect + 1;
      this.disabledTab();
      this.viewListaLateral = true;
      this.currentPage = 1;
      this.busqueda.setValue(null);
      this.busquedaLateral = null;

      this.listaAuxLateral = [];
      this.listadoAuxPaginado = [];
      this.nameLateral = this.listaJerarquizacion[this.indexSelect].nameSelect = data.nombre;
      this.listaJerarquizacion[this.indexSelect - 1].uuidSelect = data.uuid;

      this.listaJerarquizacion[this.indexSelect].visible = true;
      for (let i = this.indexSelect + 1; i < this.listaJerarquizacion.length; i++) {
        this.listaJerarquizacion[i].visible = false;
      }

      switch (this.indexSelect) {
        case 1:
          this.getListadoProcesosPaginado();
          this.getListadoProcesos();
          break;
        case 2:
          this.getListadoAreas();
          this.getListadoAreasPaginado();
          break;
        case 3:
          this.getListadoSubAreas();
          this.getListadoSubAreasPaginado();
          break;
        case 4:
          this.viewListaLateral = false;
          this.postListadoDocumentosPaginado();
          break;
      }
      this.cdr.detectChanges();
      this.staticTabs.tabs[this.indexSelect].active = true;
      this.staticTabs.tabs[this.indexSelect].selectTab.emit(this.staticTabs.tabs[this.indexSelect]);
    }
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    switch (this.indexSelect) {
      case 0:
        this.getListadoMacroprocesosPaginado();
        break;
      case 1:
        this.getListadoProcesosPaginado();
        break;
      case 2:
        this.getListadoAreasPaginado();
        break;
      case 3:
        this.getListadoSubAreasPaginado();
        break;
      case 4:
        if (this.busqueda.value?.length > 0) {
          this.getListadoDocumentosPaginadoBusqueda();
        } else {
          this.postListadoDocumentosPaginado();
        }
        break;
    }
  }

  initFiltersModoGrid(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      fechaCreacion: null,
      fechaEdicion: null,
      uuidMacroproceso: null,
      uuidArea: null,
      uuidCreador: null,
      tipoDocumento: null,
      fechaVencimiento: null,
      documentoVinculados: null,
      uuidProceso: null,
      uuidSubarea: null,
      idInterno: null,
      estado: null
    };
    if (initData) {
      this.postListadoDocumentosPaginado();
    }
  }

  filterDocModoGrid(event: PostListadoDocumentoPaginadoRequestDTO) {
    this.currentPage = 1;
    this.cerrarFiltrosModoGrid(false);

    this.filtros = event;

    this.postListadoDocumentosPaginado();
  }

  cerrarFiltrosModoGrid(event: boolean) {
    this.viewFilter = event;
  }

  getListadoDocumentosPaginadoBusqueda() {
    this.loaderPaginado = true;

    this.apiService.getListadoDocumentosPaginadoBusqueda(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (response) => this.listadoDocumentosPaginadoSuccess(response),
      (error) => this.listadoDocumentosPaginadoError(error)
    );
  }

  postListadoDocumentosPaginado() {
    this.loaderPaginado = true;
    this.busqueda.setValue(null);

    let dataRequest: PostListadoDocumentoPaginadoRequestDTO = JSON.parse(JSON.stringify({ 
      uuidCreador: this.filtros.uuidCreador,
      tipoDocumento: this.filtros.tipoDocumento,
      documentoVinculados: this.filtros.documentoVinculados,
      idInterno: this.filtros.idInterno,
      estado: this.filtros.estado,
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      fechaEdicionInicio: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[0] : null,
      fechaEdicionTermino: this.filtros.fechaEdicion ? this.filtros.fechaEdicion[1] : null,
      fechaVencimientoInicio: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[0] : null,
      fechaVencimientoTermino: this.filtros.fechaVencimiento ? this.filtros.fechaVencimiento[1] : null,
      uuidMacroproceso: this.filtros.uuidMacroproceso ? this.filtros.uuidMacroproceso : this.listaJerarquizacion[0].uuidSelect,
      uuidArea: this.filtros.uuidArea ? this.filtros.uuidArea : this.listaJerarquizacion[2].uuidSelect,
      uuidProceso: this.filtros.uuidProceso ? this.filtros.uuidProceso : this.listaJerarquizacion[1].uuidSelect,
      uuidSubarea: this.filtros.uuidSubarea ? this.filtros.uuidSubarea : this.listaJerarquizacion[3].uuidSelect,
    }, (indice, valor) => { if (valor !== null) { return valor; } }));

    this.apiService.postListadoDocumentosPaginado(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
      (response) => this.listadoDocumentosPaginadoSuccess(response),
      (error) => this.listadoDocumentosPaginadoError(error)
    );
  }

  listadoDocumentosPaginadoSuccess(response: GetPostListadoDocumentosPaginadoResponseDTO) {
    this.listadoDocumentos = response.data.content;
    this.totalDocumentos = response.data.totalElements;
    this.loaderPaginado = false;
    this.disabledTab(this.loaderSimple || this.loaderPaginado);
  }

  listadoDocumentosPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_DOCUMENTOS_PAGINADOS;
    this.toastr.error(msg);
    this.loaderPaginado = false;
    this.disabledTab(false);
  }

  getListadoMacroprocesos() {
    this.loaderSimple = true;
    this.apiService.getListadoMacroprocesos().subscribe(
      (response: GetListadoMacroprocesosResponseDTO) => {
        this.listaSimpleMacroprocesos = response.data.filter(macro => macro.habilitado);
        this.selectTab(0);
        this.loaderSimple = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS;
        this.toastr.error(msg);
        this.loaderSimple = false;
      }
    );
  }

  getListadoMacroprocesosPaginado() {
    this.loaderPaginado = true;
    this.apiService.getListadoMacroprocesosPaginado(this.currentPage - 1, this.pageSize).subscribe(
      (response: GetListadoMacroprocesosPaginadosResponseDTO) => {
        this.listadoPagMacroprocesos = response.data.content.filter(macro => macro.habilitado);
        this.selectTab(0);
        this.loaderPaginado = false;

        this.totalDocumentos = this.listaJerarquizacion[0].totalDocumentos = response.data.totalElements;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
      }
    );
  }

  getListadoProcesosPaginado() {
    this.loaderPaginado = true;
    this.apiService.getListadoProcesosPaginado(this.currentPage - 1, this.pageSize, this.listaJerarquizacion[0].uuidSelect).subscribe(
      (response: GetListadoProcesosPaginadosResponseDTO) => {
        this.listadoPagProcesos = response.data.content.filter(proceso => proceso.habilitado);
        this.selectTab(1);
        this.loaderPaginado = false;
        this.totalDocumentos = this.listaJerarquizacion[1].totalDocumentos = response.data.totalElements;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
        this.disabledTab(false);
      }
    );
  }

  getListadoProcesos() {
    this.loaderSimple = true;
    this.apiService.getListadoProcesos(this.listaJerarquizacion[0].uuidSelect).subscribe(
      (response: GetListadoProcesosResponseDTO) => {
        this.listaSimpleProcesos = response.data.filter(proceso => proceso.habilitado);
        this.selectTab(1);
        this.loaderSimple = false;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS;
        this.toastr.error(msg);
        this.loaderSimple = false;
        this.disabledTab(false);
      }
    );
  }

  getListadoAreas() {
    this.loaderSimple = true;
    this.apiService.getListadoAreas(this.listaJerarquizacion[1].uuidSelect).subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleAreas = response.data.filter(area => area.habilitado);
        this.selectTab(2);
        this.loaderSimple = false;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
        this.loaderSimple = false;
        this.disabledTab(false);
      }
    );
  }

  getListadoAreasPaginado() {
    this.loaderPaginado = true;
    this.apiService.getListadoAreasPaginado(this.currentPage - 1, this.pageSize, this.listaJerarquizacion[1].uuidSelect).subscribe(
      (response: GetListadoAreasPaginadosResponseDTO) => {
        this.listadoPagAreas = response.data.content.filter(area => area.habilitado);
        this.selectTab(2);
        this.loaderPaginado = false;

        this.totalDocumentos = this.listaJerarquizacion[2].totalDocumentos = response.data.totalElements;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
        this.disabledTab(false);
      }
    );
  }

  getListadoSubAreas() {
    this.loaderSimple = true;
    this.apiService.getListadoSubAreas(this.listaJerarquizacion[2].uuidSelect, this.listaJerarquizacion[1].uuidSelect).subscribe(
      (response: GetListadoSubAreasResponseDTO) => {
        this.listaSimpleSubareas = response.data.filter(sub => sub.habilitado);
        this.selectTab(3);
        this.loaderSimple = false;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
        this.loaderSimple = false;
        this.disabledTab(false);
      }
    );
  }

  getListadoSubAreasPaginado() {
    this.loaderPaginado = true;
    this.apiService.getListadoSubAreasPaginado(this.currentPage - 1, this.pageSize, this.listaJerarquizacion[2].uuidSelect, this.listaJerarquizacion[1].uuidSelect).subscribe(
      (response: GetListadoSubareaPaginadosResponseDTO) => {
        this.listadoPagSubareas = response.data.content.filter(sub => sub.habilitado);
        this.selectTab(3);
        this.loaderPaginado = false;

        this.totalDocumentos = this.listaJerarquizacion[3].totalDocumentos = response.data.totalElements;
        this.disabledTab(this.loaderSimple || this.loaderPaginado);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUBAREAS_PAGINADOS;
        this.toastr.error(msg);
        this.loaderPaginado = false;
        this.disabledTab(false);
      }
    );
  }

  getDescargarDocumento(url: string) {
    window.open(url, '_self');
  }

  openModalEditarModoGrid(uuid: string) {
    const modalEditar: BsModalRef = this.modalService.show(ModalEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        uuid
      }
    });

    this.cerrarModalCargaData(modalEditar);
  }

  openModalEliminarModoGrid(uuid: string, nombre: string) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid,
        nombre
      }
    });

    this.cerrarModalCargaData(modalEliminar);
  }

  openModalActualizarModoGrid(uuid: string) {
    const modalActualizar: BsModalRef = this.modalService.show(ModalActualizarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        uuid
      }
    });

    this.cerrarModalCargaData(modalActualizar);
  }

  openModalDetalleModoGrid(uuid: string) {
    const modalDetalle: BsModalRef = this.modalService.show(ModalDetalleComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        estadoDetalle: 'ACTUAL'
      }
    });

    this.cerrarModalCargaData(modalDetalle);
  }

  openModalHistorialModoGrid(uuid: string, nombreDoc: string) {
    this.modalService.show(ModalHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid,
        nombreDoc
      }
    });
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      if (myData === 'ELIMINAR' || myData === 'EDITAR' || myData === 'ACTUALIZAR') {
        this.postListadoDocumentosPaginado();
      }
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}

