import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { Base64Util } from 'src/app/util/base64.util';
import { DesviacionDTO } from 'src/dtos/mantenedor/desviacion.dto';
import { ReclamoDTO } from 'src/dtos/mantenedor/reclamo.dto';
import { GetDetalleReclamoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleReclamo.response.dto';
import { GetListadoDesviacionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDesviaciones.response.dto';
import { PutEditarReclamoResponseDTO } from 'src/dtos/response/mantenedor/putEditarReclamo.response.dto';

@Component({
  selector: 'app-modal-reclamos-editar',
  templateUrl: './modal-reclamos-editar.component.html',
  styleUrls: ['./modal-reclamos-editar.component.scss']
})
export class ModalReclamosEditarComponent {
  uuid: string;

  editarForm: FormGroup;

  blobFile: Blob[] = [];

  loader: boolean = false;

  listadoDesviaciones: DesviacionDTO[] = [];
  detalleReclamo: ReclamoDTO;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.editarForm = this.formBuilder.group({
      file: new FormControl(null),
      filesSource: new FormArray([], [Validators.required]),
      impactoDesviacion: new FormControl(null, [Validators.required])
    });
    this.getListadoDesviacionMantenedor();
    this.getObtenerReclamoMantenedor();
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(reader.result));
      };

      reader.onloadend = async () => {
        file['url'] = reader.result.toString().includes('image') ? reader.result.toString() : this.obtenerUrl(file);
      }

      reader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.editarForm.get('filesSource') as FormArray).push(new FormControl(file));
      this.editarForm.get('file').patchValue(null);
    }
  }

  getAdjuntos() {
    return (this.editarForm.get('filesSource') as FormArray).controls;
  }

  removeAdjunto(i: number) {
    (this.editarForm.get('filesSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        url = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return url;
  }

  getListadoDesviacionMantenedor() {
    this.apiServiceMantenedor.getListadoDesviacionMantenedor().subscribe(
      (response: GetListadoDesviacionesResponseDTO) => {
        this.listadoDesviaciones = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_DESVIACIONES;
        this.toastr.error(msg);
      }
    );
  }

  getObtenerReclamoMantenedor() {
    this.loader = true;
    this.apiServiceMantenedor.getObtenerReclamoMantenedor(this.uuid, false).subscribe(
      (response: GetDetalleReclamoResponseDTO) => {
        this.detalleReclamo = response.data;
        this.editarForm.patchValue({ impactoDesviacion: this.detalleReclamo.desviacion.uuid });
        if (this.detalleReclamo.adjuntos) {
          this.detalleReclamo.adjuntos.forEach(async adjunto => {
            let file = await fetch(adjunto.url).then(r => r.blob());
            this.blobFile.push(file);
            (this.editarForm.get('filesSource') as FormArray).push(new FormControl({ url: adjunto.url, name: adjunto.nombreArchivo }));
          });
        }
        this.loader = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_RECLAMO;
        this.toastr.error(msg);
        this.loader = false;
      }
    );
  }

  editarReclamo() {
    const value = this.editarForm.value;
    let formData = new FormData();
    formData.append('uuidImpactoDesviacion', value.impactoDesviacion);

    const nombreArchivos = (this.editarForm.get('filesSource') as FormArray).value;
    this.blobFile.forEach((file, index) => {
      formData.append('archivos[' + index + ']', file, nombreArchivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    });

    this.apiServiceMantenedor.putEditarReclamo(this.uuid, formData).subscribe(
      (response: PutEditarReclamoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_RECLAMO);
        this.onClose.next('EDITAR');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_RECLAMO;
        this.toastr.error(msg);
      }
    );
  }
}
