import { ChangeDetectorRef, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalReclamosEditarComponent } from './modals/modal-reclamos-editar/modal-reclamos-editar.component';
import { ModalReclamosCerrarComponent } from './modals/modal-reclamos-cerrar/modal-reclamos-cerrar.component';
import { ModalReclamosComentariosComponent } from './modals/modal-reclamos-comentarios/modal-reclamos-comentarios.component';
import { ModalReclamosHistorialComponent } from './modals/modal-reclamos-historial/modal-reclamos-historial.component';
import { ModalReclamosRechazarComponent } from './modals/modal-reclamos-rechazar/modal-reclamos-rechazar.component';
import { ModalReclamosEliminarComponent } from './modals/modal-reclamos-eliminar/modal-reclamos-eliminar.component';
import { ToastrService } from 'ngx-toastr';
import { ApiMantenedorService } from '../services/api-mantenedor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppMessage } from '../app.message';
import { ReclamoDTO } from 'src/dtos/mantenedor/reclamo.dto';
import { GetListadoSemaforoResponseDTO } from 'src/dtos/response/mantenedor/getListadoSemaforo.response.dto';
import { SemaforoDTO } from 'src/dtos/mantenedor/semaforo.dto';
import * as moment from 'moment';
import { PostListadoReclamoPaginadoRequestDTO } from 'src/dtos/request/mantenedor/postListadoReclamoPaginado.request.dto';
import { GetPostListadoReclamosPaginadosResponseDTO } from 'src/dtos/response/mantenedor/getPostListadoReclamosPaginados.response.dto';
import { PostExportarReclamosRequestDTO } from 'src/dtos/request/mantenedor/postExportarReclamo.request.dto';
import { saveAs } from 'file-saver';
import { UserService } from '../services/user.service';
import { UsuarioModel } from 'src/models/usuario.model';
import { FormControl } from '@angular/forms';
import { GetObtenerProcedimientoResponseDTO } from 'src/dtos/response/mantenedor/getObtenerProcedimiento.response.dto';
import { ProcedimientoDTO } from 'src/dtos/mantenedor/procedimiento.dto';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reclamos',
  templateUrl: './reclamos.component.html',
  styleUrls: ['./reclamos.component.scss']
})
export class ReclamosComponent {
  listadoPagReclamos: ReclamoDTO[] = [];
  auxListado: ReclamoDTO[] = [];
  listadoSemaforo: SemaforoDTO[] = [];
  filtros: any;
  usuario: UsuarioModel;
  detalleProcedimiento: ProcedimientoDTO;

  totalRegister: number = 0;
  pageSize: number = 10;
  currentPage: number = 1;
  contCheck: number = 0;

  busqueda = new FormControl(null);

  checkReclamos: boolean = false;
  viewFilter: boolean = false;
  loaderPaginado: boolean = false;
  loaderSubirProc: boolean = false;

  formCtrlSub: Subscription;

  constructor(
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private modalService: BsModalService,
    private apiServiceMantenedor: ApiMantenedorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.usuario = this.userService.getUser();
    this.viewFilter = false;

    this.initFilters();
    this.obtenerProcedimiento();
    this.getListadoSemaforoMantenedor();

    this.formCtrlSub = this.busqueda.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      if (this.busqueda.value !== null) {
        this.initFilters(false);
        this.listadoPagReclamos = [];
        this.getListadoReclamosPaginadoMantenedorBusqueda();
      }
    });
  }

  goToIngresarReclamo() {
    if (this.usuario.preferencias) {
      if (this.usuario.preferencias.area && this.usuario.preferencias.sitio && this.usuario.preferencias.subArea) {
        this.router.navigate(['web/reclamos-ingresar']);
      }
      else {
        this.toastr.error('Debe seleccionar sus preferencias para poder ingresar un reclamo');
      }
    }
    else {
      this.toastr.error('Debe seleccionar sus preferencias para poder ingresar un reclamo');
    }
    
  }

  pageChanged(event: any): void {
    this.checkReclamos = true;
    this.seleccionMultiple();
    this.currentPage = event.page;
    if (this.busqueda.value?.length > 0) {
      this.getListadoReclamosPaginadoMantenedorBusqueda();
    } else {
      this.postListadoReclamosPaginadoMantenedor();
    }
  }

  initFilters(initData: boolean = true) {
    this.currentPage = 1;
    this.filtros = {
      fechaCreacion: null,
      fechaCierre: null,
      uuidSubareaResponsable: null,
      uuidEstado: null,
      uuidTipoReclamo: null,
      uuidCreador: null,
      uuidAreaResponsable: null,
      uuidFilial: null,
      uuidSitioResponsable: null,
      exportar: false
    };

    if (initData) {
      this.postListadoReclamosPaginadoMantenedor();
    }
  }

  filterReclamos(event: any) {
    this.currentPage = 1;
    this.cerrarFiltros(false);

    this.filtros = event;

    this.postListadoReclamosPaginadoMantenedor();
  }

  cerrarFiltros(event: boolean) {
    this.viewFilter = event;
  }

  seleccionMultiple() {
    this.auxListado = this.listadoPagReclamos;
    this.contCheck = 0;
    for (const iterator of this.auxListado) {
      if (!this.checkReclamos) {
        this.contCheck++;
      }
      iterator.checkReclamo = !this.checkReclamos;
    }
    this.checkReclamos = !this.checkReclamos;
  }

  selReclamo(uuid: string) {
    const reclamoAux = this.listadoPagReclamos.find(d => d.uuid === uuid);

    if (reclamoAux && reclamoAux.checkReclamo) {
      reclamoAux.checkReclamo = false;
      if (this.contCheck > 0) {
        this.contCheck--;
      }
    } else {
      reclamoAux.checkReclamo = true;
      this.contCheck++;
    }

    this.checkReclamos = (this.contCheck > 1) ? true : false;
    this.auxListado = this.listadoPagReclamos;
  }

  calcularSemaforo(fecha: string) {
    const cantidad = moment().diff(fecha, 'days');
    const color = this.listadoSemaforo.find(ls => cantidad <= ls.cantidad);
    return color ? { color: color.color, cantidad } : { color: 'rojo', cantidad };
  }

  obtenerProcedimiento() {
    this.loaderSubirProc = true;
    this.apiServiceMantenedor.getProcedimientoMantenedor().subscribe(
      (response: GetObtenerProcedimientoResponseDTO) => {
        this.detalleProcedimiento = response.data;
        this.loaderSubirProc = false;
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.detalleProcedimiento = error.error.data;
        } else {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_GET_PROCEDIMIENTO;
          this.toastr.error(msg);
        }
        this.loaderSubirProc = false;
      }
    );
  }

  descargarProcedimiento() {
    window.open(this.detalleProcedimiento.url, '_blank');
  }

  getListadoReclamosPaginadoMantenedorBusqueda() {
    this.loaderPaginado = true;

    this.apiServiceMantenedor.getListadoReclamosPaginadoMantenedorBusqueda(this.currentPage - 1, this.pageSize, this.busqueda.value).subscribe(
      (resp) => this.listadoReclamosPaginadoSuccess(resp),
      (error) => this.listadoReclamosPaginadoError(error)
    );
  }

  postListadoReclamosPaginadoMantenedor() {
    this.loaderPaginado = true;
    this.busqueda.setValue(null);

    const dataRequest: PostListadoReclamoPaginadoRequestDTO = JSON.parse(JSON.stringify({
      fechaCierreInicio: this.filtros.fechaCierre ? this.filtros.fechaCierre[0] : null,
      fechaCierreTermino: this.filtros.fechaCierre ? this.filtros.fechaCierre[1] : null,
      fechaCreacionInicio: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[0] : null,
      fechaCreacionTermino: this.filtros.fechaCreacion ? this.filtros.fechaCreacion[1] : null,
      uuidSubareaResponsable: this.filtros.uuidSubareaResponsable,
      uuidAreaResponsable: this.filtros.uuidAreaResponsable,
      uuidSitioResponsable: this.filtros.uuidSitioResponsable,
      uuidCreador: this.filtros.uuidCreador,
      uuidEstado: this.filtros.uuidEstado,
      uuidFilial: this.filtros.uuidFilial,
      uuidTipoReclamo: this.filtros.uuidTipoReclamo,
      exportar: this.filtros.exportar
    }, (indice, valor) => { if (valor !== null) { return valor; } }));
    if (dataRequest.exportar) {
      this.apiServiceMantenedor.postExportarListadoFiltradoMantenedor(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
        (response) => {
          console.log(response);
          let blob = new Blob([response.body], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          saveAs(blob, `listaReclamos.xlsx`);
          this.toastr.success(AppMessage.SUCCESS_DESCARGAR_RECLAMOS);
          this.loaderPaginado = false;
          this.initFilters();
        },
        (error) => {
          console.log(error);
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_RECLAMOS;
          this.toastr.error(msg);
          this.loaderPaginado = false;
          this.initFilters();
        }
      )
    }
    else {
      this.apiServiceMantenedor.postListadoReclamosPaginadoMantenedor(this.currentPage - 1, this.pageSize, dataRequest).subscribe(
        (response) => this.listadoReclamosPaginadoSuccess(response),
        (error) => this.listadoReclamosPaginadoError(error)
      );
    }

  }

  listadoReclamosPaginadoSuccess(response: GetPostListadoReclamosPaginadosResponseDTO) {
    this.listadoPagReclamos = response.data.content;
    this.currentPage = response.data.pageable.pageNumber + 1;
    this.totalRegister = response.data.totalElements;
    for (const iterator of this.listadoPagReclamos) {
      iterator.checkReclamo = false;
      iterator.estadoSemaforo = this.calcularSemaforo(iterator.fechaCreacion);
    }
    this.auxListado = this.listadoPagReclamos;
    this.checkReclamos = false;
    this.loaderPaginado = false;
  }

  listadoReclamosPaginadoError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_RECLAMOS_PAGINADO;
    this.toastr.error(msg);
    this.loaderPaginado = false;
  }

  getListadoSemaforoMantenedor() {
    this.apiServiceMantenedor.getListadoSemaforoMantenedor().subscribe(
      (response: GetListadoSemaforoResponseDTO) => {
        this.listadoSemaforo = response.data;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_SEMAFORO;
        this.toastr.error(msg);
      }
    );
  }

  postExportarReclamosMantenedor() {
    const reclamos: PostExportarReclamosRequestDTO = {
      uuidReclamos: this.auxListado.filter(reclamo => reclamo.checkReclamo).map(reclamo => reclamo.uuid)
    };
    if (reclamos.uuidReclamos.length > 0) {
      this.apiServiceMantenedor.postExportarReclamosMantenedor(reclamos).subscribe(
        (response: any) => {
          let blob = new Blob([response.body], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          saveAs(blob, `listaReclamos.xlsx`);
          this.toastr.success(AppMessage.SUCCESS_DESCARGAR_RECLAMOS);
        },
        (error: HttpErrorResponse) => {
          const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DESCARGAR_RECLAMOS;
          this.toastr.error(msg);
        }
      );
    }
  }

  openModalReclamosEditar(reclamo: ReclamoDTO) {
    const modalEditar: BsModalRef = this.modalService.show(ModalReclamosEditarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid
      }
    });
    this.cerrarModalReclamos(modalEditar);
  }

  openModalReclamosCerrar(reclamo: ReclamoDTO) {
    const modalCerrar: BsModalRef = this.modalService.show(ModalReclamosCerrarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid,
        idInterno: reclamo.idInterno,
        idImpacto: reclamo.desviacion.uuid
      }
    });
    this.cerrarModalReclamos(modalCerrar);
  }

  openModalReclamosComentarios(reclamo: ReclamoDTO) {
    this.modalService.show(ModalReclamosComentariosComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-lg",
      initialState: {
        reclamo
      }
    });
  }

  openModalReclamosHistorial(reclamo: ReclamoDTO) {
    this.modalService.show(ModalReclamosHistorialComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered modal-right",
      initialState: {
        uuid: reclamo.uuid,
        idReclamo: reclamo.idInterno
      }
    });
  }

  openModalReclamosRechazar(reclamo: ReclamoDTO) {
    const modalRechazar: BsModalRef = this.modalService.show(ModalReclamosRechazarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid,
        idInterno: reclamo.idInterno
      }
    });
    this.cerrarModalReclamos(modalRechazar);
  }

  openModalReclamosEliminar(reclamo: ReclamoDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalReclamosEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      class: "modal-dialog-centered",
      initialState: {
        uuid: reclamo.uuid,
        idInterno: reclamo.idInterno
      }
    });
    this.cerrarModalReclamos(modalEliminar);
  }

  cerrarModalReclamos(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      if (myData === 'ELIMINAR' || myData === 'RECHAZAR' || myData === 'EDITAR' || myData === 'CREAR' || myData === 'CERRAR') {
        this.postListadoReclamosPaginadoMantenedor();
      }
      modal.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.formCtrlSub.unsubscribe();
  }
}