export class Base64Util {


    public static base64toBlob(dataURI) {
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
      else byteString = (dataURI.split(',')[1]);
  
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
  
      return new Blob([ab],{type: mimeString});
    }
  
  }