<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fas fa-wrench ico-page" aria-hidden="true"></i>
            <span class="title1">Tiempos respuesta reclamos</span>
        </div>
    </div>
    <div class="section-page">
        <div class="table">
            <div class="table-responsive">
                <table id="example" class="display" style="width:100%">
                    <caption>Mantenedor tiempos de respuesta</caption>
                    <thead>
                        <tr>
                            <th scope="col" style="width: 40%;">Tiempo</th>
                            <th scope="col" style="width: 40%;">Color</th>
                            <th scope="col" style="word-break:normal;">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let color of listadoSemaforo; let i = index">
                            <td>{{color.nombre}}</td>
                            <td>
                                <div class="color" [ngClass]="{
                                    'green-back': color.color === 'verde',
                                    'yellow-back': color.color === 'amarillo',
                                    'red-back' : color.color === 'rojo'}"></div>
                            </td>
                            <td class=" text-nowrap">
                                <button type="button" class="btn-ico-tool" (click)="openModalTrEditar(color, i)">
                                    <i class="fas fa-pen ico-action" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>