import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { UsuarioModel } from 'src/models/usuario.model';
import { AppMessage } from '../app.message';
import { ActividadService } from '../services/actividad.service';
import { ApiGestionService } from '../services/api-gestion.service';
import { UserService } from '../services/user.service';
import { Base64Util } from '../util/base64.util';

@Component({
  selector: 'app-gestion-crear-plan',
  templateUrl: './gestion-crear-plan.component.html',
  styleUrls: ['./gestion-crear-plan.component.scss']
})
export class GestionCrearPlanComponent {

  form: FormGroup;
  crearPlanForm: FormGroup;
  uuidActividad: string;
  user: UsuarioModel;
  blobFile: Blob[] = [];
  deshacerEliminar: boolean = false;
  adjuntoRemovido: any;
  loader: boolean = false;
  disabledBtn: boolean = false;
  minDate = new Date();


  constructor(
    private actividadService: ActividadService,
    private apiGestion: ApiGestionService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private userService: UserService,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.uuidActividad = this.actividadService.getUuidSelected();
    this.form = this.formBuilder.group({
      planes: this.formBuilder.array([this.initForm()])
    })
  }

  get planes(){ return this.form.controls["planes"] as FormArray}

  initForm() {
    return this.formBuilder.group({
      nombre: new FormControl(null, [Validators.required]),
      uuidResponsable: new FormControl(this.user.uuid),
      fechaTerminoPlan: new FormControl(null, [Validators.required]),
      archivos: new FormGroup({
        file: new FormControl(null),
        filesSource: new FormArray([]),
        blobFiles: new FormArray([])
      }),
      comentarios: new FormControl(''),
      uuidGestionActividad: new FormControl(this.uuidActividad),
    })
  }

  addPlan() {
    this.planes.push(this.initForm());
  }

  deletePlan(index: number) {
    this.planes.removeAt(index);
  }

  subirArchivo(event: any, index: number) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        (this.form.controls.planes['controls'][index].controls['archivos'].controls['blobFiles'] as FormArray).push(new FormControl(Base64Util.base64toBlob(reader.result)))
      };

      reader.onloadend = async () => {
        file['url'] = reader.result.toString().includes('image') ? reader.result.toString() : this.obtenerUrl(file);
      }

      reader.onerror = async (error) => {
        console.error('error al cargar archivo', error);
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.form.controls.planes['controls'][index].controls['archivos'].controls['filesSource'] as FormArray).push(new FormControl(file));
      (this.form.controls.planes['controls'][index].controls['archivos'].controls['file'] as FormArray).patchValue(null)
    }
  }

  obtenerUrl(adjunto) {
    let url;
    switch (true) {
      case adjunto.name.includes('.pdf'):
        url = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case adjunto.name.includes('.doc'):
        url = '../assets/img/extension-archivo/k-doc.png';
        break;
      case adjunto.name.includes('.xls'):
        url = '../assets/img/extension-archivo/k-xls.png';
        break;
      case adjunto.name.includes('.ppt'):
        url = '../assets/img/extension-archivo/k-ppt.png';
        break;
    }
    return url;
  }


  getAdjuntos(index: number) {
    return (this.form.controls.planes['controls'][index].controls['archivos'].controls['filesSource'] as FormArray).controls
  }

  removeAdjunto(planIndex: number, fileIndex: number) {
   // this.deshacerEliminar = true;
   /* this.adjuntoRemovido = {
      blobFile: this.blobFile[fileIndex],
      filesSource: this.getAdjuntos(planIndex)[fileIndex].value
    }; */
    (this.form.controls.planes['controls'][planIndex].controls['archivos'].controls['filesSource'] as FormArray).removeAt(fileIndex);
    (this.form.controls.planes['controls'][planIndex].controls['archivos'].controls['blobFiles'] as FormArray).removeAt(fileIndex)
  }

  deshacer() {
    this.deshacerEliminar = false;
    (this.crearPlanForm.get('archivos.filesSource') as FormArray).push(new FormControl(this.adjuntoRemovido.filesSource));
    this.blobFile.push(this.adjuntoRemovido.blobFile)
  }

  crearPlanDeAccion() {
    this.loader = true;
    let ultimo = false;
    for (let i = 0; i < this.planes.controls.length; i++){
      let data = this.planes.controls[i].value;
      let formData = new FormData();
      formData.append('nombre', data.nombre);
      formData.append('uuidResponsable', data.uuidResponsable);
      formData.append('fechaTerminoPlan', this.datepipe.transform(data.fechaTerminoPlan, 'yyyy-MM-dd HH:mm:ss'));
      formData.append('comentarios', data.comentarios);
      formData.append('uuidGestionActividad', this.uuidActividad);

      const nombreArchivos = (this.form.controls.planes['controls'][i].controls['archivos'].controls['filesSource'] as FormArray).value;
      (this.form.controls.planes['controls'][i].controls['archivos'].controls['blobFiles'] as FormArray).value.forEach((file, index) => {
        formData.append('archivos[' + index + ']', file, nombreArchivos[index].name);
      })
      if (i == this.planes.controls.length - 1) {
        ultimo = true;
      }
      this.apiGestion.postCrearPlanDeAccion(formData).subscribe((resp) => this.postCrearPlanDeAccionSuccess(resp, ultimo),
        (error) => this.postCrearPlanDeAccionError(error));
    }
  
  }

  postCrearPlanDeAccionSuccess(resp: GeneralResponseDTO, ultimo: boolean) {
    this.loader = false;
    this.toastr.success(AppMessage.SUCCESS_CREAR_PLAN_DE_ACCION);
    if (ultimo) {
      this.goToDetalleActividad(); 
    }
  }

  postCrearPlanDeAccionError(error: HttpErrorResponse) {
    this.loader = false;
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_CREAR_PLAN_DE_ACCION;
    this.toastr.error(msg);
  }

  regresar() {
    this.location.back();
  }

  goToDetalleActividad() {
    //this.actividadService.setUuidSelected(this.uuidActividad);
    this.router.navigate(['web/gestion-consultar'],{queryParams:{uuidActividad: this.uuidActividad}});
  }

 }
