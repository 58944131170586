import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { SemaforoDTO } from 'src/dtos/mantenedor/semaforo.dto';
import { PutActualizarSemaforoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSemaforo.request.dto';
import { PutActualizarSemaforoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSemaforo.response.dto';

@Component({
  selector: 'app-modal-tr-editar',
  templateUrl: './modal-tr-editar.component.html',
  styleUrls: ['./modal-tr-editar.component.scss']
})
export class ModalTrEditarComponent {
  data: SemaforoDTO;
  acumulado: number;

  editarForm: FormGroup;

  onClose: Subject<boolean>;

  constructor(
    private apiServiceMantenedor: ApiMantenedorService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.onClose = new Subject();
    this.editarForm = this.formBuilder.group({
      cantidad: new FormControl(this.data.cantidad, Validators.required)
    });
  }

  putActualizarSemaforoMantenedor() {
    const data: PutActualizarSemaforoRequestDTO = {
      cantidad: this.editarForm.controls.cantidad.value,
      uuid: this.data.uuid
    };
    this.apiServiceMantenedor.putActualizarSemaforoMantenedor(data).subscribe(
      (response: PutActualizarSemaforoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_SEMAFORO);
        this.onClose.next(true);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_SEMAFORO;
        this.toastr.error(msg);
      }
    );
  }
}
