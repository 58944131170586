<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title"><i class="fas fa-wrench ico-page" aria-hidden="true"></i><span
                class="title1">Mantenedor</span></div>
    </div>
    <div class="section-page">
        <div class="mantenedor-section-main">
            <a routerLink="/web/mantenedor-tipodocumento">
                <div class="mantenedor-section" style="background-image:url(assets/img/mant-tipodoc.jpg)">
                    <i class="fas fa-wrench ico-mantenedor" aria-hidden="true"></i> Tipo de documento
                </div>
            </a>
            <a routerLink="/web/mantenedor-categorias">
                <div class="mantenedor-section" style="background-image:url(assets/img/mant-categorias.jpg)">
                    <i class="fas fa-wrench ico-mantenedor" aria-hidden="true"></i> Categorías
                </div>
            </a>
            <a routerLink="/web/mantenedor-tiemposrespuesta">
                <div class="mantenedor-section" style="background-image:url(assets/img/mant-semaforo.jpg)">
                    <i class="fas fa-wrench ico-mantenedor" aria-hidden="true"></i> Tiempos de respuesta reclamos
                </div>
            </a>
            <a routerLink="/web/mantenedor-usuarios">
                <div class="mantenedor-section" style="background-image:url(assets/img/mant-usuarios.jpg)">
                    <i class="fas fa-wrench ico-mantenedor" aria-hidden="true"></i> Usuarios
                </div>
            </a>
            <a routerLink="/web/mantenedor-jerarquizacion">
                <div class="mantenedor-section" style="background-image:url(assets/img/mant-jerarquizacion.jpg)">
                    <i class="fas fa-wrench ico-mantenedor" aria-hidden="true"></i> Jerarquización
                </div>
            </a>
        </div>
    </div>
</div>
<div class="full-height">&nbsp;</div>