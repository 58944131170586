import { BaseResponseDTO } from "../base.response.dto";

export class GetPorcentajesHomeDocumentalResponseDTO extends BaseResponseDTO {
    success: boolean;
    msg: string;
    data: {
        por_vencer: number;
        totalDocumentos: number;
        vencidos: number;
        vigentes: number;
    };
}
