import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { DimensionDTO } from 'src/dtos/mantenedor/dimension.dto';
import { DimensionesPorAreasDTO } from 'src/dtos/mantenedor/dimensionesPorArea.dto';
import { MacroprocesoDTO } from 'src/dtos/mantenedor/macroproceso.dto';
import { ProcesoDTO } from 'src/dtos/mantenedor/proceso.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { PutActualizarAreaRequestDTO } from 'src/dtos/request/mantenedor/putActualizarArea.request.dto';
import { PutActualizarMacroprocesoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarMacroproceso.request.dto';
import { PutActualizarProcesoRequestDTO } from 'src/dtos/request/mantenedor/putActualizarProceso.request.dto';
import { PutActualizarSitioRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSitio.request.dto';
import { PutActualizarSubareaRequestDTO } from 'src/dtos/request/mantenedor/putActualizarSubarea.request.dto';
import { GetDetalleAreaResponseDTO } from 'src/dtos/response/mantenedor/getDetalleArea.response.dto';
import { GetDetalleMacroprocesoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleMacroproceso.response.dto';
import { GetDetalleProcesoResponseDTO } from 'src/dtos/response/mantenedor/getDetalleProceso.response.dto';
import { GetDetalleSitioResponseDTO } from 'src/dtos/response/mantenedor/getDetalleSitio.response.dto';
import { GetDetalleSubareaResponseDTO } from 'src/dtos/response/mantenedor/getDetalleSubarea.response.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { GetListadoDimensionesResponseDTO } from 'src/dtos/response/mantenedor/getListadoDimensiones.response.dto';
import { GetListadoMacroprocesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoMacroprocesos.response.dto';
import { GetListadoProcesosResponseDTO } from 'src/dtos/response/mantenedor/getListadoProcesos.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { PutActualizarAreaResponseDTO } from 'src/dtos/response/mantenedor/putActualizarArea.response.dto';
import { PutActualizarMacroprocesoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarMacroproceso.response.dto';
import { PutActualizarProcesoResponseDTO } from 'src/dtos/response/mantenedor/putActualizarProceso.response.dto';
import { PutActualizarSitioResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSitio.response.dto';
import { PutActualizarSubareaResponseDTO } from 'src/dtos/response/mantenedor/putActualizarSubarea.response.dto';

@Component({
  selector: 'app-modal-j-editar',
  templateUrl: './modal-j-editar.component.html',
  styleUrls: ['./modal-j-editar.component.scss']
})
export class ModalJEditarComponent {
  titleModal: string;
  uuid: string;

  editarForm: FormGroup;

  listaSimpleMacroprocesos: MacroprocesoDTO[] = [];
  listaSimpleProcesos: ProcesoDTO[] = [];
  listaSimpleArea: AreaDTO[] = [];
  listaSimpleSubarea: SubareaDTO[] = [];
  listaDimensiones: DimensionDTO[] = [];
  dimensionesPorArea: Array<DimensionesPorAreasDTO> = [];

  loaderMacroprocesos: boolean = false;
  loaderDimensiones: boolean = false;
  loaderProcesos: boolean = false;
  loaderArea: boolean = false;
  loaderSubarea: boolean = false;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.obtenerInformacion();
    this.initForm();
  }

  obtenerInformacion() {
    switch (this.titleModal) {
      case 'Macroproceso':
        this.getDetalleMacroproceso();
        break;
      case 'Proceso':
        this.getDetalleProceso();
        this.getListadoMacroprocesos();
        break;
      case 'Área':
        this.getListadoDimensiones();
        this.getListadoProcesos();
        this.getDetalleArea();
        break;
      case 'Subárea':
        this.getListadoDimensiones();
        this.getDetalleSubarea();
        break;
      case 'Sitio':
        this.getListadoSubAreas();
        this.getDetalleSitio();
        break;
      default:
        break;
    }
  }

  initForm(data?: any) {
    this.editarForm = this.formBuilder.group({
      nombre: new FormControl(data?.nombre ? data.nombre : null, [Validators.required])
    });

    switch (this.titleModal) {
      case 'Proceso':
        this.editarForm.removeControl('macroprocesoAsociado');
        this.editarForm.removeControl('procesoAsociado');
        this.editarForm.removeControl('areaAsociada');
        this.editarForm.removeControl('subareaAsociada');
        this.editarForm.addControl('macroprocesoAsociado', new FormControl(data?.macroprocesos.uuid ? data?.macroprocesos.uuid : null, [Validators.required]));
        break;
      case 'Área':
        this.editarForm.removeControl('macroprocesoAsociado');
        this.editarForm.removeControl('areaAsociada');
        this.editarForm.removeControl('subareaAsociada');
        this.editarForm.addControl('procesoAsociado', new FormControl(data?.procesos.length > 0 ? data?.procesos.map(x => x.uuid) : null, [Validators.required]));
        this.editarForm.addControl('dimensionAsociadas', new FormControl(data?.dimensiones.length > 0 ? data?.dimensiones.map(x => x.uuid) : null, [Validators.required]));
        break;
      case 'Subárea':
        this.editarForm.removeControl('macroprocesoAsociado');
        this.editarForm.removeControl('procesoAsociado');
        this.editarForm.removeControl('dimensionAsociadas');
        this.editarForm.removeControl('subareaAsociada');
        this.editarForm.addControl('areaAsociada', new FormControl(data?.dimensionesPorArea.length > 0 ? data?.dimensionesPorArea.map(x => x.uuid) : null, [Validators.required]));
        break;
      case 'Sitio':
        this.editarForm.removeControl('macroprocesoAsociado');
        this.editarForm.removeControl('procesoAsociado');
        this.editarForm.removeControl('dimensionAsociadas');
        this.editarForm.removeControl('areaAsociada');
        this.editarForm.addControl('subareaAsociada', new FormControl(data?.subArea.uuid ? data?.subArea.uuid : null, [Validators.required]));
        break;
      default:
        break;
    }
  }

  validarForm() {
    if (this.titleModal === 'Subárea') {
      let validarDimensiones = false;
      this.dimensionesPorArea.forEach(element => {
        if (element.dimensiones.some(dimension => dimension.value)) {
          validarDimensiones = true;
        }
      });
      return this.editarForm.valid && validarDimensiones;
    }
    return this.editarForm.valid;
  }

  selectArea(detalleSubarea?: SubareaDTO) {
    this.dimensionesPorArea.forEach((elementArea, index) => {
      let areaSeleccionada;
      if (detalleSubarea) {
        areaSeleccionada = detalleSubarea.dimensionesPorArea.find(areaDetalle => areaDetalle.uuid === elementArea.uuid);
      } else {
        areaSeleccionada = this.editarForm.value.areaAsociada.some(areaForm => areaForm === elementArea.uuid);
      }
      const area = this.listaSimpleArea.find(areaListado => areaListado.uuid === elementArea.uuid);

      elementArea.dimensiones.forEach((elementDimension, index2) => {
        if (areaSeleccionada) {
          const result = area.dimensiones.find(ad => ad.uuid === elementDimension.uuid);
          elementDimension.disabled = result ? false : true;
          if (detalleSubarea && areaSeleccionada.dimensiones.some(asd => asd.uuid === elementDimension.uuid)) {
            this.checkDimension(index, index2);
          }
        } else {
          elementDimension.value = false;
          elementDimension.disabled = true;
        }
      });
    });
  }

  checkDimension(i: number, j: number) {
    this.dimensionesPorArea[i].dimensiones[j].value = !this.dimensionesPorArea[i].dimensiones[j].value;
  }

  editarRegistro() {
    if (this.validarForm()) {
      switch (this.titleModal) {
        case 'Macroproceso':
          this.putActualizarMacroproceso();
          break;
        case 'Proceso':
          this.putActualizarProceso();
          break;
        case 'Área':
          this.putActualizarArea();
          break;
        case 'Subárea':
          this.putActualizarSubarea();
          break;
        case 'Sitio':
          this.putActualizarSitio();
          break;
        default:
          break;
      }
    }
  }

  putActualizarMacroproceso() {
    const data: PutActualizarMacroprocesoRequestDTO = {
      nombre: this.editarForm.value.nombre,
      uuid: this.uuid
    };
    this.apiServiceMantenedor.putActualizarMacroprocesoMantenedor(data).subscribe(
      (response: PutActualizarMacroprocesoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_MACROPROCESOS);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_MACROPROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  putActualizarProceso() {
    const data: PutActualizarProcesoRequestDTO = {
      nombre: this.editarForm.value.nombre,
      uuid: this.uuid,
      uuidMacroproceso: this.editarForm.value.macroprocesoAsociado
    };
    this.apiServiceMantenedor.putActualizarProcesoMantenedor(data).subscribe(
      (response: PutActualizarProcesoResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_PROCESOS);
        this.onClose.next('1');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_PROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  putActualizarArea() {
    const data: PutActualizarAreaRequestDTO = {
      nombre: this.editarForm.value.nombre,
      uuid: this.uuid,
      uuidDimensiones: this.editarForm.value.dimensionAsociadas,
      uuidProcesos: this.editarForm.value.procesoAsociado,
    };
    this.apiServiceMantenedor.putActualizarAreaMantenedor(data).subscribe(
      (response: PutActualizarAreaResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_AREAS);
        this.onClose.next('2');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  putActualizarSubarea() {
    let aux = [];
    this.dimensionesPorArea.forEach(area => {
      aux.push({
        uuidArea: area.uuid,
        dimensiones: area.dimensiones.filter(fd => fd.value).map(md => md.uuid)
      });
    });
    const data: PutActualizarSubareaRequestDTO = {
      nombre: this.editarForm.value.nombre,
      uuid: this.uuid,
      dimensionPorAreas: aux.filter(f => f.dimensiones.length > 0)
    };
    this.apiServiceMantenedor.putActualizarSubareaMantenedor(data).subscribe(
      (response: PutActualizarSubareaResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_SUBAREAS);
        this.onClose.next('3');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_SUBAREAS;
        this.toastr.error(msg);
      }
    );
  }

  putActualizarSitio() {
    const data: PutActualizarSitioRequestDTO = {
      nombre: this.editarForm.value.nombre,
      uuid: this.uuid,
      uuidSubArea: this.editarForm.value.subareaAsociada
    };

    this.apiServiceMantenedor.putActualizarSitioMantenedor(data).subscribe(
      (response: PutActualizarSitioResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_EDITAR_SITIOS);
        this.onClose.next('4');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_EDITAR_SITIOS;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleMacroproceso() {
    this.apiServiceMantenedor.getDetalleMacroprocesoMantenedor(this.uuid).subscribe(
      (response: GetDetalleMacroprocesoResponseDTO) => {
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_MACROPROCESO;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleProceso() {
    this.apiServiceMantenedor.getDetalleProcesoMantenedor(this.uuid).subscribe(
      (response: GetDetalleProcesoResponseDTO) => {
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_PROCESO;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleArea() {
    this.apiServiceMantenedor.getDetalleAreaMantenedor(this.uuid).subscribe(
      (response: GetDetalleAreaResponseDTO) => {
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleSubarea() {
    this.apiServiceMantenedor.getDetalleSubareaMantenedor(this.uuid).subscribe(
      (response: GetDetalleSubareaResponseDTO) => {
        this.getListadoAreas(response.data);
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_SUBAREAS;
        this.toastr.error(msg);
      }
    );
  }

  getDetalleSitio() {
    this.apiServiceMantenedor.getDetalleSitioMantenedor(this.uuid).subscribe(
      (response: GetDetalleSitioResponseDTO) => {
        this.initForm(response.data);
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_DETALLE_SITIOS;
        this.toastr.error(msg);
      }
    );
  }

  getListadoMacroprocesos() {
    this.loaderMacroprocesos = true;
    this.apiServiceMantenedor.getListadoMacroprocesosMantenedor().subscribe(
      (response: GetListadoMacroprocesosResponseDTO) => {
        this.listaSimpleMacroprocesos = response.data.filter(macro => macro.habilitado || macro.uuid === this.editarForm.controls.macroprocesoAsociado.value);
        this.loaderMacroprocesos = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_MACROPROCESOS;
        this.toastr.error(msg);
        this.loaderMacroprocesos = false;
      }
    );
  }

  getListadoProcesos() {
    this.loaderProcesos = true;
    this.apiServiceMantenedor.getListadoProcesosMantenedor().subscribe(
      (response: GetListadoProcesosResponseDTO) => {
        this.listaSimpleProcesos = response.data.filter(p => p.habilitado || this.editarForm.controls.procesoAsociado.value.some(val => val === p.uuid));
        this.loaderProcesos = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_PROCESOS;
        this.toastr.error(msg);
        this.loaderProcesos = false;
      }
    );
  }

  getListadoAreas(detalleSubarea: SubareaDTO) {
    this.loaderArea = true;
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (response: GetListadoAreasResponseDTO) => {
        this.listaSimpleArea = response.data.filter(a => a.habilitado || this.editarForm.controls.areaAsociada.value.some(val => val === a.uuid));
        this.listaSimpleArea.forEach(elementArea => {
          const dimensiones = [];
          this.listaDimensiones.forEach(elementDimension => {
            dimensiones.push({
              uuid: elementDimension.uuid,
              value: false,
              disabled: true
            });
          });
          this.dimensionesPorArea.push({
            uuid: elementArea.uuid,
            dimensiones
          });
        });
        this.selectArea(detalleSubarea);
        this.loaderArea = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
        this.loaderArea = false;
      }
    );
  }

  getListadoSubAreas() {
    this.loaderSubarea = true;
    this.apiServiceMantenedor.getListadoSubAreasMantenedor().subscribe(
      (responseSubarea: GetListadoSubAreasResponseDTO) => {
        this.listaSimpleSubarea = responseSubarea.data.filter(sa => sa.habilitado || this.editarForm.controls.subareaAsociada.value === sa.uuid);
        this.loaderSubarea = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
        this.loaderSubarea = false;
      }
    );
  }

  getListadoDimensiones() {
    this.loaderDimensiones = true;
    this.apiServiceMantenedor.getListadoDimensiones().subscribe(
      (response: GetListadoDimensionesResponseDTO) => {
        this.listaDimensiones = response.data;
        this.loaderDimensiones = false;
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTAR_DIMENSIONES;
        this.toastr.error(msg);
        this.loaderDimensiones = false;
      }
    );
  }
}
