import { ActividadDTO } from "src/dtos/gestion/actividad.dto";
import { BaseResponseDTO } from "../base.response.dto";

export class PostListadoActividadesPaginadoResponseDTO extends BaseResponseDTO{
  success: boolean;
  msg: string;
  data: {
    content: Array<ActividadDTO>;
    pageable: {
      sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
      },
      offset: number;
      pageNumber: number;
      pageSize: number;
      paged: boolean;
      unpaged: boolean;
  },
    last: boolean;
    totalPages: number;
    totalElements: number;
    number: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
  },
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
  }

}