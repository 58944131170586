import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { UserService } from "../services/user.service";
import { LoaderService } from "../services/loader.service";



@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private petitions: any = [];

  constructor(
    private loaderService: LoaderService,
    private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.userService.getUserToken() && this.userService.isUserLogged() && !request.url.includes('/sso/final/')) {
      request = request.clone({ headers: request.headers.set('X-TOKEN', this.userService.getUserToken()) });
    }

    this.beforeRequestShowLoader();

    if (!request.headers.has('Content-Type') && (request.method !== 'DELETE') && (request.params.get('multipart') !== 'true')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      if (!request.url.includes('exportar')) {
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.afterRequestHideLoader();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.afterRequestHideLoader();
        return throwError(error);
      }));
  }

  beforeRequestShowLoader() {
    this.petitions.push(new Date());
    if (this.petitions.length === 1) {
      this.loaderService.present();
    }
  }

  afterRequestHideLoader() {
    if (this.petitions && this.petitions.length > 0) {
      this.petitions.pop();
    }

    if (this.petitions.length === 0) {
      this.loaderService.dismiss();
    }
  }
}
