import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ActividadService } from 'src/app/services/actividad.service';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ArticuloDTO } from 'src/dtos/gestion/articulo.dto';
import { ModalMcEliminarComponent } from '../modals/modal-mc-eliminar/modal-mc-eliminar.component';

@Component({
  selector: 'app-novedadeslss',
  templateUrl: './novedadeslss.component.html',
  styleUrls: ['./novedadeslss.component.scss']
})
export class NovedadeslssComponent {

  uuid: string;
  listadoArticulos: ArticuloDTO[] = []
  page: number = 1;
  pageSize: number = 10;
  totalRegister: number = 0;
  fechaActual: Date = new Date();
  mesSeleccionado: number = this.fechaActual.getMonth() + 1;
  anioSeleccionado: number = this.fechaActual.getFullYear();
  meses = [
    { nombre: 'Enero', valor: 1 },
    { nombre: 'Febrero', valor: 2 },
    { nombre: 'Marzo', valor: 3 },
    { nombre: 'Abril', valor: 4 },
    { nombre: 'Mayo', valor: 5 },
    { nombre: 'Junio', valor: 6 },
    { nombre: 'Julio', valor: 7 },
    { nombre: 'Agosto', valor: 8 },
    { nombre: 'Septiembre', valor: 9 },
    { nombre: 'Octubre', valor: 10 },
    { nombre: 'Noviembre', valor: 11 },
    { nombre: 'Diciembre', valor: 12 },
  ];
  anios: number[] = [
    this.fechaActual.getFullYear(),
    this.fechaActual.getFullYear() + 1,
    this.fechaActual.getFullYear() + 2,
  ];

  imagenPortada: any;
  portadaUrl: string;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private actividadService: ActividadService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.uuid = params.uuid;
      this.getListadoArticulos();
      this.getPortada();
    });
  }

  getListadoArticulos() {
    this.apiGestion.getObtenerArticulosFiltro(this.uuid, this.page -1, this.pageSize, this.anioSeleccionado, this.mesSeleccionado).subscribe((resp) => {
      this.listadoArticulos = resp.data.content;
    },
      (error) => {
      this.toastr.error('Error obtener listado articulos')
    })
  }

  getPortada() {
    this.apiGestion.getImagenPortada(this.uuid).subscribe((resp) => {
      this.imagenPortada = resp.data;
      this.portadaUrl = this.imagenPortada.url.url;
      console.log(this.portadaUrl);
    }, (error) => {
      console.log(error);
    })
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const formData = new FormData();
      formData.append('imagen', file);
      this.apiGestion.putSubirImagenPortada(this.uuid, formData).subscribe((resp) => {
        this.toastr.success('Imagen subida correctamente');
        this.getPortada();
      }, (error) => {
        this.toastr.error('Error al subir portada.');
      })
    }
  }

    
  openModalMcEliminar(articulo: ArticuloDTO) {
    const modalEliminar: BsModalRef = this.modalService.show(ModalMcEliminarComponent, {
      ignoreBackdropClick: false,
      keyboard: false,
      initialState: {
        uuid: articulo.uuidArticulo,
        nombre: articulo.titulo,
        tipo: 'articulo'
      },
      class: "modal-dialog-centered",
    });
    this.cerrarModalCargaData(modalEliminar);
  }

  cerrarModalCargaData(modal: BsModalRef) {
    modal.content.onClose.subscribe(myData => {
      this.getListadoArticulos();
      modal.hide();
      this.cdr.detectChanges();
    });
  }
}