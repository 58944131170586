import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { UserService } from 'src/app/services/user.service';
import { Base64Util } from 'src/app/util/base64.util';
import { ActividadDTO } from 'src/dtos/gestion/actividad.dto';
import { ComentarioGestionDTO } from 'src/dtos/gestion/comentarioGestion.dto';
import { UsuarioDTO } from 'src/dtos/mantenedor/usuario.dto';
import { GetComentariosGestionResponseDTO } from 'src/dtos/response/gestion/getComentariosGestion.response.dto';
import { PostEnviarComentarioGestionResponseDTO } from 'src/dtos/response/gestion/postEnviarComentarioGestion.response.dto';
import { UsuarioModel } from 'src/models/usuario.model';

@Component({
  selector: 'app-modal-gestion-comentarios',
  templateUrl: './modal-gestion-comentarios.component.html',
  styleUrls: ['./modal-gestion-comentarios.component.scss']
})
export class ModalGestionComentariosComponent {

  responderForm: FormGroup;
  usuario: UsuarioModel;
  blobFile: Blob[] = [];
  actividad: ActividadDTO;
  loader: boolean = false;
  listadoUsuarios: UsuarioDTO[] = [];
  infoComentarios: ComentarioGestionDTO;


  constructor(
    public bsModalRef: BsModalRef,
    private apiGestion: ApiGestionService,
    private userService: UserService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit(): void {
    this.usuario = this.userService.getUser();
    this.responderForm = this.formBuilder.group({
      comentario: new FormControl('', [Validators.required]),
      file: new FormControl(null),
      filesSource: new FormArray([])
    });
    this.loader = true;
    this.getComentarios();
  }

  addAdjunto(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {
        this.blobFile.push(Base64Util.base64toBlob(fileReader.result));
      };

      fileReader.onloadend = async () => {
        file['url'] = fileReader.result.toString();
      }

      fileReader.onerror = async (error) => {
        this.toastr.error(AppMessage.ERROR_CARGAR_ARCHIVO, 'Error');
      };
      (this.responderForm.get('filesSource') as FormArray).push(new FormControl(file));
      this.responderForm.get('file').patchValue(null);
    }
  }

  removeAdjunto(i: number) {
    (this.responderForm.get('filesSource') as FormArray).removeAt(i);
    this.blobFile.splice(i, 1);
  }

  getAdjuntos() {
    return (this.responderForm.get('filesSource') as FormArray).controls;
  }

  obtenerUrl(nombreArchivo: string) {
    let urlImage;
    switch (true) {
      case nombreArchivo.includes('.pdf'):
        urlImage = '../assets/img/extension-archivo/k-pdf.png';
        break;
      case nombreArchivo.includes('.doc'):
        urlImage = '../assets/img/extension-archivo/k-doc.png';
        break;
      case nombreArchivo.includes('.xls'):
        urlImage = '../assets/img/extension-archivo/k-xls.png';
        break;
      case nombreArchivo.includes('.ppt'):
        urlImage = '../assets/img/extension-archivo/k-ppt.png';
        break;
      default:
        urlImage = '../assets/img/extension-archivo/k-default.png';
        break;
    }
    return urlImage;
  }

  getComentarios() {
    this.apiGestion.getComentarios(this.actividad.uuid).subscribe((resp) => this.getComentariosSuccess(resp),
      (error) => this.getComentariosError(error));
  }

  getComentariosSuccess(resp: GetComentariosGestionResponseDTO) {
    this.infoComentarios = resp.data;
    this.infoComentarios?.comentario.forEach(usuario => {
      this.listadoUsuarios.push(usuario.usuario);
    });
    
    this.listadoUsuarios = [...new Map(this.listadoUsuarios.map(item => {
      return [item.nombre, item]
    })).values()];

    this.loader = false;
  }

  getComentariosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_COMENTARIOS;
    this.toastr.error(msg);
    this.loader = false;
  }

  enviarComentario() {
    const comentario = this.responderForm.value;
    let formData = new FormData();
    formData.append('mensaje', comentario.comentario);
    formData.append('uuidGestionActividad', this.actividad.uuid);

    const archivos = (this.responderForm.get('filesSource') as FormArray).value;
    this.blobFile.forEach((file, index) => {
      formData.append('archivos[' + index + ']', file, archivos[index].name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    });

    this.apiGestion.postGuardarComentario(formData).subscribe((resp) => this.enviarComentarioSuccess(resp),
      (error) => this.enviarComentarioError(error));
  }

  enviarComentarioSuccess(resp: PostEnviarComentarioGestionResponseDTO) {
    this.cleanComentario();
    this.loader = false;
    this.getComentarios();
  }

  enviarComentarioError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_RESPONDER_COMENTARIO;
    this.toastr.error(msg);
  }

  cleanComentario() {
    this.blobFile = [];
    (this.responderForm.get('filesSource') as FormArray).clear();
    this.responderForm.patchValue({
      comentario: null,
      file: null
    });
  }

  getDescargarAdjuntoComentario(url: string) {
    window.open(url, '_self');
  }

  getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  }
  
 }
