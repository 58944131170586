import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  present() {
    console.log('present loading modal');
  }

  dismiss() {
    console.log('dismiss loading modal');
  }
}
