import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PostRegistrarPreferenciasRequestDTO } from 'src/dtos/request/documental/postRegistrarPreferencias.request.dto';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';
import { AppMessage } from '../app.message';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { ApiMantenedorService } from '../services/api-mantenedor.service';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoAreas.response.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UsuarioModel } from 'src/models/usuario.model';
import { ModuloDTO } from 'src/dtos/documental/modulo.dto';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  listadoArea: AreaDTO[] = [];
  listadoSubArea: SubareaDTO[] = [];
  listadoSitio: SitiosDTO[] = [];
  listadoModulos: ModuloDTO[] = [];
  preferencias: UsuarioModel;

  preferenceForm: FormGroup;

  loaderAreas: boolean = false;
  loaderSubareas: boolean = false;
  loaderSitios: boolean = false;
  loaderModulos: boolean = false;

  titulo: string = '';
  subTitulo: string = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private apiServiceMantenedor: ApiMantenedorService,
  ) { }

  ngOnInit(): void {
    this.preferencias = this.userService.getUser();
    console.log(this.preferencias);

    this.titulo = '<strong>' + this.preferencias.nombre + ' ' + this.preferencias.apellido + '</strong><br>' + this.preferencias.email;
    this.subTitulo = this.preferencias.preferencias?.area ? this.preferencias.preferencias?.area.nombre : '';
    this.subTitulo += this.preferencias.preferencias?.subArea ? ' - ' + this.preferencias.preferencias?.subArea.nombre : '';
    this.subTitulo += this.preferencias.preferencias?.sitio ? ' - ' + this.preferencias.preferencias?.sitio.nombre : '';

    this.getListadoAreas();
    this.getListadoModulos();

    this.preferenceForm = this.formBuilder.group({
      area: new FormControl(this.preferencias.preferencias ? this.preferencias.preferencias.area?.uuid : null, [Validators.required]),
      subarea: new FormControl(null),
      sitio: new FormControl(null),
      modulos: new FormControl(this.preferencias.preferencias?.modulos ? this.preferencias.preferencias.modulos?.map(m => m.uuid) : null),
    });
  }

  getListadoAreas() {
    this.loaderAreas = true;
    this.apiServiceMantenedor.getListadoAreasMantenedor().subscribe(
      (resp) => this.getListadoAreasSuccess(resp),
      error => this.getListadoAreasError(error)
    );
  }

  getListadoAreasSuccess(response: GetListadoAreasResponseDTO) {
    this.listadoArea = response.data;
    this.loaderAreas = false;
    this.getListadoSubAreas();
  }

  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
    this.loaderAreas = false;
    this.toastr.error(msg);
  }

  getListadoSubAreas(change: boolean = false) {
    if (this.preferenceForm.controls.area.value) {
      if (change) {
        this.preferenceForm.patchValue({ subarea: null, sitio: null });
      }
      this.loaderSubareas = true;
      this.apiServiceMantenedor.getListadoSubAreasMantenedor(this.preferenceForm.controls.area.value).subscribe(
        (resp) => this.getListadoSubAreasSuccess(resp, change),
        error => this.getListadoSubAreasError(error)
      );
    } else {
      this.loaderSubareas = false;
    }
  }

  getListadoSubAreasSuccess(response: GetListadoSubAreasResponseDTO, change: boolean) {
    this.listadoSubArea = response.data;
    this.loaderSubareas = false;
    this.preferenceForm.patchValue({ subarea: change ? null : this.preferencias.preferencias.subArea?.uuid });
    this.getListadoSitios();
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
    this.toastr.error(msg);
    this.loaderSubareas = false;
  }

  getListadoSitios(change: boolean = false) {
    if (this.preferenceForm.controls.subarea.value) {
      if (change) {
        this.preferenceForm.patchValue({ sitio: null });
      }
      this.loaderSitios = true;
      this.apiServiceMantenedor.getListadoSitiosMantenedor(this.preferenceForm.controls.subarea.value).subscribe(
        (resp) => this.getListadoSitiosSuccess(resp, change),
        error => this.getListadoSitiosError(error)
      );
    } else {
      this.loaderSitios = false;
    }
  }

  getListadoSitiosSuccess(response: GetListadoSitiosResponseDTO, change: boolean) {
    this.listadoSitio = response.data;
    this.loaderSitios = false;
    this.preferenceForm.patchValue({ sitio: change ? null : this.preferencias.preferencias.sitio?.uuid });

  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
    this.toastr.error(msg);
    this.loaderSitios = false;
  }

  getListadoModulos() {
    this.loaderModulos = true;
    this.apiService.getObtenerModulos().subscribe((resp) => {
      this.listadoModulos = resp.data;
      console.log(this.listadoModulos);
      this.loaderModulos = false;
    },
      (error) => {
        console.log(error);
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_MODULOS;
        this.toastr.error(msg);
        this.loaderModulos = false;
    })
  }


  savePreference() {
    if (this.preferenceForm.valid) {
      const data: PostRegistrarPreferenciasRequestDTO = {
        uuid_area: this.preferenceForm.controls.area.value,
        uuid_subarea: this.preferenceForm.controls.subarea.value,
        uuid_sitio: this.preferenceForm.controls.sitio.value,
        modulos: this.preferenceForm.controls.modulos.value
      };
      this.apiService.postRegistrarPreferencias(data, this.userService.getUser().uuid).subscribe(
        (resp) => this.postRegistrarPreferenciasSuccess(resp),
        error => this.postRegistrarPreferenciasError(error)
      );
    }
  }

  postRegistrarPreferenciasSuccess(response: GeneralResponseDTO) {
    this.userService.setUserPreferencias(response.data);
    this.toastr.success(AppMessage.SUCCESS_REGISTRAR_PREFERENCIAS);
    this.router.navigate(['web/home']);
  }

  postRegistrarPreferenciasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_REGISTRAR_PREFERENCIAS;
    this.toastr.error(msg);
  }

  cerrarSesion() {
    this.apiService.postLogout().subscribe(
      (res) => {
        this.userService.cleanSesion();
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' + environment.urlRedirectCerrarSesion;
      },
      (error: HttpErrorResponse) => {
        this.userService.cleanSesion();
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=' + environment.urlRedirectCerrarSesion;
      }
    );
  }
}
