<div class="modal-content-small">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
                Adjuntos Archivo 125
        </h5>
        <button type="button" class="close-modal" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="pictures vertical-scroll">
            <div *ngFor="let adjunto of detalleImplementacion.adjunto" (click)="seleccionarImagen(adjunto.url)" class="images"><img [src]="adjunto.url" width="100%" alt="foto" /></div>
            <!--<div class="images"><img src="assets/img/imagen.jpg" width="100%" alt="foto" /></div>-->
        </div>
        <div class="picture-big"><img [src]="imagenSeleccionada" width="100%" alt="foto" /></div>
    </div>
</div>