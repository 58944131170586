<!-- Modal editar -->
<div class="modal-content">
    <div class="loader-container-modal" *ngIf="loader">
        <div class="lds-ring-modal">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="modal-header" *ngIf="editForm && !loader">
        <h5 class=" modal-title" id="exampleModalLabel">Editar documento</h5>
        <button type="button" class="close-modal" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="editForm" *ngIf="editForm && !loader">
        <div class=" modal-body">
            <div class="field-three">
                <div class="lab"><label for="name">Tipo de documento</label></div>
                <div class="inp" *ngIf="!loaderTipoDocumento">
                    <ng-select class="select" formControlName="tipoDocumento" required placeholder="Seleccione"
                        (change)="obtenerVigencia(); getListadoDocumentos(true);"
                        [ngClass]="{
                            'border-error': editForm.controls['tipoDocumento'].touched && !editForm.controls['tipoDocumento'].valid}">
                        <ng-option *ngFor="let tipoDoc of listaSimpleTipoDocumento" [value]="tipoDoc.uuid">
                            {{tipoDoc.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderTipoDocumento">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">ID</label></div>
                <div class="inp">
                    <input type="text" name="documental-id" id="documental-id" class="inp-modal" placeholder="000"
                        formControlName="id" required (focusout)="getValidarIdInterno()"
                        [ngClass]="{'border-error': (editForm.controls['id'].touched && !editForm.controls['id'].valid) || !idValido}" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Adjuntar archivo</label></div>
                <div class="inp">
                    <input [type]="btnClean ? 'text' : 'file'" name="documental-adjuntararchivo" [readonly]="btnClean"
                        id="documental-adjuntararchivo" class="inp-modal" placeholder="Adjunte archivo"
                        formControlName="file" required (change)="selectArchivo($event)"
                        accept="image/*,.pdf,application/msword,.docx,application/vnd.ms-excel,.xlsx,application/vnd.ms-powerpoint,.pptx"
                        [ngClass]="{'border-error': editForm.controls['file'].touched && !editForm.controls['file'].valid}" />
                    <i *ngIf="!btnClean" class="fas fa-paperclip ico-input" aria-hidden="true"></i>
                    <i *ngIf="btnClean" class="fas fa-times ico-input" (click)="clearInput()" aria-hidden="true"></i>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" name="documental-nombre" id="documental-nombre" class="inp-modal"
                        placeholder="Nombre" formControlName="nombre" required
                        [ngClass]="{'border-error': editForm.controls['nombre'].touched && !editForm.controls['nombre'].valid}" />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Versión</label></div>
                <div class="inp">
                    <input type="text" name="documental-version" id="documental-version" class="inp-modal"
                        formControlName="version" disabled />
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Macroporceso</label></div>
                <div class="inp" *ngIf="!loaderMacroprocesos">
                    <ng-select class="select" formControlName="macroproceso" required placeholder="Seleccione"
                        (change)="getListadoProcesos(true)"
                        [ngClass]="{'border-error': editForm.controls['macroproceso'].touched && !editForm.controls['macroproceso'].valid}">
                        <ng-option *ngFor="let macro of listaSimpleMacroprocesos" [value]="macro.uuid">
                            {{macro.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderMacroprocesos">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Proceso</label></div>
                <div class="inp" *ngIf="!loaderProcesos">
                    <ng-select class="select" formControlName="proceso" required placeholder="Seleccione"
                        (change)="getListadoAreas(true)"
                        [ngClass]="{'border-error': editForm.controls['proceso'].touched && !editForm.controls['proceso'].valid}">
                        <ng-option *ngFor="let proceso of listaSimpleProcesos" [value]="proceso.uuid">
                            {{proceso.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderProcesos">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Área</label></div>
                <div class="inp" *ngIf="!loaderArea">
                    <ng-select class="select" formControlName="area" required placeholder="Seleccione"
                        (change)="getListadoSubAreas(true)"
                        [ngClass]="{'border-error': editForm.controls['area'].touched && !editForm.controls['area'].valid}">
                        <ng-option *ngFor="let area of listaSimpleArea" [value]="area.uuid">
                            {{area.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderArea">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Subárea</label></div>
                <div class="inp" *ngIf="!loaderSubArea">
                    <ng-select class="select" formControlName="subarea" required placeholder="Seleccione"
                        [ngClass]="{'border-error': editForm.controls['subarea'].touched && !editForm.controls['subarea'].valid}">
                        <ng-option *ngFor="let sub of listaSimpleSubArea" [value]="sub.uuid">
                            {{sub.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSubArea">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de documento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaDocumento" [bsConfig]="datepickerConfig" disabled required>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha inicio vigencia</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaInicio" [bsConfig]="datepickerConfig" required
                        (onHidden)="obtenerVigencia()"
                        [ngClass]="{'border-error': editForm.controls['fechaInicio'].touched && !editForm.controls['fechaInicio'].valid}">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Fecha de vencimiento</label></div>
                <div class="inp">
                    <input type="text" placeholder="Seleccione fecha" class="inp-modal" bsDatepicker
                        formControlName="fechaVencimiento" [bsConfig]="datepickerConfigVencimiento" required
                        [ngClass]="{'border-error': editForm.controls['fechaVencimiento'].touched && !editForm.controls['fechaVencimiento'].valid}">
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Vincular a otros documentos</label></div>
                <div class="inp" *ngIf="!loaderDocumentosVicunlados">
                    <ng-select class="select" formControlName="documentoVinculados" placeholder="Seleccione"
                        [multiple]="true">
                        <ng-option *ngFor="let dv of listaSimpleDocumentosVicunlados" [value]="dv.uuid">
                            {{dv.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderDocumentosVicunlados">
                    <input class="inp-modal" type="text" placeholder="Seleccione" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field-three">
                <div class="lab"><label for="name">Estado</label></div>
                <div class="inp">
                    <input type="text" name="documental-estado" id="documental-estado" class="inp-modal" disabled
                        formControlName="estado" required />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="editarDocumento()">Editar documento</button>
        </div>
    </form>
</div>