<form [formGroup]="filterForm" *ngIf="filterForm">
    <button class="btn-filter close-filter" (click)="cerrarFiltros()"><i class="fas fa-times"
            aria-hidden="true"></i></button>
    <div class="field-half">
        <div class="lab"><label >Tipo de metodología</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="tipo-metodologia" id="tipo-metodologia" placeholder="Seleccione" formControlName="metodologia"
                [ngClass]="{'border-error': filterForm.controls['metodologia'].touched && 
                !filterForm.controls['metodologia'].valid}">
                    <ng-option *ngFor="let metodologia of listadoMetodologias" [value]="metodologia">{{metodologia}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="field-half">
        <div class="lab"><label >Área</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="area" id="area" placeholder="Seleccione" formControlName="uuidArea"
                (change)="getListadoSubAreas()">
                    <ng-option *ngFor="let area of listadoAreas" [value]="area">{{area.nombre}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="field-half">
        <div class="lab"><label >Estado</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="estado" id="estado" placeholder="Seleccione" formControlName="uuidEstado">
                    <ng-option *ngFor="let estado of listadoEstados" [value]="estado.uuid">{{estado.nombre}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="field-half">
        <div class="lab"><label >Subárea</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="subarea" id="subarea" placeholder="Seleccione" formControlName="uuidSubArea"
                (change)="getListadoSitios()">
                    <ng-option *ngFor="let subarea of listadoSubAreas" [value]="subarea.uuid">{{subarea.nombre}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="field-half">
        <div class="lab"><label >Periodo fiscal</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="periodo-fiscal" id="periodo-fiscal" placeholder="Seleccione" formControlName="uuidAnioFiscal"
                [ngClass]="{'border-error': filterForm.controls['uuidAnioFiscal'].touched && 
                !filterForm.controls['uuidAnioFiscal'].valid}">
                    <ng-option *ngFor="let anio of listadoAniosFiscal" [value]="anio.uuid">{{anio.nombre}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="field-half">
        <div class="lab"><label >Sitio</label></div>
        <div class="inp">
            <label class="select">
                <ng-select name="sitio" id="sitio" placeholder="Seleccione" formControlName="uuidSitio">
                    <ng-option *ngFor="let sitio of listadoSitios" [value]="sitio.nombre">{{sitio.nombre}}</ng-option>
                </ng-select>
            </label> 
        </div>
    </div>
    <div class="filter-box-btn">
        <button class="btn btn-table btn-filter1" (click)="filtrar()">Filtrar</button>
    </div>
</form>