import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppMessage } from 'src/app/app.message';
import { ApiGestionService } from 'src/app/services/api-gestion.service';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { AnioFiscalDTO } from 'src/dtos/gestion/anioFiscal.dto';
import { EstadoActividadDTO } from 'src/dtos/gestion/estadoActividad.dto';
import { AreaDTO } from 'src/dtos/mantenedor/area.dto';
import { EstadoDTO } from 'src/dtos/mantenedor/estado.dto';
import { SitiosDTO } from 'src/dtos/mantenedor/sitios.dto';
import { SubareaDTO } from 'src/dtos/mantenedor/subarea.dto';
import { GetListadoAreasResponseDTO } from 'src/dtos/response/documental/getListadoAreas.response.dto';
import { GetListadoEstadosResponseDTO } from 'src/dtos/response/mantenedor/getListadoEstados.response.dto';
import { GetListadoSitiosResponseDTO } from 'src/dtos/response/mantenedor/getListadoSitios.response.dto';
import { GetListadoSubAreasResponseDTO } from 'src/dtos/response/mantenedor/getListadoSubAreas.response.dto';

@Component({
  selector: 'app-filtrar-por-metodologia',
  templateUrl: './filtrar-por-metodologia.component.html',
  styleUrls: ['./filtrar-por-metodologia.component.scss']
})
export class FiltrarPorMetodologiaComponent implements OnInit {

  filterForm: FormGroup;
  listadoAreas: AreaDTO[] = [];
  listadoSubAreas: SubareaDTO[] = [];
  listadoSitios: SitiosDTO[] = [];
  listadoEstados: EstadoDTO[] = [];
  listadoAniosFiscal: AnioFiscalDTO[] = [];
  viewFilter: boolean = false;
  listadoMetodologias = ['5S', '7D', 'LSS'];



  @Output() cerrar: EventEmitter<boolean> = new EventEmitter();
  @Output() filterRequest: EventEmitter<any> = new EventEmitter();
  @Input() filtrosForm: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiMantenedor: ApiMantenedorService,
    private apiGestion: ApiGestionService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initFilters();
  }

  ngOnChanges() {
    this.getEstados();
    this.getListadoAreas();
    this.getListadoAniosFiscal();
  }

  initFilters() {
    console.log(this.filtrosForm);
    this.filterForm = this.formBuilder.group({
      metodologia: new FormControl(null, Validators.required),
      uuidAnioFiscal: new FormControl(this.filtrosForm.uuidAnioFiscal, Validators.required),
      uuidArea: new FormControl(null),
      uuidEstado: new FormControl(null),
      uuidSitio: new FormControl(null),
      uuidSubArea: new FormControl(null)
    });
  }

  getEstados() {
    this.apiGestion.getListadoDeEstados().subscribe((resp) => this.getEstadosSuccess(resp),
      (error) => this.getEstadosError(error));
  }

  getEstadosSuccess(resp: GetListadoEstadosResponseDTO) {
    this.listadoEstados = resp.data;
    this.filterForm.patchValue({ uuidEstado: this.filtrosForm.uuidEstado });
  }

  getEstadosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_OBTENER_ESTADOS;
        this.toastr.error(msg);
  }

  getListadoAreas() {
    this.listadoAreas = [];
    this.apiMantenedor.getListadoAreasMantenedor().subscribe((resp) => this.getListadoAreasSuccess(resp),
      (error) => this.getListadoAreasError(error));
  }

  getListadoAreasSuccess(resp: GetListadoAreasResponseDTO) {
    this.listadoAreas = resp.data;
    this.getListadoSubAreas();
  }

  getListadoAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_AREAS;
        this.toastr.error(msg);
  }

  getListadoSubAreas() {
    this.filterForm.patchValue({ uuidSubArea: null, uuidSitio: null });
    this.listadoSubAreas = [];
    this.apiMantenedor.getListadoSubAreasMantenedor(this.filterForm.controls.uuidArea?.value?.uuid).subscribe((resp) => this.getListadoSubAreasSuccess(resp),
      (error) => this.getListadoSubAreasError(error));
  }

  getListadoSubAreasSuccess(resp: GetListadoSubAreasResponseDTO) {
    this.listadoSubAreas = resp.data;
    this.getListadoSitios();
  }

  getListadoSubAreasError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SUB_AREAS;
        this.toastr.error(msg);
  }

  getListadoSitios() {
    this.filterForm.patchValue({ uuidSitio: null });
    this.listadoSitios = []
    this.apiMantenedor.getListadoSitiosMantenedor(this.filterForm.controls.uuidSubArea.value).subscribe((resp) => this.getListadoSitiosSuccess(resp),
      (error) => this.getListadoSitiosError(error));
  }

  getListadoSitiosSuccess(resp: GetListadoSitiosResponseDTO) {
    this.listadoSitios = resp.data;
  }

  getListadoSitiosError(error: HttpErrorResponse) {
    const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_LISTADO_SITIOS;
        this.toastr.error(msg);
  }

  getListadoAniosFiscal() {
    this.apiGestion.getObtenerAniosFiscales().subscribe((resp) => {
      this.listadoAniosFiscal = resp.data;
      for (const iterator of this.listadoAniosFiscal) {
        iterator.fechaInicioDate = new Date(iterator.fechaInicioAnioFiscal);
        iterator.fechaTerminoDate = new Date(iterator.fechaTerminoAnioFiscal);
      }
    }, (error) => {
      this.toastr.error('Error al obtener listado periodos fiscales.');
    })

  }


  filtrar() {
    if (this.filterForm.valid) {
      let aux: any = {};
      const form = this.filterForm.value;
  
      aux = {
        titulo: 'Metodologia ' + form.metodologia + (form.uuidArea?.nombre ? ' - ' + form.uuidArea.nombre : ''),
        metodologia: form.metodologia,
        uuidAnioFiscal: form.uuidAnioFiscal,
        uuidArea: form.uuidArea?.uuid,
        uuidEstado: form.uuidEstado,
        uuidSitio: form.uuidSitio,
        uuidSubArea: form.uuidSubArea
      }
      console.log('FILTROS FORM: ',this.filtrosForm);
      this.filterRequest.emit(aux);
    }
    else {
      this.validateAllFormFields(this.filterForm);
    }
  }

  cerrarFiltros() {
    this.cerrar.emit(false);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);     
      if (control instanceof FormControl) {            
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
