import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AppMessage } from 'src/app/app.message';
import { ApiMantenedorService } from 'src/app/services/api-mantenedor.service';
import { GeneralResponseDTO } from 'src/dtos/response/general.response.dto';

@Component({
  selector: 'app-modal-j-habilitar',
  templateUrl: './modal-j-habilitar.component.html',
  styleUrls: ['./modal-j-habilitar.component.scss']
})
export class ModalJHabilitarComponent {
  titleModal: string;
  nombreTipo: string;
  uuid: string;

  onClose: Subject<string>;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private apiServiceMantenedor: ApiMantenedorService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  habilitarRegistro() {
    switch (this.titleModal) {
      case 'Macroproceso':
        this.habilitarMacroproceso();
        break;
      case 'Proceso':
        this.habilitarProceso();
        break;
      case 'Área':
        this.habilitarArea();
        break;
      case 'Subárea':
        this.habilitarSubarea();
        break;
      case 'Sitio':
        this.habilitarSitio();
        break;
      default:
        break;
    }
  }

  habilitarMacroproceso() {
    this.apiServiceMantenedor.putHabilitarMacroprocesoMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_HABILITAR_MACROPROCESOS);
        this.onClose.next('0');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_MACROPROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  habilitarProceso() {
    this.apiServiceMantenedor.putHabilitarProcesoMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_HABILITAR_PROCESOS);
        this.onClose.next('1');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_PROCESOS;
        this.toastr.error(msg);
      }
    );
  }

  habilitarArea() {
    this.apiServiceMantenedor.putHabilitarAreaMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_HABILITAR_AREAS);
        this.onClose.next('2');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_AREAS;
        this.toastr.error(msg);
      }
    );
  }

  habilitarSubarea() {
    this.apiServiceMantenedor.putHabilitarSubreaMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_HABILITAR_SUBAREAS);
        this.onClose.next('3');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_SUBAREAS;
        this.toastr.error(msg);
      }
    );
  }

  habilitarSitio() {
    this.apiServiceMantenedor.putHabilitarSitioMantenedor(this.uuid).subscribe(
      (response: GeneralResponseDTO) => {
        this.toastr.success(AppMessage.SUCCESS_HABILITAR_SITIOS);
        this.onClose.next('4');
      },
      (error: HttpErrorResponse) => {
        const msg = error.error && error.error.msg ? error.error.msg : AppMessage.ERROR_HABILITAR_SITIOS;
        this.toastr.error(msg);
      }
    );
  }
}
