<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Crear tipo de documento</h5>
        <button type="button" class="close-modal" (click)="bsModalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="crearFormTipoDocumento" *ngIf="crearFormTipoDocumento">
        <div class="modal-body">
            <div class="field-half">
                <div class="lab"><label for="name">Nombre</label></div>
                <div class="inp">
                    <input type="text" formControlName="nombre" class="inp-modal" placeholder="Nombre" required
                        [ngClass]="{'border-error': crearFormTipoDocumento.controls['nombre'].touched && !crearFormTipoDocumento.controls['nombre'].valid}" />
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Días previos</label></div>
                <div class="inp">
                    <input type="number" formControlName="diasPrevios" class="inp-modal" placeholder="Días previos"
                        required
                        [ngClass]="{'border-error': crearFormTipoDocumento.controls['diasPrevios'].touched && !crearFormTipoDocumento.controls['diasPrevios'].valid}" />
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Periodo de vigencia</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="periodoVigencia" required placeholder="Seleccione"
                        [ngClass]="{'border-error': crearFormTipoDocumento.controls['periodoVigencia'].touched && !crearFormTipoDocumento.controls['periodoVigencia'].valid}">
                        <ng-option *ngFor="let vigencia of listaPeriodoVigencia" [value]="vigencia.uuid">
                            {{vigencia.periodoVigencia}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="field-half">
                <div class="lab"><label for="name">Tipos de documentos vinculados</label></div>
                <div class="inp">
                    <ng-select class="select" formControlName="tiposDocumentos" placeholder="Seleccione"
                        [multiple]="true">
                        <ng-option *ngFor="let tipo of listaTipoDocumentos" [value]="tipo.uuid">
                            {{tipo.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-modal" (click)="crearTipo()"
                [disabled]="this.crearFormTipoDocumento.invalid">
                Crear tipo documento</button>
        </div>
    </form>
</div>