<div class="loader-container" *ngIf="loaderDocumental || loaderReclamo || loaderGestion">
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<div class="container-fluid">
    <div class="header">
        <div class="logo-top">
            <div class="logo-top1">
                <img src="assets/img/komatsu-cummins.svg" width="180px" height="29px" alt="Komatsu" />
            </div>
        </div>
        <div class="notifications-profile">
            <app-notification-profile></app-notification-profile>
        </div>
        <div class="title">
            <i class="fa fa-home ico-page" aria-hidden="true"></i>
            <span class="title1">¡Hola {{usuario.nombre + ' ' + usuario.apellido}}!</span>
        </div>
        <div [ngClass]="{'notifications-home': listadoNotificaciones.length < 10, 'notifications-home-alert': listadoNotificaciones.length >= 10}">
            <i class="fas fa-bell bell1" aria-hidden="true"></i><i class="fas" [ngClass]="{'fas fa-circle dot-red': listadoNotificaciones.length > 0}" aria-hidden="true"></i>
            <div class="alert">
                <div class="alert-number1">{{listadoNotificaciones.length}}</div>
                <div class="alert-name1">Notificaciones</div>
            </div>
        </div>
    </div>
    <form class="filter-home" [formGroup]="filtrosHomeForm" *ngIf="filtrosHomeForm">
        <div class="title2">Filtrar</div>
        <div class="section blue-strongh">
            <div class="field-two line-vertical">
                <div class="lab white">
                    <label for="name">Periodo de fechas</label>
                    <i class="far fa-calendar-alt ico-input2" aria-hidden="true"></i>
                </div>
                <div class="inp">
                    <input type="text" formControlName="periodo" placeholder="Seleccione Periodo" class="inp1"
                        bsDaterangepicker [bsConfig]="configbsConfig">
                </div>
            </div>
            <div class="field">
                <div class="lab white"><label for="name">Área</label></div>
                <div class="inp" *ngIf="!loaderAreas">
                    <ng-select class="select-nborder" formControlName="area" placeholder="Seleccione Área"
                        (change)="getListadoSubAreas(true)">
                        <ng-option *ngFor="let area of listadoArea" [value]="area.uuid">{{area.nombre}}</ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderAreas">
                    <input class="inp-modal" type="text" placeholder="Seleccione Área" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="lab white"><label for="name">Subárea</label></div>
                <div class="inp" *ngIf="!loaderSubareas">
                    <ng-select class="select-nborder" formControlName="subarea" placeholder="Seleccione Subárea"
                        (change)="getListadoSitios(true)">
                        <ng-option *ngFor="let sub of listadoSubArea" [value]="sub.uuid">{{sub.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSubareas">
                    <input class="inp-modal" type="text" placeholder="Seleccione Subárea" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="lab white"><label for="name">Sitio</label></div>
                <div class="inp" *ngIf="!loaderSitios">
                    <ng-select class="select-nborder" formControlName="sitio" placeholder="Seleccione Sitio">
                        <ng-option *ngFor="let sitio of listadoSitio" [value]="sitio.uuid">{{sitio.nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="inp" *ngIf="loaderSitios">
                    <input class="inp-modal" type="text" placeholder="Seleccione Sitio" />
                    <div class="icon-container">
                        <i class="loader" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="section1">
            <button class="btn-primary" (click)="filtrar()">Filtrar</button>
        </div>
    </form>
    <div class="graph-home">
        <div class="graph-title">Periodo entre:
            <strong>
                {{rango[0] | date: 'dd/MM/yyyy'}} -
                {{rango[1] | date: 'dd/MM/yyyy'}}
            </strong>
        </div>
        <div class="containers">
            <div class="container shadow">
                <div class="title-chart">
                    <i class="fas fa-file-alt ico-chart" aria-hidden="true"></i>
                    <span class="title1">Documental</span>
                </div>
                <div class="data-chart">
                    <div class="data-chart-status">
                        <div class="total">Cantidad Documentos: {{porcentajes?.totalDocumentos}}</div>
                        <div class="detail-chart">
                            <i class="fas fa-check-square ico-doc-details green margin-ico" aria-hidden="true"></i>
                            Vigentes
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-clock ico-doc-details yellow margin-ico" aria-hidden="true"></i>
                            Por vencer
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-calendar-times ico-doc-details red margin-ico" aria-hidden="true"></i>
                            Vencidos
                        </div>
                    </div>
                    <div class="data-chart-graph">
                        <div id="chart-documental" style="width: 100%; height: 200px;"></div>
                    </div>
                </div>
            </div>
            <div class="container shadow">
                <div class="title-chart">
                    <i class="fas fa-exclamation ico-chart" aria-hidden="true"></i>
                    <span class="title1">Reclamos</span>
                </div>
                <div class="data-chart">
                    <div class="data-chart-status">
                        <div class="total">Total: {{porcentajesReclamo?.totalReclamos}}</div>
                        <div class="detail-chart">
                            <i class="fas fa-clock ico-doc-details green margin-ico" aria-hidden="true"></i>
                            Respondido
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-clock ico-doc-details yellow margin-ico" aria-hidden="true"></i>
                            Por responder
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-clock ico-doc-details purple margin-ico" aria-hidden="true"></i>
                            Cerrado
                        </div>
                    </div>
                    <div class="data-chart-graph">
                        <div id="chart-reclamo" style="width: 100%; height: 200px; background-color: #FFFFFF;"></div>
                    </div>
                </div>
            </div>
            <div class="container shadow">
                <div class="title-chart">
                    <i class="fas fa-thumbs-up ico-chart" aria-hidden="true"></i>
                    <span class="title1">Gestión de actividades</span>
                </div>
                <div class="data-chart">
                    <div class="data-chart-status">
                        <div class="total">Total: {{porcentajesGestion?.total}}</div>
                        <div class="detail-chart">
                            <i class="fas fa-check-square ico-doc-details green margin-ico" aria-hidden="true"></i>
                            Cumple
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-clock ico-doc-details yellow margin-ico" aria-hidden="true"></i>
                            Fuera de plazo
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-calendar-times ico-doc-details red margin-ico" aria-hidden="true"></i>
                            No cumple
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-clipboard-check ico-doc-details margin-ico" aria-hidden="true" style="color: #fe8948;"></i>
                            Revisar
                        </div>
                        <div class="detail-chart">
                            <i class="fas fa-calendar ga-icon-grey margin-ico" aria-hidden="true"></i>
                             Futuras
                        </div>
                        <div class="detail-chart">
                            <i class="far fa-check-square ga-icon-blue margin-ico" aria-hidden="true" style="color: #6979d4;"></i>
                             En curso
                        </div>
                    </div>
                    <div class="data-chart-graph">
                        <div id="chart-gestion-actividades"
                            style="width: 100%; height: 200px; background-color:transparent;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-height">&nbsp;</div>